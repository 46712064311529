/**
 * @module actions/common
 */
import {
  SET_BRANDING,
  API_URL,
  POSTAL_API,
  TOA_HANDLED,
  SET_SA_FILTER,
  GET_SA_FILTER,
  SET_RM_FILTER,
  GET_RM_FILTER,
  SET_RM_MATS_FILTER,
  GET_RM_MATS_FILTER,
  GET_USER_FILTERS,
  UPDATE_USER_FILTERS,
  GET_AGREEMENT_SCHEMA_SETTINGS,
  GET_HIDE_MODAL_SETTINGS,
  UPDATE_HIDE_MODAL_SETTINGS,
  IMAGE_HASH_REFRESH,
} from '../constants';
import axios from 'axios';
import { StringIsEmpty } from './../../utils/common';

/**
 * @description Sets the branding information
 * @return {Promise} Promise
 */
export function GetBranding(customerId) {
  return dispatch => {
    return axios.get(API_URL + '/branding/' + customerId).then(res => {
      let brand = JSON.stringify(res.data);
      const emptyBrand = StringIsEmpty(brand);

      if (!emptyBrand) {
        window.localStorage.setItem('user_branding', brand);
      }

      dispatch({
        type: SET_BRANDING,
        user_branding: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Get city by postal code
 * @return {Promise} Promise
 */
export function getCity(postalCode) {
  return dispatch => {
    return axios.get(POSTAL_API + `postnumre?nr=${postalCode}`);
  };
}

/**
 * @description Get terms of agreements
 * @return {Promise} Promise
 */
export function getTermsOfAgreement() {
  return dispatch => {
    let url = API_URL + '/policy/terms';
    return axios.get(url);
  };
}

/**
 * @description Get terms of agreements for users
 * @return {Promise} Promise
 */
export function getUserTermsOfAgreement() {
  return dispatch => {
    let url = API_URL + '/policy/terms/user';
    return axios.get(url);
  };
}

/**
 * @description Get terms of agreements for logged in user
 * @return {Promise} Promise
 */
export function getAcceptedUserTermsOfAgreement() {
  return dispatch => {
    let url = API_URL + '/policy/terms/user/accepted';
    return axios.get(url);
  };
}

/**
 * @description Add or update terms of agreement
 * @return {Promise} Promise
 */
export function addUpdateTermsOfAgreement(data) {
  return dispatch => {
    let url = API_URL + '/policy/terms';
    return axios.post(url, data);
  };
}

/**
 * @description Accept user terms of agreement
 * @return {Promise} Promise
 */
export function acceptTermsOfAgreement(data) {
  return dispatch => {
    let url = API_URL + '/policy/terms/user';
    return axios.post(url, data);
  };
}

/**
 * @description Recall accepted terms of agreements
 * @return {Promise} Promise
 */
export function recallAcceptedTermsOfAgreement(data) {
  return dispatch => {
    let url = API_URL + '/policy/terms/user/recall';
    return axios.post(url, data);
  };
}

/**
 * @description Handled user terms of agreement
 * @return {Promise} Promise
 */
export function handledTermsOfAgreement(value = true) {
  return dispatch => {
    dispatch({
      type: TOA_HANDLED,
      payload: value,
    });
  };
}

/**
 * @description Get personal data policy
 * @return {Promise} Promise
 */
export function getPersonalDataPolicy() {
  return dispatch => {
    let url = API_URL + '/policy/datapolicy';
    return axios.get(url);
  };
}

/**
 * @description Add or update personal data policy
 * @return {Promise} Promise
 */
export function addUpdatePersonalDataPolicy(data) {
  return dispatch => {
    let url = API_URL + '/policy/datapolicy';
    return axios.post(url, data);
  };
}

export function setFilterInLocalstorage(filterStorage) {
  return dispatch => {
    window.localStorage.setItem('filterStorage', JSON.stringify(filterStorage));
    dispatch({
      type: SET_SA_FILTER,
      payload: filterStorage,
    });
  };
}

export function getFilterFromLocalStorage() {
  return dispatch => {
    const filter = window.localStorage.getItem('filterStorage');
    if (!filter) return {};

    var parsed = JSON.parse(filter);
    dispatch({
      type: GET_SA_FILTER,
      payload: parsed,
    });

    return {
      ...parsed,
      filterStartDate: parsed.filterStartDate
        ? new Date(parsed.filterStartDate)
        : null,
      filterEndDate: parsed.filterEndDate
        ? new Date(parsed.filterEndDate)
        : null,
    };
  };
}

export function setRoadmapFilterInLocalstorage(filterStorage) {
  return dispatch => {
    //console.log('set roadmap filter');
    window.localStorage.setItem(
      'roadmapFilterStorage',
      JSON.stringify(filterStorage),
    );
    dispatch({
      type: SET_RM_FILTER,
      payload: filterStorage,
    });
  };
}

export function getRoadmapFilterFromLocalStorage() {
  return dispatch => {
    const filter = window.localStorage.getItem('roadmapFilterStorage');
    if (!filter) return {};

    var parsed = JSON.parse(filter);
    dispatch({
      type: GET_RM_FILTER,
      payload: parsed,
    });

    return {
      ...parsed,
      filterStartDate: parsed.filterStartDate
        ? new Date(parsed.filterStartDate)
        : null,
      filterEndDate: parsed.filterEndDate
        ? new Date(parsed.filterEndDate)
        : null,
    };
  };
}

export function setRoadmapMaterialsFilterInLocalstorage(filterStorage) {
  return dispatch => {
    //console.log('set roadmap filter');
    window.localStorage.setItem(
      'roadmapMaterialsFilterStorage',
      JSON.stringify(filterStorage),
    );
    dispatch({
      type: SET_RM_MATS_FILTER,
      payload: filterStorage,
    });
  };
}

export function getRoadmapMaterialsFilterFromLocalStorage() {
  return dispatch => {
    const filter = window.localStorage.getItem('roadmapMaterialsFilterStorage');
    if (!filter) return {};

    var parsed = JSON.parse(filter);
    dispatch({
      type: GET_RM_MATS_FILTER,
      payload: parsed,
    });

    return {
      ...parsed,
      filterStartDate: parsed.filterStartDate
        ? new Date(parsed.filterStartDate)
        : null,
      filterEndDate: parsed.filterEndDate
        ? new Date(parsed.filterEndDate)
        : null,
    };
  };
}

/**
 * @description Get agreement schema modal settings
 * @return {Promise} Promise
 */
export function getAgreementSchemaModalSetting() {
  return dispatch => {
    let url = API_URL + '/usersettings/ags';
    return axios.get(url).then(res => {
      dispatch({
        type: GET_AGREEMENT_SCHEMA_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Update agreement schema modal setting
 * @return {Promise} Promise
 */
export function updateAgreementSchemaModalSetting(data) {
  return dispatch => {
    let url = API_URL + '/usersettings/ags';
    return axios.post(url, data).then(res => {
      dispatch({
        type: GET_AGREEMENT_SCHEMA_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Get User Filters
 * @return {Promise} Promise
 */
export function getUserFilters() {
  return dispatch => {
    let url = API_URL + '/usersettings/getUserFilters';
    return axios.get(url).then(res => {
      dispatch({
        type: GET_USER_FILTERS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Update User Filters
 * @return {Promise} Promise
 */
export function updateUserFilters(data) {
  return dispatch => {
    let url = API_URL + '/usersettings/updateUserFilters';
    return axios.post(url, data).then(res => {
      dispatch({
        type: UPDATE_USER_FILTERS,
        payload: data,
      });

      return res;
    });
  };
}

/**
 * @description Get Hide Modal Settings
 * @return {Promise} Promise
 */
export function getHideModalSettings(id) {
  return dispatch => {
    let url = API_URL + '/usersettings/hideModal/' + id;
    return axios.get(url).then(res => {
      dispatch({
        type: GET_HIDE_MODAL_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Update hide modal setting
 * @return {Promise} Promise
 */
export function updateHideModalSetting(data) {
  return dispatch => {
    let url = API_URL + '/usersettings/updateHideModalSetting';
    return axios.post(url, data).then(res => {
      dispatch({
        type: UPDATE_HIDE_MODAL_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * Refreshes an image hash hopefully causing images using it to refresh/reload.
 * @returns void
 */
export function refreshImageHash() {
  return dispatch => {
    dispatch({
      type: IMAGE_HASH_REFRESH,
      payload: Date.now(),
    });
  };
}
