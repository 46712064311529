import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import { NavLink } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  IconLogUd,
  IconNotifikationer,
  IconNotifikationsActive,
  IconProfil,
  TagpasLogoBlaaOutlinet,
} from '../../assets/icons_v2';

// Actions
import { getPlatformBrandingUser } from '../../redux/actions/branding';

// Utils
import { SYSTEM_MESSAGE_TYPE } from '../../redux/constants';
import { getUserData } from '../../utils/userUtil';

// Components
import Typography from '../Typography';
import UserAvatar from '../Custom/UserAvatar';
import NotificationsMenu from '../HeaderMenus/NotificationsMenu';

const Header = props => {
  const { settings, system, getPlatformBrandingUser } = props;

  const [isLoading, setLoading] = useState(true);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [activeHeaderMenuItem, setActiveHeaderMenuItem] = useState(-1);
  const [platformBranding, setPlatformBranding] = useState(null);

  useEffect(() => {
    getPlatformBrandingUser()
      .then(res => {
        setPlatformBranding(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const renderLogo = () => {
    let defaultLogo = <TagpasLogoBlaaOutlinet className='logo' />;
    if (!!platformBranding?.platformLogo) {
      defaultLogo = (
        <img
          className='img-logo'
          src={platformBranding.platformLogo.fileUrl}
          alt='logo'
        />
      );
    }

    return (
      <div className='logo-content'>
        <div className='logo-wrapper' onClick={() => props.setRoute('/')}>
          {!isLoading && <a href='/'>{defaultLogo}</a>}
        </div>
      </div>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const setActiveMenuItem = index => {
    let value = -1;
    if (index !== activeHeaderMenuItem) {
      value = index;
    }
    setActiveHeaderMenuItem(value);
  };

  const renderMenuItem = ({ Comp, Icon, action }, index) => {
    const active = activeHeaderMenuItem === index;

    const onClickAction = () => {
      if (action) action();
      setActiveMenuItem(index);
    };

    const onBlurAction = () => {
      setActiveMenuItem(-1);
    };

    return (
      <div key={index} className={'menu-item'}>
        <div
          className={'icon-wrap' + (active ? ' active' : '')}
          onClick={onClickAction}
        >
          <Icon className='icon' />
        </div>
        {!!Comp && <Comp active={active} onBlur={onBlurAction} />}
      </div>
    );
  };

  const renderHeaderMenu = () => {
    const hasUnread = settings?.hasUnread ?? false;
    const notisIcon = hasUnread ? IconNotifikationsActive : IconNotifikationer;

    const menuItems = [
      {
        Icon: notisIcon,
        Comp: NotificationsMenu,
      },
    ];

    return <div className='menu'>{menuItems.map(renderMenuItem)}</div>;
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const renderProfileDropdown = () => {
    const navAction = route => {
      props.setRoute(route);
    };

    return (
      <Dropdown
        isOpen={showProfileMenu}
        toggle={() => setShowProfileMenu(!showProfileMenu)}
      >
        <DropdownToggle style={{ display: 'none' }} />
        <DropdownMenu>
          <NavLink to={'/profile'}>
            <DropdownItem
              onClick={() => navAction('/profile')}
              className='btn-wrap'
            >
              <div className='icon'>
                <IconProfil className='profile-btn' />
              </div>
              <div className='text'>{i18next.t(1100)}</div>
            </DropdownItem>
          </NavLink>
          <NavLink to={'/logout'}>
            <DropdownItem className='btn-wrap'>
              <div className='icon'>
                <IconLogUd className='logout-btn' />
              </div>
              <div className='text'>{i18next.t(12)}</div>
            </DropdownItem>
          </NavLink>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderUserProfile = () => {
    const openClass = showProfileMenu ? ' active' : '';
    const user = getUserData(props.auth);

    return (
      <div
        className={'profile' + openClass}
        onClick={
          showProfileMenu ? null : () => setShowProfileMenu(!showProfileMenu)
        }
      >
        <div className='info'>
          <Typography className='name'>{user?.name}</Typography>
          <Typography className='company'>{user?.customerName}</Typography>
        </div>
        <UserAvatar className='header' />
        {renderProfileDropdown()}
      </div>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const renderSystemMessage = () => {
    let displayMessage = false;
    let textContent;

    let stylingProps = {
      backgroundColor: 'red',
      cursor: 'pointer',
    };

    let fontProps = {
      fontWeight: 'bold',
      margin: '5px 15px 15px 15px',
    };

    let fontColorClass = 'white';

    if (system.systemMessage.length > 0) {
      const message = system.systemMessage[0];
      displayMessage = message.active;

      if (displayMessage) {
        textContent = (
          <div
            className='content'
            dangerouslySetInnerHTML={{
              __html: `${message.header.substr(0, 57).trim() + '...'}<br/>${
                message.content
              }`,
            }}
          />
        );

        switch (message.type) {
          case SYSTEM_MESSAGE_TYPE.WARNING:
            stylingProps.backgroundColor = 'yellow';
            fontColorClass = 'black';
            break;
          case SYSTEM_MESSAGE_TYPE.URGENT:
            stylingProps.backgroundColor = 'red';
            break;
          case SYSTEM_MESSAGE_TYPE.INFORMATIVE:
            stylingProps.backgroundColor = '#60c363';
            break;
          case SYSTEM_MESSAGE_TYPE.STANDARD_PRIMARY:
            stylingProps.backgroundColor = '#003341';
            break;
          case SYSTEM_MESSAGE_TYPE.STANDARD:
          default:
            stylingProps.backgroundColor = '#FFF';
            // stylingProps.borderLeft = '1px solid #dee2e6';
            fontColorClass = 'black';
            break;
        }
      }
    }

    if (!displayMessage) return null;

    //console.log(this.props);

    return (
      <div
        className='global_message'
        style={{
          ...stylingProps,
        }}
      >
        <div
          className={fontColorClass}
          style={{
            ...fontProps,
          }}
          onClick={() => {
            props.history.push('/systeminfo-view');
            window.location.reload();
          }}
        >
          {displayMessage && textContent}
        </div>
      </div>
    );
  };

  return (
    <div className='custom-header'>
      {renderLogo()}
      {renderSystemMessage()}
      {renderHeaderMenu()}
      {renderUserProfile()}
    </div>
  );
};

function mapStateToProps({ auth, systemInfo, notifications }) {
  return {
    auth,
    system: systemInfo,
    settings: notifications.settings,
  };
}

export default connect(mapStateToProps, { getPlatformBrandingUser })(Header);
