import { GET_LANGUAGES, SET_LANGUAGES, SET_USER_LANGUAGE } from '../constants';

const initialState = {
  languageIndex: [],
  currentLanguage: 'dk',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LANGUAGES:
      return {
        ...state,
        languageIndex: action.payload,
      };
    case SET_LANGUAGES:
      return {
        ...state,
        languageIndex: action.payload,
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    default:
      return state;
  }
};
