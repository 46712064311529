export const formatSizeUnits = bytes => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    bytes = bytes + ' bytes';
  } else if (bytes === 1) {
    bytes = bytes + ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
};

export const calculateBytes = (size, type) => {
  var bytes = 0;

  if (type === 'KB') {
    bytes = size * 1024;
  } else if (type === 'MB') {
    bytes = size * 1048576;
  } else if (type === 'GB') {
    bytes = size * 1073741824;
  } else if (type === 'TB') {
    bytes = size * 1073741824;
  }

  return bytes;
};
