import { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { logout, loginOutAs } from '../redux/actions/auth';

const Logout = props => {
  const { auth, logout, loginOutAs, history } = props;

  useEffect(() => {
    if (auth?.isAuthenticated) {
      if (auth.loginAsUser) {
        loginOutAs();
      } else {
        logout();
      }
      reload();
    }
    // eslint-disable-next-line
  }, []);

  const reload = () => {
    history.push('/');
    window.location.reload();
  };

  return null;
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { logout, loginOutAs })(Logout);
