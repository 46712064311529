import React from 'react';
import i18next from '../i18n';
import { Card, CardBody, Container } from 'reactstrap';

const InaccessibleFeature = props => {
  return (
    <Container>
      <Card>
        <CardBody>
          <div>{i18next.t(6411)}</div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default InaccessibleFeature;
