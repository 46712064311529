import * as React from 'react';

const SvgIconSagsoverblik = props => (
  <svg
    data-name='Icon - Sagsoverblik'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 19'
    {...props}
  >
    <path data-name='Path 248' d='M0 0h24v19.2H0Z' fill='none' />
    <path
      data-name='Path 249'
      d='M0 19.2h24v-4.8H0Zm2.4-3.6h2.4V18H2.4ZM0 0v4.8h24V0Zm4.8 3.6H2.4V1.2h2.4ZM0 12h24V7.2H0Zm2.4-3.6h2.4v2.4H2.4Z'
    />
  </svg>
);

export default SvgIconSagsoverblik;
