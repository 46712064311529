import * as React from 'react';

const SvgIconNotifikationsactive = props => (
  <svg
    data-name='Icon - Notifikations active'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    width='100%'
    height='100%'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Notifikations_active_svg__a'>
        <path data-name='Rectangle 1183' d='M0 0h22.105v24.13H0z' />
      </clipPath>
      <clipPath id='Icon_-_Notifikations_active_svg__b'>
        <path data-name='Rectangle 1184' d='M0 0h22.105v24.13H0z' />
      </clipPath>
    </defs>
    <g data-name='Group 179'>
      <g data-name='Group 174'>
        <g
          data-name='Group 173'
          clipPath='url(#Icon_-_Notifikations_active_svg__a)'
          transform='translate(.895)'
        >
          <path
            data-name='Path 242'
            d='M12.592 2.557a6.8 6.8 0 0 0-1.748-.507V.986a.985.985 0 1 0-1.971 0v1.065a6.9 6.9 0 0 0-5.916 6.821v2.748a6.614 6.614 0 0 1-2.354 5.061 1.725 1.725 0 0 0 1.122 3.03h16.266a1.725 1.725 0 0 0 1.114-3.043 6.6 6.6 0 0 1-2.346-5.052v-.176a5.539 5.539 0 0 1-4.168-8.886'
          />
        </g>
      </g>
      <g data-name='Group 176'>
        <g
          data-name='Group 175'
          clipPath='url(#Icon_-_Notifikations_active_svg__b)'
          transform='translate(.895)'
        >
          <path
            data-name='Path 243'
            d='M9.858 23.727a3.7 3.7 0 0 0 3.622-2.957H6.236a3.7 3.7 0 0 0 3.622 2.957'
          />
        </g>
      </g>
      <g data-name='Group 178'>
        <g
          data-name='Group 177'
          clipPath='url(#Icon_-_Notifikations_active_svg__b)'
          transform='translate(.895)'
        >
          <path
            data-name='Path 244'
            d='M17.234 10.637a4.872 4.872 0 1 0-4.872-4.872 4.872 4.872 0 0 0 4.872 4.872'
            fill='#ce2729'
          />
        </g>
      </g>
    </g>
    <path data-name='Rectangle 1186' fill='none' d='M0 0h24v24H0z' />
  </svg>
);

export default SvgIconNotifikationsactive;
