import React, { useState } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { connect } from 'react-redux';
import i18next from '../i18n';
import { osName } from 'react-device-detect';
//import TaghaandbogenLogo from './../assets/img/logo/method-draw-image.svg';
import CountryList from 'countries-list';
import ReCAPTCHA from 'react-google-recaptcha';

// Actions
import { login } from '../redux/actions/auth';
import { getSystemMessage } from '../redux/actions/systemInfo';
import { getFeatures } from '../redux/actions/features';
import { getCustomer } from '../redux/actions/customers';
import { CreateEmployee } from '../redux/actions/employees';
import { EMPLOYEE_TYPE, ACCESS_LEVEL } from '../redux/constants';

// Utils
import { CUSTOMER_STATUS } from '../redux/constants';
import { getUserPositionTypeStrings } from '../utils/userUtil';

// Components
import TextInput from '../components/Custom/Forms/TextInput';
import PhoneInput from '../components/Custom/Forms/PhoneInput';
import ReactSelect from '../components/Custom/ReactSelect';

import TermsOfAgreementModal from '../components/Custom/TermsOfAgreementModal';

const CreateProfilePage = props => {
  const {
    login,
    logout,
    getCustomer,
    getSystemMessage,
    getFeatures,
    GetBranding,
    CreateEmployee,
    history,
    auth,
  } = props;
  const [isSubmitting, setSubmitting] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [countryCode, setCountryCode] = useState('+45');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [userPositionType, setUserPositiontype] = useState(0);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const getCountryCodeOptions = () => {
    return Object.keys(CountryList.countries).map((country, index) => {
      const obj = CountryList.countries[country];
      const value = `+${obj.phone}`;
      const name = `${obj.emoji} ${value}`;

      return { label: name, value };
    });
  };

  const getCountryOptions = () => {
    const countryOptions = [
      ...Object.keys(CountryList.countries).map((country, index) => {
        const obj = CountryList.countries[country];
        return {
          label: obj.native,
          value: obj.native,
        };
      }),
    ];

    return countryOptions;
  };

  const handleLogin = (username, password) => {
    login({
      username: username,
      password: password,
      devicetype: osName,
    })
      .then(res => {
        if (res.data.customerStatus === CUSTOMER_STATUS.Closed) {
          const { user } = res.data;
          getCustomer(user.groupId, user.belongsToCustomerId)
            .then(res => {
              logout();
            })
            .catch(err => {
              logout();
            });
        } else {
          getSystemMessage();
          getFeatures();
          if (res.data.accessLevel > 1 && res.data.user) {
            const { user } = res.data;

            GetBranding(user.belongsToCustomerId).catch(err => {
              console.log('Failed to get branding.', err);
            });
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleCreateProfile = () => {
    setSubmitting(true);

    let { hasErrors, repeatError, nameError } = false;

    if (password !== repeatPassword) {
      repeatError = true;
      hasErrors = true;
    }

    if (name === '') {
      nameError = true;
      hasErrors = true;
    }

    // Create profile
    if (!hasErrors) {
      let dataObj = {
        name: name,
        userName: mail,
        mail: mail,
        country: country,
        countryCode: countryCode,
        userPositionType: userPositionType,
        mobile: phone,
        password: password,
        employeeType: EMPLOYEE_TYPE.RoofingContractor,
        role: ACCESS_LEVEL.Employee,
      };

      CreateEmployee(0, dataObj)
        .then(res => {
          handleLogin(mail, password);
        })
        .catch(err => {
          console.log('error', err);
          if (err.response.data.length > 0) {
            let tmpErrors = err.response.data.map(x => {
              return x.dictionaryKey;
            });
            setErrors(tmpErrors);
            setSubmitting(false);
          }
        });
    } else {
      let tmpErrors = [];
      if (repeatError) {
        tmpErrors.push(3255);
      }
      if (nameError) {
        tmpErrors.push(80);
      }
      setErrors(tmpErrors);
      setSubmitting(false);
    }
  };

  const onChangeMail = e => {
    if (e.target.name.includes('Mail') || e.target.name.includes('mail')) {
      e.target.value = e.target.value.replaceAll(' ', '');
    }

    setMail(e.target.value);
  };

  const onChangeName = e => {
    setName(e.target.value);
  };

  const onChangePhone = e => {
    setPhone(e.target.value);
  };

  const onChangeCountryCode = e => {
    setCountryCode(e.target.value);
  };

  const onChangeCountry = e => {
    setCountry(e.value);
  };

  const onChangePositionType = e => {
    setUserPositiontype(e.value);
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
  };

  const onChangeRepeatPassword = e => {
    setRepeatPassword(e.target.value);
  };

  const handledCaptcha = e => {
    //console.log('captcha', e);
    setCaptcha(e);
  };

  const timeoutCaptcha = () => {
    setCaptcha(null);
  };

  const renderCreateForm = () => {
    // console.log(this.state.errors.filter(x => x === 1112).length > 0);
    return (
      <Form>
        {/* <div className='text-left pb-4'>
          <img
            src={TaghaandbogenLogo}
            style={{ height: '75px' }}
            alt='Tagpas'
          />
        </div> */}

        <TextInput
          for={i18next.t(250) + '*'}
          placeholder={i18next.t(250)}
          name='name'
          onChange={onChangeName}
          error={errors.filter(x => x === 80).length > 0}
          errorText={i18next.t(80)}
          value={name}
          disabled={isSubmitting}
        />

        <TextInput
          for={i18next.t(25) + '*'}
          placeholder={i18next.t(25)}
          name='mail'
          onChange={onChangeMail}
          error={errors.filter(x => x === 1111 || x === 7042)}
          errorText={errors
            .filter(x => x === 1111 || x === 7042)
            .map(e => i18next.t(e))
            .join('\n')}
          value={mail}
          disabled={isSubmitting}
        />
        <FormGroup>
          <PhoneInput
            for={i18next.t(27) + '*'}
            name='phone'
            placeholder={i18next.t(9100)}
            onChange={onChangePhone}
            onChangeCode={e =>
              onChangeCountryCode({ target: { name: 'countryCode', value: e } })
            }
            value={phone}
            valueCode={{
              label: countryCode,
              value: countryCode,
            }}
            // error={errors.filter(x => x === 1112).length > 0}
            // errorText={i18next.t(1112)}
            options={getCountryCodeOptions()}
            disabled={isSubmitting}
          />
          {errors.filter(x => x === 1112).length > 0 ? (
            <p style={{ color: 'red' }}>{i18next.t(1112)}</p>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Label>{i18next.t(1160)}*</Label>
          <ReactSelect
            value={getCountryOptions().find(item => item.value === country)}
            options={getCountryOptions()}
            name={'country'}
            onChange={onChangeCountry}
            placeholder={'Danmark'}
            disabled={isSubmitting}
          />
        </FormGroup>
        <FormGroup>
          <Label>{i18next.t(1346)}*</Label>
          <ReactSelect
            value={getUserPositionTypeStrings().find(
              item => item.value === userPositionType,
            )}
            options={getUserPositionTypeStrings()}
            name={'userPositionType'}
            onChange={onChangePositionType}
            placeholder={0}
            disabled={isSubmitting}
            error={errors.filter(x => x === 1347).length > 0}
            errorText={errors
              .filter(x => x === 1347)
              .map(e => i18next.t(e))
              .join('\n')}
          />
        </FormGroup>
        <TextInput
          for={i18next.t(9097) + '*'}
          placeholder={i18next.t(9097)}
          name='password'
          onChange={onChangePassword}
          error={errors.filter(
            x => x === 77 || x === 78 || x === 7044 || x === 79 || x === 7043,
          )}
          errorText={errors
            .filter(
              x => x === 77 || x === 78 || x === 7044 || x === 79 || x === 7043,
            )
            .map(e => i18next.t(e))
            .join('\n')}
          value={password}
          type='password'
          disabled={isSubmitting}
        />

        <TextInput
          for={i18next.t(249) + '*'}
          placeholder={i18next.t(249)}
          name='repeatPassword'
          onChange={onChangeRepeatPassword}
          error={errors.find(x => x === 3255)}
          errorText={i18next.t(3255)}
          value={repeatPassword}
          type='password'
          disabled={isSubmitting}
        />

        <hr />
        <div style={{ marginBottom: '1rem' }}>
          <a href='https://tagpas.dk/privatlivs-politik/'>{i18next.t(6437)}</a>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <ReCAPTCHA
            sitekey='6Lf8jaEfAAAAAE8evcRpLN7CpIXCK1i44G6ia3si'
            onChange={handledCaptcha}
            onExpired={timeoutCaptcha}
          />
        </div>

        <Button
          size='lg'
          block
          color='primary'
          onClick={() => handleCreateProfile()}
          disabled={isSubmitting || !captcha}
        >
          {isSubmitting ? <Spinner /> : i18next.t(9095)}
        </Button>
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          <div className='sub-box'>
            <p onClick={() => history.push('/login')}>{i18next.t(9101)}</p>
          </div>
        </Row>
      </Form>
    );
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto',
      }}
    >
      <Col md={6} lg={4}>
        <Card body>
          {renderCreateForm()}
          {auth?.isAuthenticated && <TermsOfAgreementModal {...props} />}
        </Card>
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  login,
  getSystemMessage,
  getFeatures,
  getCustomer,
  CreateEmployee,
})(CreateProfilePage);
