import * as React from 'react';

const SvgIconLock = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    {...props}
  >
    <path data-name='Rectangle 1295' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Icon feather-rotate-ccw'>
      <path
        data-name='Icon awesome-lock'
        d='M19.856 10.625h-1.071V7.531A6.67 6.67 0 0 0 11.999 1a6.67 6.67 0 0 0-6.786 6.531v3.094H4.142a2.1 2.1 0 0 0-2.143 2.063v8.25A2.1 2.1 0 0 0 4.142 23h15.714a2.1 2.1 0 0 0 2.143-2.062v-8.25a2.1 2.1 0 0 0-2.143-2.063Zm-4.643 0H8.785V7.531a3.159 3.159 0 0 1 3.214-3.093 3.159 3.159 0 0 1 3.214 3.094Z'
      />
    </g>
  </svg>
);

export default SvgIconLock;
