import React from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';

// Actions

// Utils

// Components
import ControlItemPhoto from './ControlItemPhoto';
import ControlItemText from './ControlItemText';

const ControlItemTextPhotos = props => {
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// TEXT

  const renderTextInput = () => {
    return <ControlItemText {...props} hideAdmin />;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// Photo

  const renderFileInput = () => {
    const fileTitle = i18next.t(1060);

    return (
      <ControlItemPhoto
        {...props}
        control={{ ...props.control, title: fileTitle, showInfoIcon: false }}
      />
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {renderTextInput()}
      {renderFileInput()}
    </div>
  );
};

function mapStateToProps({ auth, schemas, cases }) {
  return {
    auth,
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {})(ControlItemTextPhotos);
