import * as React from 'react';

const SvgIconLogUd = props => (
  <svg
    data-name='Icon - Log-ud'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Log-ud_svg__a'>
        <path data-name='Rectangle 1219' d='M0 0h21.01v21.556H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1218' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 219'>
      <g
        data-name='Group 217'
        clipPath='url(#Icon_-_Log-ud_svg__a)'
        transform='translate(1.471 1.197)'
      >
        <path
          data-name='Path 271'
          d='M0 19.816V1.74A1.739 1.739 0 0 1 1.739 0h10.069a1.74 1.74 0 0 1 1.74 1.74v7.473H8.34a1.565 1.565 0 1 0 0 3.13h5.208v7.473a1.74 1.74 0 0 1-1.74 1.74H1.739A1.739 1.739 0 0 1 0 19.816'
        />
      </g>
      <g
        data-name='Group 218'
        clipPath='url(#Icon_-_Log-ud_svg__a)'
        transform='translate(1.471 1.197)'
      >
        <path
          data-name='Path 272'
          d='M20.755 10.163 17.838 7.25c-.339-.339-.614-.225-.614.255v1.969h-2.376a1.304 1.304 0 0 0 0 2.609h2.375v1.968c0 .48.275.594.614.255l2.912-2.912a.869.869 0 0 0 0-1.231'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconLogUd;
