import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import { v4 as uuidv4 } from 'uuid';

// Elements
import { Button, Card, Col, Collapse, Container } from 'reactstrap';
import TaghaandbogenLogo from '../../assets/img/logo/method-draw-image.svg';

// Actions
import { sendErrorLog } from '../../redux/actions/error';

// Components
import Typography from '../Typography';
import { getUserData } from '../../utils/userUtil';
import { ERROR_TYPE } from '../../redux/constants';

// Utils
const { detect } = require('detect-browser');

// ComponentDidCatch does not exist in functional components, cannot be converted.
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    const { catchError } = this.props;

    this.state = {
      hasError: false,
      error: catchError ? catchError.message : null,
      errorInfo: catchError ? catchError : null,
      guid: null,

      showDetails: false,
    };
  }

  static getDerivedStateFromError(error) {
    //console.log('getDerivedStateFromError', error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //console.log('componentDidCatch', error, errorInfo);
    //if (this.state.error !== null && this.state.errorInfo !== null) {
    this.setState({ error, errorInfo, guid: uuidv4() }, () =>
      this.handleAutoReportError(),
    );
    //}
  }

  handleAutoReportError = () => {
    const { guid, error, errorInfo } = this.state;
    const { auth, catchError } = this.props;

    let userId = -1;
    let metadata = {
      appVersion: process.env.REACT_APP_VERSION,
    };

    try {
      metadata.url = window.location.href;
    } catch (err) {
      metadata.errorUrl = {
        message: 'Failed to get url',
        error: err,
      };
    }

    try {
      const user = getUserData(auth);
      userId = user.id;
      metadata.loggedInAsUser = auth.loginAsUser ? true : false;
    } catch (err) {
      metadata.error = {
        message: 'Failed to get user information',
        error: err,
      };
    }

    try {
      const browser = detect();

      if (browser) {
        metadata.browser = {
          name: browser.name,
          version: browser.version,
          os: browser.os,
        };
      }
    } catch (err) {
      metadata.error2 = {
        message: 'Failed to get browser information',
        error: err,
      };
    }

    let infoText = '';
    if (catchError && errorInfo !== null) {
      infoText = errorInfo.stack.toString();
    } else if (errorInfo !== null) {
      infoText = errorInfo.componentStack.toString();
    }

    let errorObj = {
      guid,
      userId,
      error: error.toString(),
      stackTrace: infoText.trim(),
      metadata,
      type: ERROR_TYPE.WebError,
    };

    // console.log(errorObj);
    this.props.sendErrorLog(errorObj);
  };

  renderLogo = () => {
    return (
      <div className='logo-wrapper'>
        <div className='logo'>
          <Card body className='border-0'>
            <img
              src={TaghaandbogenLogo}
              className='authform_logo'
              alt='Tagpas'
            />
          </Card>
        </div>
      </div>
    );
  };

  renderErrorBlock = () => {
    const { simple, catchError } = this.props;
    const { error, errorInfo, showDetails, guid } = this.state;
    //console.log(catchError);

    if (catchError !== undefined && error === null) {
      this.setState(
        {
          error: catchError.message,
          errorInfo: catchError,
          guid: uuidv4(),
        },
        () => {
          this.handleAutoReportError();
        },
      );
    }

    const toggleDetails = () => {
      this.setState({ showDetails: !this.state.showDetails });
    };

    let colClasses = 'error-col';
    if (simple) {
      colClasses += ' simple';
    }
    let infoText = '';
    if (catchError !== undefined && errorInfo !== null) {
      infoText = errorInfo.stack.toString();
    } else if (errorInfo !== null) {
      console.log(errorInfo);
      infoText = errorInfo.componentStack.toString();
    }

    return (
      <Container className='error-wrapper'>
        <Col className={colClasses}>
          <div className='error-page'>
            <Typography type='h4'>{i18next.t(1451)}</Typography>
            <Typography>
              {i18next.t(1454)} {i18next.t(1455)?.toLowerCase()}.
              <Button
                color='btnSecondary'
                onClick={toggleDetails}
                className='float-right'
                size='sm'
              >
                {i18next.t(1452)}
              </Button>
            </Typography>
            <Collapse isOpen={showDetails}>
              <hr className='border-0' />
              <Card body className='border-0'>
                {error && <p>{error.toString()}</p>}
                {errorInfo && <p>{infoText}</p>}
              </Card>
            </Collapse>
            <hr />
            {!simple && this.renderLogo()}
            <Typography style={{ textAlign: 'center' }}>
              {i18next.t(1456)}: {guid}
            </Typography>
            {/* <Typography style={{ textAlign: 'center' }}>
              {i18next.t(1453)}:{' '}
              <a href='mailto:example@example.com'>example@example.com</a>
            </Typography> */}
          </div>
        </Col>
      </Container>
    );
  };

  render() {
    const { hasError } = this.state;
    const { catchError } = this.props;
    //console.log(this.props);
    // console.log(this.state);

    if (hasError || catchError != null) {
      //console.log('Error');
      return this.renderErrorBlock();
    } else {
      //console.log('No error');
      return this.props.children;
    }
  }
}

// const ErrorBoundary = props => {
//   const { auth, simple, catchError } = props;
//   const [hasError, setHasError] = useState(false);
//   const [error, setError] = useState(catchError ? catchError.message : null);
//   const [errorInfo, setErrorInfo] = useState(catchError ? catchError : null);
//   const [guid, setGuid] = useState(null);
//   const [showDetails, setShowDetails] = useState(false);

//   componentDidCatch(error, errorInfo) {
//     // There is no hook equivelant to componentDidCatch yet, no timetable for implementation
//     //console.log('componentDidCatch', error, errorInfo);
//     setError(error);
//     setErrorInfo(errorInfo);
//     setGuid(uuidv4());
//     handleAutoReportError();
//   }

//   // const getDerivedStateFromError = error => {
//   //   //console.log('getDerivedStateFromError', error);
//   //   return { hasError: true };
//   // };

//   const handleAutoReportError = () => {
//     let userId = -1;
//     let metadata = {
//       appVersion: process.env.REACT_APP_VERSION,
//     };

//     try {
//       metadata.url = window.location.href;
//     } catch (err) {
//       metadata.errorUrl = {
//         message: 'Failed to get url',
//         error: err,
//       };
//     }

//     try {
//       const user = getUserData(auth);
//       userId = user.id;
//       metadata.loggedInAsUser = auth.loginAsUser ? true : false;
//     } catch (err) {
//       metadata.error = {
//         message: 'Failed to get user information',
//         error: err,
//       };
//     }

//     try {
//       const browser = detect();

//       if (browser) {
//         metadata.browser = {
//           name: browser.name,
//           version: browser.version,
//           os: browser.os,
//         };
//       }
//     } catch (err) {
//       metadata.error2 = {
//         message: 'Failed to get browser information',
//         error: err,
//       };
//     }

//     let infoText = '';
//     if (catchError && errorInfo !== null) {
//       infoText = errorInfo.stack.toString();
//     } else if (errorInfo !== null) {
//       infoText = errorInfo.componentStack.toString();
//     }

//     let errorObj = {
//       guid,
//       userId,
//       error: error.toString(),
//       stackTrace: infoText.trim(),
//       metadata,
//       type: ERROR_TYPE.WebError,
//     };

//     // console.log(errorObj);
//     props.sendErrorLog(errorObj);
//   };

//   const renderLogo = () => {
//     return (
//       <div className='logo-wrapper'>
//         <div className='logo'>
//           <Card body className='border-0'>
//             <img
//               src={TaghaandbogenLogo}
//               className='authform_logo'
//               alt='Tagpas'
//             />
//           </Card>
//         </div>
//       </div>
//     );
//   };

//   const renderErrorBlock = () => {
//     //console.log(catchError);

//     if (catchError !== undefined && error === null) {
//       setError(catchError.message);
//       setErrorInfo(catchError);
//       setGuid(uuidv4());
//       handleAutoReportError();
//     }

//     const toggleDetails = () => {
//       setShowDetails(!showDetails);
//     };

//     let colClasses = 'error-col';
//     if (simple) {
//       colClasses += ' simple';
//     }
//     let infoText = '';
//     if (catchError !== undefined && errorInfo !== null) {
//       infoText = errorInfo.stack.toString();
//     } else if (errorInfo !== null) {
//       console.log(errorInfo);
//       infoText = errorInfo.componentStack.toString();
//     }

//     return (
//       <Container className='error-wrapper'>
//         <Col className={colClasses}>
//           <div className='error-page'>
//             <Typography type='h4'>{i18next.t(1451)}</Typography>
//             <Typography>
//               {i18next.t(1454)} {i18next.t(1455).toLowerCase()}.
//               <Button
//                 color='btnSecondary'
//                 onClick={toggleDetails}
//                 className='float-right'
//                 size='sm'
//               >
//                 {i18next.t(1452)}
//               </Button>
//             </Typography>
//             <Collapse isOpen={showDetails}>
//               <hr className='border-0' />
//               <Card body className='border-0'>
//                 {error && <p>{error.toString()}</p>}
//                 {errorInfo && <p>{infoText}</p>}
//               </Card>
//             </Collapse>
//             <hr />
//             {!simple && renderLogo()}
//             <Typography style={{ textAlign: 'center' }}>
//               {i18next.t(1456)}: {guid}
//             </Typography>
//           </div>
//         </Col>
//       </Container>
//     );
//   };

//   if (hasError || catchError != null) {
//     //console.log('Error');
//     return renderErrorBlock();
//   } else {
//     //console.log('No error');
//     return props.children;
//   }
// };

ErrorBoundary.defaultProps = {
  simple: true,
};

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

export default connect(mapStateToProps, { sendErrorLog })(ErrorBoundary);
