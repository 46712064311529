const AREA_TOTAL = {
  HUGE: 48000000,
  VERY_LARGE: 35000000,
  LARGE: 25000000,
  MEDIUM: 12000000,
  MEDIUM_SMALL: 2000000,
  SMALL: 1000000,
  VERY_SMALL: 500000,
  NANO: 250000,
};

export function getMarkingSizeByLadder(image) {
  if (!image) return 120;
  const total = image.width * image.height;

  let result = 30;

  if (total >= AREA_TOTAL.HUGE) {
    result = 105;
  } else if (total >= AREA_TOTAL.VERY_LARGE) {
    result = 85;
  } else if (total >= AREA_TOTAL.LARGE) {
    result = 65;
  } else if (total >= AREA_TOTAL.MEDIUM) {
    result = 45;
  } else if (total >= AREA_TOTAL.MEDIUM_SMALL) {
    result = 30;
  } else if (total >= AREA_TOTAL.SMALL) {
    result = 15;
  } else if (total >= AREA_TOTAL.VERY_SMALL) {
    result = 15;
  } else if (total >= AREA_TOTAL.NANO) {
    result = 15;
  }

  // console.log(result, total, image.width, image.height);

  return result;
}
