import { SB_CONTROL_TYPE, SB_CRITERIA_BEHAVIOR } from '../redux/constants';

/**
 * Validate if a schema can be marked as done.
 * @param {Object} schema Schema object.
 * @returns bool
 */
export const isValidSchema = schema => {
  if (!schema) return false;
  if (!schema.controlItems || schema.controlItems?.length === 0) return false;

  let controls = [];
  schema.controlItems.forEach(x => {
    if (x.isGroup) {
      if (x.required) {
        controls.push(
          ...x.controlItems.map(y => {
            return {
              ...y,
              required: true,
            };
          }),
        );
      } else {
        controls.push(...x.controlItems.filter(y => y.required));
      }
    } else {
      controls.push(x);
    }
  });

  // Get required controls and if 0, we return true.
  const requiredControls = controls.filter(
    x => x.required && isPassingCriteria(schema, x),
  );
  if (requiredControls.length === 0) return true;

  // Parse and check if all controls are valid.
  for (let i = 0; i < requiredControls.length; i++) {
    const control = requiredControls[i];

    let result;
    if (control.isGroup) {
      control.controlItems.forEach(x => (result = validateControl(x)));
    } else {
      result = validateControl(control);
    }

    // If just one control is false, then the schema is invalid.
    if (!result) return false;
  }

  return true;
};

/**
 * Checks if the control is valid based on its type requirements.
 * @param {Object} control
 * @returns bool
 */
export const validateControl = control => {
  if (!control) return false;

  const {
    textValue,
    maxCharacters,
    dateValue,
    checkboxValues,
    dropdownValue,
    allCategories,
    categoryValues,
    productValues,
    dropdownSelectedUsers,
    numberValue,
    files,
    minFiles,
    maxFiles,
    // signatureValue,
    timeTableUsers,
    answeredByUserId,
    hideMarkedQuestion,
    checkboxOptions,
  } = control;
  const filesNum = files?.length ?? 0;
  let selectedIds = [];

  switch (control.controlType) {
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.PhotoAreaMarking:
      return filesNum > 0;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.PhotoDocumentation:
      if (!!minFiles && !!maxFiles) {
        return minFiles <= filesNum && filesNum <= maxFiles;
      } else if (!!minFiles && !maxFiles) {
        return minFiles <= filesNum;
      } else if (!minFiles && !!maxFiles) {
        return filesNum <= maxFiles;
      } else {
        return filesNum > 0;
      }
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.Signature:
      selectedIds = dropdownSelectedUsers?.map(x => x.userId) ?? [];
      return selectedIds.length > 0;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.DateSelector:
      return !!dateValue;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.DropdownProducts:
      if (allCategories) {
        return (
          categoryValues?.trim().length > 0 && productValues?.trim().length > 0
        );
      } else {
        return productValues?.trim().length > 0;
      }
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.DropdownUsers:
      selectedIds = dropdownSelectedUsers?.map(x => x.userId) ?? [];
      return selectedIds.length > 0;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.DropdownCustom:
      return dropdownValue?.trim().length > 0;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.DropdownCommentPhotos:
      return (
        dropdownValue?.trim().length > 0 ||
        textValue?.trim().length > 0 ||
        filesNum > 0
      );
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.Checkboxes:
      if (!!checkboxOptions.find(x => x.value)) return true;
      return checkboxValues?.trim().length > 0;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.Text:
      if (!textValue) return false;
      const length = textValue?.trim().length;

      if (maxCharacters === 0 && length === 0) {
        return false;
      } else if (!!maxCharacters && maxCharacters > 0) {
        return length <= maxCharacters && length > 0;
      } else {
        return true;
      }
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.TextUneditable:
      return true;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.TextPhotos:
      return textValue?.trim().length > 0 || filesNum > 0;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.Numbers:
      if (!numberValue) return false;
      return !!numberValue;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.TimeTableUsers:
      selectedIds = timeTableUsers?.map(x => x.userId) ?? [];
      return selectedIds.length > 0;
    ////////////////////////////////////////////////////////////////////////////////
    case SB_CONTROL_TYPE.Checkmark:
      return !hideMarkedQuestion || !!answeredByUserId;
    ////////////////////////////////////////////////////////////////////////////////
    default:
      return true;
  }
};

////////////////////////////////////////////////////////////////////////
///////////////////////  Parse Control Items   /////////////////////////
////////////////////////////////////////////////////////////////////////

export function preprocessControlItemsAndGroups(data) {
  return data.map(schemaGroup => {
    let parsed = {
      ...schemaGroup,
    };

    parsed.schemas = schemaGroup.schemas.map(schema => {
      const filtered = schema.controlItems.filter(x => !x.isDeleted);
      const groups = schema.controlItemGroups;

      // Handle groups
      const foundItemIds = [];
      const tmpGroups = [];

      groups.forEach(x => {
        const items = filtered.filter(
          c => !!c.controlItemGroupId && c.controlItemGroupId === x.id,
        );

        foundItemIds.push(...items.map(c => c.id));

        tmpGroups.push({
          ...x,
          controlItems: items,
          isGroup: true,
        });
      });

      // Normal control items,
      const controlItems = filtered.filter(x => !foundItemIds.includes(x.id));

      let result = [...tmpGroups, ...controlItems];
      result.sort((a, b) => a.index - b.index);

      return {
        ...schema,
        controlItems: result,
        rawControlItems: sortRawsByIndex(result, filtered),
      };
    });

    return parsed;
  });
}

function sortRawsByIndex(controls, raws) {
  const allowedTypes = [
    SB_CONTROL_TYPE.PhotoDocumentation, // 2
    SB_CONTROL_TYPE.TextPhotos, // 15
    SB_CONTROL_TYPE.DropdownCommentPhotos, // 14
  ];

  let result = [];
  let foundIds = [];

  controls.forEach((x, i) => {
    let rawItem;
    if (!!x.isGroup) {
      for (let l = 0; l < x.controlItems.length; l++) {
        const item = x.controlItems[l];

        rawItem = raws.find(y => y.id === item.id);

        if (allowedTypes.includes(rawItem.controlType)) {
          result.push(rawItem);
          foundIds.push(rawItem.id);
        }
      }
    } else {
      rawItem = raws.find(y => y.id === x.id);

      if (allowedTypes.includes(rawItem.controlType)) {
        result.push(rawItem);
        foundIds.push(rawItem.id);
      }
    }
  });
  const remainder = raws.filter(x => !foundIds.includes(x.id));

  result.push(...remainder);

  // console.log(result, raws, controls);

  return result;
}

/////////////////////////////////////////////////////////////////////////////////////////////////
////// Criteria handling

/**
 * Check if a control-item passes its crieria.
 * @param {Object} schema Schema
 * @param {Object} control ControlItem
 * @returns boolean
 */
export const isPassingCriteria = (schema, control) => {
  const { criteria } = control;

  const minMaxValueCheck = (minValue, maxValue, value) => {
    if (minValue === 0 && maxValue === 0) return true;
    if (!value) return false;

    if ((minValue > 0 && maxValue > 0) || (minValue < 0 && maxValue < 0)) {
      return value >= minValue && value <= maxValue;
    } else if (minValue > 0 && maxValue === 0) {
      return value >= minValue;
    } else if (minValue < 0 && maxValue === 0) {
      return value <= minValue;
    } else if (minValue === 0 && (maxValue > 0 || maxValue < 0)) {
      return value <= maxValue;
    } else {
      return true;
    }
  };

  const criteriaPassCheck = crit => {
    if (!crit) return true;
    const {
      controlType,
      targetControlItemId,
      hasValue,
      hasValues,
      minValue,
      maxValue,
    } = crit;
    let target;
    const tmpTarget = schema.rawControlItems.find(
      x => x.id === targetControlItemId,
    );

    if (!!tmpTarget.controlItemGroupId) {
      const group = schema.controlItems.find(
        x => x.isGroup && x.id === tmpTarget.controlItemGroupId,
      );
      target = group.controlItems.find(x => x.id === targetControlItemId);
    } else {
      target = schema.controlItems.find(x => x.id === targetControlItemId);
    }

    let pass = true;

    if (targetControlItemId && target) {
      const {
        textValue,
        numberValue,
        dateValue,
        signatureValue,
        files,
        productValues,
        dropdownValue,
        checkboxValues,
        dropdownSelectedUsers,
        timeTableUsers,
        marked,
        hideMarkedQuestion,
        answeredByUserId,
      } = target;

      switch (controlType) {
        case SB_CONTROL_TYPE.PhotoAreaMarking:
          if (hasValue) {
            pass = files.length > 0;
          }
          break;
        case SB_CONTROL_TYPE.PhotoDocumentation:
          if (hasValue) {
            pass = files.length > 0;
          }
          pass = minMaxValueCheck(minValue, maxValue, files.length);
          break;
        case SB_CONTROL_TYPE.Signature:
          if (hasValue) {
            pass = !!signatureValue;
          }
          break;
        case SB_CONTROL_TYPE.DateSelector:
          if (hasValue) {
            pass = !!dateValue;
          }
          break;
        case SB_CONTROL_TYPE.DropdownProducts:
          if (hasValue) {
            pass = productValues?.trim().length > 0;
          }
          if (hasValues) {
            const vals = hasValues
              .split(',')
              .filter(x => !!x || x?.trim().length > 0);
            const prodVals =
              productValues
                ?.split(',')
                .filter(x => !!x || x?.trim().length > 0) ?? [];

            const intersected = vals.filter(value => prodVals.includes(value));
            pass = intersected.length > 0;
          }
          break;
        case SB_CONTROL_TYPE.DropdownUsers:
          if (hasValue) {
            pass = dropdownSelectedUsers?.length > 0;
          }
          break;
        case SB_CONTROL_TYPE.DropdownCustom:
          if (hasValue) {
            pass = dropdownValue?.trim().length > 0;
          }
          if (hasValues) {
            const vals = hasValues
              .split(',')
              .filter(x => !!x || x?.trim().length > 0);
            const dpVals =
              dropdownValue
                ?.split(',')
                .filter(x => !!x || x?.trim().length > 0) ?? [];

            const intersected = vals.filter(value => dpVals.includes(value));
            pass = intersected.length > 0;
          }
          break;
        case SB_CONTROL_TYPE.DropdownCommentPhotos:
          if (hasValue) {
            pass =
              dropdownValue?.trim().length > 0 ||
              files?.length > 0 ||
              !!textValue ||
              textValue?.trim().length > 0;
          }
          if (hasValues) {
            const vals = hasValues
              .split(',')
              .filter(x => !!x || x?.trim().length > 0);
            const dpVals =
              dropdownValue
                ?.split(',')
                .filter(x => !!x || x?.trim().length > 0) ?? [];

            const intersected = vals.filter(value => dpVals.includes(value));
            pass = intersected.length > 0;
          }
          break;
        case SB_CONTROL_TYPE.Checkboxes:
          if (hasValue) {
            pass = checkboxValues?.trim().length > 0;
          }
          if (hasValues) {
            const vals = hasValues
              .split(',')
              .filter(x => !!x || x?.trim().length > 0);
            const cbVals =
              checkboxValues
                ?.split(',')
                .filter(x => !!x || x?.trim().length > 0) ?? [];

            const intersected = vals.filter(value => cbVals.includes(value));
            pass = intersected.length > 0;
          }
          break;
        case SB_CONTROL_TYPE.Text:
        case SB_CONTROL_TYPE.TextUneditable:
          if (hasValue) {
            pass = !!textValue || textValue?.trim().length > 0;
          }
          break;
        case SB_CONTROL_TYPE.TextPhotos:
          if (hasValue) {
            pass =
              !!textValue || textValue?.trim().length > 0 || files.length > 0;
          }
          pass = minMaxValueCheck(minValue, maxValue, files.length);
          break;
        case SB_CONTROL_TYPE.Numbers:
          if (hasValue) {
            pass = !!numberValue;
          }
          pass = minMaxValueCheck(minValue, maxValue, numberValue);
          break;
        case SB_CONTROL_TYPE.TimeTableUsers:
          if (hasValue) {
            pass = !!timeTableUsers || timeTableUsers?.length > 0;
          }
          pass = minMaxValueCheck(minValue, maxValue, timeTableUsers.length);
          break;
        case SB_CONTROL_TYPE.Checkmark:
          if (hasValue) {
            pass =
              marked || hideMarkedQuestion || (!marked && !!answeredByUserId);
          }
          break;
        default:
          break;
      }
    }

    return pass;
  };

  const checkCriteria = () => {
    if (!criteria || criteria?.length === 0) return true;
    let pass = true;

    // criteria.sort((a, b) => a.id - b.id);
    // console.log('criteria', criteria);

    for (let i = 0; i < criteria.length; i++) {
      const crit = criteria[i];
      pass = criteriaPassCheck(crit);

      // console.log(
      //   control.id,
      //   crit,
      //   pass,
      //   crit.linkBehavior === SB_CRITERIA_BEHAVIOR.AND,
      // );

      if (
        [SB_CRITERIA_BEHAVIOR.OR, SB_CRITERIA_BEHAVIOR.Unset].includes(
          crit.linkBehavior,
        ) &&
        pass
      ) {
        break;
      } else if (crit.linkBehavior === SB_CRITERIA_BEHAVIOR.AND && !pass) {
        break;
      }
    }

    return pass;
  };

  return checkCriteria();
};
