import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import { Container } from 'reactstrap';
import { isMobile } from 'react-device-detect';

// Actions
import { getSigningSchemas } from '../../redux/actions/schemas';

// Utils

// Components
import PageSpinner from '../../components/PageSpinner';
import ControlSchemaSigning from '../../components/ControlSchemas/ControlSchemaSigning';
import { displayCurrentLangObj } from '../../utils/translation/langauges';
import Typography from '../../components/Typography';

const SchemaSigning = props => {
  const { getSigningSchemas, schemas } = props;
  const { schemaGroups, schemaOptions } = schemas;
  const [isLoading, setLoading] = useState(true);
  const [signatureToken, setSignatureToken] = useState(null);
  const [errorOccured, setErrorOccured] = useState(false);

  useEffect(() => {
    const token = props.match.params.token;
    if (!token) {
      props.history('/');
    } else {
      getSigningSchemas(token)
        .then(() => {
          setSignatureToken(token);
        })
        .catch(err => {
          console.log(err);
          setErrorOccured(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line
  }, []);

  const renderSchema = (schema, index, group) => {
    let title;
    const option = schemaOptions.find(x => x.id === group.sbSchemaId);
    if (option) {
      title = displayCurrentLangObj(option.name);
    }

    const sharedProps = {
      schema,
      index,
      groupId: group.id,
      signatureToken,
      title,
    };

    return <ControlSchemaSigning key={index} {...sharedProps} />;
  };

  const renderSchemaGroups = () => {
    if (
      !schemaGroups ||
      schemaGroups?.length === 0 ||
      isLoading ||
      errorOccured
    )
      return null;

    const groups = schemaGroups.filter(x => !x.invoiced);
    if (groups.length === 0) return null;

    const flattendSchemas = [];
    groups.forEach(g => {
      g.schemas.forEach((s, i) => {
        flattendSchemas.push({
          ...s,
          _schemaIndex: i,
          _group: g,
        });
      });
    });

    return (
      <>
        <Typography className='signing_description'>
          <strong>{i18next.t(21187)}</strong>
        </Typography>
        {flattendSchemas.map(x => renderSchema(x, x._schemaIndex, x._group))}
      </>
    );
  };

  const renderError = () => {
    if (!errorOccured || isLoading) return null;

    return (
      <div className='unsupported-container'>
        <p>{i18next.t(6033)}</p>
      </div>
    );
  };

  if (isMobile) {
    return (
      <div className='signing_schemas mobile'>
        {isLoading && <PageSpinner />}
        {renderError()}
        {renderSchemaGroups()}
      </div>
    );
  } else {
    return (
      <div className='signing_schemas'>
        <Container>
          {isLoading && <PageSpinner />}
          {renderError()}
          {renderSchemaGroups()}
        </Container>
      </div>
    );
  }
};

function mapStateToProps({ schemas }) {
  return {
    schemas,
  };
}

export default connect(mapStateToProps, {
  getSigningSchemas,
})(SchemaSigning);
