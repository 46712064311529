import React, { useState } from 'react';
import i18next from '../../i18n';
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import ConfirmModal from './ConfirmModal';
import Typography from '../Typography';
import PageSpinner from '../PageSpinner';
import { MdClose, MdMenu } from 'react-icons/md';

const CreateModal = props => {
  const {
    className,
    extraClass,
    confirmClose,
    burgerOptions,
    showActionSpinner,
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [showBurgerActions, setShowBurgerActions] = useState(false);

  const toggleConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const getCreateButton = () => {
    const color = props.color ? props.color : 'btnPrimary';

    if (props.showCreate) {
      let btnText = props.text ? props.text : i18next.t(18);
      return (
        <Button color={color} onClick={props.toggle}>
          {btnText}
        </Button>
      );
    } else {
      return null;
    }
  };

  const renderActionSpinner = () => {
    if (showActionSpinner) {
      return <PageSpinner color='primary' extraClass='margin-bot-15px' />;
    }
  };

  const renderConfirmModal = () => {
    const confirmAction = () => {
      setShowConfirm(false);
      props.toggle();
      //this.setState({ showConfirm: false }, props.toggle);
    };
    return (
      <ConfirmModal
        header={i18next.t(201)}
        show={showConfirm}
        toggle={toggleConfirm}
        confirmFunc={confirmAction}
        cancelFunc={toggleConfirm}
        color='success'
        cancelColor='danger'
      >
        <Typography>{i18next.t(200)}</Typography>
      </ConfirmModal>
    );
  };

  const renderBurgerOptions = () => {
    const headerAction = confirmClose ? toggleConfirm : props.toggle;
    const toggleNavbar = () => setShowBurgerActions(!showBurgerActions);

    const renderItems = (item, index) => {
      return (
        <DropdownItem key={index} onClick={item.action}>
          {item.title}
        </DropdownItem>
      );
    };

    return (
      <React.Fragment>
        {props.title}

        <ButtonDropdown
          className='float-right'
          isOpen={showBurgerActions}
          toggle={toggleNavbar}
        >
          <DropdownToggle outline>
            <MdMenu size={25} />
          </DropdownToggle>
          <DropdownMenu>{burgerOptions.map(renderItems)}</DropdownMenu>

          <Button onClick={headerAction}>
            <MdClose size={25} />
          </Button>
        </ButtonDropdown>
      </React.Fragment>
    );
  };

  const renderModalHeader = () => {
    const headerAction = confirmClose ? toggleConfirm : props.toggle;
    const showBurgerOptions = burgerOptions?.length > 0;

    if (showBurgerOptions) {
      return (
        <ModalHeader className='modal-header-burger'>
          {renderBurgerOptions()}
        </ModalHeader>
      );
    } else {
      return <ModalHeader toggle={headerAction}>{props.title}</ModalHeader>;
    }
  };

  return (
    <div className={extraClass}>
      {getCreateButton()}
      <Modal
        className={className}
        isOpen={props.show}
        toggle={props.toggle}
        fade={false}
        size={props.size}
        centered
        backdrop='static'
      >
        {renderModalHeader()}
        <ModalBody>
          {renderActionSpinner()}
          {props.children}
        </ModalBody>
      </Modal>
      {confirmClose && renderConfirmModal()}
    </div>
  );
};

CreateModal.defaultProps = {
  confirmClose: false,
};

export default CreateModal;
