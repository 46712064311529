import React, { useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Collapse } from 'reactstrap';
import { MdKeyboardArrowDown } from 'react-icons/md';

// Utils

// Components
import Typography from '../Typography';

const CollapsableCard = props => {
  const {
    id,
    body,
    title,
    headerLeft,
    headerRight,
    extra,
    left,
    footer,
    defaultOpen,
    toggle,
  } = props;
  const [isOpen, setOpen] = useState(!!defaultOpen);

  const toggleAction = () => {
    if (!!toggle) {
      toggle();
    } else {
      setOpen(!isOpen);
    }
  };

  const getIsOpen = () => {
    if (!!toggle) return props.isOpen;

    return isOpen;
  };

  const arrowStatus = getIsOpen() ? ' open' : ' closed';

  const renderExtras = () => {
    if (!extra) return null;

    return <div className='cc-extras'>{extra}</div>;
  };

  const renderLeft = () => {
    if (!left) return null;

    return <div className='cc-left'>{left}</div>;
  };

  const renderHeader = () => {
    const leftTmp = <div className='header-left'>{headerLeft}</div>;
    const rightTmp = <div className='header-right'>{headerRight}</div>;

    if (!!headerLeft && !!headerRight) {
      return (
        <>
          {leftTmp}
          {rightTmp}
        </>
      );
    } else if (!!headerLeft) {
      return leftTmp;
    } else if (!!headerRight) {
      return rightTmp;
    } else if (!!title) {
      return (
        <Typography type='h5' className='header-title'>
          {title}
        </Typography>
      );
    }
    return null;
  };

  const renderFooter = () => {
    if (!footer) return null;

    return <CardFooter>{footer}</CardFooter>;
  };

  return (
    <div id={id} className='collapsable-card'>
      {renderLeft()}
      <Card>
        <CardHeader className='cc-header' onClick={toggleAction}>
          <div className='cc-content'>{renderHeader()}</div>
          <div className={'status-arrow' + arrowStatus}>
            <MdKeyboardArrowDown size={25} />
          </div>
        </CardHeader>
        <Collapse isOpen={getIsOpen()}>
          <CardBody className='cc-body'>{body}</CardBody>
          {renderFooter()}
        </Collapse>
      </Card>
      {renderExtras()}
    </div>
  );
};

export default CollapsableCard;
