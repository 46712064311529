import {
  GET_FOLDERS,
  GET_CURRENT_FOLDER,
  GET_FILES,
  GET_FAVORITES,
  SET_CURRENT_FOLDER,
} from '../constants';

const initialState = {
  folders: [],
  files: [],
  favorites: [],
  currentFolder: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case GET_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case GET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload,
      };
    case GET_FAVORITES:
      return {
        ...state,
        favoritess: action.payload,
      };
    case SET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload,
      };
    default:
      return state;
  }
};
