import { GET_FEATURES } from '../constants';

const initialState = {
  features: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_FEATURES:
      return {
        ...state,
        features: action.payload,
      };
    default:
      return state;
  }
};
