import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import { isMobile } from 'react-device-detect';

// Actions
import { getContacts } from '../../../redux/actions/contacts';

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';
import { getUserData } from '../../../utils/userUtil';
import { EMPLOYEE_TYPE } from '../../../redux/constants';

// Components
import CustomModal from '../../Custom/CustomModal';
import InfoTip from '../../Custom/InfoTip';
import LastEditor from '../../Custom/LastEditor';
import ReactSelect from '../../Custom/ReactSelect';
import ControlItemMetaAdminModal from '../ControlItemMetaAdminModal';

const ControlItemSignature = props => {
  const {
    control,
    disabled,
    employees,
    areControlsValid,
    signingOnly,
    disallowOverwrite, // TODO: Implement this, so only the assignee can sign
    onChangeControlContacts,
    getContacts,
    refreshGroups,
  } = props;
  const {
    title,
    alternativeTitle,
    signatureValue,
    userOptions,
    dropdownSelectedUsers,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    placeholder,
    errorMessage,
  } = control;
  const [valid, setValid] = useState(true);
  const [showSignModal, setShowSignModal] = useState(false);
  const [signerIdMatchCurrentUser, setSignerIdMatchCurrentUser] = useState(
    false,
  );
  const [allowSigning, setAllowSigning] = useState(
    !signatureValue || signatureValue?.trim().length === 0,
  );
  const [contactsRaw, setContactsRaw] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  let sigPad = useRef(null);

  const internals = [
    EMPLOYEE_TYPE.ExecutivePerson,
    EMPLOYEE_TYPE.CompanyOwner,
    EMPLOYEE_TYPE.InternalSubcontractor,
  ];

  const allowedTypes = userOptions?.map(x => x.type) ?? [];
  const selectedIds = dropdownSelectedUsers?.map(x => x.userId) ?? [];
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    if (selectedIds.length === 0) {
      setSignerIdMatchCurrentUser(false);
    } else {
      const tmpUser = getUserData(props.auth);
      setSignerIdMatchCurrentUser(selectedIds.includes(tmpUser.id));
    }

    // eslint-disable-next-line
  }, [selectedIds]);

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [signatureValue]);

  useEffect(() => {
    if (!signingOnly && selectedIds.length > 0) {
      getContacts({ id: selectedIds[0] }).then(res => {
        const options = res.data.map(x => {
          return {
            value: x.id,
            label: x.name,
          };
        });

        setContactsRaw(res.data);
        setContactOptions(options);
      });
    }
    // eslint-disable-next-line
  }, [dropdownSelectedUsers]);

  const toggleModal = () => {
    setShowSignModal(!showSignModal);
    setAllowSigning(!signatureValue || signatureValue?.trim().length === 0);
  };

  const onChangeContact = val => {
    setSelectedContact(val);

    let data;
    if (val) {
      data = contactsRaw.find(x => x.id === val.value);
    }

    onChangeControlContacts(control.id, data);
  };

  const onChange = value => {
    props.onChange({ target: { name: 'signatureValue', value } });
  };

  const onChangeSelection = (name, value) => {
    let val = !!value ? [value] : [];

    if (!!signatureValue?.trim().length > 0) {
      const tmp = val.map(x => x.value);
      if (selectedIds.sort().join(',') !== tmp.sort().join(',')) {
        onChange(null);
      }
    }

    val = val.map(x => {
      return {
        userId: x.value,
      };
    });

    props.onChangeArray(name, val);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  const sharedProps = {
    placeholder,
    closeMenuOnSelect: true,
    isDisabled: disabled || signingOnly,
  };

  const renderDropdown = () => {
    let options = [];
    let value;

    if (allowedTypes?.length > 0) {
      options = employees
        .filter(x => allowedTypes.includes(x.employeeType))
        .map(x => {
          return {
            label: x.name,
            value: x.id,
          };
        });
    }

    if (dropdownSelectedUsers?.length > 0) {
      value = options.find(x => selectedIds.includes(x.value));
    }

    return (
      <ReactSelect
        name='dropdownSelectedUsers'
        options={options}
        value={value}
        onChange={v => onChangeSelection('dropdownSelectedUsers', v)}
        {...sharedProps}
      />
    );
  };

  const renderSigningArea = () => {
    let action =
      (!disabled || signingOnly || signerIdMatchCurrentUser) &&
      !disallowOverwrite
        ? toggleModal
        : null;

    if (!signerIdMatchCurrentUser) action = null;

    return (
      <div
        className={'sb-signature-preview' + (!!action ? ' clickable' : '')}
        onClick={action}
      >
        {!signatureValue && (
          <div className='centered-watermark-text'>
            <div className='centered-watermark-text__text'>
              {i18next.t(1088)}
            </div>
          </div>
        )}
        {signatureValue && (
          <img className='img-preview' src={signatureValue} alt='' />
        )}
      </div>
    );
  };

  const renderModal = () => {
    let cvsProps = {
      height: window.innerHeight * 0.5,
      className: 'signature',
    };

    if (isMobile) {
      cvsProps = {
        height: Math.max(450, window.innerHeight * 0.5),
        className: 'signature',
      };
    }

    const handleSign = () => {
      const val = sigPad.toDataURL('image/png');
      onChange(val);
      setShowSignModal(!showSignModal);
    };

    const handleClear = () => {
      try {
        sigPad.clear();
      } catch (error) {}
      setAllowSigning(true);
    };

    const renderFooter = () => {
      return (
        <div className='footer-btns'>
          {allowSigning && (
            <Button size='sm' color='success' onClick={handleSign}>
              {i18next.t(1093)}
            </Button>
          )}
          <Button size='sm' outline onClick={handleClear}>
            {i18next.t(1092)}
          </Button>
          <Button size='sm' color='btnSecondary' outline onClick={toggleModal}>
            {i18next.t(2)}
          </Button>
        </div>
      );
    };

    return (
      <CustomModal
        show={showSignModal}
        toggle={toggleModal}
        size={'xl'}
        title={title}
        footer={renderFooter()}
      >
        {!allowSigning && (
          <div className='sb-signature-preview sigmodal'>
            <img className='img-preview' src={signatureValue} alt='' />
          </div>
        )}
        {allowSigning && (
          <ReactSignatureCanvas
            canvasProps={cvsProps}
            ref={ref => (sigPad = ref)}
            clearOnResize={false}
          />
        )}
      </CustomModal>
    );
  };

  const renderContact = () => {
    if (signingOnly) return null;
    const user = employees.find(x => x.id === selectedIds[0]);
    let isInternal;

    if (user) {
      isInternal = internals.includes(user.employeeType);
    }

    if (selectedIds.length === 0 || isInternal || user?.isPrivate) return null;

    return (
      <FormGroup row>
        <Label sm={labelSize}>{i18next.t(13)}</Label>
        <Col sm={inputSize}>
          <ReactSelect
            value={selectedContact}
            options={contactOptions}
            onChange={onChangeContact}
            placeholder={i18next.t(1030) + '...'}
            disabled={disabled}
          />
        </Col>
      </FormGroup>
    );
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <>
      <FormGroup row>
        <Label sm={labelSize}>
          <div className='label-wrap'>
            {renderTitle()}
            {renderInfo()}
          </div>
          {titleValid()}
          <LastEditor {...control} />
        </Label>
        <Col sm={inputSize}>{renderDropdown()}</Col>
      </FormGroup>
      {renderContact()}
      <FormGroup row>
        <Label sm={labelSize}>
          <div className='label-wrap'></div>
        </Label>
        <Col sm={inputSize}>
          {renderSigningArea()}
          {!disabled && (
            <ControlItemMetaAdminModal
              control={control}
              callback={refreshGroups}
            />
          )}
        </Col>
        {renderModal()}
      </FormGroup>
    </>
  );
};

function mapStateToProps({ auth, schemas, cases, employees }) {
  return {
    auth,
    schemas,
    cases,
    employees: employees.employees,
  };
}

export default connect(mapStateToProps, { getContacts })(ControlItemSignature);
