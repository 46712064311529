import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../i18n';
import { loginAs } from '../redux/actions/auth';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Progress,
} from 'reactstrap';
import Page from '../components/Page';

// Actions
import {
  getFolder,
  getFolders,
  getFiles,
  setFolderRedux,
  searchFiles,
  searchFolders,
  getCapacity,
} from '../redux/actions/documents';

// Utils
import { getUserData } from '../utils/userUtil';
import { hasMinAccessLevel, hasFeature } from '../utils/contentByAccessLevel';
import { formatSizeUnits } from '../utils/formatSizeUnits';
import withFeature from '../hocs/withFeature';

// Components
import SearchInput from '../components/SearchInput';
import DocumentsOverview from '../components/Custom/DocumentsOverview';
import DocumentsUploadModal from '../components/Custom/DocumentsUploadModal';
import DocumentCreateModal from '../components/Custom/DocumentCreateModal';
import PageSpinner from '../components/PageSpinner';

// Constants
import {
  CUSTOMER_STATUS,
  DOCUMENT_FOLDER_TYPE,
  FEATURE,
} from '../redux/constants';
import { UploadIcon } from '../components/Custom//DocumentIcons';

const DocumentsPage = props => {
  const { auth, features, folders, files } = props;

  const user = getUserData(auth);
  let authTypeCheck =
    props.type === 1
      ? hasMinAccessLevel(auth, 1)
      : user.hasAP && hasFeature(features, [FEATURE.Documents]);

  // State
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [updating, setUpdating] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [customerId] = useState(user.customerId <= 0 ? 0 : user.customerId);
  const [canEdit] = useState(authTypeCheck);
  const [showDocumentsUploadModal, setShowDocumentsUploadModal] = useState(
    false,
  );
  const [showDocumentsCreateModal, setShowDocumentsCreateModal] = useState(
    false,
  );
  const [showGoBack, setShowGoBack] = useState(false);
  const [disableAllFunctions] = useState(
    auth.customerStatus === CUSTOMER_STATUS.Paused,
  );
  const [timer, setTimer] = useState(null);

  // Data
  const [currentFolderParentId, setCurrentFolderParentId] = useState(0);
  const [previousFolderParentId, setPreviousFolderParentId] = useState(0);
  const [currentParentFolder, setCurrentParentFolderState] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [modalData] = useState(null);
  const [sortRule, setSortRule] = useState('ALF');
  const [filter, setFilter] = useState('');
  const [filledCapacity, setFilledCapacity] = useState(0);
  const [fullCapacity, setFullCapacity] = useState(0);

  useEffect(() => {
    setCurrentParentFolder(0);
    navigate(0);
    getCapacity();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDocuments();
    // setLoading(true);
    // setUpdating(true);
    // eslint-disable-next-line
  }, [folders, files]);

  useEffect(() => {
    if (updating) {
      getDocuments();
      setUpdating(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [updating]);

  const navigate = (id, isReturn = false) => {
    setSubmitting(true);
    if (id !== 0) {
      setShowGoBack(true);
    } else {
      setShowGoBack(false);
    }
    setFilter('');
    props
      .getFolders(customerId, id)
      .then(() => {})
      .catch(err => {
        console.log(err);
      });
    props
      .getFiles(customerId, id)
      .then(() => {})
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
    setCurrentParentFolder(id, isReturn);
  };

  const handleRefresh = () => {
    let currentId = props.currentFolder ? props.currentFolder.id : 0;
    //console.log(currentFolderParentId, props.currentFolder);
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }
    setLoading(true);
    if (currentId !== 0) {
      setShowGoBack(true);
    } else {
      setShowGoBack(false);
    }
    setFilter('');

    props
      .getFolders(tempCustomerId, currentFolderParentId)
      .then(() => {
        setUpdating(true);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });

    props
      .getFiles(tempCustomerId, currentFolderParentId)
      .then(() => {
        setUpdating(true);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
    getCapacity();
  };

  const getCapacity = () => {
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }

    props
      .getCapacity(tempCustomerId)
      .then(res => {
        //console.log(res.data);
        setFilledCapacity(Number(res.data.filledCapacity));
        setFullCapacity(Number(res.data.fullCapacity));
      })
      .catch(err => {});
  };

  const getDocuments = () => {
    if (folders !== null) {
      let documents = [];
      let tempFolders = folders;
      let tempFiles = files;
      let tempGlobals = [];
      let tempOther = [];

      switch (sortRule) {
        case 'ALF':
          tempGlobals = tempFolders.filter(a => a.type === 1);
          tempGlobals.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true }),
          );
          tempOther = tempFolders.filter(a => a.type !== 1);
          tempOther.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true }),
          );
          tempFiles.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true }),
          );
          tempFolders = tempGlobals.concat(tempOther);
          break;
        case 'OLD':
          tempGlobals = tempFolders.filter(a => a.type === 1);
          tempGlobals.sort((a, b) =>
            a.updatedDate.localeCompare(b.updatedDate),
          );
          tempOther = tempFolders.filter(a => a.type !== 1);
          tempOther.sort((a, b) => a.updatedDate.localeCompare(b.updatedDate));
          tempFiles.sort((a, b) => a.updatedDate.localeCompare(b.updatedDate));
          tempFolders = tempGlobals.concat(tempOther);
          break;
        case 'NEW':
          tempGlobals = tempFolders.filter(a => a.type === 1);
          tempGlobals.sort((a, b) =>
            b.updatedDate.localeCompare(a.updatedDate),
          );
          tempOther = tempFolders.filter(a => a.type !== 1);
          tempOther.sort((a, b) => b.updatedDate.localeCompare(a.updatedDate));
          tempFiles.sort((a, b) => b.updatedDate.localeCompare(a.updatedDate));
          tempFolders = tempGlobals.concat(tempOther);
          break;
        default:
          tempGlobals = tempFolders.filter(a => a.type === 1);
          tempGlobals.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true }),
          );
          tempOther = tempFolders.filter(a => a.type !== 1);
          tempOther.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true }),
          );
          tempFiles.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true }),
          );
          tempFolders = tempGlobals.concat(tempOther);
          break;
      }
      documents = tempFolders.concat(tempFiles);
      setDocuments(documents);
      setSubmitting(false);
    }
  };

  const setCurrentParentFolder = (id, isReturn = false) => {
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }
    let parentFolder = {};
    if (id === 0) {
      let type =
        tempCustomerId === 0
          ? DOCUMENT_FOLDER_TYPE.Global
          : DOCUMENT_FOLDER_TYPE.Standard;
      parentFolder = {
        id: 0,
        name: i18next.t(7072),
        type: type,
        last_edited: 'Aldrig',
        isFile: false,
        isFolder: true,
        parentId: 0,
        path: '/' + tempCustomerId,
      };
      props.setFolderRedux(parentFolder);
      setCrumbs(parentFolder, isReturn, breadcrumbs);
      setCurrentParentFolderState(parentFolder);
      setCurrentFolderParentId(id);
      setPreviousFolderParentId(parentFolder.id);
    } else {
      props
        .getFolder(tempCustomerId, id)
        .then(res => {
          //console.log(res);
          if (res.data) {
            setCrumbs(res.data, isReturn, breadcrumbs);
            setCurrentParentFolderState(res.data);
            setPreviousFolderParentId(res.data.parentId);
            setCurrentFolderParentId(id);
          }
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    }

    return parentFolder;
  };

  const setCrumbs = (currentFolder, isReturn, oldCrumbs) => {
    let crumbs = [];
    oldCrumbs.forEach(x => {
      if (!crumbs.find(y => y.id === x.id)) {
        crumbs.push(x);
      }
    });

    if (crumbs.find(x => x.id === currentFolder.id)) {
      crumbs.length =
        crumbs.indexOf(crumbs.find(x => x.id === currentFolder.id)) + 1;
    }

    if (!isReturn) {
      crumbs.push({
        ...currentFolder,
        path: ' > ' + currentFolder.name,
      });
    }

    //console.log(crumbs);
    setBreadcrumbs(crumbs);
    setPreviousFolderParentId(currentFolder.parentId);
  };

  const setParentIds = (id, previousId) => {
    setCurrentFolderParentId(id);
    setPreviousFolderParentId(previousId);
    setCurrentParentFolder(id, 0);
  };

  const sortBy = value => {
    switch (value) {
      case 'ALF':
        setSortRule('ALF');
        break;
      case 'OLD':
        setSortRule('OLD');
        break;
      case 'NEW':
        setSortRule('NEW');
        break;
      default:
        setSortRule('ALF');
        break;
    }
    handleRefresh();
  };

  const goBack = () => {
    navigate(previousFolderParentId, true);
  };

  const onFilterChange = e => {
    setFilter(e.target.value);

    clearTimeout(timer);
    const id = setTimeout(() => search(), 1000);
    setTimer(id);
  };

  const search = e => {
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }

    if (filter !== '') {
      setLoading(true);
      const searchObject = {
        Term: filter,
      };

      props
        .searchFolders(tempCustomerId, searchObject)
        .then(() => {
          setLoading(false);
          setUpdating(true);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });

      props
        .searchFiles(tempCustomerId, searchObject)
        .then(() => {
          setLoading(false);
          setUpdating(true);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    } else {
      handleRefresh();
    }
  };

  const toggleDocumentsCreateModal = () => {
    setShowDocumentsCreateModal(!showDocumentsCreateModal);
    if (showDocumentsCreateModal) {
      setTimeout(handleRefresh(), 1000);
    }
  };

  const renderGoBack = () => {
    return (
      <Button
        outline
        color='secondary'
        className='float-left btn m-1'
        onClick={goBack}
        //size='sm'
      >
        {i18next.t(1039)}
      </Button>
    );
  };

  const renderCreateFolder = () => {
    return (
      <Button
        outline
        color='btnSecondary'
        className='float-right btn m-1'
        onClick={toggleDocumentsCreateModal}
        //size='sm'
        disabled={filter !== '' ? true : false}
      >
        {i18next.t(7073)}
      </Button>
    );
  };

  const renderUpload = () => {
    return (
      <Button
        color='btnSecondary'
        className='float-right btn m-1'
        onClick={() => setShowDocumentsUploadModal(!showDocumentsUploadModal)}
        //size='sm'
        disabled={filter !== '' ? true : false}
      >
        <img
          src={UploadIcon}
          alt=''
          className='document-tools-icon white margin-right-10px'
        />
        {i18next.t(7074)}
      </Button>
    );
  };

  const renderStorageCapacity = () => {
    const perc = ((filledCapacity / fullCapacity) * 100).toFixed(2);
    let color = 'btnSecondary';
    if (perc > 95) {
      color = 'danger';
    }

    return (
      <div className='float-right m-1'>
        <div className='text-align-center'>
          {formatSizeUnits(filledCapacity)} / {formatSizeUnits(fullCapacity)}
        </div>
        <Progress color={color} className='capacity-bar' value={perc}>
          {perc >= 10 && <React.Fragment>{perc} %</React.Fragment>}
        </Progress>
      </div>
    );
  };

  return (
    <Page title={i18next.t(7072)} breadcrumbs={breadcrumbs}>
      {showDocumentsUploadModal && (
        <DocumentsUploadModal
          show={showDocumentsUploadModal}
          toggle={() => setShowDocumentsUploadModal(!showDocumentsUploadModal)}
          handleRefresh={handleRefresh}
          filledCapacity={filledCapacity}
          fullCapacity={fullCapacity}
          documents={documents}
          currentFolderParentId={currentFolderParentId}
        />
      )}

      {showDocumentsCreateModal && (
        <DocumentCreateModal
          currentFolderParentId={currentFolderParentId}
          show={showDocumentsCreateModal}
          updating={updating}
          modalData={modalData}
          documents={documents}
          toggle={toggleDocumentsCreateModal}
          handleRefresh={handleRefresh}
        />
      )}

      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col className='align-self-center col'>
                  {showGoBack && renderGoBack()}
                </Col>
                {!disableAllFunctions && (
                  <Col className='align-self-center col'>
                    {!loading &&
                      ((currentParentFolder.type ===
                        DOCUMENT_FOLDER_TYPE.Global &&
                        customerId <= 0) ||
                        (currentParentFolder.type !==
                          DOCUMENT_FOLDER_TYPE.Global &&
                          customerId >= 1) ||
                        currentParentFolder.id === 0) &&
                      canEdit && (
                        <React.Fragment>
                          {renderCreateFolder()}
                          {renderUpload()}
                        </React.Fragment>
                      )}
                  </Col>
                )}
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <SearchInput onChange={onFilterChange} onBlur={search} />
                </Col>
                <Col>
                  {submitting && (
                    <div className='documents-loading'>
                      <PageSpinner />
                    </div>
                  )}
                </Col>
                <Col>{!loading && canEdit && renderStorageCapacity()}</Col>
              </Row>
              <Row>
                <Col>
                  {!loading && (
                    <DocumentsOverview
                      props={props}
                      currentFolderParentId={currentFolderParentId}
                      previousFolderParentId={previousFolderParentId}
                      setParentIds={setParentIds}
                      toggleReturn={() => setShowGoBack(!showGoBack)}
                      isModal={false}
                      navigate={navigate}
                      documents={documents}
                      handleRefresh={handleRefresh}
                      //sortDropdown={sortDropdown}
                      sortBy={sortBy}
                      parentFolder={currentParentFolder}
                      breadcrumbs={[
                        {
                          id: 0,
                          name: 'Root',
                          type: 1,
                          last_edited: 'Aldrig',
                          isFile: false,
                          isFolder: true,
                          parentId: 0,
                          path: 'Hjem',
                        },
                        ...breadcrumbs.slice(1, breadcrumbs.length - 1),
                      ]}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    currentFolder: state.documents.currentFolder,
    folders: state.documents.folders,
    files: state.documents.files,
    features: state.features.features,
  };
}

export default connect(mapStateToProps, {
  loginAs,
  getFolder,
  getFolders,
  getFiles,
  setFolderRedux,
  searchFiles,
  searchFolders,
  getCapacity,
})(withFeature(DocumentsPage, [FEATURE.Documents, FEATURE.DocumentsBasic]));
