import React from 'react';
import i18next from '../i18n';
import { MdSearch } from 'react-icons/md';
import { Form, Input } from 'reactstrap';

const SearchInput = props => {
  const { placeholderExtra, placeholder, className } = props;

  let ph = i18next.t(39);
  if (placeholder) {
    ph = placeholder;
  }

  let classNames = `cr-search-form__input ${className}`;

  return (
    <Form inline className='cr-search-form' onSubmit={e => e.preventDefault()}>
      <MdSearch
        size='20'
        className='cr-search-form__icon-search text-secondary'
      />
      <Input
        type='search'
        className={classNames}
        onChange={props.onChange}
        placeholder={ph + (placeholderExtra ? placeholderExtra : '') + '...'}
        autoComplete='new-password'
        value={props.value}
        disabled={props.disabled}
      />
    </Form>
  );
};

export default SearchInput;
