import React from 'react';
import i18next from '../../i18n';
import {
  Button,
  Modal,
  Card,
  CardBody,
  //CardHeader,
  Row,
  Col,
} from 'reactstrap';

// Utils

// Components
import PageSpinner from '../PageSpinner';

const DocumentConfirmModal = props => {
  const {
    //color,
    //cancelColor,
    showActionSpinner,
    confirmText,
    cancelText,
  } = props;

  let toggleAction = !showActionSpinner ? props.toggle : null;

  let btnConfirmText = confirmText ? confirmText : i18next.t(1);
  let btnCancelText = cancelText ? cancelText : i18next.t(2);

  return (
    <Modal isOpen={props.show} toggle={toggleAction} centered backdrop='static'>
      <Card>
        {props.header && (
          <div className='document-confirm-header'>{props.header}</div>
        )}
        <CardBody>
          {showActionSpinner && (
            <PageSpinner color='primary' extraClass='margin-bot-15px' />
          )}
          <Row>
            <div className='document-confirm-body'>{props.body}</div>
          </Row>
          <Row>
            <Col>
              <Button
                color='danger'
                className='float-right m-1'
                //color={color}
                onClick={props.confirmFunc}
                disabled={showActionSpinner}
              >
                {btnConfirmText}
              </Button>
              <Button
                outline
                color='btnSecondary'
                className='float-right m-1'
                //color={cancelColor}
                onClick={props.cancelFunc}
                disabled={showActionSpinner}
              >
                {btnCancelText}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  );
};

DocumentConfirmModal.defaultProps = {
  color: 'danger',
  cancelColor: 'btnSecondary',
};

export default DocumentConfirmModal;
