import { MdSettings, MdWidgets } from 'react-icons/md';
import i18next from '../i18n';
//import { splitText } from './common';
import { LogoutIcon } from '../components/Custom/Icons';
import { ACCESS_LEVEL, FEATURE } from '../redux/constants';
import {
  IconBi,
  IconDokumenter,
  IconEksterneParter,
  IconForsikringsselskaber,
  IconInfo,
  IconInterneBrugere,
  //IconKontrolplan,
  IconKundegrupperVirksomheder,
  IconKundeVirksomhed,
  IconKvalitetssikring,
  IconProdukter,
  IconSagsoverblik,
  IconServiceaftale,
  IconSystembeskeder,
  IconSysteminfo,
  IconTermsConditions,
  IconVarmtArbejde,
  Notifikationer2,
  IconSkemabygger,
  IconSprog,
} from '../assets/icons_v2';

export const hasMinAccessLevel = (auth, minLevel, reverse = false) => {
  // 1 - Super, 2 - Local, 3 - Responsible

  if (auth.loginAsUser) {
    const _user = JSON.parse(auth.loginAsUser);

    if (reverse) {
      return _user.loginData.accessLevel >= minLevel;
    }
    return _user.loginData.accessLevel <= minLevel;
  } else {
    if (reverse) {
      return auth.user.accessLevel >= minLevel;
    }
    return auth.user.accessLevel <= minLevel;
  }
};

export const getLoginAsUserData = auth => {
  let data = null;

  if (auth) {
    if (auth.loginAsUser) {
      data = auth.loginAsUser;
    }
  }

  return data;
};

export const isAllowedAccess = (user, featureNumber) => {
  let allowed = false;

  if (user) {
    const features = user.accessableFeatures.split(',');
    const found = features.find(feat => feat === `${featureNumber}`);

    if (found) {
      allowed = true;
    }
  }

  return allowed;
};

export const sidebarRoutes = (
  accessLevel,
  isLogoutAs,
  isLogoutName,
  accessibleFeatures,
  userData,
) => {
  let navItems = [];
  if (accessLevel <= 0) {
    return navItems;
  }

  const isSuperAdmin = accessLevel === ACCESS_LEVEL.SuperAdmin;
  const isLocalAdmin = accessLevel === ACCESS_LEVEL.LocalAdmin;

  if (isSuperAdmin || isLocalAdmin) {
    const excludedFeatures = [
      FEATURE.Hotwork,
      FEATURE.QualityInsurance,
      FEATURE.ServiceAgreement,
      FEATURE.Materials,
      FEATURE.Roadmap,
    ];
    accessibleFeatures = accessibleFeatures.filter(
      x => !excludedFeatures.includes(x.type),
    );
  }

  // Get available features
  navItems.push(...getFeatures(accessibleFeatures));

  // Standard features
  if (userData?.employeeType > 0 || isSuperAdmin || isLocalAdmin) {
    navItems.push({
      to: '/products',
      name: i18next.t(3279),
      exact: true,
      Icon: IconProdukter,
      order: 6,
    });
  }

  // Role specific features
  if (isSuperAdmin) {
    navItems.push(
      {
        to: '/groups',
        name: i18next.t(6),
        exact: true,
        Icon: IconKundegrupperVirksomheder,
        order: 7,
      },
      {
        to: '/insurances',
        name: i18next.t(61),
        exact: true,
        Icon: IconForsikringsselskaber,
        order: 9,
      },
      {
        to: '/systeminfo',
        name: i18next.t(3264),
        exact: true,
        Icon: IconSysteminfo,
        order: 10,
      },
      {
        to: '/systemMessages',
        name: i18next.t(6042),
        exact: true,
        Icon: IconSystembeskeder,
        order: 11,
      },
      {
        to: '/bi',
        name: i18next.t(182),
        exact: true,
        Icon: IconBi,
        order: 12,
      },
      // {
      //   to: '/template',
      //   name: i18next.t(6009),
      //   exact: true,
      //   Icon: IconKontrolplan,
      //   order: 13,
      // },
      {
        to: '/policies',
        name: i18next.t(6434),
        exact: true,
        Icon: IconTermsConditions,
        order: 14,
      },
      {
        to: '/schemabuilder',
        name: i18next.t(11000),
        exact: true,
        Icon: IconSkemabygger,
        order: 22,
      },
      {
        to: '/language',
        name: i18next.t(67),
        exact: true,
        Icon: IconSprog,
        order: 23,
      },
    );
  }

  if (accessLevel <= ACCESS_LEVEL.LocalAdmin) {
    navItems.push({
      to: '/customers',
      name: i18next.t(8),
      exact: true,
      Icon: IconKundeVirksomhed,
      order: 8,
    });
  }

  if (accessLevel <= ACCESS_LEVEL.SuperAdmin) {
    navItems.push({
      to: '/featureconfig',
      name: i18next.t(7052),
      exact: true,
      Icon: MdWidgets,
      order: 15,
    });
  }

  if (accessLevel <= ACCESS_LEVEL.CustomerAdmin) {
    navItems.push(
      {
        to: '/users',
        name: i18next.t(6035),
        exact: true,
        Icon: IconInterneBrugere,
        order: 16,
      },
      {
        to: '/external-parties',
        name: i18next.t(6036),
        exact: true,
        Icon: IconEksterneParter,
        order: 17,
      },
      {
        to: '/systempush',
        name: i18next.t(6060),
        exact: true,
        Icon: Notifikationer2,
        order: 18,
      },
      {
        to: '/systeminfo-view',
        name: i18next.t(3266),
        exact: true,
        Icon: IconInfo,
        order: 19,
      },
    );
  }

  // if (accessLevel <= ACCESS_LEVEL.Employee) {
  //   navItems.push({
  //     to: '/profile',
  //     name: i18next.t(7007),
  //     exact: true,
  //     Icon: MdAccountCircle,
  //   });
  // }

  // Logout is always final item
  if (isLogoutAs) {
    navItems.push({
      to: '/logout',
      name: logoutText(isLogoutAs, isLogoutName),
      exact: true,
      Icon: LogoutIcon,
      isLogoutAs: isLogoutAs,
      svg: true,
      order: 20,
    });
  }

  if (accessLevel === ACCESS_LEVEL.CustomerAdmin) {
    navItems.push({
      to: '/settings',
      name: i18next.t(149),
      exact: true,
      Icon: MdSettings,
      order: 21,
    });
  }

  if (isSuperAdmin || isLocalAdmin) {
    const excludedRoutes = ['/users', '/external-parties'];
    navItems = navItems.filter(x => !excludedRoutes.includes(x.to));
  }

  navItems.sort((a, b) => a.order - b.order);

  return navItems;
};

function logoutText(loggedInAs, isLogoutName) {
  let text = '';
  if (loggedInAs) {
    text = `${i18next.t(75)}\n${isLogoutName}`;
  } else {
    text = i18next.t(12);
  }

  return text;
}

export const featureCheckingEnabled = () => {
  return process.env.REACT_APP_ENABLE_FEATURE_CHECKING === 'true';
};

function getFeatures(features) {
  const featureList = [
    {
      to: '/roadmap',
      name: i18next.t(9007),
      exact: true,
      Icon: IconSagsoverblik,
      svg: false,
      feature: [FEATURE.Roadmap, FEATURE.Materials],
      order: 1,
    },
    {
      to: '/hotwork',
      name: i18next.t(35),
      exact: true,
      Icon: IconVarmtArbejde,
      feature: FEATURE.Hotwork,
      order: 2,
    },
    {
      to: '/quality',
      name: i18next.t(36),
      exact: true,
      Icon: IconKvalitetssikring,
      feature: FEATURE.QualityInsurance,
      order: 3,
    },
    {
      to: '/serviceAgreements',
      name: i18next.t(3224),
      exact: true,
      Icon: IconServiceaftale,
      feature: FEATURE.ServiceAgreement,
      order: 4,
    },
    {
      to: '/documents',
      name: i18next.t(7072),
      exact: true,
      Icon: IconDokumenter,
      feature: [FEATURE.Documents, FEATURE.DocumentsBasic],
      order: 5,
    },
  ];

  return featureList.filter(x => {
    const temp = features.filter(y => y.active).map(y => y.type);

    if (Array.isArray(x.feature)) {
      let found = false;
      for (let i = 0; i < x.feature.length; i++) {
        const feat = x.feature[i];

        found = temp.includes(feat);

        if (found) break;
      }

      return found;
    } else {
      return temp.includes(x.feature);
    }
  });
}

export const hasFeature = (
  features,
  featureTypes,
  isServiceAgreement = false,
) => {
  for (let index = 0; index < featureTypes.length; index++) {
    let featureType = featureTypes[index];

    if (isServiceAgreement && featureType === FEATURE.QualityInsurance) {
      featureType = FEATURE.ServiceAgreement;
    }

    if (
      features
        .filter(x => x.active)
        .map(x => x.type)
        .includes(featureType)
    )
      return true;
  }

  return false;
};
