import React, { useState } from 'react';
import i18next from '../../i18n';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';

// Actions

// Utils

// Components

const ImagePickerModal = ({ files, onImageClick, toggle, show, isSaving }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const gallery = files ?? [];

  const onSetImageAction = () => {
    if (selectedImage) {
      onImageClick(selectedImage);
    }
  };

  const renderContent = () => {
    return (
      <div className='move-images-wrapper'>
        <div className='control-gallery'>
          {gallery.map((image, index) => {
            // console.log(image);
            const origName = image.fileTitle;
            const target = 'tp-' + image.id;

            return (
              <div
                key={index}
                className={
                  'image-wrapper' +
                  (selectedImage?.id === image.id ? ' active' : '')
                }
                onClick={() => setSelectedImage(image)}
              >
                <img className='image' src={image.thumbUrl} alt={origName} />
                <div className='name-wrap'>
                  <p id={target} className='name'>
                    {origName}
                  </p>
                </div>
                <UncontrolledTooltip className='image-tooltip' target={target}>
                  {origName}
                </UncontrolledTooltip>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <ModalFooter>
        <div className='footer-btns move-images'>
          <div className='right-btns'>
            <Button
              onClick={toggle}
              disabled={isSaving}
              color='btnSecondary'
              outline
            >
              {i18next.t(2)}
            </Button>
            <Button
              onClick={onSetImageAction}
              disabled={!selectedImage || isSaving}
              color='btnSecondary'
            >
              {i18next.t(254)}
            </Button>
          </div>
        </div>
      </ModalFooter>
    );
  };

  return (
    <Modal
      className='custom-modal moving-images'
      isOpen={show}
      toggle={toggle}
      fade={false}
      size={'xl'}
      centered
    >
      <ModalHeader toggle={toggle}>{i18next.t(1327)}</ModalHeader>
      <ModalBody>{renderContent()}</ModalBody>
      {renderFooter()}
    </Modal>
  );
};

export default ImagePickerModal;
