import {
  SET_HEADERINFO,
  SET_USER_LANGUAGE,
  SET_BRANDING,
  TOA_HANDLED,
  SET_SA_FILTER,
  GET_SA_FILTER,
  GET_USER_FILTERS,
  UPDATE_USER_FILTERS,
  GET_AGREEMENT_SCHEMA_SETTINGS,
  GET_HIDE_MODAL_SETTINGS,
  UPDATE_HIDE_MODAL_SETTINGS,
  IMAGE_HASH_REFRESH,
} from '../constants';

const initialState = {
  headerInfo: {
    companyName: '',
  },
  user_lang: 'eng',
  user_branding: null,
  termsOfAgreementHandled: false,
  filterStorage: {},
  agreementSchemaModalSettings: [],
  userFilters: null,
  hideModalSetting: null,
  imageHash: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_HEADERINFO:
      return {
        ...state,
        headerInfo: action.headerInfo,
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        user_lang: action.user_lang,
      };
    case SET_BRANDING:
      return {
        ...state,
        user_branding: action.user_branding,
      };
    case TOA_HANDLED:
      return {
        ...state,
        termsOfAgreementHandled: action.payload,
      };
    case SET_SA_FILTER:
      return {
        ...state,
        filterStorage: action.payload,
      };
    case GET_SA_FILTER:
      return {
        ...state,
        filterStorage: action.payload,
      };
    case GET_AGREEMENT_SCHEMA_SETTINGS:
      return {
        ...state,
        agreementSchemaModalSettings: action.payload,
      };
    case GET_USER_FILTERS:
      return {
        ...state,
        userFilters: action.payload,
      };
    case UPDATE_USER_FILTERS:
      return {
        ...state,
        userFilters: action.payload,
      };
    case GET_HIDE_MODAL_SETTINGS:
      return {
        ...state,
        hideModalSetting: action.payload,
      };
    case UPDATE_HIDE_MODAL_SETTINGS:
      return {
        ...state,
        hideModalSetting: action.payload,
      };
    case IMAGE_HASH_REFRESH:
      return {
        ...state,
        imageHash: action.payload,
      };
    default:
      return state;
  }
};
