import {
  GET_CONTROLPLANS,
  SET_CONTROLPLANS,
  GET_CONTROLPLAN,
  SET_CONTROLPLAN,
  GET_CONTROLPLAN_ON_CASE,
  SET_CONTROLPLAN_ON_CASE,
} from '../constants';

const initialState = {
  controlPlans: [],
  controlPlansOnCase: [],
  currentControlPlan: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CONTROLPLANS:
      return {
        ...state,
        controlPlans: [...action.payload],
      };
    case SET_CONTROLPLANS:
      return {
        ...state,
        controlPlans: [...action.payload],
      };
    case GET_CONTROLPLAN:
      return {
        ...state,
        currentControlPlan: action.payload,
      };
    case SET_CONTROLPLAN:
      return {
        ...state,
        currentControlPlan: action.payload,
      };
    case GET_CONTROLPLAN_ON_CASE:
      return {
        ...state,
        controlPlansOnCase: action.payload,
      };
    case SET_CONTROLPLAN_ON_CASE:
      return {
        ...state,
        controlPlansOnCase: action.payload,
      };
    default:
      return state;
  }
};
