import {
  SB_GET_SCHEMA_CATEGORIES,
  //   SB_GET_SCHEMAS_WITH_INDEX,
  //   SB_ADD_SCHEMA_TO_CATEGORY,
  //   SB_UPDATE_SCHEMA_IN_CATEGORY,
  //   SB_REMOVE_SCHEMA_FROM_CATEGORY,
  //   SB_ADD_SCHEMA_PACKAGE,
  //   SB_UPDATE_SCHEMA_PACKAGE,
  //   SB_REMOVE_SCHEMA_PACKAGE,
  //   SB_GET_SCHEMA_PACKAGES_INDICE,
  SB_SET_CURRENT_SCHEMA_CATEGORY,
  //   SB_ADD_CATEGORY_TO_PACKAGES,
  //   SB_UPDATE_CATEGORY_IN_PACKAGES,
  //   SB_REMOVE_CATEGORY_FROM_PACKAGE,
  SB_SET_CURRENT_SCHEMA_PACKAGE,
  //   SB_GET_PACKAGE_SCHEMAS,
  //   SB_ADD_SCHEMA_TO_PACKAGE,
  //   SB_UPDATE_SCHEMA_IN_PACKAGE,
  //   SB_REMOVE_SCHEMA_FROM_PACKAGE,
  SB_GET_SCHEMAS,
  SB_GET_SCHEMA,
  //   SB_ADD_SCHEMA,
  SB_SET_CURRENT_SCHEMA,
  //   SB_UPDATE_SCHEMA,
  //   SB_REMOVE_SCHEMA,
  SB_GET_RELATION_CONTROLS,
  SB_SET_RELATION_CONTROL,
  //   SB_ADD_RELATION_CONTROL,
  //   SB_UPDATE_RELATION_CONTROL,
  //   SB_REMOVE_RELATION_CONTROL,
  SB_GET_RELATION_GROUPS,
  SB_SET_RELATION_GROUP,
  SB_GET_CRITERIA,
  //   SB_ADD_CRITERIA,
  //   SB_UPDATE_CRITERIA,
  //   SB_REMOVE_CRITERIA,
  SB_GET_CONTROL_ITEMS,
  SB_GET_CONTROL_ITEM,
  //   SB_ADD_CONTROL_ITEM,
  //   SB_UPDATE_CONTROL_ITEM,
  //   SB_REMOVE_CONTROL_ITEM,
  SB_SET_CONTROL_ITEM,
} from '../constants';

const initialState = {
  packages: [],
  schemas: [],
  categories: [],
  controls: [],
  currentSchema: null,
  currentControlItem: null,
  currentPackage: null,
  currentCategory: null,
  relations: [],
  groups: [],
  criterias: [],
  relation: null,
  group: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SB_GET_SCHEMAS:
      return {
        ...state,
        schemas: action.payload,
      };
    case SB_GET_SCHEMA:
    case SB_SET_CURRENT_SCHEMA:
      return {
        ...state,
        currentSchema: action.payload,
      };
    case SB_GET_CONTROL_ITEMS:
      return {
        ...state,
        controls: action.payload,
      };
    case SB_GET_CONTROL_ITEM:
    case SB_SET_CONTROL_ITEM:
      return {
        ...state,
        currentControlItem: action.payload,
      };
    case SB_GET_SCHEMA_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SB_SET_CURRENT_SCHEMA_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      };
    case SB_SET_CURRENT_SCHEMA_PACKAGE:
      return {
        ...state,
        currentPackage: action.payload,
      };
    case SB_GET_RELATION_CONTROLS:
      return {
        ...state,
        relations: action.payload,
      };
    case SB_SET_RELATION_CONTROL:
      return {
        ...state,
        relation: action.payload,
      };
    case SB_GET_RELATION_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case SB_SET_RELATION_GROUP:
      return {
        ...state,
        group: action.payload,
      };
    case SB_GET_CRITERIA:
      return {
        ...state,
        criterias: action.payload,
      };
    default:
      return state;
  }
};
