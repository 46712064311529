import React, { useState } from 'react';
import i18next from '../../i18n';
import { Button } from 'reactstrap';

// Actions

// Utils

// Components
import ImagePickerModal from './ImagePickerModal';

const GalleryPicker = ({ files, disabled, onImageClick, isSaving }) => {
  const [showModal, setShowModal] = useState(false);
  const casefiles = files ?? [];

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className='galleryPicker'>
      <Button size='sm' color='secondary' outline onClick={toggleModal}>
        {i18next.t(1328)}
      </Button>
      <div className='gallery'>
        <ImagePickerModal
          files={casefiles}
          show={showModal}
          toggle={toggleModal}
          disabled={disabled}
          onImageClick={onImageClick}
          isSaving={isSaving}
        />
      </div>
    </div>
  );
};

export default GalleryPicker;
