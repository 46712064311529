import * as React from 'react';

const SvgIconSignatur = props => (
  <svg
    data-name='Icon - signatur'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Signatur_svg__a'>
        <path data-name='Rectangle 1237' d='M0 0h21.944v19.459H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1236' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 233'>
      <g
        data-name='Group 232'
        clipPath='url(#Icon_-_Signatur_svg__a)'
        transform='translate(1.219 2.079)'
      >
        <path
          data-name='Path 280'
          d='M2.615 13.285 0 14.912v3.912a.633.633 0 0 0 .633.633h13.715a.632.632 0 0 0 .633-.633v-6.789l-2.473 2.477a1.9 1.9 0 0 1-.97.519l-2.984.6a1.9 1.9 0 0 1-2.269-1.82l-1.315 1.12a1.35 1.35 0 0 1-2.225-1.028v-.54a.077.077 0 0 0-.043-.073.081.081 0 0 0-.087 0m4.82-3.85 4.746-4.746H.633A.632.632 0 0 0 0 5.322v8.1l1.949-1.21a1.35 1.35 0 0 1 2.06 1.148v.54a.077.077 0 0 0 .049.077.076.076 0 0 0 .089-.012l1.814-1.553a.632.632 0 0 1 .578-.128l.377-1.882a1.9 1.9 0 0 1 .519-.97m10.891-2.537-3.281-3.283-6.714 6.714a.631.631 0 0 0-.173.323l-.6 2.984a.633.633 0 0 0 .621.757.614.614 0 0 0 .124-.012l2.984-.6a.622.622 0 0 0 .323-.173Zm3.432-5.52L20.565.182a.632.632 0 0 0-.895 0l-.746.746-.15-.149a.632.632 0 0 0-.895 0l-1.94 1.94 3.282 3.282 1.94-1.94a.632.632 0 0 0 0-.895l-.15-.149.746-.746a.632.632 0 0 0 0-.9'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconSignatur;
