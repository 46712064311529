import * as React from 'react';

function SvgIconDownload2(props) {
  return (
    <svg
      data-name='Component 70'
      xmlns='http://www.w3.org/2000/svg'
      width={25}
      height={25}
      viewBox='0 0 24 24'
      {...props}
    >
      <defs>
        <clipPath id='a'>
          <path data-name='Rectangle 1258' fill='none' d='M0 0H24V24H0z' />
        </clipPath>
      </defs>
      <g data-name='Mask Group 1' clipPath='url(#a)'>
        <g data-name='Icon - Download' transform='translate(0 .024)'>
          <path
            data-name='Rectangle 1263'
            transform='translate(0 -.024)'
            fill='none'
            d='M0 0H24V24H0z'
          />
          <path
            data-name='Rectangle_1258'
            transform='translate(1.428 .96)'
            fill='none'
            d='M0 0H21.6V21.6H0z'
          />
          <g>
            <path
              data-name='Path 305'
              d='M4.692 23.028A3.214 3.214 0 011.5 19.8v-4.392a1.02 1.02 0 112.04 0v4.4a1.165 1.165 0 001.152 1.176h15.18a1.165 1.165 0 001.152-1.176v-4.4a1.02 1.02 0 112.04 0v4.4a3.2 3.2 0 01-3.18 3.216z'
              transform='translate(-.283 -.283)'
            />
            <path
              data-name='Path 306'
              d='M12.288 16.56a1 1 0 01-.732-.312l-5.364-5.5a1.018 1.018 0 111.464-1.412l4.632 4.752 4.632-4.752a1.018 1.018 0 111.464 1.416l-5.364 5.5a1.038 1.038 0 01-.732.308z'
              transform='translate(-.283 -.283)'
            />
            <path
              data-name='Path 307'
              d='M12.288 16.56a1.019 1.019 0 01-1.02-1.02V2.5a1.02 1.02 0 112.04 0v13.052a1.017 1.017 0 01-1.02 1.008z'
              transform='translate(-.283 -.283)'
            />
          </g>
        </g>
      </g>
      <g
        data-name='Icon feather-download'
        fill='none'
        //stroke='#003341'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      >
        <path
          data-name='Path 299'
          d='M24.216 17.55v4.444a2.207 2.207 0 01-2.191 2.222H6.691A2.207 2.207 0 014.5 21.994V17.55'
          transform='translate(-2.357 -2.357)'
        />
        <path
          data-name='Path 300'
          d='M8.944 12.125l5.414 5.555 5.414-5.555'
          transform='translate(-2.357 -2.357)'
        />
        <path
          data-name='Path 301'
          d='M14.358 17.681V4.5'
          transform='translate(-2.357 -2.357)'
        />
      </g>
    </svg>
  );
}

export default SvgIconDownload2;
