import i18next from '../i18n';

export const GetErrorCodeDescription = code => {
  const errorCodes = {
    1: i18next.t(1106),
    2: i18next.t(1107),
    8: i18next.t(1108),
    9: i18next.t(1109),
    10: i18next.t(1110),
    11: i18next.t(1111),
    12: i18next.t(1112),
    13: i18next.t(1113),
    14: i18next.t(1114),
    15: i18next.t(1115),
    16: i18next.t(1116),
    17: i18next.t(1117),
    18: i18next.t(1118),
    19: i18next.t(1119),
    20: i18next.t(1120),
    21: i18next.t(1121),
    22: i18next.t(1122),
    23: i18next.t(1123),
    24: i18next.t(1124),
    25: i18next.t(1125),
    26: i18next.t(1126),
    27: i18next.t(1127),
    28: i18next.t(1128),
    29: i18next.t(1129),
    30: i18next.t(1130),
    31: i18next.t(1131),
    32: i18next.t(1132),
    33: i18next.t(1133),
    34: i18next.t(1134),
    35: i18next.t(1135),
    36: i18next.t(1136),
    37: i18next.t(1137),
    38: i18next.t(1138),
    39: i18next.t(1139),
    40: i18next.t(1140),
    41: i18next.t(1141),
    42: i18next.t(1245),
    43: i18next.t(1249),
    44: i18next.t(1250),
    45: i18next.t(1251),
    46: i18next.t(1252),
    47: i18next.t(1262),
    48: i18next.t(1263),
    49: i18next.t(7042),
    50: i18next.t(21234),

    // Alt:
    81: i18next.t(81),
    1111: i18next.t(1111),
    1112: i18next.t(1112),
  };

  if (!code) {
    return errorCodes[1];
  }
  return errorCodes[code];
};
