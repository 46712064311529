import * as React from 'react';

const SvgIconBi = props => (
  <svg
    data-name='Icon - Bi'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Bi_svg__a'>
        <path data-name='Rectangle 1191' d='M0 0h21.433v19.683H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1190' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 185'>
      <g
        data-name='Group 184'
        clipPath='url(#Icon_-_Bi_svg__a)'
        transform='translate(1.381 2.084)'
      >
        <path
          data-name='Path 252'
          d='M11.788 8.585v9.228a1.87 1.87 0 0 0 3.739 0V8.585a1.87 1.87 0 0 0-3.739 0m-5.894 5.132v4.1a1.87 1.87 0 1 0 3.739 0v-4.1a1.87 1.87 0 0 0-3.739 0M0 8.585v9.228a1.87 1.87 0 0 0 3.739 0V8.585a1.87 1.87 0 0 0-3.739 0M21.362 4.93a1.382 1.382 0 0 0-2.191-1.119l-3.917-2.262a.732.732 0 0 0 .012-.167 1.381 1.381 0 1 0-2.762 0 1.449 1.449 0 0 0 .083.452l-4.334 4.31a1.536 1.536 0 0 0-.476-.083 1.614 1.614 0 0 0-.429.071L3 1.786a1.519 1.519 0 0 0 .071-.4A1.381 1.381 0 1 0 1.69 2.767a1.461 1.461 0 0 0 .464-.083L6.478 7a1.349 1.349 0 0 0-.071.429 1.381 1.381 0 0 0 2.762 0 1.261 1.261 0 0 0-.06-.369l4.382-4.36a1.34 1.34 0 0 0 1.084-.131L18.6 4.894v.024A1.382 1.382 0 0 0 19.98 6.3a1.365 1.365 0 0 0 1.381-1.369m.071 12.884V10.4a1.87 1.87 0 0 0-3.739 0v7.418a1.87 1.87 0 0 0 3.739 0'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconBi;
