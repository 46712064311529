import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
} from '../constants';

const initialState = {
  data: [],
  settings: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_NOTIFICATION_SETTINGS:
    case UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    default:
      return state;
  }
};
