import React, { useState } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../../i18n';
import { FormText } from 'reactstrap';

// Actions

// Utils
import { parseDate, StringIsEmpty } from '../../../../utils/common';
import { displayCurrentLangObj } from '../../../../utils/translation/langauges';

// Components
import TextInput from '../../Forms/TextInput';
import Typography from '../../../Typography';
import ControlItem from '../../../ControlSchemas/ControlItem';
import CollapsableCard from '../../CollapsableCard';

const SchemaItem = props => {
  const {
    schema,
    groupIndex,
    isServiceAgreement,
    selectControl,
    _targetControlId,
    imageObjects,
  } = props;
  const { currentSchemaGroup } = props.schemas;
  const [isOpen, setOpen] = useState(false);
  const labelSize = 3;
  const inputSize = 9;
  let title;
  if (schema.isDone) {
    title = displayCurrentLangObj(
      schema.titleWhenDone,
      props.language.currentLanguage,
    );
  } else {
    title =
      displayCurrentLangObj(
        currentSchemaGroup.name,
        props.language.currentLanguage,
      ) + (!StringIsEmpty(schema.name) ? ' - ' + schema.name : '');
  }

  const onChangeName = e => {};

  const renderSchemaId = () => {
    let displayId = schema.displayId ? `${schema.displayId}` : '';
    let parsedDate = parseDate(schema.createdDate);
    if (schema.controlItems?.length > 0) {
      if (schema.controlItems[0].manualAnswerDate) {
        parsedDate = parseDate(schema.controlItems[0].manualAnswerDate);
      }
    }

    //console.log(schema);

    if (displayId.length > 0) displayId += `.${props.index}`;

    if (isServiceAgreement) {
      if (schema.actualServiceDate) {
        parsedDate = parseDate(schema.actualServiceDate);
      } else {
        parsedDate = parseDate(schema.expectedServiceDate);
      }
    }

    if (parsedDate !== '01-01-0001') {
      if (displayId.length > 0) {
        displayId += ` - ${parsedDate}`;
      } else {
        displayId = parsedDate;
      }
    }
    return displayId;
  };

  const renderSchemaMenuContent = () => {
    let statusContent;

    if (schema.isDone) {
      statusContent = (
        <span className='status'>
          {i18next.t(1003)}{' '}
          {/* <MdCheckBox
            color={getColor('grey')}
            size={28}
            className='margin-left-10px'
          /> */}
        </span>
      );
    } else {
      statusContent = (
        <span className='status'>
          {/* {i18next.t(1003)}{' '}
          <MdCheckBoxOutlineBlank size={28} className='margin-left-10px' /> */}
        </span>
      );
    }

    return <div>{statusContent}</div>;
  };

  const renderControlItem = (control, idx) => {
    let sharedProps = {
      schema,
      groupIndex,
      itemIndex: idx,
      schemaIndex: props.index,
      control,
      disableAllFunctions: true,
      selectControl,
      _targetControlId,
      imageObjects,
      isMovingImages: true,
    };

    if (control.isGroup) {
      return renderControlItemGroup(control, idx, sharedProps);
    } else {
      return (
        <div key={idx} className='control-item-wrap'>
          <ControlItem key={idx} {...sharedProps} />
        </div>
      );
    }
  };

  const renderControlItemGroup = (group, gIndex, sharedProps) => {
    let groupTitle;
    if (!!group.title && group.title?.trim().length > 0) {
      groupTitle = displayCurrentLangObj(
        group.title,
        props.language.currentLanguage,
      );
    }

    return (
      <div key={'xtid' + gIndex} className='control-item-group'>
        {!!groupTitle && (
          <Typography>
            <strong>{groupTitle}</strong>
          </Typography>
        )}
        {group.controlItems.map((x, i) => {
          let expandedProps = {
            ...sharedProps,
            itemIndex: i,
            control: { ...x, required: group.required || x.required },
            itemGroupIndex: gIndex,
            group,
          };

          return (
            <ControlItem key={'gi+' + i} {...expandedProps} isGroupControl />
          );
        })}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <>
        <TextInput
          row
          disabled={true}
          for={i18next.t(1300)}
          labelSize={labelSize}
          inputSize={inputSize}
          name='name'
          value={schema.name}
          onChange={onChangeName}
        />

        {/* Controls */}
        {schema.controlItems.map(renderControlItem)}
      </>
    );
  };

  return (
    <div className='control-schema-item'>
      <CollapsableCard
        id={schema.uniqueId}
        headerLeft={
          <div>
            <Typography type='h5' className='schema-title'>
              {title}
              <FormText>{renderSchemaId()}</FormText>
            </Typography>
          </div>
        }
        headerRight={renderSchemaMenuContent()}
        body={renderBody()}
        toggle={() => setOpen(!isOpen)}
        isOpen={isOpen}
      />
    </div>
  );
};

function mapStateToProps({ auth, schemas, cases, language }) {
  return {
    auth,
    schemas,
    cases,
    language,
  };
}

export default connect(mapStateToProps, {})(SchemaItem);
