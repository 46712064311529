import React from 'react';
import { connect } from 'react-redux';
import InaccessibleFeature from '../components/InaccessibleFeature';
import { FEATURE } from '../redux/constants';

/**
 * @param {Component} WrappedComponent React component that belongs to a certain feature.
 * We use redux and the features stored to determine if the user is allowed to view/use the component.
 * @param {Array} featureTypes Based on the feature enums.
 * @returns Component or generic "No access" display.
 */
const withFeature = (WrappedComponent, featureTypes, hideNoAccess) => {
  const FeatureHOC = class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        show: false,
      };
    }

    componentDidMount() {
      this.hasFeature();
    }

    componentDidUpdate(prevProps) {
      const prevFeatures = JSON.stringify(prevProps.features);
      const curFeatures = JSON.stringify(this.props.features);
      if (prevFeatures !== curFeatures) {
        this.hasFeature();
      }
    }

    hasFeature = () => {
      const { features, isServiceAgreement } = this.props;
      let show = false;

      for (let index = 0; index < featureTypes.length; index++) {
        let featureType = featureTypes[index];

        if (isServiceAgreement && featureType === FEATURE.QualityInsurance) {
          featureType = FEATURE.ServiceAgreement;
        }

        show = features
          .filter(x => x.active)
          .map(x => x.type)
          .includes(featureType);

        if (show) break;
      }

      this.setState({ show });
    };

    renderInaccessible = () => {
      if (hideNoAccess) return null;

      return <InaccessibleFeature />;
    };

    renderContent = () => {
      if (typeof WrappedComponent === 'function') {
        return <WrappedComponent {...this.props} />;
      }
      if (typeof WrappedComponent === 'object') {
        return WrappedComponent;
      }
    };

    render() {
      const { show } = this.state;

      return (
        <React.Fragment>
          {!show && this.renderInaccessible()}
          {show && this.renderContent()}
        </React.Fragment>
      );
    }
  };

  function mapStateToProps(state) {
    return {
      features: state.features.features,
    };
  }

  if (typeof WrappedComponent === 'function') {
    return connect(mapStateToProps, {})(FeatureHOC);
  }

  if (typeof WrappedComponent === 'object') {
    const Elem = connect(mapStateToProps, {})(FeatureHOC);
    return <Elem />;
  }
};

export default withFeature;
