import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../i18n';
import queryString from 'query-string';
import { isIOS, isMobile, isSafari } from 'react-device-detect';

// Actions
import { getHotworkWithToken } from '../redux/actions/hotwork';

// Utils
import { API_URL } from '../redux/constants';

// Components
import PageSpinner from '../components/PageSpinner';

const VaInfoPage = props => {
  const { location, getHotworkWithToken } = props;
  const [isLoading, setLoading] = useState(true);
  const [path, setPath] = useState('');

  useEffect(() => {
    const query = queryString.parse(location.search);
    let token = query.token;

    if (token) {
      const tmpPath = API_URL + '/pdf/download/pdf?token=' + token;
      getHotworkWithToken(token)
        .then(res => {
          setLoading(false);
          setPath(tmpPath);
          if (isMobile && (isIOS || isSafari)) {
            window.location.replace(tmpPath);
          }
        })
        .catch(err => {
          console.log(err);

          setLoading(false);
          setPath(tmpPath);
        });
    }
    // eslint-disable-next-line
  }, []);

  const renderiOSVersion = () => {
    return (
      <div className='unsupported-container'>
        <p>{i18next.t(1498).replace('[URL]', path)}</p>
      </div>
    );
  };

  if (isLoading) {
    return <PageSpinner />;
  }

  if (isMobile && (isIOS || isSafari)) {
    return renderiOSVersion();
  } else {
    return <iframe title={`${new Date()}`} src={path} className='pdf-page' />;
  }
};

function mapStateToProps({ auth, cases }) {
  return {
    caseInfo: cases.selectedCase,
    auth,
  };
}

export default connect(mapStateToProps, {
  getHotworkWithToken,
})(VaInfoPage);
