import React, { useState } from 'react';
import i18next from '../../i18n';
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { MdClose, MdMenu } from 'react-icons/md';
import { isMobile } from 'react-device-detect';

// Utils

// Components
import ConfirmModal from './ConfirmModal';
import Typography from '../Typography';
import PageSpinner from '../PageSpinner';

const CustomModal = props => {
  const {
    extraClass,
    showActionSpinner,
    confirmClose,
    size,
    fullscreen,
  } = props;

  const [showConfirm, setShowConfirm] = useState(false);
  const [showBurgerActions, setShowBurgerActions] = useState(false);

  const toggleConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const renderActionSpinner = () => {
    if (showActionSpinner) {
      return <PageSpinner color='primary' extraClass='margin-bot-15px' />;
    }
  };

  const renderConfirmModal = () => {
    const confirmAction = () => {
      setShowConfirm(false);
      props.toggle();
    };
    return (
      <ConfirmModal
        header={i18next.t(201)}
        show={showConfirm}
        toggle={toggleConfirm}
        confirmFunc={confirmAction}
        cancelFunc={toggleConfirm}
        color='success'
        cancelColor='danger'
      >
        <Typography>{i18next.t(200)}</Typography>
      </ConfirmModal>
    );
  };

  const renderBurgerOptions = () => {
    const { confirmClose, burgerOptions } = props;

    const headerAction = confirmClose ? toggleConfirm : props.toggle;
    const toggleNavbar = () => setShowBurgerActions(!showBurgerActions);

    const renderItems = (item, index) => {
      return (
        <DropdownItem key={index} onClick={item.action}>
          {item.title}
        </DropdownItem>
      );
    };

    return (
      <React.Fragment>
        {this.props.title}

        <ButtonDropdown
          className='float-right'
          isOpen={showBurgerActions}
          toggle={toggleNavbar}
        >
          <DropdownToggle outline>
            <MdMenu size={25} />
          </DropdownToggle>
          <DropdownMenu>{burgerOptions.map(renderItems)}</DropdownMenu>

          <Button onClick={headerAction}>
            <MdClose size={25} />
          </Button>
        </ButtonDropdown>
      </React.Fragment>
    );
  };

  const renderModalHeader = () => {
    const { confirmClose, burgerOptions } = props;
    const headerAction = confirmClose ? toggleConfirm : props.toggle;
    const showBurgerOptions = burgerOptions?.length > 0;

    if (showBurgerOptions) {
      return (
        <ModalHeader className='modal-header-burger'>
          {renderBurgerOptions()}
        </ModalHeader>
      );
    } else {
      return <ModalHeader toggle={headerAction}>{props.title}</ModalHeader>;
    }
  };

  const xlSize = !isMobile & !fullscreen && size === 'xl' ? ' xl-size' : '';
  return (
    <div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        fade={false}
        size={props.size}
        centered
        backdrop='static'
        className={
          'custom-modal ' +
          (extraClass ? extraClass : '') +
          xlSize +
          (isMobile & fullscreen ? ' fullscreen' : '')
        }
      >
        {renderModalHeader()}
        <ModalBody>
          {renderActionSpinner()}
          {props.children}
        </ModalBody>
        {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
      </Modal>
      {confirmClose && renderConfirmModal()}
    </div>
  );
};

CustomModal.defaultProps = {
  confirmClose: false,
};

export default CustomModal;
