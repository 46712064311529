import React, { useState } from 'react';
import i18next from '../../../i18n';
import { Spinner } from 'reactstrap';
//import accept from 'attr-accept';
import async from 'async';

// Utils
import { getAllFileEntries } from '../../../utils/fileUtils';

// class FileOrFolderInput extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       error: null,
//     };
//     this.inputRef = React.createRef();
//     this.onDragDrop = this.onDragDrop.bind(this);
//     this.onClickDrop = this.onClickDrop.bind(this);
//   }

//   onDragOver = e => {
//     e.preventDefault();
//     try {
//       e.dataTransfer.dropEffect = 'copy';
//     } catch (error) {}
//     return false;
//   };

//   onClickDrop(e) {
//     e.preventDefault();
//     this.setState({ error: null });

//     const droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
//     let acceptedFiles = [];
//     let rejectedFiles = [];

//     async.each(
//       droppedFiles,
//       (file, cb) => {
//         acceptedFiles.push(file);
//         cb();
//       },
//       () => {
//         if (acceptedFiles.length > 0) {
//           this.props.onDropAccepted(acceptedFiles);
//         }
//         if (rejectedFiles.length > 0) {
//           if (this.props.onDropRejected) {
//             this.props.onDropRejected(rejectedFiles);
//           }
//         }
//       },
//     );

//     if (droppedFiles.length === 0) {
//       this.setState({ error: i18next.t(8006) });
//     }
//   }

//   async onDragDrop(e) {
//     e.preventDefault();
//     this.setState({ error: null });

//     let acceptedFiles = [];
//     let rejectedFiles = [];
//     // console.log(e.dataTransfer);
//     let items = await getAllFileEntries(e.dataTransfer.items);
//     //console.log(items);

//     async.each(
//       items,
//       (file, cb) => {
//         acceptedFiles.push(file);
//         cb();
//       },
//       () => {
//         if (acceptedFiles.length > 0) {
//           this.props.onDropAccepted(acceptedFiles);
//         }
//         if (rejectedFiles.length > 0) {
//           if (this.props.onDropRejected) {
//             this.props.onDropRejected(rejectedFiles);
//           }
//         }
//       },
//     );
//     if (items.length === 0) {
//       this.setState({ error: i18next.t(8006) });
//     }
//   }

//   isAcceptableFile(file) {
//     return accept(file, this.props.accept);
//   }

//   render() {
//     const { isUploading, disabled } = this.props;
//     const { error } = this.state;
//     if (isUploading) {
//       return (
//         <div className='fileDropzone'>
//           <Spinner color='primary' />
//         </div>
//       );
//     }

//     let labelText = i18next.t(1184);
//     if (this.props.labelText) {
//       labelText = this.props.labelText;
//     }

//     let contentClass = '';
//     return (
//       <div className={contentClass}>
//         <div
//           className='fileDropzone'
//           onClick={() => this.inputRef.current.click()}
//           onDragOver={this.onDragOver}
//           onDrop={this.onDragDrop}
//         >
//           <input
//             ref={this.inputRef}
//             name={this.props.name}
//             type='file'
//             value=''
//             onChange={this.onClickDrop}
//             multiple
//             className='fileInput'
//             disabled={disabled}
//             webkitdirectory='true'
//             mozdirectory='true'
//             directory='true'
//           />
//           <div>{labelText}</div>
//         </div>
//         <div className='error' style={{ color: 'red' }}>
//           {error}
//         </div>
//       </div>
//     );
//   }
// }

const FileOrFolderInput = props => {
  const { isUploading, disabled } = props;
  const [error, setError] = useState(false);

  const inputRef = React.createRef();

  const onDragOver = e => {
    e.preventDefault();
    try {
      e.dataTransfer.dropEffect = 'copy';
    } catch (error) {}
    return false;
  };

  const onClickDrop = e => {
    e.preventDefault();
    setError(null);

    const droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let acceptedFiles = [];
    let rejectedFiles = [];

    async.each(
      droppedFiles,
      (file, cb) => {
        acceptedFiles.push(file);
        cb();
      },
      () => {
        if (acceptedFiles.length > 0) {
          props.onDropAccepted(acceptedFiles);
        }
        if (rejectedFiles.length > 0) {
          if (props.onDropRejected) {
            props.onDropRejected(rejectedFiles);
          }
        }
      },
    );

    if (droppedFiles.length === 0) {
      //this.setState({ error: i18next.t(8006) });
      setError(i18next.t(8006));
    }
  };

  const onDragDrop = async e => {
    e.preventDefault();
    setError(null);

    let acceptedFiles = [];
    let rejectedFiles = [];
    // console.log(e.dataTransfer);
    let items = await getAllFileEntries(e.dataTransfer.items);
    //console.log(items);

    async.each(
      items,
      (file, cb) => {
        acceptedFiles.push(file);
        cb();
      },
      () => {
        if (acceptedFiles.length > 0) {
          props.onDropAccepted(acceptedFiles);
        }
        if (rejectedFiles.length > 0) {
          if (props.onDropRejected) {
            props.onDropRejected(rejectedFiles);
          }
        }
      },
    );
    if (items.length === 0) {
      //this.setState({ error: i18next.t(8006) });
      setError(i18next.t(8006));
    }
  };

  if (isUploading) {
    return (
      <div className='fileDropzone'>
        <Spinner color='primary' />
      </div>
    );
  }

  let labelText = i18next.t(1184);
  if (props.labelText) {
    labelText = props.labelText;
  }

  let contentClass = '';
  return (
    <div className={contentClass}>
      <div
        className='fileDropzone'
        onClick={() => inputRef.current.click()}
        onDragOver={onDragOver}
        onDrop={onDragDrop}
      >
        <input
          ref={inputRef}
          name={props.name}
          type='file'
          value=''
          onChange={onClickDrop}
          multiple
          className='fileInput'
          disabled={disabled}
          webkitdirectory='true'
          mozdirectory='true'
          directory='true'
        />
        <div>{labelText}</div>
      </div>
      <div className='error' style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  );
};

FileOrFolderInput.defaultProps = {
  accept: 'image/jpeg,image/jpg,image/png,image/dng,image/heic,application/pdf',
  onDropAccepted: () =>
    console.error('FileOrFolderInput requires onDropAccepted prop'),
};

export default FileOrFolderInput;
