import * as React from 'react';

const SvgSkemabygger = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <path d='M21 23H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2ZM4.864 18.07a1.288 1.288 0 1 0 1.288 1.289 1.29 1.29 0 0 0-1.288-1.289Zm13.122-4.429a3.409 3.409 0 1 0 3.44 3.408 3.428 3.428 0 0 0-3.44-3.408ZM9.87 18.649a.868.868 0 0 0 0 1.714h4.921a4.312 4.312 0 0 1-1.2-1.714Zm-6.444-4.808A.624.624 0 0 0 3 14a.517.517 0 0 0 0 .777l1.316 1.205a.639.639 0 0 0 .849 0l2.652-2.431a.517.517 0 0 0 0-.778.642.642 0 0 0-.849 0l-2.12 1.947a.162.162 0 0 1-.214 0L3.85 14a.624.624 0 0 0-.424-.159Zm6.555.108a.857.857 0 0 0 0 1.713h3.6a4.283 4.283 0 0 1 1.175-1.713ZM3.426 9.175a.627.627 0 0 0-.426.16.517.517 0 0 0 0 .777l1.316 1.205a.637.637 0 0 0 .849 0l2.654-2.431a.517.517 0 0 0 0-.777.641.641 0 0 0-.85 0l-2.121 1.943a.159.159 0 0 1-.214 0l-.784-.717a.627.627 0 0 0-.424-.16Zm6.555.075a.857.857 0 0 0 0 1.713h10.063a.857.857 0 0 0 0-1.713Zm-6.6-4.757a.549.549 0 0 0-.389.937l1.2 1.205a.55.55 0 0 0 .778 0L7.4 4.2a.549.549 0 0 0 0-.777.55.55 0 0 0-.778 0L4.679 5.37a.133.133 0 0 1-.1.041.135.135 0 0 1-.1-.041l-.718-.717a.546.546 0 0 0-.385-.161Zm6.6-.172a.857.857 0 0 0 0 1.713h10.063a.857.857 0 0 0 0-1.713Zm8.005 15.163a.492.492 0 0 1-.491-.491v-1.457h-1.479a.487.487 0 1 1 0-.974h1.479v-1.456a.491.491 0 1 1 .983 0v1.456h1.479a.487.487 0 1 1 0 .974h-1.48v1.456a.492.492 0 0 1-.491.491Z' />
    <path fill='none' d='M0 0h24v24H0z' />
  </svg>
);

export default SvgSkemabygger;
