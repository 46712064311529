import React from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';

// Actions

// Utils
import { displayCurrentLangObj } from '../../../utils/translation/langauges';

// Components
import InfoTip from '../../Custom/InfoTip';
import { Col, FormGroup, Label } from 'reactstrap';

const ControlItemUneditable = props => {
  const { control } = props;
  const {
    title,
    alternativeTitle,
    textValue,
    infoTitle,
    infoText,
    showInfoIcon,
  } = control;
  const labelSize = 3;
  const inputSize = 9;

  let tmpTitle = title;
  if (alternativeTitle?.trim().length > 0) tmpTitle = alternativeTitle;

  const html = displayCurrentLangObj(textValue, props.language.currentLanguage);
  const hasHtml = html?.trim().length > 0;
  // console.log('html', hasHtml, html);

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  // if (!textValue) return null;

  return (
    <FormGroup row>
      <Label sm={labelSize}>
        <div className='label-wrap'>
          {tmpTitle}
          {renderInfo()}
        </div>{' '}
      </Label>
      <Col sm={inputSize}>
        <div className='control-item-uneditable'>{hasHtml && parse(html)}</div>
      </Col>
    </FormGroup>
  );
};

function mapStateToProps({ auth, schemas, cases, language }) {
  return {
    auth,
    schemas,
    cases,
    language,
  };
}

export default connect(mapStateToProps, {})(ControlItemUneditable);
