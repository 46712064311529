import i18next from '../i18n';

export const hasNumber = new RegExp('[0-9]+');
export const hasLetter = new RegExp('[a-zæøå]+');
export const hasMin8Chars = new RegExp('.{8,}');
export const hasSpecialChars = new RegExp(
  '[!@#$%?^&*"\\[\\]\\{\\}<>/\\(\\)=\\\\\\-_´+`~\\:;,\\.€\\|]',
);
export const hasUpperCase = new RegExp('[A-ZÆØÅ]');

const passValidator = password => {
  if (!password) {
    return { valid: false, text: i18next.t(85) };
  }

  const HN = hasNumber.test(password);
  const HL = hasLetter.test(password);
  const HM8C = hasMin8Chars.test(password);
  const HSC = hasSpecialChars.test(password);
  const HUC = hasUpperCase.test(password);
  const valid = HN && HL && HM8C && HSC && HUC;
  let text = '';

  if (!valid) {
    if (!HN) {
      text += i18next.t(77) + '\n';
    }
    if (!HL) {
      text += i18next.t(78) + '\n';
    }
    if (!HUC) {
      text += i18next.t(7044) + '\n';
    }
    if (!HSC) {
      text += i18next.t(7043) + '\n';
    }
    if (!HM8C) {
      text += i18next.t(79) + '\n';
    }
    return { valid: valid, text };
  } else {
    return { valid, text };
  }
};

export default passValidator;
