/**
 * @module actions/customerGroups
 */
import axios from 'axios';
import { API_URL, SET_SYSTEM_INFO, SET_SYSTEM_MESSAGE } from '../constants';

/**
 * @description Get list of systeminfo/changelog from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getSystemInfo() {
  let url = API_URL + '/about/changelogs';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: SET_SYSTEM_INFO,
        payload: res.data,
      });
      return res.data;
    });
  };
}

/**
 * @description Create systeminfi/changelog
 * @return {Promise} Axios promise
 */
export function createSystemInfo(data) {
  let url = API_URL + '/about/changelogs';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get list of system messages from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getSystemMessage() {
  let url = API_URL + '/about/system/message';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: SET_SYSTEM_MESSAGE,
        payload: res.data,
      });
      return res.data;
    });
  };
}

/**
 * @description Create system message for all users
 * @return {Promise} Axios promise
 */
export function createSystemMessage(data) {
  let url = API_URL + '/about/system';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Push message to all mobile devices
 * @return {Promise} Axios promise
 */
export function createPushMessage(data) {
  let url = API_URL + '/about/system/push';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get preview push messages
 * @return {Promise} Axios promise
 */
export function getPushMessage() {
  let url = API_URL + '/about/system/push';
  return dispatch => {
    return axios.get(url);
  };
}
