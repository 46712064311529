import * as React from 'react';

const SvgPdf = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 336 336'
    {...props}
  >
    <defs>
      <path
        id='PDF_svg__a'
        fillRule='evenodd'
        d='M213 20H58v294h218V87l-63-67z'
      />
      <path id='PDF_svg__b' fillRule='evenodd' d='m213 20 63 67h-63V20z' />
      <path id='PDF_svg__c' d='M52 157h235v108H52z' />
      <mask
        id='PDF_svg__d'
        x={0}
        y={0}
        maskContentUnits='userSpaceOnUse'
        maskUnits='userSpaceOnUse'
      >
        <path d='M0 0h333v336H0z' />
        <use fill='#fff' xlinkHref='#PDF_svg__a' />
      </mask>
      <mask
        id='PDF_svg__f'
        x={0}
        y={0}
        maskContentUnits='userSpaceOnUse'
        maskUnits='userSpaceOnUse'
      >
        <path d='M0 0h333v336H0z' />
        <use fill='#fff' xlinkHref='#PDF_svg__b' />
      </mask>
      <mask
        id='PDF_svg__h'
        x={0}
        y={0}
        maskContentUnits='userSpaceOnUse'
        maskUnits='userSpaceOnUse'
      >
        <path d='M0 0h333v336H0z' />
        <use fill='#fff' xlinkHref='#PDF_svg__c' />
      </mask>
      <linearGradient
        id='PDF_svg__e'
        x1={218.04}
        x2={244.5}
        y1={22.01}
        y2={87}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#F0F0F0' />
        <stop offset={1} stopColor='#E1E1E1' />
      </linearGradient>
      <linearGradient
        id='PDF_svg__g'
        x1={56.7}
        x2={291.7}
        y1={158.08}
        y2={265}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#F20013' />
        <stop offset={1} stopColor='#DA0011' />
      </linearGradient>
    </defs>
    <use fill='#FFF' xlinkHref='#PDF_svg__a' />
    <use
      fillOpacity={0}
      stroke='#B0B0B0'
      strokeWidth={4}
      mask='url(#PDF_svg__d)'
      xlinkHref='#PDF_svg__a'
    />
    <use fill='url(#PDF_svg__e)' xlinkHref='#PDF_svg__b' />
    <use
      fillOpacity={0}
      stroke='#B7B8B8'
      strokeWidth={4}
      mask='url(#PDF_svg__f)'
      xlinkHref='#PDF_svg__b'
    />
    <path
      fill='#C8C8C8'
      fillRule='evenodd'
      d='M80 52h115a1 1 0 0 1 0 2H80a1 1 0 0 1 0-2zM80 73h115a1 1 0 0 1 0 2H80a1 1 0 0 1 0-2zM80 94h178a1 1 0 0 1 0 2H80a1 1 0 0 1 0-2zM80 115h178a1 1 0 0 1 0 2H80a1 1 0 0 1 0-2zM80 136h178a1 1 0 0 1 0 2H80a1 1 0 0 1 0-2zM80 284h178a1 1 0 0 1 0 2H80a1 1 0 0 1 0-2z'
    />
    <use fill='url(#PDF_svg__g)' xlinkHref='#PDF_svg__c' />
    <use
      fillOpacity={0}
      stroke='#91000F'
      strokeWidth={2}
      mask='url(#PDF_svg__h)'
      xlinkHref='#PDF_svg__c'
    />
    <text
      fontSize={0}
      style={{
        whiteSpace: 'pre',
      }}
    >
      <tspan
        x={89.5}
        y={238.5}
        fill='#FFF'
        fontFamily='Arial'
        fontSize={80}
        fontWeight={400}
        style={{
          whiteSpace: 'pre',
          textTransform: 'none',
        }}
      >
        {'PDF'}
      </tspan>
    </text>
  </svg>
);

export default SvgPdf;
