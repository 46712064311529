import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      {...props}
    ></svg>
  );
}

export default SvgComponent;
