import * as React from 'react';

const SvgIconSystembeskeder = props => (
  <svg
    data-name='Icon - Systembeskeder'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <g data-name='Group 238'>
      <path
        data-name='Path 283'
        d='M16.01 11.305a.47.47 0 0 1-.138.014 1.046 1.046 0 0 1-1.049-.911 1.07 1.07 0 0 1 .9-1.2.518.518 0 0 1 .152-.014 1.046 1.046 0 0 1 1.049.911.518.518 0 0 1 .014.152 1.07 1.07 0 0 1-.925 1.049m-2.982-.773a1.049 1.049 0 0 1-1.021.787.956.956 0 0 1-.276-.041 1.062 1.062 0 0 1-.745-1.3 1.049 1.049 0 0 1 1.021-.787.957.957 0 0 1 .276.041 1.049 1.049 0 0 1 .787 1.021.956.956 0 0 1-.041.276m-3.81-.179a1.073 1.073 0 0 1-1.049.966h-.1a1.053 1.053 0 0 1-.952-1.146 1.073 1.073 0 0 1 1.049-.966h.1a1.073 1.073 0 0 1 .966 1.049.235.235 0 0 0-.014.1m11.039-8.311h-16.5A2.053 2.053 0 0 0 1.704 4.1l-.009 18.58 4.127-4.127h14.439a2.053 2.053 0 0 0 2.057-2.057V4.114a2.065 2.065 0 0 0-2.057-2.071'
      />
    </g>
    <path data-name='Rectangle 1242' fill='none' d='M0 0h24v24H0z' />
  </svg>
);

export default SvgIconSystembeskeder;
