import * as React from 'react';

const SvgIconInterneBrugere = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Interne-brugere_svg__a'>
        <path data-name='Rectangle 1133' d='M0 0h18.653v24H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1132' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 127'>
      <g
        data-name='Group 126'
        transform='translate(2.673)'
        clipPath='url(#Icon_-_Interne-brugere_svg__a)'
      >
        <path
          data-name='Path 229'
          d='M13.067 9.693a16.2 16.2 0 0 0 .747-5.787A4.219 4.219 0 0 0 9.28 0a4.219 4.219 0 0 0-4.533 3.907 16.2 16.2 0 0 0 .747 5.787 4.953 4.953 0 0 0 3.053 2.44 3.34 3.34 0 0 0 1.453 0 4.988 4.988 0 0 0 3.067-2.44m5.24 12.693v-5.094c0-1.76-1.6-3.053-3.213-3.787l-1.76-.8a5.864 5.864 0 0 1-8.093 0l-1.76.8C1.867 14.24.267 15.52.267 17.293v5.093a1.6 1.6 0 0 0 1.6 1.6h14.827a1.608 1.608 0 0 0 1.613-1.6'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconInterneBrugere;
