/**
 * @module actions/branding
 */
import { GET_CUSTOMER_BRANDING, API_URL } from '../constants';
import Axios from 'axios';

export function getCustomerBranding(customerId) {
  return dispatch => {
    return Axios.get(API_URL + '/branding/' + customerId).then(res => {
      dispatch({
        type: GET_CUSTOMER_BRANDING,
        payload: res.data || null,
      });
      return res;
    });
  };
}

export function getPlatformBrandingCustomer(customerId) {
  return dispatch => {
    return Axios.get(API_URL + '/branding/platform/' + customerId).then(res => {
      return res;
    });
  };
}

export function getPlatformBrandingUser() {
  return dispatch => {
    return Axios.get(API_URL + '/branding/platform').then(res => {
      return res;
    });
  };
}
