import * as React from 'react';

const SvgIconInfo = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Info_svg__a'>
        <path
          data-name='Rectangle 1196'
          transform='translate(-.189 -.19)'
          d='M0 0h22v22H0z'
        />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1195' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 192'>
      <g
        data-name='Group 191'
        clipPath='url(#Icon_-_Info_svg__a)'
        transform='translate(1.189 1.19)'
      >
        <path
          data-name='Path 255'
          d='M13.19 14.538a.558.558 0 0 1-.558.558H9.279a.558.558 0 0 1 0-1.116h.559v-3.353h-.559a.559.559 0 0 1 0-1.118h2.234a.558.558 0 0 1 .558.559v3.91h.559a.56.56 0 0 1 .559.559m-1.118-7.262a1.117 1.117 0 1 1-1.116-1.118 1.117 1.117 0 0 1 1.116 1.118m9.35 3.436a10.711 10.711 0 1 0-10.71 10.711 10.711 10.711 0 0 0 10.711-10.711'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconInfo;
