import {
  ACCESS_LEVEL,
  CUSTOMER_STATUS,
  EMPLOYEE_TYPE,
} from '../redux/constants';
import i18next from '../i18n';

export const getUserCustomerId = auth => {
  if (auth.loginAsUser) {
    const loggedUser = JSON.parse(auth.loginAsUser);
    return loggedUser.companyId;
  } else {
    const { user } = auth.user;
    return user.belongsToCustomerId;
  }
};

export const getUserData = auth => {
  if (auth?.loginAsUser) {
    const loggedUser = JSON.parse(auth.loginAsUser);
    return {
      id: loggedUser.userId,
      groupId: loggedUser.groupId,
      customerId: loggedUser.companyId,
      customerName: loggedUser.loginData.user.belongsToCustomerName,
      name: loggedUser.loginData.user.name,
      mobile: loggedUser.loginData.user.mobile,
      countryCode: loggedUser.loginData.user.countryCode,
      country: loggedUser.loginData.user.country,
      mail: loggedUser.loginData.user.mail,
      guid: loggedUser.loginData.user.guid,
      initials: loggedUser.loginData.user.initials,
      notifyByEmail: loggedUser.loginData.user.notifyByEmail,
      notifyByPush: loggedUser.loginData.user.notifyByPush,
      notifyBySMS: loggedUser.loginData.user.notifyBySMS,
      role: loggedUser.loginData.user.role,
      employeeType: loggedUser.loginData.user.employeeType,
      accessLevel: loggedUser.loginData.accessLevel,
      hasAP: loggedUser.loginData.user.hasAP,
      hasPdf: loggedUser.loginData.user.hasPdf,
      permission: getPermissions(loggedUser.loginData.user),
      customerStatus: loggedUser.loginData.customerStatus
        ? loggedUser.loginData.customerStatus
        : CUSTOMER_STATUS.Closed,
    };
  } else if (auth?.user) {
    const { user } = auth.user;

    if (user) {
      return {
        id: user.id,
        guid: user.guid,
        groupId: user.groupId,
        customerId: user.belongsToCustomerId,
        customerName: user.belongsToCustomerName,
        name: user.name,
        mobile: user.mobile,
        countryCode: user.countryCode,
        country: user.country,
        mail: user.mail,
        initials: user.initials,
        notifyByEmail: user.notifyByEmail,
        notifyByPush: user.notifyByPush,
        notifyBySMS: user.notifyBySMS,
        role: user.role,
        employeeType: user.employeeType,
        accessLevel: auth.user.accessLevel,
        hasAP: user.hasAP,
        hasPdf: user.hasPdf,
        permission: getPermissions(user),
        customerStatus: auth.customerStatus
          ? auth.customerStatus
          : CUSTOMER_STATUS.Closed,
      };
    }
  } else {
    console.log('User object in Auth is null!');
    return {
      id: 0,
      guid: '',
      groupId: 0,
      customerId: 0,
      customerName: '',
      name: '',
      mobile: '',
      mail: '',
      country: 'Danmark',
      initials: '',
      notifyByEmail: false,
      notifyByPush: false,
      notifyBySMS: false,
      role: -1,
      employeeType: EMPLOYEE_TYPE.None,
      accessLevel: ACCESS_LEVEL.None,
      hasAP: false,
      hasPdf: false,
      permission: getPermissions(),
      customerStatus: CUSTOMER_STATUS.Closed,
    };
  }
};

export const getUserTypeName = type => {
  switch (type) {
    case '1':
      return i18next.t(1103);
    case '2':
      return i18next.t(1104);
    case '3':
      return i18next.t(1105);
    default:
      return '';
  }
};

function getPermissions(user) {
  const defaultPermissions = {
    projectCreate: false,
    projectCopy: false,
    projectDelete: false,
    showAddSchemaCategory: false,
  };

  if (!user) return defaultPermissions;
  if (!user.permission || typeof user.permission !== 'object')
    return defaultPermissions;

  return { ...defaultPermissions, ...user.permission };
}

export const getUserPositionTypeStrings = () => {
  return [
    { label: '', value: 0 },
    { label: i18next.t(1348), value: 1 },
    { label: i18next.t(1349), value: 2 },
    { label: i18next.t(1350), value: 3 },
    { label: i18next.t(1351), value: 4 },
    { label: i18next.t(1352), value: 5 },
    { label: i18next.t(1353), value: 6 },
    { label: i18next.t(202), value: 7 },
    { label: i18next.t(203), value: 8 },
    { label: i18next.t(1356), value: 9 },
    { label: i18next.t(216), value: 10 },
    { label: i18next.t(7), value: 11 },
    { label: i18next.t(1359), value: 12 },
    { label: i18next.t(1176), value: 13 },
  ];
};

export const getEmployeeTypeNameByType = type => {
  switch (type) {
    case EMPLOYEE_TYPE.Advisor:
      return i18next.t(202);
    case EMPLOYEE_TYPE.Architect:
      return i18next.t(203);
    case EMPLOYEE_TYPE.BuildingOwner:
      return i18next.t(54);
    case EMPLOYEE_TYPE.CompanyOwner:
      return i18next.t(7155);
    case EMPLOYEE_TYPE.ExecutivePerson:
      return i18next.t(53);
    case EMPLOYEE_TYPE.Firewatch:
      return i18next.t(55);
    case EMPLOYEE_TYPE.InternalSubcontractor:
      return i18next.t(1073);
    case EMPLOYEE_TYPE.LocalAdmin:
      return i18next.t(5) + ' Admin';
    case EMPLOYEE_TYPE.ResponsiblePerson:
      return i18next.t(108);
    case EMPLOYEE_TYPE.RoofingContractor:
      return i18next.t(173);
    case EMPLOYEE_TYPE.Subcontractor:
      return i18next.t(1073);
    case EMPLOYEE_TYPE.SuperAdmin:
      return 'Super Admin';
    case EMPLOYEE_TYPE.Supplier:
      return i18next.t(216);
    default:
      return '';
  }
};
