import * as React from 'react';

const SvgIconEksterneParter = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Eksterne-parter_svg__a'>
        <path data-name='Rectangle 1128' d='M0 0h24v20.773H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1127' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 123'>
      <g
        data-name='Group 119'
        clipPath='url(#Icon_-_Eksterne-parter_svg__a)'
        transform='translate(0 1.613)'
      >
        <path
          data-name='Path 225'
          d='m13.547 9.52 1.24-.56a4.124 4.124 0 0 0 5.693 0l1.24.56c1.133.52 2.267 1.427 2.267 2.667v3.587a1.141 1.141 0 0 1-1.133 1.133h-5.467v-1.92c0-2.347-1.84-4-3.827-4.907l-.573-.267c.187-.107.373-.2.56-.293'
        />
      </g>
      <g
        data-name='Group 120'
        clipPath='url(#Icon_-_Eksterne-parter_svg__a)'
        transform='translate(0 1.613)'
      >
        <path
          data-name='Path 226'
          d='M14.453 2.747a3.222 3.222 0 0 1 6.373 0 11.345 11.345 0 0 1-.52 4.08 3.527 3.527 0 0 1-2.147 1.72 2.226 2.226 0 0 1-1.027 0 3.405 3.405 0 0 1-2.147-1.72 11.029 11.029 0 0 1-.533-4.08'
        />
      </g>
      <g
        data-name='Group 121'
        clipPath='url(#Icon_-_Eksterne-parter_svg__a)'
        transform='translate(0 1.613)'
      >
        <path
          data-name='Path 227'
          d='M2.773 11.706C1.386 12.333 0 13.453 0 14.986v4.4a1.394 1.394 0 0 0 1.387 1.387h12.84a1.394 1.394 0 0 0 1.387-1.387v-4.4c0-1.52-1.387-2.64-2.773-3.28l-1.533-.693a5.075 5.075 0 0 1-7 0Z'
        />
      </g>
      <g
        data-name='Group 122'
        clipPath='url(#Icon_-_Eksterne-parter_svg__a)'
        transform='translate(0 1.613)'
      >
        <path
          data-name='Path 228'
          d='M3.88 3.386A3.641 3.641 0 0 1 7.8.013a3.641 3.641 0 0 1 3.92 3.373 13.777 13.777 0 0 1-.653 5.013 4.327 4.327 0 0 1-2.653 2.107 2.84 2.84 0 0 1-1.253 0 4.327 4.327 0 0 1-2.653-2.107 14.363 14.363 0 0 1-.627-5.013'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconEksterneParter;
