import * as React from 'react';

const SvgIconDokumenter = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      data-name='Path 1'
      d='M22.879 9.018H1.117c-1.106 0-1.163.451-1.1 1.005l.813 8.973c.056.549.219 1.005 1.319 1.005H21.91c1.125 0 1.256-.451 1.319-1.005l.756-9.086c.057-.549 0-.892-1.106-.892Z'
    />
    <g data-name='Group 1'>
      <path
        data-name='Path 2'
        d='M22.006 4.444c-.03-1.164-.265-1.438-.888-1.438H12.7c-1.282 0-1.434.028-2.4-1.634C9.49-.016 9.808.006 8.144.006H3.718c-1.023 0-1.387-.141-1.481 1.559-.088 1.568-.294 5.371-.323 5.954H22.1Z'
      />
    </g>
  </svg>
);

export default SvgIconDokumenter;
