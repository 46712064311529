import React, { useState, useEffect } from 'react';
import i18next from '../../i18n';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
// import { IconLock } from '../../assets/icons_v2';

// Actions

// Utils
import { createGalleryObjectsFromReferences } from '../../utils/componentUtils';
import { SB_CONTROL_TYPE } from '../../redux/constants';

// Componenets
import TextInput from '../Custom/Forms/TextInput';
import ReactSelect from '../Custom/ReactSelect';
import KonvaView from './KonvaView';

const AreaView = props => {
  const { areaImage, schema, markings } = props;
  const { allImageRefs } = markings;
  const [filteredControls, setControls] = useState([]);
  const [options, setOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);

  const [references, setReferences] = useState(null);
  const [imgGuids, setImgGuids] = useState({});
  const [bindings, setBindings] = useState({});
  const allowedTypes = [
    SB_CONTROL_TYPE.PhotoDocumentation,
    SB_CONTROL_TYPE.TextPhotos,
    SB_CONTROL_TYPE.DropdownCommentPhotos,
  ];
  const controls = schema.rawControlItems.filter(x =>
    allowedTypes.includes(x.controlType),
  );

  useEffect(() => {
    let tmpGuids = {};
    let tmpBindings = {};
    controls.forEach((item, index) => {
      const ref = allImageRefs.find(x => x.controlId === item.id);

      if (!!ref) {
        const result = createGalleryObjectsFromReferences(
          ref,
          uuidv4(),
          `${index + 1}.`,
        );

        // console.log(result);

        tmpGuids = { ...tmpGuids, ...result.imgGuids };
        tmpBindings = { ...tmpBindings, ...result.bindings };
      }
    });

    setImgGuids(tmpGuids);
    setBindings(tmpBindings);

    // eslint-disable-next-line
  }, [allImageRefs]);

  useEffect(() => {
    let initialOptions = [];

    if (!!schema) {
      const allowedTypes = [
        SB_CONTROL_TYPE.PhotoDocumentation,
        SB_CONTROL_TYPE.TextPhotos,
        SB_CONTROL_TYPE.DropdownCommentPhotos,
      ];
      initialOptions = schema.rawControlItems
        .filter(x => allowedTypes.includes(x.controlType))
        .map(item => {
          const { title, alternativeTitle } = item;
          let tmp = title;
          if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

          return {
            value: item.id,
            label: tmp,
          };
        });
    }
    setControls(initialOptions);
    setOptions(initialOptions);

    // eslint-disable-next-line
  }, [schema]);

  useEffect(() => {
    let initialImages = [];

    if (filteredControls.length > 0) {
      const cids = filteredControls.map(x => x.value);

      let refs = [];
      controls.forEach((item, idx) => {
        if (cids.includes(item.id)) {
          const ref = allImageRefs.find(x => x.controlId === item.id);

          if (!!ref) {
            refs.push({ ...ref, _overrideIndex: idx + 1 });
          }

          initialImages.push(...item.files);
        }
      });

      setReferences(refs);
    } else {
      setReferences(null);
    }
    setImages(initialImages);

    // eslint-disable-next-line
  }, [filteredControls]);

  const onMarkingSelect = fileId => {
    if (fileId > 0) {
      const index = images.findIndex(x => x.id === fileId);
      setImageIndex(index);
    }
  };

  const galleryNavigate = dir => {
    let index = imageIndex;
    const length = images.length;

    if (index === length - 1 && dir === 1) {
      // Go to beginning
      index = 0;
    } else if (index === 0 && dir === -1) {
      // Go to last
      index = length - 1;
    } else {
      index += dir;
    }

    setImageIndex(index);
  };

  const renderLeft = () => {
    let currentImage;
    if (images.length > 0 && imageIndex > -1) {
      currentImage = images[imageIndex];
    }

    if (!currentImage) {
      return (
        <div className='no-image-active'>
          <div className='text'>{i18next.t(1233)}</div>
        </div>
      );
    }

    return renderImage(currentImage);
  };

  const renderImage = currentImage => {
    let displayId;
    const guid = imgGuids[currentImage.id];
    if (!!guid) {
      displayId = bindings[guid].displayId;
    }

    return (
      <div className='active-image'>
        <TextInput
          for={i18next.t(7170)}
          placeholder={i18next.t(7170)}
          name='fileTitle'
          onChange={() => {}}
          value={currentImage.fileTitle}
          disabled={true}
        />

        <div className='img'>
          <div className='display-id'>{displayId}</div>
          <img className='preview' src={currentImage.fileUrl} alt='reference' />
          <div className='nav-prev' onClick={() => galleryNavigate(-1)}>
            <MdKeyboardArrowLeft size={30} />
          </div>
          <div className='nav-next' onClick={() => galleryNavigate(1)}>
            <MdKeyboardArrowRight size={30} />
          </div>
        </div>

        <div className='konva-view'>
          <div className='konva-container'>{renderMiniGallery()}</div>
        </div>
      </div>
    );
  };

  const renderMiniGallery = () => {
    const renderThumb = (img, index) => {
      const found = imgGuids[img.id];

      let displayId;
      let displayIdClass = '';
      let icon;
      if (!!found) {
        displayId = bindings[found]?.displayId ?? null;

        if (displayId?.trim().length > 0) {
          displayIdClass = ' did';
          // icon = <IconLock />;
        }
      }

      const action = () => onMarkingSelect(img.id);

      return (
        <div
          key={index}
          className={'thumb active' + displayIdClass}
          onClick={action}
        >
          <img className='thumb-img' src={img.fileUrl} alt='reference' />
          {displayId && <div className='display-id'>{displayId}</div>}
          {icon && <div className='icon'>{icon}</div>}
        </div>
      );
    };

    return (
      <div className='mini-gallery viewer'>
        <div className='items'>{images.map(renderThumb)}</div>
      </div>
    );
  };

  const renderRight = () => {
    if (!areaImage) {
      return <div className='no-blueprint'>{i18next.t(1233)}</div>;
    }

    return renderKonvaView();
  };

  const renderKonvaView = () => {
    const onChangeFilter = val => {
      let value = !!val ? val : [];
      setControls(value);
    };

    return (
      <div className='konva-view'>
        <div className='filter-selector'>
          <FormGroup>
            <Label size='md'>{i18next.t(21266)}</Label>
            <ReactSelect
              value={filteredControls}
              options={options}
              onChange={onChangeFilter}
              placeholder={i18next.t(1030) + '...'}
              isMulti
              closeMenuOnSelect={false}
            />
          </FormGroup>
        </div>
        <KonvaView
          currentFile={areaImage}
          currentRefs={references}
          onMarkingSelect={onMarkingSelect}
        />
      </div>
    );
  };

  return (
    <div className='split-view'>
      {!isMobile && (
        <>
          <div className='left'>{renderLeft()}</div>
          <div className='split' />
        </>
      )}
      <div className='right'>{renderRight()}</div>
    </div>
  );
};

function mapStateToProps({ markings }) {
  return {
    markings,
  };
}

export default connect(mapStateToProps, {})(AreaView);
