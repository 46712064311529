import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../../i18n';
import { Card, CardBody, Container } from 'reactstrap';

// Actions
import { getSchemaOptions } from '../../../../redux/actions/schemas';

// Utils

// Components
import FormGroupRow from '../../Forms/FormGroupRow';
import ReactSelect from '../../ReactSelect';
import SchemaGroups from './SchemaGroups';
import SchemaList from './SchemaList';

const SchemaPage = props => {
  const {
    currentCase,
    getSchemaOptions,
    setGroup,
    schemaGroup,
    selectControl,
    _targetControlId,
    imageObjects,
  } = props;
  const { buildings } = currentCase;
  const [building, setBuilding] = useState(null);
  const [schemaOptions, setSchemaOptions] = useState([]);
  const labelSize = 3;
  const inputSize = 9;
  const options = [
    ...buildings.map(item => {
      return { label: item.name, value: item.id };
    }),
  ];

  useEffect(() => {
    if (options.length > 0) {
      setBuilding(options[0]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dataObj = currentCase ? { id: currentCase?.id } : null;

    getSchemaOptions(dataObj)
      .then(res => {
        setSchemaOptions(res.data);
      })
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [currentCase]);

  const renderBuildingsMenu = () => {
    const onChangeBuilding = val => {
      setBuilding(val);
    };

    const buildingMenu = (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1' }}>
          <ReactSelect
            value={building}
            options={options}
            onChange={onChangeBuilding}
            placeholder={i18next.t(1331)}
          />
        </div>
        <div style={{ flex: '0.25', marginLeft: '1rem' }} />
      </div>
    );

    return (
      <FormGroupRow
        labelSize={labelSize}
        inputSize={inputSize}
        input={buildingMenu}
        for={i18next.t(1331)}
      />
    );
  };

  const renderContent = () => {
    let sharedProps = {
      currentCase,
      building,
      categories: schemaOptions,
      currentGroup: schemaGroup,
      setGroup,
      selectControl,
      _targetControlId,
      imageObjects,
    };

    if (schemaGroup) {
      return <SchemaList {...sharedProps} />;
    }

    return (
      <div>
        {renderBuildingsMenu()}
        {building && <SchemaGroups {...sharedProps} />}
      </div>
    );
  };

  return (
    <Card className='qi-card'>
      <CardBody>
        <Container>{renderContent()}</Container>
      </CardBody>
    </Card>
  );
};

function mapStateToProps({ auth, schemas }) {
  return {
    auth,
    schemas,
  };
}

export default connect(mapStateToProps, {
  getSchemaOptions,
})(SchemaPage);
