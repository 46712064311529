import React, { useState, useEffect, useRef } from 'react';
import i18next from '../../../i18n';
import { Button, FormText, Spinner } from 'reactstrap';
import {
  Rect,
  Circle as Circ,
  Layer,
  Stage,
  Transformer,
  Group,
  Line,
} from 'react-konva';

// Util
import { getMarkingSizeByLadder } from '../../../utils/imageMarkingUtils';
import { MARKING_TYPE } from '../../../redux/constants';

// Components
import ConfirmModal from '../ConfirmModal';
import ModalHeader from 'reactstrap/lib/ModalHeader';

const ImageMarker = props => {
  const {
    currentFile,
    downloadImageAction,
    deleteImageAction,
    isSaving,
    toggleModal,
  } = props;
  const [imgDimensions, setImgDimensions] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0,
    r: 0,
  });
  const [markings, setMarkings] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currentMarking, setCurrentMarking] = useState(null);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [removeNewGroup, setRemoveNewGroup] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [imageHash, setImageHash] = useState(Date.now());
  const imgRef = useRef(null);

  // markings: [
  //   {
  //     id: 1,
  //     groupId: 1,
  //     x: 40,
  //     y: 40,
  //     width: 10,
  //     height: 10,
  //     shape: MARKING_TYPE.Square,
  //     r: 1 // Radius for circle
  //     filled: false,
  //   }
  // ],

  useEffect(() => {
    window.addEventListener('resize', handleImageResize);

    return () => window.removeEventListener('resize', handleImageResize);
  }, []);

  const toggleConfirmDeleteModal = () => {
    setShowConfirmDelete(!showConfirmDelete);
  };

  //////////////// Marking methods ////////////////

  const isCurrentMarking = marking => {
    if (currentMarking === null) return false;
    return currentMarking === marking.id;
  };

  const getMarkingObj = (shape = MARKING_TYPE.Circle, currentGroupId) => {
    const radius = getMarkingSizeByLadder(props.currentFile);
    return {
      id: '_' + Math.random() * 1000, // Random id for handling
      groupId: currentGroupId ? currentGroupId : '_' + Math.random() * 1000,
      x: 20,
      y: 20,
      width: 20,
      height: 20,
      shape,
      r: radius,
      dimensions: imgDimensions,
      filled: false,
      hidden: false,
    };
  };

  const saveMarkings = () => {
    const tmpGroups = groups.map(group => {
      return group.map(m => {
        // Reset ids
        if (typeof m.id === 'string') {
          m.id = 0;
        }

        return m;
      });
    });

    const filteredMarkings = markings.slice().filter(x => !x.hidden);
    const tmpMarkings = filteredMarkings.map(m => {
      // Reset ids
      if (typeof m.id === 'string') {
        m.id = 0;
      }

      return m;
    });

    if (props.saveMarkings) {
      props.saveMarkings(tmpMarkings, tmpGroups);
      setImageHash(Date.now());
      setMarkings([]);
      setGroups([]);
      setCurrentMarking(null);
      setCurrentGroupId(null);
    }
  };

  const onClickImage = e => {
    var x = e.evt.layerX; //x position within the element.
    var y = e.evt.layerY; //y position within the element.
    // console.log({ x, y });

    addMarking(MARKING_TYPE.Circle, { x, y });
  };

  const connectGroup = () => {
    if (!currentGroupId) return;

    let updatedGroups = groups.slice();
    const group = markings.filter(x => x.groupId === currentGroupId);

    const updatedMarkings = markings.map(x => {
      return {
        ...x,
        hidden: x.groupId === currentGroupId || x.hidden,
      };
    });

    if (group?.length > 0) {
      updatedGroups.push(group);
    }

    setCurrentMarking(null);
    setCurrentGroupId(null);
    setGroups(updatedGroups);
    setMarkings(updatedMarkings);
  };

  const addMarking = (shape = MARKING_TYPE.Circle, pos) => {
    let newMarking = getMarkingObj(shape, currentGroupId);

    newMarking.x = imgDimensions.width / 2;
    newMarking.y = imgDimensions.height / 2;

    if (pos) {
      newMarking.x = pos.x;
      newMarking.y = pos.y;
    }

    if (shape === MARKING_TYPE.Circle) {
      const scaling = imgDimensions.height / imgDimensions.naturalHeight;

      newMarking.x /= scaling;
      newMarking.y /= scaling;
    }

    const tmpMarkings = [...markings, newMarking];
    const tmpCurrentMarking = newMarking.id;

    setMarkings(tmpMarkings);
    setCurrentMarking(tmpCurrentMarking);

    if (!currentGroupId) {
      setCurrentGroupId(newMarking.groupId);
    }
  };

  const removeMarking = () => {
    if (markings.length === 0) return;

    let newGroup = false;
    let slicedGroup = groups.slice();
    let slicedList = removeNewGroup
      ? markings.slice()
      : markings.slice(0, markings.length - 1);
    let marking =
      slicedList.length > 0 ? slicedList[slicedList.length - 1] : null;
    const nullIded = !currentGroupId && slicedGroup.length > 0;

    // console.log({
    //   slicedGroup,
    //   slicedList,
    //   marking: !!marking ? { id: marking.id, groupId: marking.groupId } : null,
    //   currentMarking,
    //   currentGroupId,
    //   newGroup: currentGroupId !== marking?.groupId,
    //   nullIded,
    // });

    if (marking) {
      newGroup = currentGroupId !== marking.groupId && !nullIded;

      if (removeNewGroup || nullIded) {
        const groupIds = [];
        slicedGroup = groups.slice(0, groups.length - 1);

        slicedGroup.forEach(x => {
          x.forEach(y => {
            if (groupIds.includes(y.groupId)) return;
            groupIds.push(y.groupId);
          });
        });

        let list = nullIded ? markings : slicedList;
        slicedList = list.map(x => {
          return {
            ...x,
            // hidden: x.groupId === lastGroup.groupId,
            hidden: groupIds.includes(x.groupId),
          };
        });
      }
    }

    setMarkings(slicedList);
    setCurrentMarking(marking ? marking.id : null);
    setCurrentGroupId(marking ? marking.groupId : null);
    setGroups(slicedGroup);
    setShowConfirmDelete(false);
    setRemoveNewGroup(newGroup);
  };

  // const setToCircle = () => {
  //   const tmpMarkings = markings.filter(m => m.id !== currentMarking);
  //   const newMarking = getMarkingObj();

  //   setMarkings([...tmpMarkings, newMarking]);
  //   setCurrentMarking(newMarking.id);
  //   setShowConfirmDelete(false);
  // };

  // const setToRect = () => {
  //   const tmpMarkings = markings.filter(m => m.id !== currentMarking);
  //   const newMarking = getMarkingObj(MARKING_TYPE.Square);

  //   setMarkings([...tmpMarkings, newMarking]);
  //   setCurrentMarking(newMarking.id);
  //   setShowConfirmDelete(false);
  // };

  //////////////// Image Processing ////////////////

  const handleImageResize = () => {
    const img = imgRef.current;

    const tmpImgDimensions = {
      width: img.width,
      height: img.height,
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight,
      loaded: true,
    };

    setImgDimensions(tmpImgDimensions);
  };

  const renderImage = () => {
    let hash = imageHash;
    if (isSaving) {
      hash += new Date();
    }

    let classNames = 'imgMarker__img';
    if (imgDimensions.loaded) {
      classNames += ' absolute';
    }

    return (
      <img
        className={classNames}
        src={`${currentFile.fileUrl}?${hash}`}
        alt={currentFile.fileTitle}
        onLoad={handleImageResize}
        ref={imgRef}
        key={imageHash}
      />
    );
  };

  //////////////// Markings Processing ////////////////

  const renderMarking = (marking, key) => {
    if (marking.hidden) return null;
    const isCurrent = isCurrentMarking(marking);

    const calcCoord = (a, b, pos) => {
      return (a / b) * pos;
    };
    const calcDimen = (imgLength, percent) => {
      return imgLength * (percent / 100);
    };
    const calcDimen2 = (newLength, originalLength) => {
      return (100 * newLength) / originalLength;
    };
    const calRadius = (r, viewHeight) => {
      const scaling = viewHeight / imgDimensions.naturalHeight;
      // console.log(
      //   `r: ${r} - Scaling: ${r *
      //     scaling} - Height: ${viewHeight} - ActualHeight: ${
      //     imgDimensions.naturalHeight
      //   }`,
      // );
      return r * scaling;
    };

    const selectMarking = () => {
      setCurrentMarking(marking.id);
    };

    const updateXY = e => {
      let updatedMarkings = markings.slice();

      const index = updatedMarkings.findIndex(x => x.id === marking.id);
      updatedMarkings[index].x = calcCoord(
        imgDimensions.naturalWidth,
        imgDimensions.width,
        e.target.x(),
      );
      updatedMarkings[index].y = calcCoord(
        imgDimensions.naturalHeight,
        imgDimensions.height,
        e.target.y(),
      );

      setMarkings(updatedMarkings);
    };

    const onTransform = e => {
      let updatedMarkings = markings.slice();
      const index = updatedMarkings.findIndex(x => x.id === marking.id);

      updatedMarkings[index].x = calcCoord(
        imgDimensions.naturalWidth,
        imgDimensions.width,
        e.x,
      );
      updatedMarkings[index].y = calcCoord(
        imgDimensions.naturalHeight,
        imgDimensions.height,
        e.y,
      );

      if (e.type === MARKING_TYPE.Square) {
        updatedMarkings[index].width = calcDimen2(e.width, imgDimensions.width);
        updatedMarkings[index].height = calcDimen2(
          e.height,
          imgDimensions.height,
        );
      } else if (e.type === MARKING_TYPE.Circle) {
        // console.log(e, updatedMarkings[index].r);

        updatedMarkings[index].r = e.r;
      }

      setMarkings(updatedMarkings);
    };

    let width = calcDimen(imgDimensions.width, marking.width);
    let height = calcDimen(imgDimensions.height, marking.height);
    let r = calRadius(marking.r, imgDimensions.height);
    const scaling = imgDimensions.height / imgDimensions.naturalHeight;
    let strokeWidth = 6 * scaling;
    if (strokeWidth < 1) strokeWidth = 1;

    if (marking.shape === MARKING_TYPE.Square) {
      return (
        <Rectangle
          x={imgDimensions.width / 2}
          y={imgDimensions.height / 2}
          width={width}
          height={height}
          stroke={isCurrent ? 'red' : 'black'}
          strokeWidth={strokeWidth}
          fill={'transparent'}
          onClick={selectMarking}
          key={`${key}_frag`}
          keyIndex={key}
          draggable
          onDragEnd={updateXY}
          isSelected={isCurrent}
          onChange={onTransform}
        />
      );
    } else if (marking.shape === MARKING_TYPE.Circle) {
      return (
        <Circle
          x={marking.x * scaling}
          y={marking.y * scaling}
          radius={r}
          stroke={'rgba(245, 66, 66, 1)'}
          strokeWidth={strokeWidth}
          outerFill={'rgba(245, 66, 66, 0.3)'}
          innerFill={'rgba(245, 66, 66, 1)'}
          onClick={selectMarking}
          key={`${key}_frag`}
          keyIndex={key}
          draggable
          onDragEnd={updateXY}
          isSelected={isCurrent}
          onChange={onTransform}
        />
      );
    }
  };

  const renderGroup = (group, index) => {
    const scaling = imgDimensions.height / imgDimensions.naturalHeight;
    let strokeWidth = 6 * scaling;
    if (strokeWidth < 1) strokeWidth = 1;

    const coordinates = [];

    group.forEach(marking => {
      coordinates.push(marking.x * scaling);
      coordinates.push(marking.y * scaling);
    });

    return (
      <Polygon
        key={`g-${index}`}
        points={coordinates}
        fill={'rgba(245, 66, 66, 0.3)'}
        stroke={'rgba(245, 66, 66, 1)'}
        strokeWidth={strokeWidth}
        closed
      />
    );
  };

  //////////////// RENDER ////////////////

  // const renderMarkingOptions = () => {
  //   let marking;
  //   if (currentMarking) {
  //     marking = markings.find(x => x.id === currentMarking);
  //   }

  //   const setFilled = () => {
  //     let updatedMarkings = markings.slice();

  //     const index = updatedMarkings.findIndex(x => x.id === currentMarking);
  //     updatedMarkings[index].filled = !updatedMarkings[index].filled;

  //     setMarkings(updatedMarkings);
  //   };

  //   return (
  //     <React.Fragment>
  //       <Button
  //         onClick={() => setFilled()}
  //         color='faded'
  //         outline
  //         className='imgMarker__btn--option padding-left-10px'
  //         disabled={!marking}
  //       >
  //         <Input
  //           type='checkbox'
  //           name={'marking_filled'}
  //           checked={marking ? marking.filled : false}
  //           onChange={() => {}}
  //           id='marking_filled'
  //           disabled={!marking}
  //         />
  //         <label for='marking_filled'>{i18next.t(7048)}</label>
  //       </Button>
  //     </React.Fragment>
  //   );
  // };

  const renderSaveMenu = () => {
    return (
      <div className='save-menu'>
        <Button
          color='success'
          className='primaryBtn float-right margin-left-10px'
          onClick={saveMarkings}
          disabled={markings?.length === 0 || isSaving}
        >
          {isSaving ? <Spinner /> : i18next.t(148)}
        </Button>
        <Button
          color='danger'
          outline
          onClick={deleteImageAction}
          disabled={isSaving}
          className='float-right margin-left-10px'
        >
          {i18next.t(147)}
        </Button>
        <Button
          color='btnSecondary'
          outline
          onClick={downloadImageAction}
          disabled={isSaving}
          className='float-right'
        >
          {i18next.t(3242)}
        </Button>
      </div>
    );
  };

  return (
    <div className='imgMarker__wrapper'>
      <div className='imgMarker'>
        <div className='sticky'>
          <ModalHeader toggle={toggleModal}>
            <div className='functions'>
              <Button
                color='btnSecondary'
                onClick={() => addMarking()}
                disabled={isSaving}
              >
                {i18next.t(6417)}
              </Button>
              <Button
                color='btnSecondary'
                className='padding-left-10px'
                onClick={connectGroup}
                disabled={
                  markings?.filter(x => !x.hidden).length < 3 || isSaving
                }
              >
                {i18next.t(7069)}
              </Button>
              <Button
                disabled={markings?.length === 0 || isSaving}
                className='padding-left-10px'
                // onClick={toggleConfirmDeleteModal}
                onClick={removeMarking}
              >
                {i18next.t(1243)}
              </Button>
            </div>
            {renderSaveMenu()}
            {/* {renderMarkingOptions()} */}
          </ModalHeader>
          <FormText>{i18next.t(217)}</FormText>
          <FormText>{currentFile?.fileTitle}</FormText>
        </div>

        <div className='imgMarker__container'>
          {renderImage()}
          <Stage
            width={imgDimensions.width}
            height={imgDimensions.height}
            onClick={onClickImage}
          >
            <Layer>
              {markings.map(renderMarking)}
              {groups.map(renderGroup)}
            </Layer>
          </Stage>
        </div>

        <ConfirmModal
          header={i18next.t(144)}
          show={showConfirmDelete}
          toggle={toggleConfirmDeleteModal}
          confirmFunc={removeMarking}
          cancelFunc={toggleConfirmDeleteModal}
        />
      </div>
    </div>
  );
};

export default ImageMarker;

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// MARKING SHAPES /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

const Rectangle = props => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  return (
    <React.Fragment>
      <Rect
        {...props}
        key={props.keyIndex}
        ref={shapeRef}
        onTap={props.onClick}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          props.onChange({
            type: MARKING_TYPE.Square,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {props.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          key={`${props.keyIndex}_tf`}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

const Circle = props => {
  const shapeRefOuter = React.useRef();
  const shapeRefInner = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRefOuter.current, shapeRefInner.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  return (
    <React.Fragment>
      <Group
        key={props.keyIndex}
        draggable={props.draggable}
        onDragEnd={props.onDragEnd}
        x={props.x}
        y={props.y}
      >
        <Circ
          x={0}
          y={0}
          radius={props.radius}
          stroke={props.stroke}
          fill={props.outerFill}
          ref={shapeRefOuter}
          onTap={props.onClick}
          onTransformEnd={e => {
            const node = shapeRefOuter.current;
            const scale = node.scale().x;

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            props.onChange({
              type: MARKING_TYPE.Circle,
              r: Math.floor(node.radius() * scale),
            });
          }}
        />
        <Circ
          x={0}
          y={0}
          radius={props.radius / 5}
          fill={props.innerFill}
          ref={shapeRefInner}
          onTap={props.onClick}
          onTransformEnd={e => {
            const node = shapeRefInner.current;
            const scale = node.scale().x;

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            props.onChange({
              type: MARKING_TYPE.Circle,
              r: Math.floor(node.radius() * scale),
            });
          }}
        />
      </Group>
      {props.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={[]}
          // enabledAnchors={[
          //   'top-left',
          //   'top-right',
          //   'bottom-left',
          //   'bottom-right',
          // ]}
          key={`${props.keyIndex}_tf`}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

const Polygon = props => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  return (
    <React.Fragment>
      <Line {...props} key={props.keyIndex} ref={shapeRef} />
      {props.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={[]}
          // enabledAnchors={[
          //   'top-left',
          //   'top-right',
          //   'bottom-left',
          //   'bottom-right',
          // ]}
          key={`${props.keyIndex}_tf`}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};
