import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, DropdownItem, FormGroup, Label, Button } from 'reactstrap';
import moment from 'moment';

// Actions
import {
  updateControlItemMetaData,
  updateSchemasControlItemsMetaData,
  removeControlItemMetaData,
} from '../../redux/actions/schemas';

// Utils
import { getUserData } from '../../utils/userUtil';
import i18n from '../../i18n';
import toast from '../../utils/toast';
import { parseDate } from '../../utils/common';

// Components
import ButtonDropdown from '../Custom/ButtonDropdown';
import CustomModal from '../Custom/CustomModal';
import ConfirmModal from '../Custom/ConfirmModal';
import ReactSelect from '../Custom/ReactSelect';
import ReactDatePicker from '../Custom/ReactDatePicker';
import Typography from '../Typography';
import TextInput from '../Custom/Forms/TextInput';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { EMPLOYEE_TYPE } from '../../redux/constants';

const ControlItemMetaAdminModal = ({
  auth,
  control,
  updateControlItemMetaData,
  updateSchemasControlItemsMetaData,
  removeControlItemMetaData,
  schema,
  extra,
  employees,
  callback,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [showControlItemModal, setShowControlItemModal] = useState(false);
  const [showSchemaModal, setShowSchemaModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState([]);

  const [userId, setUserId] = useState(null);
  const [date, setDate] = useState(new Date());
  const size = 'lg';
  const labelSize = 4;
  const inputSize = 8;
  const allowedTypes = [
    EMPLOYEE_TYPE.ExecutivePerson,
    EMPLOYEE_TYPE.CompanyOwner,
    EMPLOYEE_TYPE.InternalSubcontractor,
  ];

  //#region Use effect

  useEffect(() => {
    const user = getUserData(auth);
    setVisible(user.hasAP);

    const options = employees
      .filter(x => allowedTypes.includes(x.employeeType))
      .map(x => {
        return {
          label: x.name,
          value: x.id,
        };
      });

    if (!!control?.answeredByUserId) {
      setUserId(control?.answeredByUserId);
    } else if (options.length > 0) {
      setUserId(options[0].value);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const options = employees
      .filter(x => allowedTypes.includes(x.employeeType))
      .map(x => {
        return {
          label: x.name,
          value: x.id,
        };
      });

    setOptions(options);
    // eslint-disable-next-line
  }, [employees]);

  //#endregion

  //#region Getters

  const renderMetaInfoField = (title, value) => {
    return (
      <TextInput
        row
        for={title}
        value={value}
        labelSize={labelSize}
        inputSize={inputSize}
        disabled
      />
    );
  };

  const getCurrentUser = () => {
    let name = '';
    if (!!control?.answeredByUserId) {
      const user = employees.find(x => x.id === control.answeredByUserId);

      if (!!user) {
        name = user.name;
      }
    }

    return renderMetaInfoField(i18n.t(21423) + ':', name);
  };

  const getCurrentDate = () => {
    let date = '';
    if (!!control) {
      const dt = !!control.manualAnswerDate
        ? control.manualAnswerDate
        : control.updatedDate;

      const tmp = parseDate(dt, true);

      if (tmp !== '01.01.0001') {
        date = tmp;
      }
    }

    return renderMetaInfoField(i18n.t(21426) + ':', date);
  };

  //#endregion

  //#region Actions

  const onChangeManualDate = date => {
    setDate(date);
  };

  const toggle = () => {
    if (!!schema) {
      setShowSchemaModal(!showSchemaModal);
    } else {
      setShowControlItemModal(!showControlItemModal);
    }
  };

  const handleUpdateMetaData = () => {
    const dataObj = {
      date: moment(date).format('MM.DD.YYYY HH:mm'),
      userId,
      id: !!schema ? null : control.id,
      schemaId: !!schema ? schema.id : null,
    };

    const action = !!schema
      ? updateSchemasControlItemsMetaData
      : updateControlItemMetaData;

    setLoading(true);
    action(dataObj)
      .then(res => {
        setLoading(false);
        toggle();
        toast.success(i18n.t(4000));
        if (!!callback) callback();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        toast.error(i18n.t(212));
      });
  };

  const handleRemoveMetaData = () => {
    const dataObj = {
      id: control.id,
    };

    setLoading(true);
    removeControlItemMetaData(dataObj)
      .then(res => {
        setLoading(false);
        setShowDeleteModal(false);
        toast.success(i18n.t(4000));
        if (!!callback) callback();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        toast.error(i18n.t(212));
      });
  };

  //#endregion

  //#region Inputs

  const renderUserDropdown = () => {
    const title = !!schema ? i18n.t(21429) : i18n.t(21424);

    return (
      <FormGroup row>
        <Label sm={labelSize}>{title}</Label>
        <Col sm={inputSize}>
          <ReactSelect
            name='user'
            options={options}
            value={options.find(x => x.value === userId)}
            onChange={v => setUserId(v.value)}
            isDisabled={isLoading}
            closeMenuOnSelect
          />
        </Col>
      </FormGroup>
    );
  };

  const renderDateTimePicker = () => {
    const title = !!schema ? i18n.t(138) : i18n.t(21427);

    const defaultProps = {
      className: 'form-control',
      wrapperClass: 'meta',
      locale: 'da',
      value: date,
      format: 'dd.MM.yyyy HH:mm',
      showYearDropdown: true,
      showMonthDropdown: true,
      showTimeSelect: true,
      timeIntervals: 15,
      timeCaption: i18n.t(21428),
    };

    return (
      <FormGroup row>
        <Label sm={labelSize}>{title}</Label>
        <Col sm={inputSize}>
          <ReactDatePicker
            {...defaultProps}
            onChange={onChangeManualDate}
            unclearable
          />
        </Col>
      </FormGroup>
    );
  };

  //#endregion

  //#region Render modals

  const footer = () => {
    return (
      <div className='footer-btns'>
        <Button
          size='sm'
          color='btnSecondary'
          outline
          onClick={toggle}
          disabled={isLoading}
        >
          {i18n.t(2)}
        </Button>
        <Button
          size='sm'
          color='success'
          onClick={handleUpdateMetaData}
          disabled={isLoading || !userId || userId === 0}
        >
          {i18n.t(1180)}
        </Button>
      </div>
    );
  };

  const renderControlItemContent = () => {
    return (
      <div>
        <Typography>{i18n.t(21421)}</Typography>
        <Typography>
          <strong>{i18n.t(21422)}</strong>
        </Typography>
        {getCurrentUser()}
        {renderUserDropdown()}

        <Typography>
          <strong>{i18n.t(21425)}</strong>
        </Typography>
        {getCurrentDate()}
        {renderDateTimePicker()}
      </div>
    );
  };

  const renderSchemaContent = () => {
    return (
      <div>
        <Typography>{i18n.t(21430)}</Typography>
        <Typography>
          <strong>{i18n.t(21422)}</strong>
        </Typography>
        {renderUserDropdown()}

        <Typography>
          <strong>{i18n.t(21425)}</strong>
        </Typography>
        {renderDateTimePicker()}
      </div>
    );
  };

  const renderModal = () => {
    const title = !!schema ? i18n.t(21419) : i18n.t(21420);

    return (
      <CustomModal
        show={showControlItemModal || showSchemaModal}
        toggle={toggle}
        size={size}
        footer={footer()}
        title={title}
      >
        {!schema && renderControlItemContent()}
        {!!schema && renderSchemaContent()}
      </CustomModal>
    );
  };

  const renderDeleteModal = () => {
    return (
      <ConfirmModal
        header={i18n.t(21418)}
        show={showDeleteModal}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        cancelFunc={() => setShowDeleteModal(!showDeleteModal)}
        confirmFunc={handleRemoveMetaData}
        showActionSpinner={isLoading}
      >
        <Typography>{i18n.t(21431)}</Typography>
      </ConfirmModal>
    );
  };

  //#endregion

  //#region Main render

  const renderOptions = () => {
    return (
      <>
        {extra}
        <DropdownItem onClick={toggle}>
          <MdEdit size={20} />
          {i18n.t(21417)}
        </DropdownItem>
        <DropdownItem onClick={() => setShowDeleteModal(!showDeleteModal)}>
          <MdDeleteForever size={20} />
          {i18n.t(21418)}
        </DropdownItem>
      </>
    );
  };

  const renderMenuButton = () => {
    if (!visible || (!schema && !control?.answeredByUserId)) return null;

    const shared = (
      <>
        {renderModal()}
        {renderDeleteModal()}
      </>
    );

    if (!!schema) {
      return (
        <>
          <FormGroup row>
            <Label sm={labelSize}>{i18n.t(21419)}</Label>
            <Col sm={inputSize}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button
                  size='sm'
                  color='btnSecondary'
                  onClick={toggle}
                  disabled={isLoading}
                >
                  {i18n.t(16)}
                </Button>
              </div>
            </Col>
          </FormGroup>
          {shared}
        </>
      );
    }

    return (
      <div className='admin-menu'>
        <ButtonDropdown>{renderOptions()}</ButtonDropdown>
        {shared}
      </div>
    );
  };

  return renderMenuButton();

  //#endregion
};

function mapStateToProps({ auth, employees }) {
  return { auth, employees: employees.employees };
}

export default connect(mapStateToProps, {
  updateControlItemMetaData,
  updateSchemasControlItemsMetaData,
  removeControlItemMetaData,
})(ControlItemMetaAdminModal);
