import React from 'react';
global.appVersion = process.env.REACT_APP_VERSION;

// version from response - first param, local version second param
const semVerCompare = (versionA, versionB) => {
  const cleanStr = str =>
    str
      .replace('v', '')
      .replace(/-DEV|-DEMO|-STAGING/i, '')
      .split(/\./g);

  const latest = cleanStr(versionA);
  const current = cleanStr(versionB);

  while (latest.length || current.length) {
    const a = Number(latest.shift());
    const b = Number(current.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  componentDidMount() {
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const latestVersionId = meta.version_id;
        const currentVersionId = window.localStorage.getItem('version_id');

        const shouldForceRefresh = semVerCompare(latestVersion, currentVersion);
        if (shouldForceRefresh || latestVersionId !== currentVersionId) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`,
          );
          this.refreshCacheAndReload(latestVersionId);
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`,
          );
        }
      });
  }

  refreshCacheAndReload = versionId => {
    console.log('Clearing cache and hard reloading...');
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.location.reload();
    window.localStorage.setItem('version_id', versionId);
  };

  render() {
    return this.props.children;
  }
}

export default CacheBuster;
