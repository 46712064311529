/**
 * @module actions/emploees
 */
import axios from 'axios';
import { API_URL, SET_EMPLOYEES } from '../constants';
import { setCurrentUser } from './auth';

/**
 * @description Getting employees from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getEmployees(compId) {
  let url = API_URL + `/user/${compId}`;
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: SET_EMPLOYEES,
        employees: res.data,
      });
      return res;
    });
  };
}

/**
 * @description Getting employees from API and dispatching event
 * @param {Object} filter Filter the users by internal (1) or external (2) users. Default = { type: 0 }
 * @return {Promise} Axios promise
 */
export function filterEmployees(compId, filter = { type: 0 }) {
  let url = API_URL + `/user/${compId}/filter`;
  return dispatch => {
    return axios.post(url, filter).then(res => {
      //console.log('filterEmployees', res);
      dispatch({
        type: SET_EMPLOYEES,
        employees: res.data,
      });
      return res;
    });
  };
}

/**
 * @description Getting employees from API wihtout updating redux
 * @param {Object} filter Filter the users by internal (1) or external (2) users. Default = { type: 0 }
 * @return {Promise} Axios promise
 */
export function filterEmployeesRaw(compId, filter = { type: 0 }) {
  let url = API_URL + `/user/${compId}/filter`;
  return dispatch => {
    return axios.post(url, filter).then(res => {
      dispatch({
        type: SET_EMPLOYEES,
        employees: res.data,
      });
      return res;
    });
  };
}

/**
 * @description Getting a single employee from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getEmployee(customerId, id) {
  let url = API_URL + `/user/${customerId}/${id}`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Creating an employee
 * @return {Promise} Axios promise
 */
export function CreateEmployee(compId, data) {
  let url = API_URL + `/user/${compId}`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Updating an employee
 * @return {Promise} Axios promise
 */
export function UpdateEmployee(compId, id, data) {
  let url = API_URL + `/user/${compId}/${id}/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Updating an user-customer binding
 * @return {Promise} Axios promise
 */
export function updateBinding(data) {
  let url = API_URL + `/user/updateBinding`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description "Deleting" an employee
 * @return {Promise} Axios promise
 */
export function DeleteEmployee(compId, id) {
  let url = API_URL + `/user/${compId}/${id}/delete`;
  return dispatch => {
    return axios.post(url);
  };
}

/**
 * @description "Deleting" an employee
 * @return {Promise} Axios promise
 */
export function deleteUser(data) {
  let url = API_URL + '/user/sa-delete';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Remove a users binding to the company
 * @return {Promise} Axios promise
 */
export function removeEmployee(data) {
  let url = API_URL + `/user/remove-binder`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Remove a users binding to the company
 * @return {Promise} Axios promise
 */
export function removeUserBinding(data) {
  let url = API_URL + `/user/remove-user-binder`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get a current users avatar image
 * @return {Promise} Axios promise
 */
export function getUserAvatar() {
  let url = API_URL + `/file/avatar`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Get a users avatar image
 * @return {Promise} Axios promise
 */
export function getSpecificUserAvatar(userId) {
  let url = API_URL + `/file/avatar/` + userId;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Upload current users avatar
 * @return {Promise} Axios promise
 */
export function uploadUserAvatar(file) {
  let url = API_URL + `/file/upload/avatar/`;
  //console.log(file);

  let data = new window.FormData();
  data.append(file.fileName, file);

  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update user data
 */
export function updateLocalUserData(data) {
  return dispatch => {
    const localUserData = JSON.parse(window.localStorage.getItem('user_data'));

    const updateObj = {
      ...localUserData,
      user: {
        ...localUserData.user,
        ...data,
      },
    };

    window.localStorage.setItem('user_data', JSON.stringify(updateObj));
    dispatch(setCurrentUser(updateObj));
  };
}
