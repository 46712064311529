/**
 * @module actions/files
 */
import axios from 'axios';
import { API_URL } from '../constants';

/**
 * @description Get file token
 * @return {Promise} Axios promise
 */
export function getFileToken(data) {
  let url = API_URL + `/file/token/get`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Create a file token
 * @return {Promise} Axios promise
 */
export function createFileToken(data) {
  let url = API_URL + `/file/token`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Create a file token for QI
 * @return {Promise} Axios promise
 */
export function createFileTokenQi(data) {
  let url = API_URL + `/file/tokenQi`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Create a file token for SA
 * @return {Promise} Axios promise
 */
export function createFileTokenSa(data) {
  let url = API_URL + `/file/tokenSa`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Create a file token for schemas
 * @return {Promise} Axios promise
 */
export function createFileTokenSchemas(data) {
  let url = API_URL + `/file/tokenSchemas`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Recreate a file token
 * @return {Promise} Axios promise
 */
export function recreateFileToken(data) {
  let url = API_URL + `/file/token/regen`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Recreate a file token
 * @return {Promise} Axios promise
 */
export function recreateFileTokenSchemas(data) {
  let url = API_URL + `/file/tokenSchemas/regen`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Expire a file token
 * @return {Promise} Axios promise
 */
export function expireFileToken(data) {
  let url = API_URL + `/file/token/expire`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Expire a file token
 * @return {Promise} Axios promise
 */
export function expireFileTokenSchemas(data) {
  let url = API_URL + `/file/token/expire-schema`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Delete a file token
 * @return {Promise} Axios promise
 */
export function deleteFileToken(data) {
  let url = API_URL + `/file/token/delete`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Delete a file token
 * @return {Promise} Axios promise
 */
export function deleteFileTokenSchemas(data) {
  let url = API_URL + `/file/token/delete-schema`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update a file tokens expiration date.
 * @return {Promise} Axios promise
 */
export function updateExpirationDate(data) {
  let url = API_URL + `/file/token/expire/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update a file tokens expiration date.
 * @return {Promise} Axios promise
 */
export function updateExpirationDateSchemas(data) {
  let url = API_URL + `/file/token/expire/update-schemas`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update a file token for Qi
 * @return {Promise} Axios promise
 */
export function updateFileTokenQi(data) {
  let url = API_URL + `/file/token/updateQi`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update a file token for Qi
 * @return {Promise} Axios promise
 */
export function updateFileTokenSa(data) {
  let url = API_URL + `/file/token/updateSa`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Check if a file token is valid
 * @return {Promise} Axios promise
 */
export function validateFileToken(token) {
  let url = API_URL + `/file/token/valid?token=${token}`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Check if a file token is valid
 * @return {Promise} Axios promise
 */
export function validatePdfSchemaToken(token) {
  let url = API_URL + `/file/token/valid-schema?token=${token}`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Check if a file token exist for schema combination
 * @return {Promise} Axios promise
 */
export function checkForFileTokenForSchemaCombination(token) {
  let url = API_URL + `/file/tokenSchemas/used`;
  return dispatch => {
    return axios.post(url, token);
  };
}

/**
 * @description Upload files with no affiliation
 * @return {Promise} Axios promise
 */
export function uploadGenericFiles(files) {
  return dispatch => {
    let url = API_URL + '/file/upload/generic';

    let data = new window.FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.append(file.name, file);
    }

    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * @description Check if a file token is valid
 * @return {Promise} Axios promise
 */
export function downloadFileWithToken(token) {
  let url = API_URL + `/pdf/download/file?token=${token}`;
  return dispatch => {
    return axios.get(url).then(res => {
      const { data } = res;
      return data.path + '\\' + data.name;
    });
  };
}

/**
 * @description Check if a file token is valid
 * @return {Promise} Axios promise
 */
export function downloadPdfSchemaWithToken(token) {
  let url = API_URL + `/pdf/download/schemas-token`;
  return dispatch => {
    return axios.post(url, { token }).then(res => {
      const { data } = res;
      return data.path + '\\' + data.name;
    });
  };
}

/**
 * @description Download pdf schemas via token
 * @return {Promise} Axios promise
 */
export function downloadPdfSchemasWithToken(token) {
  let url = API_URL + `download/schemas`;
  return dispatch => {
    return axios.post(url, { token });
  };
}

/**
 * @description Update file information and rotation state
 * @return {Promise} Axios promise
 */
export function bulkFileInfoUpdate(data) {
  let url = API_URL + `/file/fileinfo-rotate`;
  return dispatch => {
    return axios.post(url, data);
  };
}
