import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';

// Actions

// Utils
import { SB_CONTROL_TYPE } from '../../redux/constants';
import { displayCurrentLangObj } from '../../utils/translation/langauges';
import { StringIsEmpty } from '../../utils/common';
import toast from '../../utils/toast';

// Components
import TextInput from '../Custom/Forms/TextInput';
import Typography from '../Typography';
import ControlItem from './ControlItem';

const ControlSchemaSigning = props => {
  const { schema, groupId, signatureToken } = props;
  const { schemaGroups } = props.schemas;
  const [isOpen, setOpen] = useState(false);
  const [setUpdating] = useState(false);
  const [missingSignatures, setMissingSignatures] = useState(false);
  const [hasSignatureValues, setHasSignatureValues] = useState(false);
  const groupIndex = schemaGroups.findIndex(x => x.id === groupId);
  const labelSize = 3;
  const inputSize = 9;
  let title =
    props.title +
    (!StringIsEmpty(displayCurrentLangObj(schema.name))
      ? ' - ' + displayCurrentLangObj(schema.name)
      : '');

  if (missingSignatures) {
    title = '';
    if (!StringIsEmpty(schema.name)) {
      title = schema.name + ' - ';
    }
    title += i18next.t(21153);
  }

  useEffect(() => {
    refreshSignatureStatus();
    // eslint-disable-next-line
  }, []);

  const onChangeNull = () => {};

  const refreshSignatureStatus = () => {
    const signatures = schema.controlItems?.filter(
      x => x.controlType === SB_CONTROL_TYPE.Signature,
    );
    const missing = signatures.filter(
      x => !x.signatureValue || x.signatureValue?.trim().length === 0,
    );
    const signed = signatures.filter(x => !!x.signatureValue);

    // console.log('useEffect', missing, signed);

    setMissingSignatures(missing.length > 0);
    setHasSignatureValues(signed.length > 0);
  };

  const showSigningSuccessfulToast = () => {
    toast.successLong(i18next.t(21184));
  };

  const renderControlItem = (control, idx) => {
    let sharedProps = {
      schema,
      groupIndex,
      itemIndex: idx,
      schemaIndex: props.index,
      control,
      setUpdating,
      disableAllFunctions: true,
      areControlsValid: true,
      clearSignatureValues:
        hasSignatureValues && control.controlType !== SB_CONTROL_TYPE.Signature,
      refreshSignatureStatus,
      signatureToken,
      signingOnly: true,
      showSigningSuccessfulToast,
    };

    if (control.isGroup) {
      return renderControlItemGroup(control, idx, sharedProps);
    } else {
      return (
        <div key={idx} className='control-item-wrap'>
          <ControlItem key={idx} {...sharedProps} />
          <hr />
        </div>
      );
    }
  };

  const renderControlItemGroup = (group, gIndex, sharedProps) => {
    let groupTitle;
    if (!!group.title && group.title?.trim().length > 0) {
      groupTitle = displayCurrentLangObj(
        group.title,
        props.language.currentLanguage,
      );
    }

    return (
      <div key={'xtid' + gIndex} className='control-item-group'>
        {!!groupTitle && (
          <Typography>
            <strong>{groupTitle}</strong>
          </Typography>
        )}
        {group.controlItems.map((x, i) => {
          let expandedProps = {
            ...sharedProps,
            itemIndex: i,
            control: { ...x, required: group.required || x.required },
            itemGroupIndex: gIndex,
            group,
          };

          return (
            <ControlItem key={'gi+' + i} {...expandedProps} isGroupControl />
          );
        })}
        <hr />
      </div>
    );
  };

  return (
    <div className='control-schema-item'>
      <Card id={'sb-' + schema.id}>
        <CardHeader
          className='controls-schema-header'
          onClick={() => setOpen(!isOpen)}
        >
          <div className='schema-header'>
            <Typography type='h5' className='schema-title'>
              {displayCurrentLangObj(title)}
            </Typography>
          </div>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>
            <TextInput
              row
              disabled={true}
              for={i18next.t(1300)}
              labelSize={labelSize}
              inputSize={inputSize}
              name='name'
              value={schema.name}
              onChange={onChangeNull}
            />

            {/* Controls */}
            {schema.controlItems.map(renderControlItem)}
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

function mapStateToProps({ schemas, language }) {
  return {
    schemas,
    language,
  };
}

export default connect(mapStateToProps, {})(ControlSchemaSigning);
