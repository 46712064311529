import axios from 'axios';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import thunk from 'redux-thunk';

//Redux
import { SET_LOGIN_AS } from './constants';
import {
  setCurrentUser,
  logout,
  loginOutAs,
  getCustomerStatus,
  getCompanyBindings,
} from './actions/auth';
import { getSystemMessage } from './actions/systemInfo';
import { getFeatures } from './actions/features';
import { getPlatformBrandingUser } from './actions/branding';

// Utils
import {
  setAuthorizationToken,
  setDeviceToken,
} from '../utils/setAuthorizationToken';

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middleware)),
  );

  try {
    // Pre-check of login-status - Tokens and user data
    const usertoken = localStorage.getItem('user_token');
    const userdata = localStorage.getItem('user_data');
    const loggedInUser = localStorage.getItem('loggedInAs');
    if (usertoken && userdata) {
      if (loggedInUser) {
        const j = JSON.parse(loggedInUser);
        setAuthorizationToken(j.loginData.token);
        setDeviceToken(j.loginData.deviceToken);
        store.dispatch({
          type: SET_LOGIN_AS,
          user: loggedInUser,
        });
      } else {
        setAuthorizationToken(usertoken);
        setDeviceToken(JSON.parse(userdata).deviceToken);
      }

      const data = JSON.parse(userdata);

      store.dispatch(setCurrentUser(data));
      store.dispatch(getSystemMessage());
      store.dispatch(getFeatures());
      store.dispatch(getCustomerStatus());
      store.dispatch(
        getCompanyBindings({ userData: data, scustomer: true, user: true }),
      );

      if (data.accessLevel !== 1) {
        store.dispatch(getPlatformBrandingUser());
      }
    }
  } catch (error) {
    console.log('An error occured', error);
    store.dispatch(logout());
  }

  // Axios response interceptor - Logout on 401
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error) {
        if (error.response) {
          if (
            error.response.status === 401 &&
            window.location.pathname !== '/approval' &&
            window.location.pathname !== '/verification'
          ) {
            const isLoginAs = !!store.getState().auth.loginAsUser;

            if (isLoginAs) {
              store.dispatch(loginOutAs());
            } else {
              // Because of problematic implementation (auth.user)
              setTimeout(() => store.dispatch(logout()), 0);
              // store.dispatch(logout());
            }

            // Because of problematic implementation (auth.user)
            setTimeout(() => {
              if (window.location.pathname !== '/login') {
                history.push('/');
                window.location.reload(true);
              }
            }, 200);
            // if (window.location.pathname !== '/login') {
            //   history.push('/');
            //   window.location.reload(true);
            // }
          } else if (error.response.status === 503) {
            // Redirect to base url if in maintenance mode
            console.log('redirect', process.env.PUBLIC_URL + '/');
            window.location.href = process.env.PUBLIC_URL + '/';
          }
        }
      }
      return Promise.reject(error);
    },
  );

  return store;
}
