import React from 'react';
import { Input, Label, FormGroup, Col } from 'reactstrap';
import ReactSelect from '../ReactSelect';
import CountryList from 'countries-list';

/**
 * Custom text-input with ability to have a label displayed to its left.
 * @param {*} props - set properies here
 * @param {Boolean} row
 * @param {Boolean} disabled
 * @param {Number} labelSize
 * @param {Number} inputSize
 * @param {String} for
 * @param {String} type
 * @param {String} name
 * @param {*} value
 * @param {Function} onChange
 * @param {Function} onBlur
 * @param {String} placeholder
 * @param {Boolean} error - Bind to a boolean value
 * @param {String} errorText - Displayed text when error is true
 */

const PhoneInput = props => {
  const {
    row,
    infoText,
    extra, //error, errorText
    type,
    name,
    value,
    valueCode,
    onChange,
    onChangeCode,
    onBlur,
    placeholder,
    disabled,
    inputSize,
    error,
    errorText,
  } = props;

  const onHandleTelephoneChange = e => {
    let telephone = e.target.value;
    const regex = /^[0-9\b]+$/;

    if (telephone === '' || regex.test(telephone)) {
      props.onChange(e);
    }
  };

  const renderLabel = () => {
    let label = <Label for={props.for}>{props.for}</Label>;

    if (props.row) {
      label = (
        <Label for={props.for} sm={props.labelSize}>
          {props.for}
        </Label>
      );
    }
    return label;
  };

  const getCountryCodeOptions = () => {
    return Object.keys(CountryList.countries).map((country, index) => {
      const obj = CountryList.countries[country];
      const value = `+${obj.phone}`;
      const name = `${obj.emoji} ${value}`;

      return { label: name, value };
    });
  };

  const renderInput = () => {
    const sharedProps = {
      type: type ? type : 'text',
      name: name,
      onBlur: onBlur,
      placeholder: placeholder,
    };

    let codeProps = {
      ...sharedProps,
      value: valueCode ? valueCode : '',
      onChange: onChangeCode,
      disabled,
    };

    let inputProps = {
      ...sharedProps,
      value: value ? value : '',
      onChange: onChange,
      disabled,
      autoComplete: 'new-password',
    };

    let countrySelector = (
      <ReactSelect
        value={valueCode}
        options={getCountryCodeOptions()}
        onChange={onChangeCode}
        className='countryCodeInput'
        placeholder=''
        isDisabled={disabled}
      />
    );

    let inputComp = (
      <div className='countryPhoneInput'>
        {countrySelector}
        <Input
          {...inputProps}
          className='phoneInput'
          onChange={onHandleTelephoneChange}
        />
      </div>
    );

    if (row) {
      codeProps.bsSize = 'md';
      inputProps.bsSize = 'md';
      inputComp = (
        <Col sm={inputSize}>
          <div className='countryPhoneInput'>
            {countrySelector}
            <Input
              {...inputProps}
              className='phoneInput'
              onChange={onHandleTelephoneChange}
            />
          </div>
          {extra}
          {error && <p style={{ color: 'red' }}>{errorText}</p>}
        </Col>
      );
    }

    return inputComp;
  };

  return (
    <FormGroup row={row}>
      {renderLabel()}
      {renderInput()}
      {infoText ? (
        <p style={{ fontSize: 11, marginTop: 10 }}>
          {infoText ? infoText : null}
        </p>
      ) : null}
      {!row ? extra : null}
      {/* {error && <p style={{ color: 'red' }}>{errorText}</p>} */}
    </FormGroup>
  );
};

export default PhoneInput;
