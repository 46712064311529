import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, FormText } from 'reactstrap';

// Actions

// Utils
import { displayCurrentLangObj } from '../../../../utils/translation/langauges';

// Components
import Typography from '../../../Typography';

const SchemaGroups = props => {
  const { building, categories, schemas, setGroup } = props;
  const { schemaGroups } = schemas;
  const currentGroups = schemaGroups
    .filter(x => x.caseBuildingId === building.value)
    .map(x => x.sbSchemaId);

  const renderCategoryItem = (group, key) => {
    const category = schemaGroups.find(
      x => x.sbSchemaId === group.id && x.caseBuildingId === building.value,
    );

    const renderItemInformation = () => {
      const total = category.schemas.length;
      const doneSchemas = category.schemas.filter(x => x.isDone).length;

      return (
        <>
          <div className='number-of-schemas'>
            <span className='all_done'>{doneSchemas}</span>
            <span className='status'>/{total}</span>
          </div>
        </>
      );
    };

    return (
      <div key={key}>
        <div className='schema__category'>
          <Card className='header'>
            <CardHeader
              className={'controls-schema-header'}
              onClick={() => setGroup(group)}
            >
              <div className='schema-header'>
                <Typography type='h5' className='schema-title'>
                  {displayCurrentLangObj(
                    group.name,
                    props.language.currentLanguage,
                  )}
                  <FormText>{group.displayId}</FormText>
                </Typography>
                <div className='schema-menu'>{renderItemInformation()}</div>
              </div>
            </CardHeader>
          </Card>
        </div>
        <br />
      </div>
    );
  };

  const renderGroups = () => {
    const renders = [];

    if (categories) {
      categories.forEach((x, index) => {
        const schemasInCategory = x.schemas.filter(y =>
          currentGroups.includes(y.id),
        );

        if (schemasInCategory.length > 0) {
          const render = (
            <div key={index}>
              <Typography type='p'>
                <b>
                  {displayCurrentLangObj(
                    x.category.name,
                    props.language.currentLanguage,
                  )}
                </b>
              </Typography>
              {schemasInCategory.map(renderCategoryItem)}
              <hr />
            </div>
          );

          renders.push(render);
        }
      });
    }

    return renders;
  };

  return <div>{renderGroups()}</div>;
};

function mapStateToProps({ auth, schemas, language }) {
  return {
    auth,
    schemas,
    language,
  };
}

export default connect(mapStateToProps, {})(SchemaGroups);
