import React from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';

// Actions

// Utils

// Components
import ControlItemDropdownCustom from './ControlItemDropdownCustom';
import ControlItemPhoto from './ControlItemPhoto';
import ControlItemText from './ControlItemText';

const ControlItemDropdownCommentPhotos = props => {
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// DROPDOWN

  const renderDropdown = () => {
    return (
      <ControlItemDropdownCustom
        {...props}
        control={{
          ...props.control,
          placeholder: i18next.t(1468),
        }}
        hideAdmin
      />
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// TEXT

  const rendertextInput = () => {
    const textTitle = i18next.t(3205);

    return (
      <ControlItemText
        {...props}
        control={{ ...props.control, title: textTitle, showInfoIcon: false }}
        hideAdmin
      />
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// Photo

  const renderFileInput = () => {
    const fileTitle = i18next.t(1060);

    return (
      <ControlItemPhoto
        {...props}
        control={{ ...props.control, title: fileTitle, showInfoIcon: false }}
      />
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {renderDropdown()}
      {rendertextInput()}
      {renderFileInput()}
    </div>
  );
};

function mapStateToProps({ auth, schemas, cases }) {
  return {
    auth,
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {})(ControlItemDropdownCommentPhotos);
