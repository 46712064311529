import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import i18next from '../../i18n';
import { languages } from '../../utils/translation/langauges';

// Actions
import { getLanguages, setLanguage } from '../../redux/actions/language';

const LoadingBoundary = props => {
  const { setLanguage } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get Langauge Index for translation
    props.getLanguages().then(res => {
      languages.forEach(x => {
        let langObj = {};
        let lang = x.lang;
        res.forEach(element => {
          langObj[element.key] = element[lang];
        });

        i18next.addResourceBundle(x.lang, 'translation', langObj);
      });

      setLoading(false);
    });
    const lang = window.localStorage.getItem('user_lang');
    if (lang) {
      i18next.changeLanguage(lang);
      setLanguage(lang);
    } else {
      window.localStorage.setItem('user_lang', 'dk');
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div width='100%' height='100%'>
        <Spinner className='preload-loading' />
      </div>
    );
  }

  return props.children;
};

function mapStateToProps({ language }) {
  return {
    language,
  };
}

export default connect(mapStateToProps, { getLanguages, setLanguage })(
  LoadingBoundary,
);
