import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';

// Actions

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import Typography from '../../Typography';
import CustomCheckbox from '../../Custom/Forms/Checkbox';
import InfoTip from '../../Custom/InfoTip';
import LastEditor from '../../Custom/LastEditor';
import ControlItemMetaAdminModal from '../ControlItemMetaAdminModal';

const ControlItemCheckmark = props => {
  const {
    control,
    disabled,
    onChangeCheckmark,
    areControlsValid,
    refreshGroups,
  } = props;
  const {
    title,
    alternativeTitle,
    marked,
    hideMarkedQuestion,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    answeredByUserId,
    errorMessage,
  } = control;
  const [valid, setValid] = useState(true);
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [control]);

  const onChange = (name, value) => {
    onChangeCheckmark({
      target: {
        name,
        checked: value,
      },
    });
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <div className='control-item_checkmark'>
      <Typography>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor {...control} />
      </Typography>
      <CustomCheckbox
        row
        disabled={disabled}
        for={i18next.t(140)}
        labelSize={labelSize}
        inputSize={inputSize}
        name={`yes`}
        onChange={() => onChange('marked', true)}
        checked={marked && answeredByUserId && !hideMarkedQuestion}
      />
      <CustomCheckbox
        row
        disabled={disabled}
        for={i18next.t(1044)}
        labelSize={labelSize}
        inputSize={inputSize}
        name={`no`}
        onChange={() => onChange('marked', false)}
        checked={!marked && answeredByUserId && !hideMarkedQuestion}
      />
      <CustomCheckbox
        row
        disabled={disabled}
        for={i18next.t(214)}
        labelSize={labelSize}
        inputSize={inputSize}
        name={`notRelevant`}
        onChange={() => onChange('hideMarkedQuestion', !hideMarkedQuestion)}
        checked={hideMarkedQuestion || !answeredByUserId}
      />
      {!disabled && (
        <ControlItemMetaAdminModal control={control} callback={refreshGroups} />
      )}
      <br />
    </div>
  );
};

function mapStateToProps({ auth, schemas, cases }) {
  return {
    auth,
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {})(ControlItemCheckmark);
