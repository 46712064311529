import React from 'react';

// Utils
import { isJSON } from '../common';

// Icons
import { SB_CONTROL_TYPE } from '../../redux/constants';
import {
  DanishFlag,
  UnitedKingdomFlag,
  PolandFlag,
  GermanFlag,
  NorwayFlag,
  //SwedenFlag,
} from '../../assets/icons_v2';

// Global current user lang
const globalLang = window.localStorage.getItem('user_lang');

export const languages = [
  { name: 'Dansk', lang: 'dk', icon: <DanishFlag /> },
  { name: 'English', lang: 'en', icon: <UnitedKingdomFlag /> },
  { name: 'Polski', lang: 'pl', icon: <PolandFlag /> },
  { name: 'Deutsch', lang: 'de', icon: <GermanFlag /> },
  { name: 'Norsk', lang: 'no', icon: <NorwayFlag /> },
  //{ name: 'Svenska', lang: 'se', icon: <SwedenFlag /> },
];

export function languageIsValid(lang) {
  if (languages.find(x => x.lang === lang)) {
    return true;
  }
  return false;
}

export function initialLanguageObject() {
  let obj = {};
  languages.forEach(x => {
    obj[x.lang] = '';
  });
  return JSON.stringify(obj);
}

export function changeCurrentLangObj(obj, value, lang = globalLang) {
  const isValidJson = typeof obj === 'string' && isJSON(obj);

  let dataObj = {
    [lang]: value,
  };

  if (isValidJson) {
    const prev = JSON.parse(obj);
    dataObj = { ...prev, [lang]: value };
  }

  return JSON.stringify(dataObj);
}

export function displayCurrentLangObj(obj, lang = globalLang) {
  let out;

  try {
    out = typeof obj === 'string' ? JSON.parse(obj.replace(/\n/g, '\\n')) : obj;
    if (!out[lang]) {
      return '';
    }
    return out[lang];
  } catch (error) {
    // JSON parsing failed, output the original string
    //return obj+"-MANGLER";
    return obj;
  }
}

////////////////////////////////////////////////////////////////////////
///////////////////////  Parse Control Items   /////////////////////////
////////////////////////////////////////////////////////////////////////

export function parseControlItemsLang(data, lang = globalLang) {
  let out = data;
  //console.log(out);
  out.forEach(group => {
    if (group.schemas.length > 0)
      group.schemas.forEach(schema => {
        schema.titleWhenDone = displayCurrentLangObj(schema.titleWhenDone);
        if (schema.controlItems.length > 0)
          schema.controlItems.forEach(controlItem => {
            // Data
            controlItem.title = displayCurrentLangObj(controlItem.title);
            controlItem.alternativeTitle = displayCurrentLangObj(
              controlItem.alternativeTitle,
            );
            controlItem.name = displayCurrentLangObj(controlItem.name);
            controlItem.infoTitle = displayCurrentLangObj(
              controlItem.infoTitle,
            );
            controlItem.infoText = displayCurrentLangObj(controlItem.infoText);
            controlItem.errorMessage = displayCurrentLangObj(
              controlItem.errorMessage,
            );
            controlItem.placeholder = displayCurrentLangObj(
              controlItem.placeholder,
            );
            // Options
            controlItem.dropdownOptions.forEach(dropdownOption => {
              dropdownOption.label = displayCurrentLangObj(
                dropdownOption.label,
              );
            });
            controlItem.checkboxOptions.forEach(checkboxOption => {
              checkboxOption.label = displayCurrentLangObj(
                checkboxOption.label,
              );
            });
            controlItem.categoryOptions.forEach(categoryOption => {
              categoryOption.label = displayCurrentLangObj(
                categoryOption.label,
              );
            });
            // Special cases ie. TextUneditable
            if (controlItem.controlType === SB_CONTROL_TYPE.TextUneditable) {
              controlItem.textValue = displayCurrentLangObj(
                controlItem.textValue,
              );
            }
          });
      });
  });

  //console.log(out);
  return out;
}

////////////////////////////////////////////////////////////////////////
///////////////////////  Parse Control Plan   /////////////////////////
////////////////////////////////////////////////////////////////////////

export function parseControlPlanLang(item, lang = globalLang) {}
