import React from 'react';
import { connect } from 'react-redux';
import i18next from '../../../../i18n';
import { Button } from 'reactstrap';

// Actions

// Utils
import { displayCurrentLangObj } from '../../../../utils/translation/langauges';

// Components
import Typography from '../../../Typography';
import SchemaItem from './SchemaItem';

const SchemaList = props => {
  const {
    building,
    schemas,
    setGroup,
    selectControl,
    currentGroup,
    _targetControlId,
    imageObjects,
  } = props;
  const { schemaGroups } = schemas;
  const schemaGroupIndex = schemaGroups.findIndex(
    x =>
      x.sbSchemaId === currentGroup.id && x.caseBuildingId === building?.value,
  );
  const schemaGroup =
    schemaGroupIndex > -1 ? schemaGroups[schemaGroupIndex] : null;

  const renderGoBackButton = () => {
    return (
      <div className='float-right margin-left-10px'>
        <Button onClick={() => setGroup(null)}>{i18next.t(1039)}</Button>
      </div>
    );
  };

  const renderLabel = () => {
    if (!currentGroup) return '';
    const { name, customTitle, displayId } = currentGroup;
    let text = '';
    let title = name;
    if (customTitle?.trim().length > 0) {
      title = customTitle;
    }

    title = displayCurrentLangObj(title, props.language.currentLanguage);

    if (displayId && displayId.length > 0) {
      text = `${displayId} - ${title}`;
    } else {
      text = title;
    }

    return (
      <Typography className='label' type='p'>
        <b>{text}</b>
      </Typography>
    );
  };

  const renderSchema = (schema, index) => {
    const sharedProps = {
      schema,
      building,
      index,
      groupIndex: schemaGroupIndex,
      selectControl,
      _targetControlId,
      imageObjects,
    };

    return <SchemaItem key={index} {...sharedProps} />;
  };

  return (
    <div className='category__schemas'>
      <Typography type='h3'>{building?.label}</Typography>
      <div className='category__header'>
        {renderLabel()}
        <div className='buttons'>{renderGoBackButton()}</div>
      </div>
      {schemaGroup?.schemas.map(renderSchema)}
    </div>
  );
};

function mapStateToProps({ auth, schemas, language }) {
  return {
    auth,
    schemas,
    language,
  };
}

export default connect(mapStateToProps, {})(SchemaList);
