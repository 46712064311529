import React from 'react';
import i18next from '../../../i18n';
import { Spinner } from 'reactstrap';
import accept from 'attr-accept';
import async from 'async';

const FileInput = props => {
  const {
    isUploading,
    disabled,
    gallery,
    isMovingImages,
    movingImages,
  } = props;

  const inputRef = React.createRef();

  const onDragOver = e => {
    e.preventDefault();
    if (props.isMovingImages) return;
    try {
      e.dataTransfer.dropEffect = 'copy';
    } catch (error) {}

    return false;
  };

  const onDrop = e => {
    e.preventDefault();
    if (props.isMovingImages) return;

    const droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let acceptedFiles = [];
    let rejectedFiles = [];

    async.each(
      droppedFiles,
      (file, cb) => {
        if (props.useAccept) {
          // console.log('acceptable', this.isAcceptableFile(file));
          if (!isAcceptableFile(file)) {
            rejectedFiles.push(file);
            cb();
          } else {
            acceptedFiles.push(file);
            cb();
          }
        } else {
          acceptedFiles.push(file);
          cb();
        }
      },
      () => {
        if (acceptedFiles.length > 0) {
          props.onDropAccepted(acceptedFiles);
        }

        if (rejectedFiles.length > 0) {
          if (props.onDropRejected) {
            props.onDropRejected(rejectedFiles);
          }
        }
      },
    );
  };

  const isAcceptableFile = file => {
    return accept(file, props.accept);
  };

  if (isUploading) {
    return (
      <div className='fileDropzone'>
        <Spinner color='primary' />
      </div>
    );
  }

  let labelText = i18next.t(1184);
  if (props.labelText) {
    labelText = props.labelText;
  }

  let contentClass = '';
  if (gallery) {
    contentClass = 'fileDropContent';
  }
  if (isMovingImages) {
    if (!gallery) {
      contentClass = 'fileDropContent';
    }
    contentClass += ' moving-images';
  }
  let acceptProps = {};
  if (props.useAccept) {
    acceptProps = {
      accept: props.accept,
    };
  }

  return (
    <div className={contentClass}>
      <div
        className='fileDropzone'
        onClick={() => inputRef.current.click()}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <input
          ref={inputRef}
          name={props.name}
          type='file'
          value=''
          onChange={onDrop}
          multiple
          className='fileInput'
          disabled={disabled || isMovingImages}
          {...acceptProps}
        />

        <div>{labelText}</div>
      </div>
      {gallery}
      {movingImages}
    </div>
  );
};

FileInput.defaultProps = {
  accept: 'image/jpeg,image/jpg,image/png,image/dng,image/heic,application/pdf',
  onDropAccepted: () => console.error('FileInput requires onDropAccepted prop'),
};

export default FileInput;
