import React, { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

// Utils

// Components
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { IconInfo } from '../../assets/icons_v2';
import { getRng } from '../../utils/common';

const InfoTip = props => {
  const { text, html, title, id, placement, inline } = props;
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  let classes = '';
  if (inline) {
    classes += ' inline';
  }

  let content;
  if (text) {
    content = text;
  }
  if (html) {
    content = parse(html);
  }

  return (
    <span
      className={'custom-tooltip-wrap' + classes}
      onClick={toggle}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <IconInfo id={id} className='icon' />
      <Popover
        target={id}
        placement={placement}
        toggle={toggle}
        isOpen={open}
        className='custom-popover-style'
      >
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{content}</PopoverBody>
      </Popover>
    </span>
  );
};

InfoTip.propTypes = {
  id: PropTypes.string,
  placement: PropTypes.string,
};

InfoTip.defaultProps = {
  id: 'it' + Date.now() + '-' + getRng(),
  placement: 'auto',
};

export default InfoTip;
