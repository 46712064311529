import * as React from 'react';

const SvgIconProdukter = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Produkter_svg__a'>
        <path data-name='Rectangle 1114' d='M0 0h20.587v22.163H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1111' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 109'>
      <path data-name='Rectangle 1112' fill='none' d='M0 0h24v24H0z' />
      <g data-name='Group 108'>
        <g data-name='Group 107'>
          <g
            data-name='Group 103'
            clipPath='url(#Icon_-_Produkter_svg__a)'
            transform='translate(1.707 1)'
          >
            <path
              data-name='Rectangle 1113'
              fill='none'
              d='M-2.032-1.317h24.625v24.625H-2.032z'
            />
          </g>
          <g
            data-name='Group 104'
            clipPath='url(#Icon_-_Produkter_svg__a)'
            transform='translate(1.707 1)'
          >
            <path
              data-name='Path 219'
              d='M7.227 18.703a2.673 2.673 0 1 1 0-3.78 2.668 2.668 0 0 1 0 3.78m-5.664-5.651a5.337 5.337 0 1 0 7.548 0 5.343 5.343 0 0 0-7.548 0'
            />
          </g>
          <g
            data-name='Group 105'
            clipPath='url(#Icon_-_Produkter_svg__a)'
            transform='translate(1.707 1)'
          >
            <path
              data-name='Path 220'
              d='M20.599 6.328a3.4 3.4 0 0 1-3.509-1.49c-.062-.086-.517-.911-.591-1.022a6.584 6.584 0 0 0-6.021-3.8 5.735 5.735 0 0 0-3.251 1.367c-.135.259-.271.517-.406.763q-2.714 4.78-5.454 9.575a6.555 6.555 0 0 1 5.294-1.182 6.19 6.19 0 0 1 3.448 1.921c.074.086.148.16.222.246.025.025-1.8-2.524.062.074a8.591 8.591 0 0 1 1.305 2.721l.628 1.872c.431.973 1.588 3 3.952 2.241Z'
            />
          </g>
          <g
            data-name='Group 106'
            clipPath='url(#Icon_-_Produkter_svg__a)'
            transform='translate(1.707 1)'
          >
            <path
              data-name='Path 221'
              d='M4.371 15.846a1.376 1.376 0 1 0 1.945 0 1.39 1.39 0 0 0-1.945 0'
            />
          </g>
        </g>
        <path data-name='Rectangle 1118' fill='none' d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
);

export default SvgIconProdukter;
