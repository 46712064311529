/**
 * @module utils/setAuthorizationToken
 */
import axios from 'axios';

/**
 * @description Sets or deletes Axios default Authorization header
 * @param  {token | boolean} token User token or false
 * @return {undefined}
 */
export function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Basic ${token}`;
    // axios.defaults.headers.common['Authorization'] = token
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

/**
 * @description Sets or deletes Axios default x-device-token header
 * @param  {token | boolean} token Device token or false
 * @return {undefined}
 */
export function setDeviceToken(token) {
  if (token) {
    axios.defaults.headers.common['x-device-token'] = token;
  } else {
    delete axios.defaults.headers.common['x-device-token'];
  }
}
