import React from 'react';
import {
  Circle as Circ,
  Rect,
  Transformer,
  Group,
  Line,
  Text,
} from 'react-konva';
import { MARKING_TYPE } from '../../redux/constants';
import { splitArrayIntoChunks } from '../../utils/common';

export const Circle = props => {
  const shapeRefOuter = React.useRef();
  const shapeRefInner = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRefOuter.current, shapeRefInner.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  const getText = () => {
    if (props.text?.trim().length > 0) {
      return (
        <Text text={props.text} x={props.radius + 10} y={0} fontSize={20} />
      );
    }
    return null;
  };

  const onMouseEnter = e => {
    // style stage container:
    const container = e.target.getStage().container();
    container.style.cursor = 'pointer';
  };

  const onMouseLeave = e => {
    const container = e.target.getStage().container();
    container.style.cursor = 'default';
  };

  let extraProps = {};
  if (props.clickable) {
    extraProps = {
      onMouseEnter,
      onMouseLeave,
    };
  }

  return (
    <React.Fragment>
      <Group
        key={props.keyIndex}
        draggable={props.draggable}
        onDragEnd={props.onDragEnd}
        x={props.x}
        y={props.y}
        {...extraProps}
      >
        <Circ
          x={0}
          y={0}
          radius={props.radius}
          stroke={props.stroke}
          fill={props.outerFill}
          ref={shapeRefOuter}
          onClick={props.onClick}
          onTransformEnd={e => {
            const node = shapeRefOuter.current;
            const scale = node.scale().x;

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            props.onChange({
              type: MARKING_TYPE.Circle,
              r: Math.floor(node.radius() * scale),
            });
          }}
        />
        <Circ
          x={0}
          y={0}
          radius={props.radius / 5}
          fill={props.innerFill}
          ref={shapeRefInner}
          onClick={props.onClick}
          onTransformEnd={e => {
            const node = shapeRefInner.current;
            const scale = node.scale().x;

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            props.onChange({
              type: MARKING_TYPE.Circle,
              r: Math.floor(node.radius() * scale),
            });
          }}
        />
        {getText()}
      </Group>
      {props.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={[]}
          // enabledAnchors={[
          //   'top-left',
          //   'top-right',
          //   'bottom-left',
          //   'bottom-right',
          // ]}
          key={`${props.keyIndex}_tf`}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export const Polygon = props => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  const getText = pos => {
    if (props.text?.trim().length > 0) {
      return <Text text={props.text} {...pos} fontSize={20} />;
    }
    return null;
  };

  const findPolygonCenter = vertices => {
    const len = vertices.length;
    const x_coords = vertices.map(x => x[0]);
    const y_coords = vertices.map(y => y[1]);

    const xCenter = x_coords.reduce((sum, x) => sum + x, 0) / len;
    const yCenter = y_coords.reduce((sum, y) => sum + y, 0) / len;

    return { x: xCenter, y: yCenter };
  };

  let displayId;
  if (props.points?.length > 1) {
    const vertices = splitArrayIntoChunks(props.points, 2);
    const pos = findPolygonCenter(vertices);

    displayId = getText(pos);
  }

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  const onMouseEnter = e => {
    // style stage container:
    const container = e.target.getStage().container();
    container.style.cursor = 'pointer';
  };

  const onMouseLeave = e => {
    const container = e.target.getStage().container();
    container.style.cursor = 'default';
  };

  let extraProps = {};
  if (props.clickable) {
    extraProps = {
      onMouseEnter,
      onMouseLeave,
    };
  }

  return (
    <React.Fragment>
      <Line
        {...props}
        key={props.keyIndex}
        ref={shapeRef}
        onTap={props.onClick}
        {...extraProps}
      />
      {displayId}
      {props.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={[]}
          // enabledAnchors={[
          //   'top-left',
          //   'top-right',
          //   'bottom-left',
          //   'bottom-right',
          // ]}
          key={`${props.keyIndex}_tf`}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export const Rectangle = props => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  return (
    <React.Fragment>
      <Rect
        {...props}
        key={props.keyIndex}
        ref={shapeRef}
        onTap={props.onClick}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          props.onChange({
            type: MARKING_TYPE.Square,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {props.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          key={`${props.keyIndex}_tf`}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};
