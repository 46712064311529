import * as React from 'react';

const SvgTagpaslogoBlaaOutlinet = props => (
  <svg
    id='Tagpas_logo-blaa_outlinet_svg__Lag_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x={0}
    y={0}
    width='100%'
    height='100%'
    viewBox='0 0 534.1 197'
    style={{
      enableBackground: 'new 0 0 534.1 197',
    }}
    xmlSpace='preserve'
    {...props}
  >
    <g
      id='Tagpas_logo-blaa_outlinet_svg__Tagpas_logo-gr\xF8n'
      transform='translate(-15.511 -11.453)'
    >
      <g
        style={{
          enableBackground: 'new',
        }}
      >
        <path
          className='Tagpas_logo-blaa_outlinet_svg__st1'
          d='M236.1 116h-19.9v-11h53.1v11h-19.8v61.2H236V116zM303.9 171.3c-2.1 3.7-7.9 7-15.5 7-9.8 0-17.8-5.3-17.8-15.9 0-10.6 10.3-14.6 20.8-15.4l12.1-.8v-1.4c0-6.5-4.3-9.5-11.3-9.5-6.7 0-12.2 2.5-14.4 4.2l-4.1-8.6c3.9-2.3 11-5.3 19.8-5.3 15.5 0 23.4 7 23.4 22.6v29H304l-.1-5.9zm-9.3-16.4c-7.1.4-10.6 2.8-10.6 7.1s3.2 7.2 8.8 7.2c5.2 0 9.1-3 10.9-5.5v-9.3l-9.1.5zM366.2 167.5c-3.5 5.2-9.5 7.9-17.2 7.9-12.8 0-23.6-8.2-23.6-24.3 0-14 9.8-25.6 24.9-25.6 6.8 0 12.5 2.5 16.1 7.9h.2l-.1-7h13.3v40.1c0 11.1-2.3 17.4-6.6 21.8-4.4 4.6-10.9 8.9-23 8.9-12 0-21-5.7-23.5-7.5l5.5-9.3c3.2 2.3 11.5 6 18 6 11.4 0 16.3-5.3 16.3-16.5v-2.5h-.3zM353 136.1c-8.8 0-14 6.5-14 14.7 0 7.7 5 14.1 13.8 14.1 9.6 0 14.4-6.4 14.4-14.1 0-7.2-5.5-14.7-14.2-14.7zM406 133.6l.2.1c4.1-6.1 10.1-8.4 16.8-8.4 14.8 0 23.9 11.5 23.9 26.6 0 14.3-9.5 26.1-24.9 26.1-7.7 0-12-2.7-16.2-7.7l-.2.1c.1 2.4.2 4.7.2 8.3v17.6h-13.3v-69.7h13.6l-.1 7zm-.5 18.3c0 8.5 5.5 15.9 14.1 15.9 8.7 0 13.7-7.4 13.7-15.9 0-8.8-5.4-15.7-14.1-15.7-8 0-13.7 6.2-13.7 15.7zM485.6 171.3c-2.1 3.7-7.9 7-15.5 7-9.8 0-17.8-5.3-17.8-15.9 0-10.6 10.3-14.6 20.8-15.4l12.1-.8v-1.4c0-6.5-4.3-9.5-11.3-9.5-6.7 0-12.2 2.5-14.4 4.2l-4.1-8.6c3.9-2.3 11-5.3 19.8-5.3 15.5 0 23.4 7 23.4 22.6v29h-12.9l-.1-5.9zm-9.3-16.4c-7.1.4-10.6 2.8-10.6 7.1s3.2 7.2 8.8 7.2c5.2 0 9.1-3 10.9-5.5v-9.3l-9.1.5zM527.5 135.6c-4.4 0-6.6 1.7-6.6 4.1 0 2.2 1.7 4 7.3 5.8l3.6 1.1c11.2 3.7 15.2 7.6 15.2 14.9 0 9.6-7.6 16.7-20.1 16.7-7.7 0-16.4-3.1-20.5-7.8l5.4-9.1c6 4.6 11.1 6.9 15.4 6.9 5 0 7.3-1.9 7.3-4.8 0-2.6-2-4.1-8.5-6.3l-5.9-2c-7.5-2.5-11.4-7-11.4-14.4 0-9.2 8.4-15.2 18.1-15.2 9 0 14 2.4 18.2 5.4l-5.7 8.9c-2.9-2.1-7.2-4.2-11.8-4.2z'
        />
      </g>
      <g id='Tagpas_logo-blaa_outlinet_svg__app-ikon'>
        <g
          id='Tagpas_logo-blaa_outlinet_svg__Group_85'
          transform='translate(8.811 3.953)'
        >
          <g id='Tagpas_logo-blaa_outlinet_svg__Group_183'>
            <defs>
              <path
                id='Tagpas_logo-blaa_outlinet_svg__SVGID_1_'
                d='M6.7 7.5H169v197H6.7z'
              />
            </defs>
            <clipPath id='Tagpas_logo-blaa_outlinet_svg__SVGID_00000007398027554336882630000005655617978931058866_'>
              <use
                xlinkHref='#Tagpas_logo-blaa_outlinet_svg__SVGID_1_'
                style={{
                  overflow: 'visible',
                }}
              />
            </clipPath>
            <g
              id='Tagpas_logo-blaa_outlinet_svg__Group_82'
              style={{
                clipPath:
                  'url(#Tagpas_logo-blaa_outlinet_svg__SVGID_00000007398027554336882630000005655617978931058866_)',
              }}
            >
              <path
                id='Tagpas_logo-blaa_outlinet_svg__Path_27'
                className='Tagpas_logo-blaa_outlinet_svg__st1'
                d='M84.1 100.3v104.2l-63.2-26.9c-1.7-.8-2.9-2.5-2.9-4.4V79.6c.1-1.6.9-3.1 2.2-4.1 1.4-.9 3.1-1.1 4.6-.4l59.3 25.2z'
              />
            </g>
          </g>
          <g id='Tagpas_logo-blaa_outlinet_svg__Group_184'>
            <defs>
              <path
                id='Tagpas_logo-blaa_outlinet_svg__SVGID_00000176746529417462557980000000263108372227686029_'
                d='M6.7 7.5H169v197H6.7z'
              />
            </defs>
            <clipPath id='Tagpas_logo-blaa_outlinet_svg__SVGID_00000019678241318122329530000002144096688638162105_'>
              <use
                xlinkHref='#Tagpas_logo-blaa_outlinet_svg__SVGID_00000176746529417462557980000000263108372227686029_'
                style={{
                  overflow: 'visible',
                }}
              />
            </clipPath>
            <g
              id='Tagpas_logo-blaa_outlinet_svg__Group_83'
              style={{
                clipPath:
                  'url(#Tagpas_logo-blaa_outlinet_svg__SVGID_00000019678241318122329530000002144096688638162105_)',
              }}
            >
              <path
                id='Tagpas_logo-blaa_outlinet_svg__Path_28'
                className='Tagpas_logo-blaa_outlinet_svg__st1'
                d='M158 79.6v93.6c0 1.9-1.1 3.7-2.9 4.5l-63.2 26.8v-104l59.4-25.2c1.5-.7 3.2-.5 4.6.4 1.2.9 2 2.4 2.1 3.9'
              />
            </g>
          </g>
          <g id='Tagpas_logo-blaa_outlinet_svg__Group_185'>
            <defs>
              <path
                id='Tagpas_logo-blaa_outlinet_svg__SVGID_00000152244760684207886980000005049808303033439898_'
                d='M6.7 7.5H169v197H6.7z'
              />
            </defs>
            <clipPath id='Tagpas_logo-blaa_outlinet_svg__SVGID_00000123405442891289424770000002986265546440742575_'>
              <use
                xlinkHref='#Tagpas_logo-blaa_outlinet_svg__SVGID_00000152244760684207886980000005049808303033439898_'
                style={{
                  overflow: 'visible',
                }}
              />
            </clipPath>
            <g
              id='Tagpas_logo-blaa_outlinet_svg__Group_84'
              style={{
                clipPath:
                  'url(#Tagpas_logo-blaa_outlinet_svg__SVGID_00000123405442891289424770000002986265546440742575_)',
              }}
            >
              <path
                id='Tagpas_logo-blaa_outlinet_svg__Path_29'
                className='Tagpas_logo-blaa_outlinet_svg__st1'
                d='M169.2 43.2v21.3c0 1.4-1 2.5-2.4 2.6-.4 0-.7-.1-1.1-.2L89.1 34.4c-.7-.2-1.3-.2-2 0L10.3 66.9c-1.3.5-2.7 0-3.3-1.3-.1-.3-.2-.7-.2-1V43.3c0-1 .6-1.9 1.5-2.3L86.5 7.8l.2-.1c.3-.2.7-.3 1-.2h.5c.4 0 .7.1 1.1.2l.2.1 78 33.1c1 .4 1.6 1.3 1.7 2.3'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgTagpaslogoBlaaOutlinet;
