import * as React from 'react';

const SvgNotifikationer2 = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Notifikationer2_svg__a'>
        <path data-name='Rectangle 1146' d='M0 0h19.61v24H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1256' fill='none' d='M0 0h24v24H0z' />
    <g
      data-name='Group 142'
      transform='translate(2.195)'
      clipPath='url(#Notifikationer2_svg__a)'
    >
      <path
        data-name='Path 236'
        d='M19.001 16.583a6.567 6.567 0 0 1-2.333-5.025V8.825a6.869 6.869 0 0 0-5.883-6.785V.98a.98.98 0 0 0-1.961 0v1.059a6.869 6.869 0 0 0-5.883 6.785v2.734A6.576 6.576 0 0 1 .6 16.591a1.715 1.715 0 0 0 1.115 3.019h16.179a1.716 1.716 0 0 0 1.107-3.027'
      />
      <path
        data-name='Path 237'
        d='M9.805 23.6a3.683 3.683 0 0 0 3.6-2.941h-7.2a3.682 3.682 0 0 0 3.6 2.941'
      />
    </g>
  </svg>
);

export default SvgNotifikationer2;
