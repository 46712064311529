import axios from 'axios';
import FileSaver from 'file-saver';
import { API_URL, GET_CASE_MATERIALS, GET_PRODUCTS } from '../constants';

/**
 * Gets single product regardless of deleted
 * @param {Number} id product id
 * @return {Promise} Promise
 */
export function getSingleProduct(id) {
  if (id === null) return null;

  let url = API_URL + `/products/single/${id}`;
  return dispatch => {
    return axios.get(url).then(res => {
      return res.data;
    });
  };
}

/**
 * Gets single category regardless of deleted
 * @param {Number} id category id
 * @return {Promise} Promise
 */
export function getSingleProductCategory(id) {
  if (id === null) return null;

  let url = API_URL + `/products/singleCategory/${id}`;
  return dispatch => {
    return axios.get(url).then(res => {
      return res.data;
    });
  };
}

/**
 * Gets product categories
 * @param {Number} type Standard = 1 | Custom = 2
 * @param {Boolean} datasheets include datasheets or not
 * @param {Boolean} excludeGlobal control if we want global product categories
 * @return {Promise} Promise
 */
export function getProductCategories(
  type,
  datasheets = false,
  excludeGlobal = false,
) {
  let url = API_URL + `/products/categories/${type}?`;

  if (datasheets) {
    url += '&includeData=true';
  }

  if (excludeGlobal) {
    url += '&excludeStandard=true';
  }

  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data,
      });

      return res.data;
    });
  };
}

/**
 * Gets product categories for a supplier
 * @param {Number} id Suppliers user id
 * @return {Promise} Promise
 */
export function getSuppliersProductCategories(id) {
  let url = API_URL + `/products/categories/supplier/${id}`;

  return dispatch => {
    return axios.get(url).then(res => {
      return res.data;
    });
  };
}

/**
 * Add/Update product category
 * @param {Object} data Product category object
 * @return {Promise} Promise
 */
export function updateProductCategories(data) {
  let url = API_URL + `/products/categories`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * Add/Update a suppliers binded product categories
 * @param {Object} data Product category objects and user-id
 * @return {Promise} Promise
 */
export function updateSuppliersProductCategories(data) {
  let url = API_URL + `/products/categories/supplier`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * Delete product category
 * @param {Object} data Product category object
 * @return {Promise} Promise
 */
export function deleteProductCategories(data) {
  let url = API_URL + `/products/categories/delete`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * Gets the customer's custom products
 * @param {Number} type Standard = 1 | Custom = 2
 * @return {Promise} Promise
 */
export function getProducts(type) {
  let url = API_URL + `/products/${type}`;
  return dispatch => {
    return axios.get(url).then(res => {
      return res.data;
    });
  };
}

/**
 * Update the customer's custom products
 * @param {Number} type Standard = 1 | Custom = 2
 * @return {Promise} Promise
 */
export function updateProduct(data, type) {
  let url = API_URL + `/products/${type}`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * Arrange the customer's custom products
 * @return {Promise} Promise
 */
export function orderCustomProduct(data) {
  let url = API_URL + '/products/customProducts/ordering';
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * Arrange the standard products
 * @return {Promise} Promise
 */
export function orderStandardProduct(data) {
  let url = API_URL + '/products/standardProducts/ordering';
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * Toggle standard products to use
 * @param {Number} product
 */
export function toggleStandardProducts(product) {
  let url = API_URL + `/products/standardProducts/toggle/`;
  return dispatch => {
    return axios.post(url, product).then(res => {
      return res.data;
    });
  };
}

/**
 * Upload datasheet to a product
 * @param {Array} files - Multi-Form data files
 * @param {Object} productId - Product Id
 */
export function uploadDatasheets(files, { productId }) {
  return dispatch => {
    let url = API_URL + `/file/upload/datasheets/${productId}`;

    let data = new window.FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.append(file.name, file);
    }

    return axios.post(url, data).then(res => {
      // console.log('uploadFilesResult', res);
      return res.data;
    });
  };
}

/**
 * Delete a specific datasheet
 * @param {Number} datasheetId - Datasheet id
 */
export function deleteDatasheet(datasheetId) {
  return dispatch => {
    let url = API_URL + `/file/delete/datasheets/${datasheetId}`;
    return axios.post(url);
  };
}

/**
 * Download a specific datasheet
 * @param {Number} productId - Product Id
 * @param {Number} datasheetId - Datasheet Id
 */
export function downloadDatasheet(productId, datasheetId) {
  return dispatch => {
    let url = API_URL + `/pdf/download/datasheet/${productId}/${datasheetId}`;

    return axios.get(url, { responseType: 'blob' }).then(res => {
      const regex = /filename=["]?(.*\.pdf)["]?;/g;
      const headerContentDisposition = res.headers['content-disposition'];
      const fileNameGroups = regex.exec(headerContentDisposition);

      FileSaver.saveAs(res.data, fileNameGroups[1]);
    });
  };
}

/**
 * Gets product categories in case
 * @param {Number} id case id
 * @return {Promise} Promise
 */
export function getProductCategoriesOnCase(id) {
  let url = API_URL + `/products/materials/${id}`;

  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: GET_CASE_MATERIALS,
        payload: res.data,
      });

      return res.data;
    });
  };
}

/**
 * Add/Update product category on case
 * @param {Object} data Product category object
 * @return {Promise} Promise
 */
export function updateProductCategoriesOnCase(data) {
  let url = API_URL + `/products/materials`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * Add/Update product in category on case
 * @param {Object} data Product category object
 * @return {Promise} Promise
 */
export function updateProductsInProductCategoriesOnCase(data) {
  let url = API_URL + `/products/materials/products`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * Get product units
 * @return {Promise} Promise
 */
export function getProductUnits() {
  let url = API_URL + `/products/units`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * Add/Update product product update
 * @param {Object} data Product unit object
 * @return {Promise} Promise
 */
export function updateProductUnit(data) {
  let url = API_URL + `/products/units`;
  return dispatch => {
    return axios.post(url, data);
  };
}
