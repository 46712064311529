import * as React from 'react';

const SvgIconKundegrupperVirksomheder = props => (
  <svg
    data-name='Icon - Virksomheder'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Kundegrupper_Virksomheder_svg__a'>
        <path data-name='Rectangle 1188' d='M0 0h21.925v20.951H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1187' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 181'>
      <g
        data-name='Group 180'
        clipPath='url(#Icon_-_Kundegrupper_Virksomheder_svg__a)'
        transform='translate(1.291 1.344)'
      >
        <path
          data-name='Path 245'
          d='M0 5.457h4.629V3.776H.5a.5.5 0 0 0-.5.5Zm17.175 12.79H5.1a.489.489 0 0 1-.475-.5V6.456H0v14a.5.5 0 0 0 .5.5h16.175a.494.494 0 0 0 .5-.5ZM5.688 1.681h5.4V.512a.481.481 0 0 0-.475-.5h-4.45a.489.489 0 0 0-.475.5Zm13.691 11.584a.5.5 0 0 1-.5.5h-1.8a.5.5 0 0 1-.5-.5v-2.132a.512.512 0 0 1 .5-.512h1.8a.5.5 0 0 1 .5.512Zm-4.446 0a.5.5 0 0 1-.512.5h-1.8a.5.5 0 0 1-.5-.5v-2.132a.512.512 0 0 1 .5-.512h1.8a.515.515 0 0 1 .512.512Zm6.992 3.411V5.238a.5.5 0 0 0-.244-.439.473.473 0 0 0-.475 0l-4.713 2.913V5.238a.5.5 0 0 0-.244-.439.473.473 0 0 0-.475 0l-4.7 2.911V2.692H5.688v14a.481.481 0 0 0 .475.5H21.45a.5.5 0 0 0 .475-.512'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconKundegrupperVirksomheder;
