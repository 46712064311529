import React from 'react';
import { Input, Label, FormGroup, Col } from 'reactstrap';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InputGroupText from 'reactstrap/lib/InputGroupText';
import InfoTip from '../InfoTip';

/**
 * Custom text-input with ability to have a label displayed to its left.
 * @param {*} props - set properies here
 * @param {Boolean} row
 * @param {Boolean} disabled
 * @param {Number} labelSize
 * @param {Number} inputSize
 * @param {String} for
 * @param {String} type
 * @param {String} name
 * @param {*} value
 * @param {Function} onChange
 * @param {Function} onBlur
 * @param {String} placeholder
 * @param {Boolean} error - Bind to a boolean value
 * @param {String} errorText - Displayed text when error is true
 * @param {String} placeholder - Placeholder tekst
 * @param {Boolean} inputOnly - Returns the input comp only
 */
const TextInput = props => {
  let lineRows = 3;
  if (props.value && typeof props.value === 'string') {
    lineRows = props.value.split('\n').length;

    if (lineRows < 3) lineRows = 3;
    if (lineRows > 12) lineRows = 12;
  }

  const prependUnitType = comp => {
    if (!props.unitType) return comp;

    return (
      <InputGroup>
        <InputGroupAddon addonType='prepend'>
          <InputGroupText>{props.unitType}</InputGroupText>
        </InputGroupAddon>
        {comp}
      </InputGroup>
    );
  };

  let inputProps = {
    type: props.type ? props.type : 'text',
    name: props.name,
    value: props.value ? props.value : '',
    onChange: props.onChange,
    onBlur: props.onBlur,
    placeholder: props.placeholder,
    disabled: props.disabled,
    rows: props.rows ? props.rows : lineRows,
    autoComplete: props.autoComplete ? props.autoComplete : 'new-password',
    pattern: props.pattern ? props.pattern : null,
    steps: props.steps ? props.steps : null,
    min: props.min ? props.min : null,
  };

  if (props.bsSize) {
    inputProps.bsSize = props.bsSize;
  }

  const infoTip = props.info && (
    <InfoTip
      id={'tt_' + props.name + '_' + (1 + Math.random() * 1000).toFixed()}
      title={props.info.title}
      text={props.info.text}
      html={props.info.html}
    />
  );

  let label = (
    <Label for={props.for}>
      {props.for}
      {props.subtitle}
      {infoTip}
      {props.labelError}
    </Label>
  );
  let inputComp = prependUnitType(<Input {...inputProps} />);

  if (props.inputOnly) return inputComp;

  const errorRender = props.error && (
    <p style={{ color: 'red' }} className='display-linebreak'>
      {props.error ? props.errorText : null}
    </p>
  );

  const infoRender = props.infoText && (
    <p style={{ fontSize: 11, marginTop: 10 }}>
      {props.infoText ? props.infoText : null}
    </p>
  );

  if (props.row) {
    label = (
      <Label for={props.for} sm={props.labelSize}>
        <div className='label-wrap'>
          {props.for}
          {infoTip}
        </div>
        {props.subtitle}
        {props.labelError}
      </Label>
    );

    inputProps.bsSize = 'md';
    inputComp = (
      <Col sm={props.inputSize}>
        {prependUnitType(<Input {...inputProps} />)}
        {props.extra}
        {infoRender}
        {errorRender}
      </Col>
    );
  }

  return (
    <FormGroup row={props.row} className={props.className}>
      {props.labelless ? null : label}
      {inputComp}
      {!props.row ? props.extra : null}
      {!props.row && infoRender}
      {!props.row && errorRender}
    </FormGroup>
  );
};

export default TextInput;
