import * as React from 'react';

const SvgIconVarmtArbejde = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <path data-name='Rectangle 1119' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 110'>
      <path data-name='Rectangle 1120' fill='none' d='M0 .333h24v23.333H0z' />
      <path
        data-name='Path 222'
        d='m20.597 12.919-.174-.335a15.72 15.72 0 0 0-.979-1.677 12.659 12.659 0 0 0-1.006-1.1 14.275 14.275 0 0 0-1.234-.993 12.344 12.344 0 0 1-1.449-1.207 6.684 6.684 0 0 1-1.113-7.5.063.063 0 0 0-.013-.08C14.616 0 14.575 0 14.549 0a8.793 8.793 0 0 0-3.287 1.757A10.7 10.7 0 0 0 8.15 13.643a.924.924 0 0 1 .094.4.694.694 0 0 1-.416.6.7.7 0 0 1-.778-.148 1.022 1.022 0 0 1-.188-.215 6.7 6.7 0 0 1-.7-6.708.089.089 0 0 0-.027-.094.065.065 0 0 0-.094 0 9.569 9.569 0 0 0-3.193 7.781 8.647 8.647 0 0 0 .349 2 8.311 8.311 0 0 0 .966 2.321 9.022 9.022 0 0 0 6.466 4.306 13.768 13.768 0 0 0 1.64.114 9.2 9.2 0 0 0 6.319-2.24 7.978 7.978 0 0 0 2.009-8.841ZM12.884 16.3a4.679 4.679 0 0 0-.161-2.12c-.067-.282-.134-.563-.188-.872a4.7 4.7 0 0 1 .174-2.508 7.741 7.741 0 0 0 .724 1.248 8.668 8.668 0 0 0 1.207 1.221 4.3 4.3 0 0 1 1.7 2.468 1.875 1.875 0 0 1 .067.55 3.934 3.934 0 0 1-1.181 2.938 4.074 4.074 0 0 1-1.409.778 3.615 3.615 0 0 1-3.622-.979 3.712 3.712 0 0 0 2.689-2.724Z'
      />
    </g>
  </svg>
);

export default SvgIconVarmtArbejde;
