import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Container } from 'reactstrap';
import i18next from '../../i18n';
import { isMobile } from 'react-device-detect';

// Utils

// Componenents
import Typography from '../../components/Typography';

const Signature = props => {
  const { hasSigned, signData, userSignedInApproval } = props;
  const [hasError, setHasError] = useState(null);

  let sigPad = useRef(null);

  useEffect(() => {
    if (hasSigned) {
      sigPad.fromDataURL(signData);
      sigPad.off();
    }
    // eslint-disable-next-line
  }, []);

  const getMobileClass = () => {
    return isMobile ? 'mobile' : '';
  };

  const clear = () => {
    sigPad.clear();
  };

  const goBackToCase = () => {
    props.goBackToCase();
  };

  const handleSignApprovals = () => {
    // const { sigData } = this.state;
    if (!sigPad.isEmpty()) {
      setHasError(false);

      const data = sigPad.toDataURL('image/png');
      props.handleSignApprovals(data);
    } else {
      setHasError(true);
    }
  };

  const renderButtons = () => {
    let backButtonClass = 'float-right';

    if (userSignedInApproval && !hasSigned) {
      backButtonClass += ' margin-right-10px';
    }

    return (
      <div>
        {!hasSigned && (
          <Button
            color='btnSecondary'
            outline
            onClick={clear}
            disabled={hasSigned}
            className='float-right'
          >
            {i18next.t(1092)}
          </Button>
        )}
        {userSignedInApproval && (
          <Button
            color='secondary'
            outline
            onClick={goBackToCase}
            className={backButtonClass}
          >
            {i18next.t(1089)}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Container className={getMobileClass()}>
      <div>
        {renderButtons()}
        <div>
          <Typography type='h2' className='left-align'>
            {i18next.t(1088)}
          </Typography>
          <SignatureCanvas
            canvasProps={props.cvsProps}
            ref={ref => (sigPad = ref)}
            clearOnResize={false}
          />
        </div>
      </div>
      <hr />
      <div>
        {hasError && <p style={{ color: 'red' }}>{i18next.t(1339)}</p>}
        {!hasSigned && (
          <Button
            color='btnPrimary'
            block
            onClick={handleSignApprovals}
            disabled={hasSigned}
          >
            {i18next.t(1093)}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Signature;
