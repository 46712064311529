import * as React from 'react';

const SvgIconSysteminfo = props => (
  <svg
    data-name='Icon - Systeminfo'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Systeminfo_svg__a'>
        <path data-name='Rectangle 1243' d='M0 0h20.643v20.643H0z' />
      </clipPath>
    </defs>
    <g data-name='Group 241'>
      <g
        data-name='Group 240'
        clipPath='url(#Icon_-_Systeminfo_svg__a)'
        transform='translate(1.664 2.165)'
      >
        <path
          data-name='Path 284'
          d='M12.275 11.557a.51.51 0 0 1-.511.511H8.727a.511.511 0 0 1 0-1.022h.511V7.995h-.511a.511.511 0 1 1 0-1.022h2.03a.51.51 0 0 1 .511.511v3.549h.5a.522.522 0 0 1 .511.525m-1.022-6.6a1.008 1.008 0 1 1-2.016 0 1 1 0 0 1 1.008-1.008.988.988 0 0 1 1.008 1.008m9.389 9.5V2.071A2.071 2.071 0 0 0 18.585.014H2.071A2.054 2.054 0 0 0 .014 2.071L0 20.643l4.129-4.129h14.442a2.065 2.065 0 0 0 2.071-2.057'
        />
      </g>
    </g>
    <path data-name='Rectangle 1244' fill='none' d='M0 0h24v24H0z' />
  </svg>
);

export default SvgIconSysteminfo;
