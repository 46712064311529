import * as React from 'react';

const SvgIconTermsConditions = props => (
  <svg
    data-name='Icon - Terms &amp; Conditions'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      data-name='Subtraction 1'
      d='M17 24H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h15a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2Zm-5.481-3.947-.519.5 1.986 1.981 4.244-4.245-.517-.5-3.727 3.732-1.467-1.469ZM3.5 15a.5.5 0 0 0 0 1h12a.5.5 0 1 0 0-1Zm0-3a.5.5 0 0 0-.5.5.506.506 0 0 0 .5.5h12a.5.5 0 1 0 0-1Zm0-3a.506.506 0 0 0-.5.5.5.5 0 0 0 .5.5h12a.5.5 0 1 0 0-1Zm0-3a.5.5 0 0 0 0 1h10a.5.5 0 1 0 0-1Z'
    />
  </svg>
);

export default SvgIconTermsConditions;
