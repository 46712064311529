import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import i18next from '../i18n';

// Actions
import { resetpassword, loginReset, logout } from '../redux/actions/auth';
import { getSystemMessage } from '../redux/actions/systemInfo';
import { getFeatures } from '../redux/actions/features';
import { getCustomer } from '../redux/actions/customers';
import { GetBranding } from '../redux/actions/common';

// Utils
import { CUSTOMER_STATUS } from '../redux/constants';

// Components
import ResetPasswordForm from '../components/ResetPasswordForm';

const ResetPassword = props => {
  const {
    auth,
    history,
    resetpassword,
    loginReset,
    getSystemMessage,
    logout,
    getFeatures,
    GetBranding,
  } = props;
  const [failedReset, setFailedReset] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      history.push('/');
    }
    const query = new URLSearchParams(props.location.search);
    const tmpToken = query.get('token');
    setToken(tmpToken);
    // eslint-disable-next-line
  }, []);

  const handleLogoClick = () => {
    window.location.href = 'https://www.nordicwaterproofing.com/';
  };

  const navigateToLogin = () => {
    history.push('/login');
  };

  const handleResetPassword = password => {
    resetpassword({
      password: password,
      resetcode: token,
    })
      .then(res1 => {
        loginReset(res1).then(res => {
          if (res.data.customerStatus === CUSTOMER_STATUS.Closed) {
            logout();
            navigateToLogin();
          } else {
            getSystemMessage();
            getFeatures();
            if (res.data.accessLevel > 1 && res.data.user) {
              const { user } = res.data;

              GetBranding(user.belongsToCustomerId)
                .then(res => {
                  history.push('/');
                })
                .catch(err => {
                  console.log('Failed to get branding.', err);
                  history.push('/');
                });
            } else {
              history.push('/');
            }
          }
        });
      })
      .catch(err => {
        setFailedReset(true);
        setErrorText(err.response.data);
      });
  };

  const handleError = errorText => {
    if (errorText) {
      if (Array.isArray(errorText)) {
        return errorText.map((err, index) => {
          let txt = i18next.t(err.dictionaryKey);
          if (err.descriptor) {
            txt += ` - ${err.descriptor}`;
          }

          return (
            <p key={`err-${index}`} style={{ color: 'red' }}>
              {txt}
            </p>
          );
        });
      }
    }

    return null;
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={6} lg={4}>
        <Card body>
          <ResetPasswordForm
            onLogoClick={handleLogoClick}
            resetFailed={failedReset}
            errorText={handleError(errorText)}
            resetpassword={handleResetPassword}
          />
        </Card>
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  resetpassword,
  GetBranding,
  loginReset,
  logout,
  getSystemMessage,
  getFeatures,
  getCustomer,
})(ResetPassword);
