import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Actions

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import { Col, FormGroup, Label } from 'reactstrap';
import InfoTip from '../../Custom/InfoTip';
import ReactSelect from '../../Custom/ReactSelect';
import LastEditor from '../../Custom/LastEditor';
import ControlItemMetaAdminModal from '../ControlItemMetaAdminModal';

const ControlItemDropdownCustom = props => {
  const {
    control,
    disabled,
    areControlsValid,
    hideAdmin,
    refreshGroups,
  } = props;
  const {
    title,
    alternativeTitle,
    dropdownOptions,
    dropdownValue,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    placeholder,
    multipleChoice,
    errorMessage,
  } = control;
  const [valid, setValid] = useState(true);
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [dropdownValue]);

  const onChange = (name, value) => {
    props.onChange({
      target: {
        name,
        value,
      },
    });
  };

  const onChangeSelection = (name, value) => {
    let val = !!value ? value : [];
    if (!multipleChoice) val = [val];

    val = val.reduce((prev, cur) => prev + cur.value + ',', '');

    onChange(name, val);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  const sharedProps = {
    placeholder: placeholder,
    closeMenuOnSelect: !multipleChoice,
    isDisabled: disabled,
    isMulti: multipleChoice,
  };

  const renderDropdown = () => {
    let options = [];
    let value;

    if (dropdownOptions?.length > 0) {
      dropdownOptions.sort((a, b) => a.index - b.index);

      options = dropdownOptions.map(x => {
        return {
          label: x.label,
          value: x.value,
        };
      });
    }

    if (dropdownValue?.length > 0) {
      const splitted = dropdownValue
        .split(',')
        .filter(x => !!x || x?.length > 0);

      if (multipleChoice) {
        value = options.filter(x => splitted.includes(x.value + ''));
      } else {
        value = options.find(x => splitted.includes(x.value + ''));
      }
    }

    return (
      <ReactSelect
        name='dropdownValue'
        options={options}
        value={value}
        onChange={v => onChangeSelection('dropdownValue', v)}
        {...sharedProps}
      />
    );
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <FormGroup row>
      <Label sm={labelSize}>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor {...control} />
      </Label>
      <Col sm={inputSize}>
        {renderDropdown()}
        {!hideAdmin && !disabled && (
          <ControlItemMetaAdminModal
            control={control}
            callback={refreshGroups}
          />
        )}
      </Col>
    </FormGroup>
  );
};

function mapStateToProps({ auth, schemas, cases }) {
  return {
    auth,
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {})(ControlItemDropdownCustom);
