import React, { useState } from 'react';
import i18next from '../i18n';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';

// Utils
import { StringIsEmpty } from '../utils/common';

// Components
import TextInput from '../components/Custom/Forms/TextInput';
import TaghaandbogenLogo from './../assets/img/logo/method-draw-image.svg';

const ForgotPasswordForm = props => {
  const { showLogo, children } = props;

  const [username, setUsername] = useState('');
  const [missingUsername, setMissingUsername] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();

    let emptyUsername = StringIsEmpty(username);
    if (!emptyUsername)
      emptyUsername = StringIsEmpty(username.replace(' ', ''));

    if (emptyUsername) {
      setUsername('');
      setMissingUsername(emptyUsername);
    } else {
      props.forgotpassword(username);
    }
  };

  const onChange = e => {
    setUsername(e.target.value);
    setMissingUsername(false);
  };

  const renderContent = () => {
    const { resetFailed, resetSent, errorText } = props;

    return (
      <div>
        {!resetSent ? (
          <TextInput
            for={i18next.t(25)}
            placeholder={i18next.t(8306)}
            name='username'
            onChange={onChange}
            error={missingUsername}
            errorText={i18next.t(80)}
            value={username}
          />
        ) : (
          <p>{i18next.t(3254)}</p>
        )}

        <hr />
        {resetFailed ? <p style={{ color: 'red' }}>{errorText}</p> : null}

        {!resetSent ? (
          <Button size='lg' block color='primary' type='submit'>
            {i18next.t(3248)}
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {showLogo && (
          <div className='text-left pb-4'>
            <img
              src={TaghaandbogenLogo}
              className='authform_logo'
              alt='Tagpas'
            />
          </div>
        )}
        {renderContent()}
        {children}
      </Form>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
};

ForgotPasswordForm.defaultProps = {
  showLogo: true,
};

export default ForgotPasswordForm;
