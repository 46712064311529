import React from 'react';
import { Input, Label, FormGroup, Col } from 'reactstrap';

/**
 * Custom checkbox with ability to have a label displayed to its left.
 * @param {*} props - set properies here
 * @param {Boolean} disabled
 * @param {String} for
 * @param {String} name
 * @param {Boolean} checked
 * @param {Function} onChange
 * @param {Boolean} error - Bind to a boolean value
 * @param {String} errorText - Displayed text when error is true
 */
const CustomCheckbox = props => {
  let textContent;
  const text = props.text ? props.text : props.for;
  const clickable = props.clickable ? 'can-click' : '';

  if (typeof text === 'object') {
    textContent = text;
  } else {
    if (props.bold) {
      textContent = (
        <p style={{ fontWeight: 'bold', paddingTop: '5px' }}>{text}</p>
      );
    } else {
      textContent = <p style={{ paddingTop: '5px' }}>{text}</p>;
    }
  }

  let classNames = '';
  if (props.className) {
    classNames = props.className;
  }

  return (
    <FormGroup check className={classNames}>
      <Label for={props.for} check>
        <Input
          type='checkbox'
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        {!props.labelless && <Col className={clickable}>{textContent}</Col>}
      </Label>
      {props.error ? (
        <p style={{ color: 'red' }}>{props.error ? props.errorText : null}</p>
      ) : null}
    </FormGroup>
  );
};

export default CustomCheckbox;
