import * as React from 'react';

const SvgIconForsikringsselskaber = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Forsikringsselskaber_svg__a'>
        <path data-name='Rectangle 1144' d='M0 0h21.428v24H0z' />
      </clipPath>
    </defs>
    <g
      data-name='Group 140'
      clipPath='url(#Icon_-_Forsikringsselskaber_svg__a)'
    >
      <path
        data-name='Path 235'
        d='M13.897 8.83h-3.428v1.843h3.114v1.486h-3.114v3.191H8.538V7.339h5.359Zm7.24-5.471a.785.785 0 0 0-.567-.647L10.924.029a.788.788 0 0 0-.42 0L.855 2.712a.784.784 0 0 0-.567.647c-.056.4-1.332 9.907 1.941 14.636a15.4 15.4 0 0 0 8.3 5.983.78.78 0 0 0 .368 0 15.4 15.4 0 0 0 8.299-5.979c3.274-4.728 2-14.234 1.941-14.636'
      />
    </g>
  </svg>
);

export default SvgIconForsikringsselskaber;
