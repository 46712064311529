export const isDevEnvironment = () => {
  const env = process.env.REACT_APP_IS_DEV_ENV;

  if (env === 'true') {
    console.log('---DEV ENVIRONMENT---');
    console.log('API:', process.env.REACT_APP_API_URL);

    return true;
  }

  console.log('PROD');
  return false;
};
