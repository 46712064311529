import React from 'react';
import i18next from '../../i18n';
import { Button, Modal, Card, CardBody, CardHeader } from 'reactstrap';
import PageSpinner from '../PageSpinner';

const ConfirmModal = props => {
  const {
    color,
    cancelColor,
    showActionSpinner,
    confirmText,
    cancelText,
  } = props;

  const renderActionSpinner = () => {
    if (showActionSpinner) {
      return <PageSpinner color='primary' extraClass='margin-bot-15px' />;
    }
  };

  let toggleAction = !showActionSpinner ? props.toggle : null;

  let btnConfirmText = confirmText ? confirmText : i18next.t(1);
  let btnCancelText = cancelText ? cancelText : i18next.t(2);

  return (
    <Modal isOpen={props.show} toggle={toggleAction} centered backdrop='static'>
      <Card>
        {props.header && <CardHeader>{props.header}</CardHeader>}
        <CardBody>
          {renderActionSpinner()}
          {props.children}
          <Button
            block
            color={color}
            onClick={props.confirmFunc}
            disabled={showActionSpinner}
          >
            {btnConfirmText}
          </Button>
          <Button
            outline
            block
            color={cancelColor}
            onClick={props.cancelFunc}
            disabled={showActionSpinner}
          >
            {btnCancelText}
          </Button>
        </CardBody>
      </Card>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  color: 'danger',
  cancelColor: 'btnSecondary',
};

export default ConfirmModal;
