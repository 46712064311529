import {
  SET_CURRENT_USER,
  SET_USER_PERMISSIONS,
  SET_LOGIN_AS,
  LOGOUT_AS,
  CUSTOMER_STATUS,
  SET_CUSTOMER_STATUS,
  SET_PAUSED_MODAL,
  HANDLED_PAUSED_MODAL,
  SET_PERMISSION_SET,
} from '../constants';

const initialState = {
  isAuthenticated: false,
  user: {},
  permissions: {},
  loginAsUser: null,
  customerStatus: CUSTOMER_STATUS.Active,
  showPausedModal: false,
  handledPausedModal: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated:
          (!(Object.keys(action.user).length === 0) &&
            action.user.verification === 0) ||
          (action.user.verification !== 0 && action.user.token),
        user: action.user,
      };
    case SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case SET_LOGIN_AS:
      return {
        ...state,
        loginAsUser: action.user,
      };
    case LOGOUT_AS:
      return {
        ...state,
        loginAsUser: null,
      };
    case SET_CUSTOMER_STATUS:
      return {
        ...state,
        customerStatus: action.payload,
      };
    case SET_PAUSED_MODAL:
      return {
        ...state,
        showPausedModal: action.payload,
      };
    case HANDLED_PAUSED_MODAL:
      return {
        ...state,
        handledPausedModal: action.payload,
      };
    case SET_PERMISSION_SET:
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            permission: {
              ...state.user.user.permission,
              ...action.payload,
            },
          },
        },
      };
    default:
      return state;
  }
};
