import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Label,
} from 'reactstrap';

// Actions
import {
  createFolder,
  updateFolder,
  getFolder,
  updateFile,
} from '../../redux/actions/documents';
import { getCustomerGroups } from '../../redux/actions/customerGroups';
import { getCustomers } from '../../redux/actions/customers';
import { getEmployees } from '../../redux/actions/employees';
import { getTeams } from '../../redux/actions/team';

// Utils
import { getUserData } from '../../utils/userUtil';
import { hasMinAccessLevel } from '../../utils/contentByAccessLevel';
import Toast from '../../utils/toast';
import { isValidFolderName } from '../../utils/common';
import { industryTypes } from '../../utils/industryType';

// Components
import TextInput from './Forms/TextInput';
import Checkbox from './Forms/Checkbox';
import PageSpinner from '../PageSpinner';
import ErrorMsg from '../ErrorMsg';
import ReactSelect from './ReactSelect';

// Constants
import {
  ACCESS_LEVEL,
  DOCUMENT_FOLDER_TYPE,
  EMPLOYEE_TYPE,
  //INDUSTRY_TYPE,
} from '../../redux/constants';

const DocumentCreateModal = props => {
  const { isUpdate, modalData, currentFolderParentId, documents } = props;

  const user = getUserData(props.auth);

  const stateInfo = {
    canEdit: props.type === 1 ? hasMinAccessLevel(props.auth, 1) : user.hasAP,
    currentFolderParentId: modalData
      ? modalData.parentId
      : currentFolderParentId,
    previousFolderParentId: currentFolderParentId,
    customerId: user.customerId,
    documents: documents ? documents : [],
    isLoading: true,
    isSubmitting: false,
    isUpdate: isUpdate,
    currentParentFolder: null,
    isSuper: hasMinAccessLevel(props.auth, 1),
    hasAP: user.hasAP,
    isCompanyOwner: user.employeeType === EMPLOYEE_TYPE.CompanyOwner,
  };
  const [state, setState] = useState(stateInfo);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorText, setErrorText] = useState('');

  const documentInfo = {
    isActive: modalData ? modalData.isActive : false,
    name: modalData ? modalData.name : null,
    path: modalData ? modalData.path : null,
    isFile: modalData ? modalData.isFile : null,
    isFolder: modalData ? modalData.isFolder : true,
    companyOwnerFolder: modalData ? modalData.companyOwnerFolder : false,
    industry: modalData ? modalData.industry : null,
  };

  const [document, setDocument] = useState(documentInfo);

  const permissionInfo = {
    employeeOptions: [],
    employeeTeamOptions: [],
    customerOptions: [],
    customerGroupsOptions: [],

    permissions_customer_types: modalData ? modalData.customerTypeList : [],
    permissions_customer_groups: modalData
      ? modalData.customerGroupAccessList
      : [],
    permissions_customer_list: modalData ? modalData.customerAccessList : [],
    permissions_list_all: modalData ? modalData.allLocalUsers : false,
    permissions_list: modalData ? modalData.userAccessList : [],
    permissions_teams: modalData ? modalData.teamAccessList : [],
  };
  const [permissions, setPermission] = useState(permissionInfo);

  useEffect(() => {
    const { isSuper, customerId, currentFolderParentId } = state;
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }

    let parentFolder = {};
    if (currentFolderParentId === 0) {
      parentFolder = {
        id: 0,
        name: 'Root',
        type: 1,
        last_edited: 'Aldrig',
        isFile: false,
        isFolder: true,
        parentId: 0,
        path: '/0',
      };
      setCrumbs(parentFolder);
    } else {
      props
        .getFolder(tempCustomerId, currentFolderParentId)
        .then(res => {
          //console.log(res.data);
          setCrumbs(res.data);
        })
        .catch(err => {
          console.log(err);
          //this.setState({ isLoading: false });
        });
    }

    if (!isSuper) {
      let perms = permissions;
      // Get employees for UAC
      props.getEmployees(tempCustomerId).then(res => {
        //console.log(res.data);
        perms.employeeOptions = res.data;
        // .filter(x =>
        //   [
        //     EMPLOYEE_TYPE.ExecutivePerson,
        //     EMPLOYEE_TYPE.InternalSubcontractor,
        //     EMPLOYEE_TYPE.CompanyOwner,
        //   ].includes(x.employeeType),
        // )
        // .map(item => {
        //   return { label: item.name, value: item.id };
        // });
        setPermission({
          ...perms,
        });
      });
      // Get teams for UAC
      props.getTeams().then(res => {
        perms.employeeTeamOptions = res.data.map(item => {
          return { label: item.name, value: item.id };
        });
        setPermission({
          ...perms,
        });
        setState({ ...state, isLoading: false });
      });
    }
    if (isSuper) {
      let perms = permissions;
      // Get employees for UAC

      props.getEmployees(0).then(res => {
        //console.log(res.data);
        perms.employeeOptions = res.data;
        // .filter(x =>
        //   [
        //     EMPLOYEE_TYPE.ExecutivePerson,
        //     EMPLOYEE_TYPE.InternalSubcontractor,
        //     EMPLOYEE_TYPE.CompanyOwner,
        //   ].includes(x.employeeType),
        // )
        // .map(item => {
        //   return { label: item.name, value: item.id };
        // });
        setPermission({
          ...perms,
        });
      });
      // Get customerGroups
      props.getCustomerGroups().then(res => {
        perms.customerGroupsOptions = res.map(item => {
          return { label: item.name, value: item.id };
        });
        setPermission({
          ...perms,
        });
      });
      // Get customers for UAC
      props.getCustomers(0).then(res => {
        perms.customerOptions = res.data.map(item => {
          return { label: item.name, value: item.id };
        });
        setPermission({
          ...perms,
        });
        setState({ ...state, isLoading: false });
      });
    }
    // useEffect as componentDidMount (empty dependency array) causes warning if dependencies arent referenced, use this to skip warning.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCrumbs = currentFolder => {
    let crumbs = '/';
    const path = currentFolder.path.split('/');
    let count = 0;
    path.forEach(element => {
      if (count > 2) {
        let crumb = element + '/';
        crumbs = crumbs.concat(crumb);
      }
      count++;
    });
    setDocument({
      ...document,
      path: crumbs,
    });
    // this.setState({
    //   path: crumbs,
    //   //isLoading: false,
    // });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { currentFolderParentId, customerId } = state;
    const {
      name,
      isActive,
      isFile,
      isFolder,
      companyOwnerFolder,
      industry,
    } = document;

    const {
      permissions_customer_types,
      permissions_customer_groups,
      permissions_customer_list,
      permissions_list_all,
      permissions_list,
      permissions_teams,
    } = permissions;

    let pathNormal = '/' + customerId + '/' + name;

    if (props.currentFolder != null) {
      pathNormal = props.currentFolder.path + '/' + name;
    }

    if (currentFolderParentId === 0) {
      pathNormal = '/' + customerId + '/' + name;
      if (customerId <= 0) {
        pathNormal = '/0/' + name;
      }
    }

    let type = DOCUMENT_FOLDER_TYPE.Standard;
    let tempCustomerId = customerId;
    if (customerId <= 0) {
      tempCustomerId = 0;
      type = DOCUMENT_FOLDER_TYPE.Global;
    }

    let dataObj = {
      name: name,
      ParentId: currentFolderParentId,
      Type: type,
      CustomerId: tempCustomerId,
      Path: pathNormal,
      isActive: isActive,
      // Access control
      companyOwnerFolder,
      allLocalUsers: permissions_list_all,
      customerTypeList: permissions_customer_types,
      customerGroupAccessList: permissions_customer_groups,
      CustomerAccessList: permissions_customer_list,
      userAccessList: permissions_list,
      teamAccessList: permissions_teams,
    };

    if (hasMinAccessLevel(props.auth, ACCESS_LEVEL.SuperAdmin)) {
      dataObj.industry = industry;
    }

    //console.log(dataObj);
    if (isUpdate && !checkForBadData()) {
      dataObj.id = modalData.id;
      setState({ ...state, isSubmitting: true });
      // this.setState({ isSubmitting: true }, () => {
      const action = isFolder
        ? props.updateFolder
        : isFile
        ? props.updateFile
        : new Promise((r, e) => e('Is neither folder or file!'));

      let toast = isFolder
        ? Toast.success(i18next.t(21032))
        : Toast.success(i18next.t(21035));

      action(dataObj)
        .then(() => {
          props.handleRefresh();
          toast();
          setState({ ...state, isSubmitting: false });
        })
        .catch(err => {
          console.log(err);
        });
      // });
    } else {
      if (!checkForBadData()) {
        setState({ ...state, isSubmitting: true });
        //this.setState({ isSubmitting: true }, () => {
        props.createFolder(dataObj).then(() => {
          props.handleRefresh();
          props.toggle();
        });
        //});
        setState({ ...state, isSubmitting: false });
        //this.setState({ isSubmitting: false });
      } else {
        //Error pop up
        //this.setState({ isSubmitting: false });
        setState({ ...state, isSubmitting: false });
      }
    }
  };

  const checkForBadData = () => {
    const { documents, currentFolderParentId, customerId } = state;
    const { name } = document;

    let missingName = false;
    let invalidInput = false;
    let nameExists = false;
    let pathToolong = false;
    let hasErrors = false;

    let errorText = '';

    if (!name) {
      missingName = true;
      errorText = i18next.t(7109);
    }

    // Validate name
    if (!isValidFolderName(name)) {
      invalidInput = true;
      errorText = i18next.t(7163);
    }

    // Check if folder name exists
    const checkDocumentsForName = documents.filter(c => c.name === name);
    if (checkDocumentsForName.length > 0) {
      // console.log('name exists', checkDocumentsForName.length > 0);
      nameExists = true;
      errorText = i18next.t(7110);
    }

    // folder path too long
    let pathNormal = '/' + customerId + '/' + name;

    if (props.currentFolder != null) {
      pathNormal = props.currentFolder.path + '/' + name;
    }

    if (currentFolderParentId === 0) {
      pathNormal = '/' + customerId + '/' + name;
      if (customerId <= 0) {
        pathNormal = '/0/' + name;
      }
    }

    if (pathNormal.length > 180) {
      pathToolong = true;
      errorText = i18next.t(7111);
    }

    if (pathToolong || missingName || invalidInput || nameExists) {
      hasErrors = true;
    }

    setHasErrors(hasErrors);
    setErrorText(errorText);
    return hasErrors;
  };

  const onChange = e => {
    setDocument({ ...document, [e.target.name]: e.target.value });
    //this.setState({ [e.target.name]: e.target.value });
  };

  const onChangeCheckedAll = e => {
    setPermission({
      ...permissions,
      permissions_list_all: e.target.checked,
      permissions_list: [],
      permissions_teams: [],
    });
    // this.setState({
    //   permissions_list_all: e.target.checked,
    //   permissions_list: [],
    //   permissions_teams: [],
    // });
  };

  const renderPermissions = () => {
    const { isSuper, hasAP, isSubmitting } = state;
    const { companyOwnerFolder, industry } = document;
    const {
      employeeOptions,
      permissions_list_all,
      permissions_list,
      employeeTeamOptions,
      permissions_teams,
    } = permissions;
    if (!isSuper && !hasAP) return null;

    //console.log({ companyOwnerFolder, employeeOptions });
    const industryOptions = industryTypes().filter(x => x.value > 0);
    const industrySplit = !!industry ? industry.split(',') : [];
    let selectedIndusties = [];
    industrySplit.forEach(x => {
      const found = industryOptions.find(y => '' + y.value === x);
      if (!!found) selectedIndusties.push(found);
    });

    let emps = [];
    if (isSuper) {
      emps = employeeOptions;
    } else {
      emps = companyOwnerFolder
        ? employeeOptions
        : employeeOptions.filter(x => !x.hasAP);
    }

    if (permissions_list?.length > 0) {
      let tmpExtras = employeeOptions.filter(x =>
        permissions_list.includes(x.id),
      );
      const simplified = emps.map(x => x.id);
      tmpExtras = tmpExtras.filter(x => !simplified.includes(x.id));

      emps.push(...tmpExtras);
    }

    let options = emps
      .filter(x => {
        if (
          ([
            EMPLOYEE_TYPE.ExecutivePerson,
            EMPLOYEE_TYPE.InternalSubcontractor,
            EMPLOYEE_TYPE.CompanyOwner,
            0,
          ].includes(x.employeeType) &&
            x.hasDoc) ||
          permissions_list.find(y => y === x.id)
        ) {
          return true;
        } else {
          return false;
        }
      })
      .map(item => {
        return { label: item.name, value: item.id };
      });

    let selectedUsers = [];
    if (permissions_list && emps) {
      selectedUsers = permissions_list.map(item => {
        return options.find(i => i.value === item);
      });
    }

    let selectedTeams = [];
    if (permissions_teams && employeeTeamOptions) {
      selectedTeams = permissions_teams.map(item => {
        return employeeTeamOptions.find(i => i.value === item);
      });
    }

    const onChangePermissions = (val, name) => {
      let value = val;
      if (!value) {
        value = [];
      }

      setPermission({
        ...permissions,
        [name]: value.map(i => i.value),
        permissions_list_all: false,
      });
      // this.setState({
      //   [name]: value.map(i => i.value),
      //   permissions_list_all: false,
      // });
    };

    const onChangeIndustry = value => {
      let val = !!value ? value : [];
      val = val.reduce((prev, cur) => prev + cur.value + ',', '');

      let updatedDoc = {
        ...document,
        industry: val,
      };

      setDocument(updatedDoc);
    };

    return (
      <React.Fragment>
        {hasMinAccessLevel(props.auth, ACCESS_LEVEL.SuperAdmin) && (
          <>
            <Label>{i18next.t(21235)}</Label>

            <div className='create-folder-permissions'>
              <ReactSelect
                for={i18next.t(21238)}
                name='industry'
                value={selectedIndusties}
                options={industryOptions}
                onChange={onChangeIndustry}
                closeMenuOnSelect={false}
                isMulti
                isClearable
              />
            </div>
          </>
        )}

        <Label>{i18next.t(8178)}</Label>
        {!companyOwnerFolder && (
          <div className='text_cap text-muted'>
            <small>{i18next.t(7167)}</small>
          </div>
        )}

        <div className='create-folder-permissions'>
          <ReactSelect
            for={i18next.t(8178)}
            name='permissions_list'
            value={selectedUsers}
            options={options}
            onChange={e => onChangePermissions(e, 'permissions_list')}
            closeMenuOnSelect={false}
            isDisabled={
              isSubmitting ||
              (user.employeeType !== EMPLOYEE_TYPE.CompanyOwner &&
                companyOwnerFolder)
            }
            isMulti
          />

          {!companyOwnerFolder && (
            <Checkbox
              for={i18next.t(7168)}
              name='permissions_list_all'
              onChange={onChangeCheckedAll}
              checked={permissions_list_all}
              disabled={isSubmitting}
            />
          )}
        </div>

        {state.customerId > 0 && !companyOwnerFolder && (
          <React.Fragment>
            <Label>{i18next.t(8179)}</Label>
            <div className='create-folder-permissions'>
              <ReactSelect
                for={i18next.t(8179)}
                name='permissions_teams'
                value={selectedTeams}
                options={employeeTeamOptions}
                onChange={e => onChangePermissions(e, 'permissions_teams')}
                closeMenuOnSelect={false}
                isDisabled={isSubmitting}
                isMulti
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderCustomerTypesAccess = () => {
    const { isSubmitting } = state;
    const { permissions_customer_types } = permissions;

    let customerTypeOptions = [
      {
        value: 1,
        label: i18next.t(7),
      },
      {
        value: 2,
        label: i18next.t(31),
      },
      {
        value: 3,
        label: i18next.t(128),
      },
    ];

    let selectedCustomerTypes = [];
    if (permissions_customer_types) {
      selectedCustomerTypes = permissions_customer_types.map(item => {
        return customerTypeOptions.find(i => i.value === item);
      });
    }

    const onChangeCustomerTypes = val => {
      let value = val;
      if (!value) {
        value = [];
      }

      setPermission({
        ...permissions,
        permissions_customer_types: value.map(i => i.value),
      });
      // this.setState({
      //   permissions_customer_types: value.map(i => i.value),
      // });
    };

    return (
      <React.Fragment>
        <Label>{i18next.t(45)}</Label>
        <div className='create-folder-permissions'>
          <ReactSelect
            for={i18next.t(45)}
            name='permissions_customer_types'
            value={selectedCustomerTypes}
            options={customerTypeOptions}
            onChange={onChangeCustomerTypes}
            closeMenuOnSelect={false}
            isDisabled={isSubmitting}
            isMulti
          />
        </div>
      </React.Fragment>
    );
  };

  const renderCustomerGroupAccess = () => {
    const { isSubmitting } = state;
    const { permissions_customer_groups, customerGroupsOptions } = permissions;

    let selectedCustomerGroups = [];
    if (permissions_customer_groups && customerGroupsOptions) {
      selectedCustomerGroups = permissions_customer_groups.map(item => {
        return customerGroupsOptions.find(i => i.value === item);
      });
    }

    const onChangeCustomerGroups = (val, context) => {
      let value = val;
      if (!value) {
        value = [];
      }

      setPermission({
        ...permissions,
        permissions_customer_groups: value.map(i => i.value),
      });
      // this.setState({
      //   permissions_customer_groups: value.map(i => i.value),
      // });
    };

    return (
      <React.Fragment>
        <Label>{i18next.t('8117')}</Label>
        <div className='create-folder-permissions'>
          <ReactSelect
            isMulti
            value={selectedCustomerGroups}
            options={customerGroupsOptions}
            onChange={onChangeCustomerGroups}
            //placeholder={placeholder}
            closeMenuOnSelect={false}
            isDisabled={isSubmitting}
          />
        </div>
      </React.Fragment>
    );
  };

  const renderCustomerAccess = () => {
    const { isSubmitting } = state;

    const { permissions_customer_list, customerOptions } = permissions;

    let selectedCustomers = [];
    if (permissions_customer_list && customerOptions) {
      selectedCustomers = permissions_customer_list.map(item => {
        return customerOptions.find(i => i.value === item);
      });
    }

    const onChangeCustomers = val => {
      let value = val;
      if (!value) {
        value = [];
      }

      setPermission({
        ...permissions,
        permissions_customer_list: value.map(i => i.value),
      });
      // this.setState({
      //   permissions_customer_list: value.map(i => i.value),
      // });
    };

    return (
      <React.Fragment>
        <Label>{i18next.t('21251')}</Label>
        <div className='create-folder-permissions'>
          <ReactSelect
            isMulti
            value={selectedCustomers}
            options={customerOptions}
            onChange={onChangeCustomers}
            //placeholder={placeholder}
            closeMenuOnSelect={false}
            isDisabled={isSubmitting}
          />
        </div>
      </React.Fragment>
    );
  };

  const renderForm = () => {
    const { isUpdate, modalData } = props;
    const { isSubmitting, isLoading, isCompanyOwner } = state;

    const { companyOwnerFolder, path, isFolder, name } = document;

    //console.log(hasErrors, errorText);

    const toggleCompanyOwnerFolder = () => {
      setDocument({ ...document, companyOwnerFolder: !companyOwnerFolder });
      //setPermission({ ...permissions, permissions_list: [] });
    };

    return (
      <Row>
        <Col>
          <Form>
            <TextInput
              for={i18next.t(13)}
              name='name'
              onChange={onChange}
              value={name}
            />

            <TextInput
              for={i18next.t(7088)}
              name='path'
              onChange={onChange}
              value={path ? path : ''}
              disabled={true}
            />

            {isCompanyOwner && isFolder && (
              <Checkbox
                for={i18next.t(7159)}
                name='companyOwnerFolder'
                onChange={toggleCompanyOwnerFolder}
                checked={companyOwnerFolder}
                className='margin-bottom-10'
              />
            )}

            {isFolder && (
              <React.Fragment>
                {state.customerId <= 0 && renderCustomerTypesAccess()}
                {state.customerId <= 0 && renderCustomerGroupAccess()}
                {state.customerId <= 0 && renderCustomerAccess()}
                {renderPermissions()}
              </React.Fragment>
            )}

            {hasErrors && (
              <ErrorMsg msg={errorText ? errorText : i18next.t(126)} />
            )}

            {!isSubmitting && !isLoading && (
              <React.Fragment>
                <Button
                  size='lg'
                  color='btnPrimary'
                  className='border-0'
                  block
                  onClick={handleSubmit}
                >
                  {isUpdate && modalData ? i18next.t(28) : i18next.t(18)}
                </Button>
              </React.Fragment>
            )}
            {(isSubmitting || isLoading) && <PageSpinner />}
          </Form>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <Modal
        isOpen={props.show}
        toggle={() => {}}
        fade={false}
        size='lg'
        centered
      >
        <ModalHeader toggle={props.toggle}>
          {!isUpdate && i18next.t(7073)}
          {isUpdate && i18next.t(16) + ' ' + document.name}
        </ModalHeader>
        <ModalBody>
          {!state.isLoading && state.canEdit && renderForm()}
          {state.isLoading && <PageSpinner style={{ padding: 15 }} />}
        </ModalBody>
      </Modal>
    </div>
  );
};

DocumentCreateModal.propTypes = {
  currentFolderParentId: PropTypes.number,
  modalData: PropTypes.object,
  isUpdate: PropTypes.bool,
};

function mapStateToProps({ auth, documents }) {
  return {
    auth,
    currentFolder: documents.currentFolder,
  };
}

export default connect(mapStateToProps, {
  createFolder,
  updateFolder,
  getFolder,
  updateFile,
  getCustomerGroups,
  getEmployees,
  getTeams,
  getCustomers,
})(DocumentCreateModal);
