import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      data-name='Component 71'
      xmlns='http://www.w3.org/2000/svg'
      width={25}
      height={25}
      viewBox='0 0 24 24'
      {...props}
    >
      <defs>
        <clipPath id='a'>
          <path data-name='Rectangle 1258' fill='none' d='M0 0H24V24H0z' />
        </clipPath>
      </defs>
      <g data-name='Mask Group 2' clipPath='url(#a)'>
        <g data-name='Icon - External link' transform='translate(.072)'>
          <path
            data-name='Rectangle 1264'
            transform='translate(-.072)'
            fill='none'
            d='M0 0H24V24H0z'
          />
          <g data-name='Group 257'>
            <path
              data-name='Path 308'
              d='M4.56 22.764a3.214 3.214 0 01-3.192-3.228v-4.392a1.02 1.02 0 012.04 0v4.4a1.165 1.165 0 001.152 1.18h15.18a1.165 1.165 0 001.152-1.176v-4.4a1.02 1.02 0 012.04 0v4.4a3.2 3.2 0 01-3.18 3.216z'
            />
            <path
              data-name='Path 309'
              d='M1.368 4.392A3.214 3.214 0 014.6 1.2h4.388a1.02 1.02 0 010 2.04h-4.4a1.165 1.165 0 00-1.18 1.152l.012 15.18a1.165 1.165 0 001.18 1.152H9a1.02 1.02 0 010 2.04H4.6a3.219 3.219 0 01-3.228-3.18z'
            />
            <path
              data-name='Rectangle_1258'
              transform='translate(1.128 .84)'
              fill='none'
              d='M0 0H21.6V21.6H0z'
            />
            <g data-name='Group 256'>
              <path
                data-name='Path 310'
                d='M21.9 10.572a1.019 1.019 0 01-1.02-1.02v-6.2h-6.2a1.02 1.02 0 010-2.04h7.22a1.019 1.019 0 011.02 1.02v7.22a1.019 1.019 0 01-1.02 1.02z'
              />
              <path
                data-name='Path 311'
                d='M11.616 13.668a.979.979 0 01-.72-.3 1.021 1.021 0 010-1.44l9.276-9.288a1.018 1.018 0 011.44 1.44l-9.288 9.288a1.022 1.022 0 01-.708.3z'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
