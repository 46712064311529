/**
 * @module actions/markings
 */
import axios from 'axios';
import {
  API_URL,
  GET_ALL_IMAGE_REFERENCES,
  SET_CURRENT_IMAGE_REFERENCES,
  UPDATE_REFERENCE_INFO,
  IS_REF_SAVE_ALLOWED,
  RECENT_UPLOADS,
} from '../constants';

/**
 * Fetch the markings for a single schema on a case. Requires KsId or SaId.
 * @param {Object} data
 * @returns
 */
export function getMarkingReferences(data) {
  return dispatch => {
    let url = API_URL + `/markings/fetchMarkings`;

    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * Fetch the markings for a single schema on a case. Requires KsId or SaId.
 * @param {Object} data
 * @returns
 */
export function getAllMarkingReferences(data) {
  return dispatch => {
    let url = API_URL + `/markings/fetchMarkings/all`;

    return axios.post(url, data).then(res => {
      dispatch({
        type: GET_ALL_IMAGE_REFERENCES,
        payload: res.data,
      });

      return res.data;
    });
  };
}

/**
 * @description Save img markings
 * @param {Object} data
 * @param {Array} markings
 */
export function saveMarkings(data) {
  return dispatch => {
    let url = API_URL + `/markings/updateMarkings`;

    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

export function saveReferences(data, allRefs) {
  return dispatch => {
    let url = API_URL + `/markings/updateReferences/`;

    return axios.post(url, data).then(res => {
      dispatch({
        type: SET_CURRENT_IMAGE_REFERENCES,
        payload: res.data,
      });

      let updatedRefs = [...allRefs];
      const curIndex = updatedRefs.findIndex(
        x => x.controlId === res.data.controlId,
      );
      if (curIndex > -1) {
        updatedRefs[curIndex] = res.data;
      } else {
        updatedRefs.push(res.data);
      }

      dispatch({
        type: GET_ALL_IMAGE_REFERENCES,
        payload: updatedRefs,
      });

      return res.data;
    });
  };
}

export function setCurrentReferences(data) {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_IMAGE_REFERENCES,
      payload: data,
    });
  };
}

export function updateReferenceInfo(data) {
  return dispatch => {
    dispatch({
      type: UPDATE_REFERENCE_INFO,
      payload: data,
    });
  };
}

export function setSaveAllowedState(val) {
  return dispatch => {
    dispatch({
      type: IS_REF_SAVE_ALLOWED,
      payload: val,
    });
  };
}

export function setRecentUploads(val) {
  return dispatch => {
    dispatch({
      type: RECENT_UPLOADS,
      payload: val,
    });
  };
}

/**
 * @description Delete area markings
 * @param {Object} data
 * @param {Array} markings
 */
export function deleteAreaImageAndReferences(id) {
  return dispatch => {
    let url = API_URL + `/markings/deleteAreaMarking/${id}`;

    return axios.post(url).then(res => {
      return res.data;
    });
  };
}

/**
 * @description Delete img markings
 * @param {Object} data
 * @param {Array} markings
 */
export function deleteImageAndReferences(id) {
  return dispatch => {
    let url = API_URL + `/markings/deleteImageMarking/${id}`;

    return axios.post(url).then(res => {
      return res.data;
    });
  };
}
