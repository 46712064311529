import React from 'react';

export default function({ msg }) {
  try {
    if (msg?.trim().length > 0) {
      const splitted = msg.split('\n');

      if (splitted.length > 0) {
        return (
          <div style={{ color: 'red' }}>
            {splitted.map(x => {
              if (x.trim().length === 0) return null;

              return <p>{x}</p>;
            })}
          </div>
        );
      }
    }
  } catch (error) {
    console.log(error);
  }

  return <p style={{ color: 'red' }}>{msg}</p>;
}
