import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledButtonDropdown,
} from 'reactstrap';

// Actions
import { loginAs } from '../../redux/actions/auth';
import {
  getFolders,
  getFiles,
  deleteFolder,
  deleteFile,
  downloadFile,
  moveFolder,
  copyFolder,
  moveFile,
  copyFile,
  updateFolder,
  updateFile,
  updateGlobalFolderVisibilty,
  updateGlobalFileVisibilty,
} from '../../redux/actions/documents';

// Utils
import { getUserData } from '../../utils/userUtil';
import {
  hasMinAccessLevel,
  hasFeature,
} from '../../utils/contentByAccessLevel';
import { parseDate, addToObject } from '../../utils/common';
import { formatSizeUnits } from '../../utils/formatSizeUnits';
import Toast from '../../utils/toast';

// Icons
import {
  GlobalDocumentIcon,
  StandardDocumentIcon,
  CasesDocumentIcon,
  ImageDocumentIcon,
  TextDocumentIcon,
  PdfDocumentIcon,
  GenericDocumentIcon,
  MoveIcon,
  CopyIcon,
  RenameIcon,
  DeleteIcon,
  //FavoriteIcon,
  ExcelDocumentIcon,
  WordDocumentIcon,
  PowerPointDocumentIcon,
  VideoDocumentIcon,
  NumbersDocumentIcon,
  PagesDocumentIcon,
  KeynoteDocumentIcon,
} from './DocumentIcons';

// Constants
import {
  DOCUMENT_TYPE,
  DOCUMENT_FOLDER_TYPE,
  EMPLOYEE_TYPE,
  CUSTOMER_STATUS,
  FEATURE,
} from '../../redux/constants';
import { BsThreeDots } from 'react-icons/bs';

// Components
import Checkbox from './Forms/Checkbox';
import DocumentCreateModal from '../../components/Custom/DocumentCreateModal';
import DocumentMoveCopyModal from '../../components/Custom/DocumentMoveCopyModal';
import DocumentConfirmModal from '../../components/Custom/DocumentConfirmModal';
import ReactTable from '../../components/Custom/ReactTable';
import PageSpinner from '../PageSpinner';

const DocumentsOverview = props => {
  const {
    navigate,
    isModal,
    currentFolderParentId,
    //previousFolderParentId,
    parentFolder,
    features,
  } = props;

  const user = getUserData(props.auth);
  let authTypeCheck =
    props.type === 1
      ? hasMinAccessLevel(props.auth, 1)
      : user.hasAP && hasFeature(features, [FEATURE.Documents]);
  const [canEdit] = useState(authTypeCheck);

  const [loading, setLoading] = useState(false);
  const [showDocumentsCreateModal, setShowDocumentsCreateModal] = useState(
    false,
  );
  const [showDocumentMoveCopyModal, setShowDocumentMoveCopyModal] = useState(
    false,
  );
  const [showDelete, setShowDelete] = useState(false);
  const [isMove, setIsMove] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [disableAllFunctions] = useState(
    props.auth.customerStatus === CUSTOMER_STATUS.Paused,
  );

  const [documents, setDocuments] = useState(
    props.documents ? props.documents : [],
  );
  const [modalData, setModalData] = useState(null);
  const [employeeType] = useState(user.employeeType);
  const [customerId] = useState(user.customerId);

  useEffect(() => {
    if (!showDocumentMoveCopyModal) {
      setDocuments(props.documents);
    }

    // eslint-disable-next-line
  }, [props.documents]);

  const toggleDocumentsCreateModal = () => {
    setShowDocumentsCreateModal(!showDocumentsCreateModal);
    //setLoading(!loading);
  };

  const getFileType = type => {
    let translate = i18next.t(20);

    switch (type) {
      case DOCUMENT_TYPE.Image:
        translate = i18next.t(7077);
        break;
      case DOCUMENT_TYPE.Text:
        translate = i18next.t(7079);
        break;
      case DOCUMENT_TYPE.Pdf:
        translate = i18next.t(7080);
        break;
      case DOCUMENT_TYPE.Excel:
        translate = i18next.t(7092);
        break;
      case DOCUMENT_TYPE.Word:
        translate = i18next.t(7093);
        break;
      case DOCUMENT_TYPE.Powerpoint:
        translate = i18next.t(7150);
        break;
      case DOCUMENT_TYPE.Video:
        translate = i18next.t(7151);
        break;
      case DOCUMENT_TYPE.Numbers:
        translate = i18next.t(7152);
        break;
      case DOCUMENT_TYPE.Pages:
        translate = i18next.t(7153);
        break;
      case DOCUMENT_TYPE.Keynote:
        translate = i18next.t(7154);
        break;
      default:
        translate = i18next.t(7078);
        break;
    }
    return translate;
  };

  const getFolderType = type => {
    let translate = i18next.t(7115);

    switch (type) {
      case DOCUMENT_FOLDER_TYPE.Global:
        translate = i18next.t(7081);
        break;
      case DOCUMENT_FOLDER_TYPE.Cases:
        translate = i18next.t(101);
        break;
      default:
        translate = i18next.t(7115);
        break;
    }
    return translate;
  };

  const fileIcons = i => {
    let out = GenericDocumentIcon;

    switch (i) {
      case DOCUMENT_TYPE.Image:
        out = ImageDocumentIcon;
        break;
      case DOCUMENT_TYPE.Text:
        out = TextDocumentIcon;
        break;
      case DOCUMENT_TYPE.Pdf:
        out = PdfDocumentIcon;
        break;
      case DOCUMENT_TYPE.Excel:
        out = ExcelDocumentIcon;
        break;
      case DOCUMENT_TYPE.Word:
        out = WordDocumentIcon;
        break;
      case DOCUMENT_TYPE.Powerpoint:
        out = PowerPointDocumentIcon;
        break;
      case DOCUMENT_TYPE.Video:
        out = VideoDocumentIcon;
        break;
      case DOCUMENT_TYPE.Numbers:
        out = NumbersDocumentIcon;
        break;
      case DOCUMENT_TYPE.Pages:
        out = PagesDocumentIcon;
        break;
      case DOCUMENT_TYPE.Keynote:
        out = KeynoteDocumentIcon;
        break;
      default:
        out = GenericDocumentIcon;
        break;
    }

    return out;
  };

  const folderIcons = i => {
    let out = StandardDocumentIcon;

    switch (i) {
      case DOCUMENT_FOLDER_TYPE.Global:
        out = GlobalDocumentIcon;
        break;
      case DOCUMENT_FOLDER_TYPE.Cases:
        out = CasesDocumentIcon;
        break;
      default:
        out = StandardDocumentIcon;
        break;
    }

    return out;
  };

  const toolsDropdown = i => {
    const showDropdown =
      ((customerId <= 0 && i.isFolder && i.type === 1) ||
        (customerId > 0 && i.isFolder && i.type !== 1) ||
        (customerId > 0 && i.isFile && parentFolder.type !== 1) ||
        (customerId > 0 && i.isFile && parentFolder.id === 0) ||
        (customerId <= 0 && i.isFile && parentFolder.type === 1)) &&
      (employeeType <= EMPLOYEE_TYPE.ExecutivePerson ||
        employeeType === EMPLOYEE_TYPE.CompanyOwner) &&
      canEdit;

    if (disableAllFunctions || !showDropdown) return null;
    //console.log(parentFolder, i);

    return (
      <UncontrolledButtonDropdown
        key='uncontrolled-status-dropdown'
        className='documents-options'
      >
        <DropdownToggle
          tag='span'
          data-toggle='dropdown'
          className='text-capitalize m-1'
          style={{ cursor: 'pointer' }}
        >
          <BsThreeDots size={20} />
        </DropdownToggle>
        <DropdownMenu>
          {showDropdown && (
            <React.Fragment>
              <DropdownItem
                onClick={() => {
                  moveItem(i);
                }}
              >
                <img src={MoveIcon} alt='' className='document-tools-icon' />{' '}
                {i18next.t(7083)}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  copyItem(i);
                }}
              >
                <img src={CopyIcon} alt='' className='document-tools-icon' />{' '}
                {i18next.t(1439)}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  renameItem(i);
                }}
              >
                <img src={RenameIcon} alt='' className='document-tools-icon' />{' '}
                {i18next.t(16)}
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  toggleDeleteModal(i);
                }}
              >
                <img src={DeleteIcon} alt='' className='document-tools-icon' />{' '}
                {i18next.t(17)}
              </DropdownItem>
            </React.Fragment>
          )}

          {/* <DropdownItem onClick={() => {favoriteItem(i)}}>
           <img src={FavoriteIcon} alt='' className='document-tools-icon' />  {i18next.t(7087)}
          </DropdownItem> */}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const renameItem = i => {
    setModalData(i);
    toggleDocumentsCreateModal();
  };

  const toggleDeleteModal = i => {
    setModalData(i);
    setShowDelete(!showDelete);
  };

  const deleteItem = () => {
    setLoading(true);

    const newDocuments = documents.filter(x => {
      return x.id !== modalData.id;
    });
    setDocuments(newDocuments);

    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }

    if (modalData.isFolder) {
      props
        .deleteFolder(tempCustomerId, modalData.id)
        .then(() => {
          Toast.success(i18next.t(21033));
          setLoading(false);
          setShowDelete(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
          setShowDelete(false);
        });
    }
    if (modalData.isFile) {
      props
        .deleteFile(tempCustomerId, modalData.id)
        .then(() => {
          Toast.success(i18next.t(21036));
          setLoading(false);
          setShowDelete(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
          setShowDelete(false);
        });
    }
  };

  const copyItem = i => {
    setModalData(i);
    setIsCopy(true);
    setIsMove(false);
    toggleDocumentMoveCopyModal();
  };

  const moveItem = i => {
    setModalData(i);
    setIsCopy(false);
    setIsMove(true);

    toggleDocumentMoveCopyModal();
  };

  const toggleDocumentMoveCopyModal = () => {
    setShowDocumentMoveCopyModal(!showDocumentMoveCopyModal);
  };

  // Not implemented
  // const favoriteItem = i => {
  //   console.log('Favorite');
  // }

  const downloadItem = i => {
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }
    downloadFile(tempCustomerId, i.id);
  };

  const onChangeActiveChecked = (i, updateVisibiltyAtCustomerLevel = false) => {
    //console.log("change active state", !i.isActive);
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }
    const dataObj = i;
    dataObj.isActive = !i.isActive;

    documents.forEach(el =>
      el.id === i.id ? { ...el, isActive: !i.isActive } : el,
    );
    setDocuments([...documents]);

    if (i.isFolder) {
      if (updateVisibiltyAtCustomerLevel) {
        props.updateGlobalFolderVisibilty(dataObj);
      } else {
        props.updateFolder(dataObj);
      }
    }
    if (i.isFile) {
      if (updateVisibiltyAtCustomerLevel) {
        props.updateGlobalFileVisibilty(dataObj);
      } else {
        props.updateFile(dataObj);
      }
    }
  };

  const getTableProps = () => {
    let headers = [
      {
        title: i18next.t(13),
        className: 'text-align-left',
        tdClass: 'no-padding',
        sortable: true,
        selector: 'name',
        sortType: 'valueString',
      },
      {
        title: i18next.t(21119),
        sortable: true,
        selector: 'lastEdited',
        sortType: 'date',
      },
      {
        title: i18next.t(7116),
        sortable: true,
        selector: 'size',
        sortType: 'fileSize',
      },
      {
        title: i18next.t(32),
        sortable: true,
        selector: 'type',
        sortType: 'string',
      },
    ];

    let colGroups = [''];

    if (
      (employeeType <= EMPLOYEE_TYPE.ExecutivePerson ||
        employeeType === EMPLOYEE_TYPE.CompanyOwner) &&
      canEdit
    ) {
      headers.splice(1, 0, {
        title: i18next.t(71),
        className: 'text-align-center',
      });
    }

    if (!isModal) {
      headers.push({
        title: '',
      });
    }

    return {
      colgroup: colGroups,
      scrollRender: {
        window: document.getElementById('main-content-wrapper'),
        body: document.getElementById('main-content-id'),
      },
      headers: {
        class: '',
        data: headers,
      },
      body: {
        class: '',
        empty: i18next.t(51),
        data: getBodyData(),
      },
    };
  };

  const getBodyData = () => {
    let bodyData = [];
    documents.forEach((_document, index) => {
      let name = '';
      let status = '';
      let lastEdited = '';
      let size = '';
      let type = '';
      let edit = '';

      if (_document.isFile) {
        // let key = 'file-' + _document.id;
        // if (this.state.isModal) {
        //   key = 'modal-file-' + _document.id;
        // }

        name = (
          <div
            onClick={() => {
              downloadItem(_document);
            }}
            style={{ cursor: 'pointer', padding: '0.75rem' }}
            value={_document.name}
          >
            <img
              src={fileIcons(_document.fileType)}
              alt=''
              className='document-item-icon'
            />
            <button
              id='sortable'
              className='margin-left-10px no-button border-none'
            >
              {_document.name}
            </button>
          </div>
        );

        if (
          (employeeType <= EMPLOYEE_TYPE.ExecutivePerson ||
            employeeType === EMPLOYEE_TYPE.CompanyOwner) &&
          canEdit
        ) {
          status = (
            <Checkbox
              name='active'
              className='text-align-center'
              onChange={() => {
                onChangeActiveChecked(
                  _document,
                  parentFolder.type === DOCUMENT_FOLDER_TYPE.Global &&
                    customerId >= 1,
                );
              }}
              checked={_document.isActive}
              disabled={
                disableAllFunctions ||
                (parentFolder.type === DOCUMENT_FOLDER_TYPE.Global &&
                  customerId >= 1)
              }
            />
          );
        }

        lastEdited =
          parseDate(_document.updatedDate) !== '01.01.0001'
            ? parseDate(_document.updatedDate)
            : parseDate(_document.createdDate);

        size = formatSizeUnits(_document.size);
        type = getFileType(_document.fileType);
        edit = !isModal ? toolsDropdown(_document) : '';
      }
      if (_document.isFolder) {
        // let key = 'folder-' + _document.id;
        // if (this.state.isModal) {
        //   key = 'modal-folder-' + _document.id;
        // }
        const isNotCompanyOwner =
          _document.companyOwnerFolder &&
          employeeType !== EMPLOYEE_TYPE.CompanyOwner;

        name = (
          <div
            onClick={() => {
              navigate(_document.id);
            }}
            style={{ cursor: 'pointer', padding: '0.75rem' }}
            value={_document.name}
          >
            <img
              src={folderIcons(_document.type)}
              alt=''
              className='document-item-icon'
            />
            <button
              id='sortable'
              className='margin-left-10px no-button border-none '
            >
              {_document.name}
            </button>
          </div>
        );

        if (
          (employeeType <= EMPLOYEE_TYPE.ExecutivePerson ||
            employeeType === EMPLOYEE_TYPE.CompanyOwner) &&
          canEdit
        ) {
          status = (
            <Checkbox
              name='active'
              className='text-align-center'
              onChange={() => {
                onChangeActiveChecked(
                  _document,
                  _document.type === DOCUMENT_FOLDER_TYPE.Global &&
                    customerId >= 1,
                );
              }}
              checked={_document.isActive}
              disabled={
                disableAllFunctions ||
                isNotCompanyOwner ||
                (_document.type === DOCUMENT_FOLDER_TYPE.Global &&
                  customerId >= 1)
              }
            />
          );
        }

        lastEdited = '';
        // parseDate(_document.updatedDate) !== '01.01.0001'
        //   ? parseDate(_document.updatedDate)
        //   : parseDate(_document.createdDate);

        size = '';
        type = getFolderType(_document.type);
        edit = !isModal && !isNotCompanyOwner ? toolsDropdown(_document) : '';
      }

      let dataObj = { _id: _document.id, name, lastEdited, size, type, edit };

      if (
        (employeeType <= EMPLOYEE_TYPE.ExecutivePerson ||
          employeeType === EMPLOYEE_TYPE.CompanyOwner) &&
        canEdit
      ) {
        dataObj = addToObject(dataObj, 'status', status, 2);
      }

      bodyData.push(dataObj);
    });

    return bodyData;
  };

  if (loading) return <PageSpinner />;
  return (
    <Row>
      <Col>
        <ReactTable
          size='sm'
          className='full-width'
          data={getTableProps()}
          striped
          responsive
          hover
        />
      </Col>

      {showDocumentsCreateModal && !disableAllFunctions && (
        <DocumentCreateModal
          currentFolderParentId={currentFolderParentId}
          show={showDocumentsCreateModal}
          isUpdate={true}
          modalData={modalData}
          handleRefresh={props.handleRefresh}
          toggle={toggleDocumentsCreateModal}
        />
      )}
      {showDocumentMoveCopyModal && !disableAllFunctions && (
        <DocumentMoveCopyModal
          currentFolderParentId={currentFolderParentId}
          show={showDocumentMoveCopyModal}
          modalData={modalData}
          isCopy={isCopy}
          isMove={isMove}
          toggle={toggleDocumentMoveCopyModal}
          handleRefresh={props.handleRefresh}
          breadcrumbs={props.breadcrumbs}
        />
      )}

      {showDelete && !disableAllFunctions && (
        <DocumentConfirmModal
          header={i18next.t(17) + ' - ' + modalData.name}
          body={modalData.isFolder ? i18next.t(7100) : i18next.t(7101)}
          show={showDelete}
          toggle={toggleDeleteModal}
          confirmFunc={deleteItem}
          cancelFunc={toggleDeleteModal}
        />
      )}
    </Row>
  );
};

DocumentsOverview.propTypes = {
  toggleReturn: PropTypes.func,
  setParentIds: PropTypes.func,
  isModal: PropTypes.bool,
  navigate: PropTypes.func,
  handleRefresh: PropTypes.func,
  sortBy: PropTypes.func,
};

function mapStateToProps({ auth, features }) {
  return {
    auth,
    features: features.features,
  };
}
export default connect(mapStateToProps, {
  loginAs,
  getFolders,
  getFiles,
  deleteFolder,
  deleteFile,
  downloadFile,
  moveFolder,
  copyFolder,
  moveFile,
  copyFile,
  updateFolder,
  updateFile,
  updateGlobalFolderVisibilty,
  updateGlobalFileVisibilty,
})(DocumentsOverview);
