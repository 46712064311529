import {
  GET_SCHEMA_GROUP,
  GET_SCHEMA_OPTIONS,
  SET_CURRENT_SCHEMA,
  SET_SCHEMA_GROUP,
  SET_MARKED_SCHEMAS,
  REFRESH_SCHEMA_GROUP,
} from '../constants';

const initialState = {
  schemaGroups: [],
  schemaOptions: [],
  currentSchemaGroup: null,
  currentSchema: null,
  marked: { show: false, schemas: [], groups: [] },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SCHEMA_GROUP:
      return {
        ...state,
        schemaGroups: action.payload,
      };
    case REFRESH_SCHEMA_GROUP:
      return {
        ...state,
        ...refreshSchemaGroupsWithNewGroup(
          action.payload,
          state.schemaGroups,
          state.currentSchema,
        ),
      };
    case SET_SCHEMA_GROUP:
      return {
        ...state,
        currentSchemaGroup: action.payload,
      };
    case SET_CURRENT_SCHEMA:
      return {
        ...state,
        currentSchema: action.payload,
      };
    case GET_SCHEMA_OPTIONS:
      return {
        ...state,
        schemaOptions: action.payload,
      };
    case SET_MARKED_SCHEMAS:
      return {
        ...state,
        marked: action.payload,
      };
    default:
      return state;
  }
};

function refreshSchemaGroupsWithNewGroup(group, allGroups, schema) {
  let result = {};
  try {
    // console.log('refreshSchemaGroupsWithNewGroup', group, allGroups, schema);
    // update group
    let updatedGroups = allGroups.slice();

    const index = allGroups.findIndex(x => x.id === group[0].id);

    if (index > -1) {
      updatedGroups[index] = group[0];
    } else {
      console.log('Could not find group!');
    }

    result.schemaGroups = updatedGroups;

    // // find current schema and update
    // let schemaIndex = group[0].schemas.findIndex(x => x.id === schema.id);
    // if (schemaIndex > -1) {
    //   result.currentSchema = group[0].schemas[schemaIndex];
    // }
  } catch (error) {
    console.log('Error refresh groups', error);
  }
  return result;
}
