import {
  GET_CASE_DESCRIPTIONS,
  GET_CUSTOMER_DESCRIPTIONS,
  SET_CASE_DESCRIPTIONS,
  SET_CUSTOMER_DESCRIPTIONS,
} from '../constants';

const initialState = {
  case_descriptions: null,
  customer_descriptions: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CASE_DESCRIPTIONS:
      return {
        ...state,
        case_descriptions: action.payload,
      };
    case SET_CUSTOMER_DESCRIPTIONS:
      return {
        ...state,
        customer_descriptions: action.payload,
      };
    case GET_CASE_DESCRIPTIONS:
      return {
        ...state,
        case_descriptions: action.payload,
      };
    case GET_CUSTOMER_DESCRIPTIONS:
      return {
        ...state,
        customer_descriptions: action.payload,
      };
    default:
      return state;
  }
};
