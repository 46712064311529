import React from 'react';
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { BsThreeDots } from 'react-icons/bs';

const ButtonDropdown = props => {
  if (!props.children) return null;
  if (props.children.length === 0) return null;

  let extraClass = '';
  if (props.className) {
    extraClass += ' ' + props.className;
  }

  return (
    <UncontrolledButtonDropdown
      key='uncontrolled-status-dropdown'
      className={'custom-button-dropdown' + extraClass}
    >
      <DropdownToggle
        tag='div'
        data-toggle='dropdown'
        className='text-capitalize m-1'
        style={{ cursor: 'pointer' }}
      >
        <BsThreeDots size={20} />
      </DropdownToggle>
      <DropdownMenu>{props.children}</DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default ButtonDropdown;
