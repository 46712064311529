import * as React from 'react';

const SvgIconServiceaftale = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Serviceaftale_svg__a'>
        <path
          data-name='Rectangle 1108'
          transform='rotate(-87.65 10.305 9.893)'
          d='M0 0h19.798v23.216H0z'
        />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1107' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 102'>
      <g
        data-name='Group 99'
        clipPath='url(#Icon_-_Serviceaftale_svg__a)'
        transform='translate(0 1.638)'
      >
        <path
          data-name='Path 216'
          d='M9.753 14.814a1.556 1.556 0 0 0-2.18.374 1.564 1.564 0 0 0-2.55-1.806 1.564 1.564 0 0 0-2.554-1.806l-1.7 2.425a1.6 1.6 0 0 0 2.309 2.154 1.407 1.407 0 0 0 .632 1.831 1.564 1.564 0 0 0 1.935-.026 1.412 1.412 0 0 0 .619 1.831 1.556 1.556 0 0 0 2.18-.374l1.7-2.412a1.582 1.582 0 0 0-.387-2.193'
        />
      </g>
      <g
        data-name='Group 100'
        clipPath='url(#Icon_-_Serviceaftale_svg__a)'
        transform='translate(0 1.638)'
      >
        <path
          data-name='Path 217'
          d='m19.676 11.345-.039-.026-7.055-5a.394.394 0 0 0-.555.09l-1.051 1.454a2.928 2.928 0 0 1-2.5 1.225 2.968 2.968 0 0 1-1.561-.529 2.913 2.913 0 0 1-.709-4.05l.542-.774a.265.265 0 0 0-.155-.439l-1.625-.451a.694.694 0 0 0-.761.322L.376 10.3a3.368 3.368 0 0 0 .645.606c.284.155.361.064.413 0a2.9 2.9 0 0 1 2.36-1.045 2.968 2.968 0 0 1 1.561.529 2.816 2.816 0 0 1 .98 1.187 2.9 2.9 0 0 1 1.445.516 2.817 2.817 0 0 1 .98 1.187 2.894 2.894 0 0 1 2.141 4.566l-.865 1.212 1.212.877a1.49 1.49 0 0 0 2.077-.361 1.473 1.473 0 0 0-.361-2.064l.49.348a1.49 1.49 0 0 0 2.077-.361 1.473 1.473 0 0 0-.361-2.064l.49.348a1.49 1.49 0 0 0 2.077-.361 1.473 1.473 0 0 0-.361-2.064l.49.348a1.49 1.49 0 0 0 2.077-.361 1.4 1.4 0 0 0-.271-2'
        />
      </g>
      <g
        data-name='Group 101'
        clipPath='url(#Icon_-_Serviceaftale_svg__a)'
        transform='translate(0 1.638)'
      >
        <path
          data-name='Path 218'
          d='m11.919 4.225.451.322 7.667 5.443a.891.891 0 0 0 .929.013l2.425-1.651a.866.866 0 0 0 .322-.864l-1.592-6.225a.647.647 0 0 0-.645-.413C20.947.824 11.171.773 10.822.76c-.4-.013-.426.09-.529.258-.684.993-3.212 4.514-3.212 4.514a1.583 1.583 0 0 0 2.592 1.819'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconServiceaftale;
