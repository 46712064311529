import { SET_INSURANCE_COMPANIES } from '../constants';

const initialState = {
  insuranceCompanies: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_INSURANCE_COMPANIES:
      return {
        ...state,
        insuranceCompanies: action.insuranceCompanies,
      };
    default:
      return state;
  }
};
