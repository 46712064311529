import React, { useState, useEffect } from 'react';
import i18next from '../../i18n';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';

// Actions
import {
  setCurrentReferences,
  saveMarkings,
  saveReferences,
  deleteAreaImageAndReferences,
  deleteImageAndReferences,
  getAllMarkingReferences,
} from '../../redux/actions/markings';
import { bulkFileInfoUpdate } from '../../redux/actions/files';
import { refreshImageHash } from '../../redux/actions/common';
import { refreshSchemaGroup } from '../../redux/actions/schemas';

// Utils
import { SB_CONTROL_TYPE } from '../../redux/constants';
import { truncateString } from '../../utils/common';
import { getImageRotated } from '../../utils/componentUtils';
import toast from '../../utils/toast';

// Componenets
import CreateModal from '../Custom/CreateModal';
import MarkingView from './MarkingView';
import AreaView from './AreaView';
import PageSpinner from '../PageSpinner';
import Typography from '../Typography';
import ConfirmModal from '../Custom/ConfirmModal';

const ImageMarking = props => {
  const {
    schema,
    control,
    show,
    toggle,
    disabled,
    editor,
    setCurrentReferences,
    startIndex,
  } = props;
  const { allImageRefs } = props.markings;
  const [areaImage, setAreaImage] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [imageIndex, setImageIndex] = useState(!!startIndex ? startIndex : 0);
  const [currentImage, setImage] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmAreaDelete, setShowConfirmAreaDelete] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [refresh, setRefresh] = useState(true);

  // File info and rotation
  const [rotations, setRotations] = useState({});
  const [fileInfos, setFileInfos] = useState({});

  useEffect(() => {
    if (refresh) {
      if (!!schema && show) {
        const areaControl = schema.controlItems.find(
          x => x.controlType === SB_CONTROL_TYPE.PhotoAreaMarking,
        );

        const foundExistingRefs = allImageRefs.find(
          x => x.controlId === control.id,
        );
        if (!!foundExistingRefs) {
          setCurrentReferences(foundExistingRefs);
        } else {
          setCurrentReferences(null);
        }

        if (!!areaControl && areaControl?.files.length > 0) {
          setAreaImage(areaControl.files[0]);
          setLoading(false);
        }
      } else {
        setCurrentReferences(null);
        setAreaImage(null);
        setLoading(true);
      }

      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [show, refresh]);

  useEffect(() => {
    if (control.files.length > 0) {
      let img = control.files[imageIndex];
      if (!!img && !!rotations[img.id]?.img) {
        img.fileUrl = rotations[img.id].img;
      }
      setImage(img);
    } else {
      setImage(null);
    }
    // eslint-disable-next-line
  }, [imageIndex]);

  const refreshAllReferences = () => {
    const caseId = props.caseInfo?.id ?? 0;
    let dataObj = {};

    if (props.caseInfo?.type === 3) {
      dataObj = { saId: caseId };
    } else {
      dataObj = { ksId: caseId };
    }
    return props.getAllMarkingReferences(dataObj).catch(err => {
      console.log(err);
    });
  };

  const getCurrentSchemaSorting = () => {
    const { schemaGroups } = props.schemas;

    const currentGroup = schemaGroups.find(
      x => x.id === props.schema.schemaGroupId,
    );
    if (!currentGroup) return null;

    return currentGroup.schemas.map(x => x.id);
  };

  const galleryNavigate = dir => {
    let index = imageIndex;
    const length = control.files.length;

    if (index === length - 1 && dir === 1) {
      // Go to beginning
      index = 0;
    } else if (index === 0 && dir === -1) {
      // Go to last
      index = length - 1;
    } else {
      index += dir;
    }

    setImageIndex(index);
  };

  const onUpdateFileInfos = (callback, errorCallback) => {
    const items = control.files.map(file => {
      const id = file.id;

      let tmpFile = {
        ...file,
      };

      if (fileInfos[id]) {
        tmpFile = {
          ...tmpFile,
          ...fileInfos[id],
        };
      }

      return {
        file: tmpFile,
        rotation: rotations[id]?.r ?? 0,
      };
    });

    items.forEach(x => {
      props.handleInfo(x.file.id, {
        fileDescription: x.file.fileDescription,
        fileTitle: x.file.fileTitle,
        width: x.file.width,
        height: x.file.height,
        fileUrl: x.file.fileUrl,
        thumbUrl: x.file.fileUrl,
        cached: true,
      });
    });

    props
      .bulkFileInfoUpdate({ items })
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        console.log(err);

        if (errorCallback) {
          errorCallback();
        }
      });
  };

  const onRotate = () => {
    let updatedRotations = {
      ...rotations,
    };
    if (!updatedRotations[currentImage.id]?.r) {
      updatedRotations[currentImage.id] = {
        r: 90,
      };
    } else {
      let degrees = updatedRotations[currentImage.id].r;
      degrees += 90;
      if (degrees > 270) {
        degrees = 0;
      }

      updatedRotations[currentImage.id].r = degrees;
    }

    const tmpImage = control.files.find(x => x.id === currentImage.id);
    getImageRotated(
      tmpImage,
      updatedRotations[currentImage.id].r,
      (img, height, width) => {
        updatedRotations[currentImage.id].img = img;
        setRotations(updatedRotations);

        let updatedInfos = { ...fileInfos };
        let info = updatedInfos[currentImage.id];
        if (!info) {
          updatedInfos[currentImage.id] = {
            ...updatedInfos[currentImage.id],
            fileUrl: img,
            height,
            width,
          };
        } else {
          updatedInfos[currentImage.id] = {
            ...currentImage,
            fileUrl: img,
            height,
            width,
          };
        }

        setFileInfos(updatedInfos);
        setImage({ ...currentImage, fileUrl: img, height, width });
      },
    );
  };

  const onChangeFileInfo = e => {
    let updatedInfos = { ...fileInfos };

    let info = updatedInfos[currentImage.id];
    if (!info) {
      updatedInfos[currentImage.id] = {
        [e.target.name]: e.target.value,
      };
    } else {
      updatedInfos[currentImage.id][e.target.name] = e.target.value;
    }

    setFileInfos(updatedInfos);
  };

  const onSaveFileInfos = () => {
    setSaving(true);

    const onSaveCallback = () => {
      setSaving(false);
      toast.success(i18next.t(21279));
    };

    const errorCallback = () => {
      toast.error(i18next.t(212));
    };

    onUpdateFileInfos(onSaveCallback, errorCallback);
  };

  const onSaveMarkings = ({ markings, bindings }) => {
    // const { markings, bindings } = props.markings;
    setSaving(true);
    onUpdateFileInfos();

    props
      .saveReferences(bindings, allImageRefs)
      .then(() => {
        props.saveMarkings(markings).then(() => {
          props.refreshImageHash();
          props
            .refreshSchemaGroup({
              groupId: props.schema.schemaGroupId,
              saId: props.caseInfo?.type === 3 ? props.caseInfo.id : null,
              indexedSort: getCurrentSchemaSorting(),
            })
            .catch(err => console.log(err));
          toast.success(i18next.t(21272));
          setSaving(false);
        });
      })
      .catch(err => {
        console.log(err);
        toast.error(i18next.t(212));
        setSaving(false);
      });
  };

  const onDelete = fileId => {
    setShowConfirm(false);

    if (control.files.length > 1) {
      setImageIndex(0);
    } else {
      setImageIndex(0);
      setImage(null);
    }

    if (props.onDelete) {
      props.onDelete(fileId, false);
      props
        .deleteImageAndReferences(fileId)
        .then(() => {
          props.refreshImageHash();
          props
            .refreshSchemaGroup({
              groupId: props.schema.schemaGroupId,
              saId: props.caseInfo?.type === 3 ? props.caseInfo.id : null,
              indexedSort: getCurrentSchemaSorting(),
            })
            .catch(err => console.log(err));
          refreshAllReferences().then(() => setRefresh(true));

          if (control.files.length > 0) {
            let img = control.files[imageIndex];
            if (!!img && !!rotations[img.id]?.img) {
              img.fileUrl = rotations[img.id].img;
            }
            setImage(img);
          }
          if (control.files.length === 0) {
            toggle();
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          setShowConfirm(false);
        });
    }
  };

  const onDeleteArea = fileId => {
    if (!fileId) return;

    setShowConfirm(false);

    if (props.onDelete) {
      props.onDelete(fileId, false);
      props
        .deleteAreaImageAndReferences(schema.id)
        .then(refreshAllReferences)
        .catch(err => console.log(err))
        .finally(() => {
          setShowConfirmAreaDelete(false);
          toggle();
        });
    }
  };

  const downloadImageAction = () => {
    fetch(currentImage.fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        const tag = document.createElement('a');

        tag.download = currentImage.fileName;
        tag.href = imageUrl;

        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      });
  };

  const toggleConfirmDelete = () => {
    setShowConfirm(!showConfirm);
  };

  const toggleConfirmAreaDelete = () => {
    setShowConfirmAreaDelete(!showConfirmAreaDelete);
  };

  const renderHeader = () => {
    let content;
    let areaName = '';
    if (areaImage?.fileTitle?.trim().length > 0) {
      areaName = ' - ' + truncateString(areaImage.fileTitle, 26);
    }

    if (editor) {
      if (disabled) return null;

      content = (
        <div className='header-btns'>
          <Button color='success' onClick={onRotate} disabled={isSaving}>
            {i18next.t(1361)}
          </Button>
          <Button
            color='btnSecondary'
            outline
            onClick={downloadImageAction}
            disabled={isSaving}
            className='float-right'
          >
            {i18next.t(3242)}
          </Button>

          <Button
            outline
            color='danger'
            onClick={toggleConfirmDelete}
            disabled={isSaving}
          >
            {i18next.t(147)}
          </Button>
          <Button
            outline
            color='success'
            onClick={onSaveFileInfos}
            disabled={isSaving}
          >
            {i18next.t(7070)}
          </Button>
        </div>
      );
    } else {
      content = (
        <div className='header-view'>
          <Typography type='h1'>
            {i18next.t(1327)}
            {areaName}
          </Typography>
          {!isMobile && !disabled && (
            <Button
              outline
              color='danger'
              onClick={toggleConfirmAreaDelete}
              disabled={isSaving}
            >
              {i18next.t(147)}
            </Button>
          )}
        </div>
      );
    }

    return content;
  };

  const renderContent = () => {
    if (isLoading) {
      return <PageSpinner />;
    }

    const sharedProps = {
      schema,
      control,
      areaImage,
      galleryNavigate,
      currentImage,
      isSaving,
    };

    if (editor) {
      let title = i18next.t(147);
      if (!!currentImage) {
        title =
          i18next.t(147) + ': ' + truncateString(currentImage.fileTitle, 30);
      }

      let tmpFileDescription = currentImage?.fileDescription;
      if (!!currentImage) {
        if (fileInfos[currentImage.id]) {
          tmpFileDescription = fileInfos[currentImage.id].fileDescription;
        }
      }
      const fileProps = {
        fileDescription: tmpFileDescription,
      };

      return (
        <>
          <MarkingView
            {...sharedProps}
            {...fileProps}
            onChangeFileInfo={onChangeFileInfo}
            onSaveMarkings={onSaveMarkings}
            disabled={disabled || isSaving}
          />
          ;
          <ConfirmModal
            header={title}
            show={showConfirm}
            toggle={toggleConfirmDelete}
            confirmFunc={() => onDelete(currentImage.id)}
            cancelFunc={toggleConfirmDelete}
          />
        </>
      );
    } else {
      let title = i18next.t(147);
      if (!!areaImage) {
        title = i18next.t(147) + ': ' + areaImage.fileTitle;
      }

      return (
        <>
          <AreaView {...sharedProps} />
          <ConfirmModal
            header={title}
            show={showConfirmAreaDelete}
            toggle={toggleConfirmAreaDelete}
            confirmFunc={() => onDeleteArea(areaImage?.id)}
            cancelFunc={toggleConfirmAreaDelete}
          />
        </>
      );
    }
  };

  return (
    <CreateModal
      className={'image-marking-modal' + (isMobile ? ' mobile' : '')}
      show={show}
      toggle={toggle}
      title={renderHeader()}
      size={'xl'}
    >
      <div className='image-marking-wrap'>{renderContent()}</div>
    </CreateModal>
  );
};

function mapStateToProps({ cases, schemas, markings }) {
  return {
    gallery: cases.caseImages,
    schemas,
    markings,
    caseInfo: cases.selectedCase,
  };
}

export default connect(mapStateToProps, {
  setCurrentReferences,
  saveMarkings,
  saveReferences,
  bulkFileInfoUpdate,
  refreshImageHash,
  deleteAreaImageAndReferences,
  deleteImageAndReferences,
  getAllMarkingReferences,
  refreshSchemaGroup,
})(ImageMarking);
