import * as React from 'react';

const SvgIconCheckmarkAlt = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    {...props}
  >
    <path data-name='Rectangle 1295' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Icon feather-rotate-ccw'>
      <path
        data-name='Icon ionic-ios-checkmark-circle'
        d='M11.999 1a11 11 0 1 0 11 11 11 11 0 0 0-11-11Zm5.632 7.959-7.071 7.1h-.005a.955.955 0 0 1-.613.291.926.926 0 0 1-.619-.3l-2.962-2.961a.211.211 0 0 1 0-.3l.941-.941a.2.2 0 0 1 .3 0l2.348 2.348 6.452-6.5a.209.209 0 0 1 .148-.063.192.192 0 0 1 .148.063l.925.957a.208.208 0 0 1 .008.306Z'
      />
    </g>
  </svg>
);

export default SvgIconCheckmarkAlt;
