import * as React from "react"

const SvgComponent = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx={12} cy={12} r={12} fill="#f0f0f0" />
      <g fill="#d80027">
        <path
          d="M9.209 10.344H23.59A11.9 11.9 0 009.209.283v10.061zM6.106 10.344v-8.9A11.9 11.9 0 000 10.344h6.106zM6.106 13.447H0a11.9 11.9 0 006.106 8.9v-8.9zM9.209 13.447v10.061A11.9 11.9 0 0023.59 13.447H9.209z"
          transform="translate(.102 .134)"
        />
      </g>
    </svg>
  )
}

export default SvgComponent