import React from 'react';
import i18next from '../../i18n';
import { connect } from 'react-redux';
import { FormText } from 'reactstrap';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { isMobile } from 'react-device-detect';

// Actions

// Utils
import { SB_CONTROL_TYPE } from '../../redux/constants';

// Componenets
import TextInput from '../Custom/Forms/TextInput';
import KonvaEditor from './KonvaEditor';

const MarkingView = props => {
  const {
    areaImage,
    disabled,
    control,
    schema,
    saveMarkings,
    saveReferences,
    currentImage,
    galleryNavigate,
    onChangeFileInfo,
  } = props;
  const allowedTypes = [
    SB_CONTROL_TYPE.PhotoDocumentation,
    SB_CONTROL_TYPE.TextPhotos,
    SB_CONTROL_TYPE.DropdownCommentPhotos,
  ];
  const prefixId = schema.rawControlItems
    .filter(x => allowedTypes.includes(x.controlType))
    .findIndex(x => x.id === control.id);

  const renderLeft = () => {
    if (!currentImage) {
      return (
        <div className='no-image-active'>
          <FormText>{i18next.t(1233)}</FormText>
        </div>
      );
    }

    return renderImage();
  };

  const renderImage = () => {
    return (
      <div className='active-image'>
        <TextInput
          for={i18next.t(43)}
          placeholder={i18next.t(43)}
          name='fileDescription'
          onChange={onChangeFileInfo}
          value={props.fileDescription}
          disabled={disabled}
        />

        <div className='img'>
          <img className='preview' src={currentImage.fileUrl} alt='reference' />
          <div className='nav-prev' onClick={() => galleryNavigate(-1)}>
            <MdKeyboardArrowLeft size={30} />
          </div>
          <div className='nav-next' onClick={() => galleryNavigate(1)}>
            <MdKeyboardArrowRight size={30} />
          </div>
        </div>
      </div>
    );
  };

  const renderRight = () => {
    if (!areaImage) {
      return <div className='no-blueprint'>{i18next.t(99999)}</div>;
    }

    return renderKonvaEditor();
  };

  const renderKonvaEditor = () => {
    return (
      <div className='konva-view'>
        <KonvaEditor
          currentFile={areaImage}
          disabled={disabled}
          schema={schema}
          control={control}
          saveMarkings={saveMarkings}
          saveReferences={saveReferences}
          onSaveMarkings={props.onSaveMarkings}
          prefixId={prefixId}
          isSaving={props.isSaving}
        />
      </div>
    );
  };

  return (
    <div className='split-view'>
      {!isMobile && (
        <>
          <div className='left'>{renderLeft()}</div>
          <div className='split' />
        </>
      )}
      <div className='right'>{renderRight()}</div>
    </div>
  );
};

function mapStateToProps({ cases }) {
  return {
    gallery: cases.caseImages,
  };
}

export default connect(mapStateToProps, {})(MarkingView);
