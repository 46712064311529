import {
  SET_CASES_QUALITY_WORK,
  SET_CASES_WARM_WORK,
  SET_CASES_ALL,
  SET_CASE,
  SET_CASE_IMAGE_GALLERY,
  SET_SERVICE_AGREEMENT,
  SET_SERVICE_AGREEMENTS,
} from '../constants';

const initialState = {
  warmCases: [],
  qualityCases: [],
  cases: [],
  selectedCase: null,
  schemas: [],
  caseImages: [],
  serviceAgreements: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CASES_QUALITY_WORK:
      return {
        ...state,
        qualityCases: [...action.qualityCases],
      };
    case SET_CASES_WARM_WORK:
      return {
        ...state,
        warmCases: [...action.warmCases],
      };
    case SET_CASES_ALL:
      return {
        ...state,
        cases: [...action.cases],
      };
    case SET_SERVICE_AGREEMENTS:
      return {
        ...state,
        serviceAgreements: action.payload,
      };
    case SET_CASE:
    case SET_SERVICE_AGREEMENT:
      return {
        ...state,
        selectedCase: action.selectedCase,
      };
    case SET_CASE_IMAGE_GALLERY:
      return {
        ...state,
        caseImages: action.images,
      };
    default:
      return state;
  }
};
