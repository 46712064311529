import * as React from 'react';

const SvgIconProfil = props => (
  <svg
    data-name='Icon - profil'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Profil_svg__a'>
        <path
          data-name='Rectangle 1226'
          transform='translate(-.191 -.371)'
          d='M0 0h22v22H0z'
        />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1225' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 225'>
      <g
        data-name='Group 224'
        clipPath='url(#Icon_-_Profil_svg__a)'
        transform='translate(1.191 1.371)'
      >
        <path
          data-name='Path 275'
          d='M10.719 0a10.719 10.719 0 1 0 10.719 10.719A10.72 10.72 0 0 0 10.719 0M7.432 5.812A11.553 11.553 0 0 0 7.98 10a3.563 3.563 0 0 0 2.22 1.767 2.277 2.277 0 0 0 1.06 0A3.563 3.563 0 0 0 13.47 10a11.546 11.546 0 0 0 .536-4.192 3.054 3.054 0 0 0-3.287-2.835 3.054 3.054 0 0 0-3.287 2.835m3.287-4.288a9.2 9.2 0 0 1 6.539 15.662v-1.663c0-1.274-1.167-2.215-2.322-2.739l-1.275-.58a4.253 4.253 0 0 1-5.86 0l-1.274.584C5.36 13.3 4.2 14.245 4.2 15.519v1.667a9.2 9.2 0 0 1 6.515-15.662'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconProfil;
