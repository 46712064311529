import React from 'react';
import { FormGroup, Col, Label } from 'reactstrap';

const FormGroupRow = props => {
  return (
    <FormGroup row>
      <Label sm={props.labelSize} for={props.for} size={props.size}>
        {props.for}
      </Label>
      <Col sm={props.inputSize}>
        {props.input}
        {props.error ? (
          <p style={{ color: 'red' }}>{props.error ? props.errorText : null}</p>
        ) : null}
      </Col>
    </FormGroup>
  );
};

export default FormGroupRow;
