import React, { useState } from 'react';
import i18next from '../i18n';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';

// Utils
import { StringIsEmpty } from '../utils/common';

// Components
import TextInput from '../components/Custom/Forms/TextInput';
import TaghaandbogenLogo from './../assets/img/logo/method-draw-image.svg';

const ResetPasswordForm = props => {
  const { showLogo, children, resetFailed, errorText } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [missingPassword, setMissingPassword] = useState(false);
  const [passwordsNotMatching, setPasswordsNotMatching] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();

    let emptyPassword = StringIsEmpty(password);
    let emptyConfirmPassword = StringIsEmpty(confirmPassword);

    if (!emptyPassword)
      emptyPassword = StringIsEmpty(password.replace(' ', ''));
    if (!emptyConfirmPassword)
      emptyConfirmPassword = StringIsEmpty(confirmPassword.replace(' ', ''));

    if (emptyPassword || emptyConfirmPassword) {
      setMissingPassword(emptyPassword);
      setPassword('');
    } else {
      if (password === confirmPassword) {
        props.resetpassword(password);
      } else {
        setPasswordsNotMatching(true);
      }
    }
  };

  const onChange = e => {
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPassword(e.target.value);
    }

    setMissingPassword(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {showLogo && (
          <div className='text-left pb-4'>
            <img
              src={TaghaandbogenLogo}
              className='authform_logo'
              alt='Tagpas'
            />
          </div>
        )}

        <TextInput
          for={i18next.t(3249)}
          placeholder={i18next.t(24)}
          name='password'
          type='password'
          onChange={onChange}
          error={missingPassword}
          errorText={i18next.t(85)}
          value={password}
        />

        <TextInput
          for={i18next.t(3252)}
          placeholder={i18next.t(24)}
          name='confirmPassword'
          type='password'
          onChange={onChange}
          error={missingPassword || passwordsNotMatching}
          errorText={
            missingPassword
              ? i18next.t(85)
              : passwordsNotMatching
              ? i18next.t(3255)
              : i18next.t(142)
          }
          value={confirmPassword}
        />

        {resetFailed ? errorText : null}
        <hr />
        <Button size='lg' block color='primary' type='submit'>
          {i18next.t(3247)}
        </Button>

        {children}
      </Form>
    </>
  );
};

ResetPasswordForm.propTypes = {
  showLogo: PropTypes.bool,
  passwordLabel: PropTypes.string,
};

ResetPasswordForm.defaultProps = {
  showLogo: true,
};

export default ResetPasswordForm;
