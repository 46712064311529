/**
 * Contains functions whose purpose is to emit/create a Toast popup
 * @module utils/toast
 */
import { toast } from 'react-toastify';

// Toast length values
export const TOAST_LENGTH = {
  DEFAULT: 3000,
  LONG: 7500,
  SHORT: 2500,
};

// Default settings
const settings = {
  position: 'bottom-right',
  autoClose: TOAST_LENGTH.DEFAULT,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

//////////////////////////////////////////////////////////////////
/**
 * Displays a green success popup.
 * @param {String} text text value
 */
function success(text) {
  toast.success(text, settings);
}

/**
 * Displays a short lasting green success popup.
 * @param {String} text text value
 */
function successShort(text) {
  toast.success(text, { ...settings, autoClose: TOAST_LENGTH.SHORT });
}

/**
 * Displays a long lasting green success popup.
 * @param {String} text text value
 */
function successLong(text) {
  toast.success(text, { ...settings, autoClose: TOAST_LENGTH.LONG });
}

//////////////////////////////////////////////////////////////////
/**
 * Displays a blue info popup.
 * @param {String} text text value
 */
function info(text) {
  toast.info(text, settings);
}

/**
 * Displays a short lasting blue info popup.
 * @param {String} text text value
 */
function infoShort(text) {
  toast.info(text, { ...settings, autoClose: TOAST_LENGTH.SHORT });
}

/**
 * Displays a long lasting blue info popup.
 * @param {String} text text value
 */
function infoLong(text) {
  toast.info(text, { ...settings, autoClose: TOAST_LENGTH.LONG });
}

//////////////////////////////////////////////////////////////////
/**
 * Displays a yellow warning popup.
 * @param {String} text text value
 */
function warning(text) {
  toast.warning(text, settings);
}

/**
 * Displays a short lasting yellow warning popup.
 * @param {String} text text value
 */
function warningShort(text) {
  toast.warning(text, { ...settings, autoClose: TOAST_LENGTH.SHORT });
}

/**
 * Displays a long lasting yellow warning popup.
 * @param {String} text text value
 */
function warningLong(text) {
  toast.warning(text, { ...settings, autoClose: TOAST_LENGTH.LONG });
}

//////////////////////////////////////////////////////////////////
/**
 * Displays a red error popup.
 * @param {String} text text value
 */
function error(text) {
  toast.error(text, settings);
}

/**
 * Displays a short lasting red error popup.
 * @param {String} text text value
 */
function errorShort(text) {
  toast.error(text, { ...settings, autoClose: TOAST_LENGTH.SHORT });
}

/**
 * Displays a long red error popup.
 * @param {String} text text value
 */
function errorLong(text) {
  toast.error(text, { ...settings, autoClose: TOAST_LENGTH.LONG });
}

//////////////////////////////////////////////////////////////////
export default {
  // default
  success,
  info,
  warning,
  error,
  // short
  successShort,
  infoShort,
  warningShort,
  errorShort,
  // long
  successLong,
  infoLong,
  warningLong,
  errorLong,
};
