/**
 * @module actions/emploees
 */
import axios from 'axios';
import { API_URL } from '../constants';

/**
 * @description Get contacts associated with a user
 * @return {Promise} Axios promise
 */
export function getContacts(data) {
  let url = API_URL + `/contacts/get`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Add contact to a user
 * @return {Promise} Axios promise
 */
export function addContact(data) {
  let url = API_URL + `/contacts/create`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description update contact on a user
 * @return {Promise} Axios promise
 */
export function updateContact(data) {
  let url = API_URL + `/contacts/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}
/**
 * @description delete contact on a user
 * @return {Promise} Axios promise
 */
export function deleteContact(contactId) {
  let url = API_URL + `/contacts/delete/` + contactId;
  return dispatch => {
    return axios.post(url);
  };
}
