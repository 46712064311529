import React, { useState } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Col, Row } from 'reactstrap';

// Utils
import { getUserData } from '../../utils/userUtil';
import { hasMinAccessLevel } from '../../utils/contentByAccessLevel';
import { formatSizeUnits } from '../../utils/formatSizeUnits';
import { isValidFolderName } from '../../utils/common';

// Actions
import { uploadDocumentFiles } from '../../redux/actions/documents';

// Components
import FileOrFolderInput from './Forms/FileOrFolderInput';
import FileInput from './Forms/FileInput';
import ErrorMsg from '../ErrorMsg';

const DocumentsUploadModal = props => {
  const {
    currentFolder,
    currentFolderParentId,
    //documents,
    filledCapacity,
    fullCapacity,
  } = props;
  const user = getUserData(props.auth);
  let authTypeCheck =
    props.type === 1 ? hasMinAccessLevel(props.auth, 1) : user.hasAP;

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [currentParentId] = useState(currentFolder ? currentFolder.id : 0);
  const [customerId] = useState(user.customerId);
  const [canEdit] = useState(authTypeCheck);
  const [folderUploaded] = useState(false);
  const [fileUploaded] = useState(false);
  //fullCapacity: 2097152,

  const onDropAccepted = files => {
    let tempCustomerId = 0;
    if (customerId > 0) {
      tempCustomerId = customerId;
    }

    // remove .DS_Store mac invisible files
    files = files.filter(file => {
      let name = file.name.slice(((file.name.lastIndexOf('/') - 1) >>> 0) + 2);
      if (file.webkitRelativePath !== '') {
        name = file.webkitRelativePath.slice(
          ((file.webkitRelativePath.lastIndexOf('/') - 1) >>> 0) + 2,
        );
      }

      // console.log(file, name, !/^.\w*$/.test(name));
      return !/^.\w*$/.test(name);
    });

    setLoading(true);
    if (!checkForBadData(files)) {
      //console.log('aftercheck', files);
      props
        .uploadDocumentFiles(files, tempCustomerId, currentParentId)
        .then(res => {
          setLoading(false);
          props.handleRefresh();
          props.toggle();
        })
        .catch(err => {
          console.error(err.response);
          const errorMsgs = err.response && err.response.data;
          setLoading(false);
          setHasErrors(true);
          setErrorText(errorMsgs);
        });
    } else {
      setLoading(false);
    }
  };

  const checkForBadData = files => {
    setHasErrors(false);
    setErrorText('');

    let invalidInput,
      nameExists,
      pathToolong,
      badNames,
      fileSizeError,
      missingFiles,
      hasErrors = false;

    let errorText = '';

    // Check for valid file types
    const allowed_file_types = [
      '.png',
      '.jpg',
      '.jpeg',
      '.pdf',
      '.dng',
      '.heic',
      '.doc',
      '.txt',
      '.xls',
      '.xlsx',
      '.csv',
      '.log',
      '.docx',

      // New file types
      '.odt',
      '.ppt',
      '.pptx',
      '.mp4',
      '.mov',
      '.pages',
      '.numbers',
      '.key',
      '.rtf',
      '.dwg',
      '.msg',
      '.eml',
      '.ods',
    ];

    let errorTypeFiles = [];

    files.map(file => {
      if (file.name.includes('.')) {
        let extension = file.name.slice(
          ((file.name.lastIndexOf('.') - 1) >>> 0) + 2,
        );
        if (extension) {
          extension = extension.toLowerCase();
        }
        if (
          allowed_file_types.filter(i => i === '.' + extension).length === 0
        ) {
          errorTypeFiles.push(file);
        }
      }
      return errorTypeFiles;
    });

    if (errorTypeFiles.length > 0) {
      //console.log('file type error', errorTypeFiles);
      invalidInput = true;
      errorText =
        i18next.t(7113) +
        errorTypeFiles.map(i => {
          return i.name + ', ';
        }) +
        i18next.t(7114);
    }

    // Check for path too long
    let errorPathFiles = [];
    let errorPathNames = [];

    files.forEach(file => {
      let pathNormal = '/' + customerId + '/' + file.name;
      if (file.webkitRelativePath !== '') {
        pathNormal = '/' + customerId + '/' + file.webkitRelativePath;
        if (currentFolder != null) {
          pathNormal = currentFolder.path + '/' + file.webkitRelativePath;
        }
        if (currentFolderParentId === 0) {
          pathNormal = '/' + customerId + '/' + file.webkitRelativePath;
          if (customerId <= 0) {
            pathNormal = '/0/' + file.webkitRelativePath;
          }
        }
      } else {
        pathNormal = '/' + customerId + '/' + file.name;
        if (currentFolder != null) {
          pathNormal = currentFolder.path + '/' + file.name;
        }
        if (currentFolderParentId === 0) {
          pathNormal = '/' + customerId + '/' + file.name;
          if (customerId <= 0) {
            pathNormal = '/0/' + file.name;
          }
        }
      }

      if (pathNormal.length > 350) {
        errorPathFiles.push(file);
      }

      const splitted = pathNormal.split('/').slice(1);
      // console.log(pathNormal, splitted);
      for (let i = 0; i < splitted.length; i++) {
        const name = splitted[i].trim();
        if (!isValidFolderName(name + '')) {
          errorPathNames.push(pathNormal);
          break;
        }
      }
    });

    if (errorPathFiles.length > 0) {
      //console.log('file path error', errorPathFiles);
      pathToolong = true;
      errorText =
        i18next.t(7112) +
        errorPathFiles.map(i => {
          return i.name + ', ';
        });
    }

    if (errorPathNames.length > 0) {
      console.log('file name error', errorPathNames);
      badNames = true;
      errorText =
        i18next.t(7163) +
        '\n' +
        errorPathNames.map(i => {
          return i + '\n';
        });
    }

    // Check if import is too big
    let importSize = 0;
    files.map(file => {
      importSize = importSize + file.size;
      return importSize;
    });

    if (filledCapacity + importSize > fullCapacity) {
      fileSizeError = true;
      errorText = i18next.t(7117) + ' ' + formatSizeUnits(importSize);
    }

    // Check if there are any files.
    if (files.length === 0) {
      missingFiles = true;
      errorText = i18next.t(8006);
    }

    if (
      pathToolong ||
      badNames ||
      invalidInput ||
      nameExists ||
      fileSizeError ||
      missingFiles
    ) {
      hasErrors = true;

      setHasErrors(true);
      setErrorText(errorText);
    }
    return hasErrors;
  };

  // console.log(currentParentId);
  // const number = 50;

  return (
    <div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        fade={false}
        size='lg'
        centered
      >
        <ModalHeader toggle={props.toggle}>{i18next.t(7074)}</ModalHeader>

        <ModalBody>
          <Row>
            <Col>{/* <div>File 1/1</div> */}</Col>
          </Row>
          <Row>
            <Col>
              {/* <div id="upload-bar">Progress bar
                  <div id="Progress"></div>
                    <div> {number} % </div>
                  </div> */}
            </Col>
          </Row>
          {canEdit && (
            <Row>
              <Col>
                <FileInput
                  name='datasheets'
                  isUploading={loading}
                  accept='application/pdf'
                  labelText={i18next.t(7090)}
                  onDropAccepted={onDropAccepted}
                  disabled={folderUploaded}
                />
              </Col>
              <Col>
                <FileOrFolderInput
                  name='datasheets'
                  isUploading={loading}
                  accept='application/pdf'
                  labelText={i18next.t(7091)}
                  onDropAccepted={onDropAccepted}
                  disabled={fileUploaded}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              {hasErrors && (
                <ErrorMsg msg={errorText ? errorText : i18next.t(126)} />
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

DocumentsUploadModal.propTypes = {
  currentFolderParentId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    currentFolder: state.documents.currentFolder,
  };
}

export default connect(mapStateToProps, {
  uploadDocumentFiles,
})(DocumentsUploadModal);
