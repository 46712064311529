import React, { useState } from 'react';
import i18next from '../i18n';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';

// Utils
import { StringIsEmpty } from '../utils/common';

// Components
import TextInput from '../components/Custom/Forms/TextInput';
import TaghaandbogenLogo from './../assets/img/logo/method-draw-image.svg';

const AuthForm = props => {
  const {
    showLogo,
    children,
    loginFailed,
    remember,
  } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [missingUsername, setMissingUsername] = useState(false);
  const [missingPassword, setMissingPassword] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();

    let emptyUsername = StringIsEmpty(username);
    let emptyPassword = StringIsEmpty(password);

    if (!emptyUsername)
      emptyUsername = StringIsEmpty(username.replace(' ', ''));
    if (!emptyPassword)
      emptyPassword = StringIsEmpty(password.replace(' ', ''));

    if (emptyUsername || emptyPassword) {
      setUsername('');
      setPassword('');
      setMissingUsername(emptyUsername);
      setMissingPassword(emptyPassword);
    } else {
      props.login(username, password);
    }
  };

  const onChange = e => {
    if (e.target.name === 'username') {
      setUsername(e.target.value);
    } else if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
    setMissingUsername(false);
    setMissingPassword(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {showLogo && (
          <div className='text-left pb-4'>
            <img
              src={TaghaandbogenLogo}
              className='authform_logo'
              alt='Tagpas'
            />
          </div>
        )}

        <TextInput
          for={i18next.t(25)}
          placeholder={i18next.t(25)}
          name='username'
          onChange={onChange}
          error={missingUsername}
          errorText={i18next.t(80)}
          value={username}
          autoComplete={remember ? 'current-password' : 'new-password'}
        />

        <TextInput
          for={i18next.t(24)}
          placeholder={i18next.t(24)}
          name='password'
          type='password'
          onChange={onChange}
          error={missingPassword}
          errorText={i18next.t(85)}
          value={password}
          autoComplete={remember ? 'current-password' : 'new-password'}
        />

        <hr />

        {props.isDev ? (
          <div>
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              {i18next.t(180)}
            </p>
            <hr />
          </div>
        ) : null}

        {loginFailed ? <p style={{ color: 'red' }}>{props.errorText}</p> : null}
        <Button size='lg' block color='primary' type='submit'>
          {i18next.t(11)}
        </Button>

        {children}
      </Form>
    </>
  );
};

AuthForm.propTypes = {
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  passwordLabel: PropTypes.string,
};

AuthForm.defaultProps = {
  showLogo: true,
};

export default AuthForm;
