import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Label } from 'reactstrap';
import moment from 'moment';

// Actions

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import InfoTip from '../../Custom/InfoTip';
import ReactDatePicker from '../../Custom/ReactDatePicker';
import LastEditor from '../../Custom/LastEditor';
import ControlItemMetaAdminModal from '../ControlItemMetaAdminModal';

const ControlItemDateSelector = props => {
  const { control, disabled, areControlsValid, refreshGroups } = props;
  const {
    title,
    alternativeTitle,
    dateValue,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    placeholder,
    errorMessage,
    dateFixed,
  } = control;
  const [valid, setValid] = useState(true);
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [dateValue]);

  const onChange = value => {
    props.onChangeDate('dateValue', value);
  };

  const onChangeDate = date => {
    let val = date ? new Date(date.setHours(12)) : null;

    onChange(val);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  const renderDateSelector = () => {
    let value = dateValue ? moment(dateValue).toDate() : null;

    let dis = disabled;
    if (dateFixed && !dateValue) {
      dis = dateFixed;
      value = new Date(new Date().setHours(12));
    }

    const defaultProps = {
      className: 'form-control',
      locale: 'da',
      value,
      format: 'dd.MM.yyyy',
      showYearDropdown: true,
      showMonthDropdown: true,
      disabled: dis,
    };

    return (
      <ReactDatePicker
        {...defaultProps}
        onChange={onChangeDate}
        placeholder={placeholder}
      />
    );
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <FormGroup row>
      <Label sm={labelSize}>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor {...control} />
      </Label>
      <Col sm={inputSize}>
        {renderDateSelector()}
        {!disabled && (
          <ControlItemMetaAdminModal
            control={control}
            callback={refreshGroups}
          />
        )}
      </Col>
    </FormGroup>
  );
};

function mapStateToProps({ auth, schemas, cases }) {
  return {
    auth,
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {})(ControlItemDateSelector);
