import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Table,
  Container,
} from 'reactstrap';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';

// Actions
import {
  PostApproval,
  SignApprovals,
  PostTokenlessApproval,
} from './../../../redux/actions/cases';
import { GetHotworkById } from '../../../redux/actions/hotwork';

// Utils
import { parseDate, splitIntoBreakpoints } from '../../../utils/common';
import { getAssigneeTitleFrom } from '../../../utils/common';
import { ASSIGNEE_TYPE, ASSIGNMENT_SCHEMA } from '../../../redux/constants';
import { getUserData } from '../../../utils/userUtil';

// Components
import BuildingownerApproval from './Approvals/BuildingownerApproval';
import PageSpinner from '../../../components/PageSpinner';
import Signature from '../../../components/Custom/Signature';
import InfoModal from '../../../components/Custom/InfoModal';
import Typography from '../../../components/Typography';

const Approval = props => {
  const {
    auth,
    location,
    history,
    match,
    GetHotworkById,
    PostApproval,
    SignApprovals,
    PostTokenlessApproval,
    caseInfo,
    userSignedInApproval,
    tokenView,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [allApproved, setAllApproved] = useState(false);
  const [showApprovals, setShowApprovals] = useState(false);
  const [showSigningReason, setShowSigningReason] = useState(false);
  const [approvals, setApprovals] = useState([]);
  const [caseId, setCaseId] = useState(-1);
  const [hasSigned, setHasSigned] = useState(false);
  const [signedData, setSignedData] = useState(null);
  const [signatureToken, setSignatureToken] = useState('');
  const [allMarked, setAllMarked] = useState(false);
  const [assignee, setAssignee] = useState(null);
  const [assigneeType, setAssigneeType] = useState(-1);
  const [declaration, setDeclaration] = useState(false);
  const [aggreementComment, setAgreementComment] = useState('');
  const [schemaType, setSchemaType] = useState(1);
  const user = getUserData(auth);
  const headerTypes = [2, 3, 5];

  useEffect(() => {
    const query = queryString.parse(location.search);

    let signatureToken = query.token;
    if (userSignedInApproval) {
      if (!auth.isAuthenticated) {
        history.push('/login');
      }

      const id = match.params.id;

      GetHotworkById(id).then(res => {
        const found = res.case.assignees.find(x => x.user.id === user.id);
        if (found) {
          handleApprovalInitialization(
            res.case,
            found.asigneeType,
            user.id,
            '',
          );
        } else {
          history.push('/');
        }
      });
    } else if (signatureToken) {
      GetHotworkById(0, signatureToken).then(res => {
        handleApprovalInitialization(
          res.case,
          res.assigneeType,
          res.userId,
          signatureToken,
        );
      });
    }

    // eslint-disable-next-line
  }, []);

  const handleApprovalInitialization = (
    _case,
    assigneeType,
    userId,
    signToken,
  ) => {
    let sigData = {};

    if (assigneeType === ASSIGNEE_TYPE.ExecutiveResponsible)
      assigneeType = ASSIGNEE_TYPE.ExecutivePerson;

    const sigState = getAssigneeInfoSignature(
      _case.signatureApprovals,
      assigneeType,
    );

    if (sigState) {
      if (sigState.signed) {
        sigData.hasSigned = true;
        sigData.signData = sigState.signatureData;
        sigData.signDate = parseDate(sigState.signDate);
      }

      if (sigState.userData) {
        sigData.userData = sigState.userData;
      }
    }

    const comment = _case.agreementComments.find(
      x => x.caseAssigneeType === assigneeType,
    );

    const questions = getAssigneeQuestionsByType(assigneeType);
    questions.sort((a, b) => {
      return a.number < b.number ? -1 : 1;
    });

    const arr = questions.map(q => q.status);
    const preApproved = questionsAreAlreadyApproved(arr);

    setCaseId(_case.id);
    setSignatureToken(signToken);
    setAssignee(userId);
    setApprovals(arr);
    setAllApproved(assigneeType === 5 ? true : preApproved);
    setAssigneeType(assigneeType);
    setSchemaType(_case.schemaType);
    setAgreementComment(comment ? comment.comment : '');
    setHasSigned(sigData.hasSigned);
    setSignedData(sigData.signData);
    setLoading(false);
  };

  const is2021Version = () => {
    return [
      ASSIGNMENT_SCHEMA.WarmWorkStandard_2021,
      ASSIGNMENT_SCHEMA.WarmWorkStandardDBI_2021,
      ASSIGNMENT_SCHEMA.QBE_2022,
      ASSIGNMENT_SCHEMA.TRUST_2023,
    ].includes(schemaType);
  };

  const getAssigneeInfoSignature = (sigs, type) => {
    const res = sigs.filter(item => item.assigneeType === type);

    if (res.length > 0) return res[0];

    return null;
  };

  const onChangeComment = e => {
    setAgreementComment(e.target.value);
  };

  const onChangeChecked = e => {
    const nameSplit = e.target.name.split('_');

    approvals[parseInt(nameSplit[1]) - 1] = e.target.checked;

    const approvalObj = {
      Status: e.target.checked,
      Number: nameSplit[1],
      CaseId: caseId,
      Type: assigneeType,
      IsArray: false,
      SignatureToken: signatureToken,
      Id: caseInfo.case.userId,
    };

    handleSendApproval(approvalObj, approvals);
  };

  const handleApproveButton = e => {
    const commentObj = {
      IsComment: true,
      Comment: aggreementComment,
      CaseId: caseId,
      Type: assigneeType,
      SignatureToken: signatureToken,
      Id: caseInfo.case.userId,
    };

    PostApproval(commentObj)
      .then(() => {
        setDeclaration(true);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleMarkAll = e => {
    const tmp = approvals.map(item => (item = true));

    setApprovals(tmp);
    setAllMarked(e.target.checked);
  };

  const handleSetDeclaration = () => {
    setDeclaration(!declaration);
  };

  const handleApproveAll = e => {
    let approvalObjs = [],
      approv = [...approvals];

    for (let i = 0; i < approvals.length; i++) {
      approvalObjs.push({
        Status: true,
        Number: i + 1,
        CaseId: caseId,
        Type: assigneeType,
      });

      approv[i] = true;
    }

    const commentObj = {
      IsComment: true,
      Comment: aggreementComment,
      CaseId: caseId,
      Type: assigneeType,
      SignatureToken: signatureToken,
    };

    approvalObjs.push(commentObj);

    const approvalObj = {
      IsArray: true,
      Approvals: approvalObjs,
      CaseId: caseId,
      SignatureToken: signatureToken,
      Id: assignee,
    };

    handleSendApproval(approvalObj, approv);
  };

  const handleApproveSingle = (e, number) => {
    let approv = [...approvals];
    approv[number - 1] = !approv[number - 1];

    const approvalObj = {
      Status: approv[number - 1],
      Number: number,
      CaseId: caseId,
      Type: assigneeType,
      SignatureToken: signatureToken,
      Id: assignee,
    };

    handleSendApproval(approvalObj, approv);
  };

  const handleSendApproval = (data, appro) => {
    let action = PostApproval;

    if (userSignedInApproval) {
      action = PostTokenlessApproval;
    }

    action(data)
      .then(() => {
        const all = questionsAreAlreadyApproved(appro);

        setApprovals(appro);
        setAllApproved(all);
        setDeclaration(true);
      })
      .catch(err => console.log(err));
  };

  const handleSignApprovals = sigData => {
    if (assignee > 0) {
      const sigObj = {
        Signature: sigData,
        CaseId: caseId,
        UserId: assignee,
        AssigneeType: assigneeType,
        SignatureToken: signatureToken,
      };

      setLoading(true);
      SignApprovals(sigObj, signatureToken)
        .then(res => {
          const { data } = res;

          if (data.signed) {
            window.location.reload();
          } else {
            setLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const getAssigneeQuestionsByType = userType => {
    if (userType === ASSIGNEE_TYPE.Subcontractor)
      userType = ASSIGNEE_TYPE.ExecutivePerson;
    if (userType === ASSIGNEE_TYPE.RoofingContractor)
      userType = ASSIGNEE_TYPE.BuildingOwner;
    if (userType === ASSIGNEE_TYPE.ExecutiveResponsible)
      userType = ASSIGNEE_TYPE.ExecutivePerson;

    if (!caseInfo) return [];
    const questions = caseInfo.case.agreementApprovals.filter(
      ag => ag.type === userType,
    );

    return questions;
  };

  const questionsAreAlreadyApproved = questions => {
    let allApproved = true;
    for (let i = 0; i < questions.length; i++) {
      if (!questions[i]) {
        allApproved = false;
        break;
      }
    }

    return allApproved;
  };

  const getQuestions = () => {
    const questionsBuildingOwner = caseInfo.case.agreementApprovals.filter(
      ag => ag.type === ASSIGNEE_TYPE.BuildingOwner,
    );
    const questionsFireWatch = caseInfo.case.agreementApprovals.filter(
      ag => ag.type === ASSIGNEE_TYPE.Firewatch,
    );
    const questionsExecutivePerson = caseInfo.case.agreementApprovals.filter(
      ag => ag.type === ASSIGNEE_TYPE.ExecutivePerson,
    );

    return [
      ...questionsBuildingOwner,
      ...questionsExecutivePerson,
      ...questionsFireWatch,
    ];
  };

  const approvalPageSwitch = () => {
    const questions = getQuestions();

    if (!declaration && !hasSigned && questions.length > 0) {
      return (
        <BuildingownerApproval
          props={props}
          questions={questions}
          approvals={approvals}
          schema={schemaType}
          onChangeChecked={onChangeChecked}
          onChangeComment={onChangeComment}
          aggreementComment={aggreementComment}
          handleApproveAll={handleApproveAll}
          handleApproveSingle={handleApproveSingle}
          hasSigned={hasSigned}
          allApproved={allApproved}
          allMarked={allMarked}
          handleMarkAll={handleMarkAll}
          handleApproveButton={handleApproveButton}
          declaration={declaration}
          handleSetDeclaration={handleSetDeclaration}
        />
      );
    } else {
      const cvsProps = {
        height: window.innerHeight * 0.5,
        className: 'signature',
      };

      return (
        <Signature
          handleSignApprovals={handleSignApprovals}
          hasSigned={hasSigned}
          signData={signedData}
          cvsProps={cvsProps}
          userSignedInApproval={userSignedInApproval}
          goBackToCase={() => history.goBack()}
        />
      );
    }
  };

  const handleShowApprovals = value => {
    setShowApprovals(value);
  };

  const handleShowSigningReasons = value => {
    setShowSigningReason(value);
  };

  const getApprovalsTitle = schemaType => {
    switch (schemaType) {
      case ASSIGNMENT_SCHEMA.QBE_2022:
      case ASSIGNMENT_SCHEMA.TRUST_2023:
      case ASSIGNMENT_SCHEMA.WarmWorkStandardDBI_2021:
      case ASSIGNMENT_SCHEMA.WarmWorkStandard_2021:
        return i18next.t(1343);
      default:
        return i18next.t(1080);
    }
  };

  const getHeaderContent = () => {
    let content = null;

    if (!declaration && !hasSigned && assigneeType !== 5) {
      content = (
        <div className={is2021Version() ? 'approval-header' : ''}>
          <Typography type='h4' className='float-left'>
            {getApprovalsTitle(schemaType)}
          </Typography>
          {is2021Version() && !tokenView && (
            <Button
              color='secondary'
              outline
              onClick={() => history.goBack()}
              className={'float-right'}
            >
              {i18next.t(1089)}
            </Button>
          )}
        </div>
      );
    } else if (
      (declaration || hasSigned || assigneeType === 5) &&
      headerTypes.includes(assigneeType) &&
      !is2021Version()
    ) {
      let title = '';
      switch (schemaType) {
        case ASSIGNMENT_SCHEMA.QBE_2022:
        case ASSIGNMENT_SCHEMA.TRUST_2023:
        case ASSIGNMENT_SCHEMA.WarmWorkStandardDBI_2021:
        case ASSIGNMENT_SCHEMA.WarmWorkStandard_2021:
          title = i18next.t(1343);
          break;
        default:
          title = i18next.t(1087);
          break;
      }

      content = (
        <div className={'approval-header centered'}>
          <Button
            color='btnSecondary'
            outline
            block
            onClick={() => handleShowApprovals(!showApprovals)}
            key='showApprovalsBtn'
          >
            {title}
          </Button>
        </div>
      );
    }

    if (!content) return null;

    return (
      <CardHeader className='approval-header-wrapper'>
        <Row>
          <Col className='align-self-center'>{content}</Col>
        </Row>
      </CardHeader>
    );
  };

  const getCaseInformation = () => {
    const _case = caseInfo.case;
    const customer = caseInfo.customer;

    const execResp = _case.assignees.find(
      x => x.asigneeType === ASSIGNEE_TYPE.ExecutiveResponsible,
    );

    return (
      <Container
        fluid
        className={'approval-info-container ' + (isMobile ? 'mobile' : '')}
      >
        <Row>
          {isMobile && (
            <>
              <Col>
                {simpleTable(
                  [
                    `${i18next.t(1011)}`,
                    `${i18next.t(1012)}`,
                    `${i18next.t(110)}`,
                    `${i18next.t(111)}`,
                    `${i18next.t(30)}`,
                    `${i18next.t(25)}`,
                    `${i18next.t(27)}`,
                    `${i18next.t(130)}`,
                  ],
                  [
                    `${_case.workplaceName}`,
                    `${_case.workplaceAddress}, ${_case.workplacePostalCode}, ${_case.workplaceCity}`,
                    `${parseDate(_case.startDate)}`,
                    `${parseDate(_case.endDate)}`,
                    `${customer.name}`,
                    `${execResp.user.mail}`,
                    `${execResp.user.countryCode} ${execResp.user.mobile}`,
                    getAssigneeTitleFrom(assigneeType, schemaType),
                  ],
                )}
              </Col>
            </>
          )}
          {!isMobile && (
            <>
              <Col>
                {simpleTable(
                  [
                    `${i18next.t(1011)}`,
                    `${i18next.t(1012)}`,
                    `${i18next.t(110)}`,
                    `${i18next.t(111)}`,
                  ],
                  [
                    `${_case.workplaceName}`,
                    `${_case.workplaceAddress}, ${_case.workplacePostalCode}, ${_case.workplaceCity}`,
                    `${parseDate(_case.startDate)}`,
                    `${parseDate(_case.endDate)}`,
                  ],
                )}
              </Col>
              <Col>
                {simpleTable(
                  [
                    `${i18next.t(30)}`,
                    `${i18next.t(25)}`,
                    `${i18next.t(27)}`,
                    `${i18next.t(130)}`,
                  ],
                  [
                    `${customer.name}`,
                    `${execResp.user.mail}`,
                    `${execResp.user.countryCode} ${execResp.user.mobile}`,
                    getAssigneeTitleFrom(assigneeType, schemaType),
                  ],
                )}
              </Col>
            </>
          )}
        </Row>
      </Container>
    );
  };

  const simpleTable = (names, values) => {
    return (
      <Table borderless size='sm' className='approval-info-tabel'>
        <tbody>
          {names.map((name, index) => {
            return (
              <tr key={`${name}`}>
                <th scope='row'>{name}:</th>
                <td>{values[index]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderHeaderTitle = () => {
    const getTitle = () => {
      switch (schemaType) {
        case ASSIGNMENT_SCHEMA.QBE_2022:
        case ASSIGNMENT_SCHEMA.TRUST_2023:
        case ASSIGNMENT_SCHEMA.WarmWorkStandardDBI_2021:
        case ASSIGNMENT_SCHEMA.WarmWorkStandard_2021:
          return i18next.t(8114);
        default:
          return i18next.t(5000);
      }
    };

    let buttonText = i18next.t(5015);
    switch (schemaType) {
      case ASSIGNMENT_SCHEMA.WarmWorkStandard:
      case ASSIGNMENT_SCHEMA.WarmWorkStandardDBI:
        buttonText = i18next.t(5005);
        break;
      default:
        buttonText = i18next.t(5015);
        break;
    }

    return (
      <div>
        <Typography
          type={is2021Version() ? 'h5' : 'h1'}
          className='text-center'
        >
          {getTitle()}
        </Typography>
        <Button
          block
          outline
          color='btnSecondary'
          size='sm'
          onClick={() => handleShowSigningReasons(!showSigningReason)}
        >
          {buttonText}
        </Button>
      </div>
    );
  };

  const renderInfoModal = () => {
    const is2021 = is2021Version();
    const headerText = is2021 ? i18next.t(5016) : i18next.t(5006);

    let content = null;
    if (is2021) {
      content = (
        <>
          <Typography type='h5'>{i18next.t(5001)}</Typography>
          <Typography>{i18next.t(5002)}</Typography>
          <Typography type='h5'>{i18next.t(5019)}</Typography>
          <Typography>{i18next.t(5020)}</Typography>
          <Typography>{i18next.t(5021)}</Typography>
          <Typography>{i18next.t(5022)}</Typography>
          <Typography type='h5'>{i18next.t(5023)}</Typography>
          <Typography>{i18next.t(5024)}</Typography>
          <Typography>{i18next.t(5025)}</Typography>
          <Typography>{i18next.t(5026)}</Typography>
          <Typography>{i18next.t(5027)}</Typography>
        </>
      );
    } else {
      content = (
        <>
          <Typography type='h5'>{i18next.t(5001)}</Typography>
          <Typography type='p'>{i18next.t(5002)}</Typography>
          <Typography type='p'>
            <strong>{i18next.t(5003)}</strong>
          </Typography>
          <div>{splitIntoBreakpoints(i18next.t(5004))}</div>
        </>
      );
    }

    return (
      <InfoModal
        show={showSigningReason}
        toggle={() => handleShowSigningReasons(!showSigningReason)}
        header={headerText}
        size='lg'
      >
        {content}
      </InfoModal>
    );
  };

  if (isLoading || !caseInfo) {
    return <PageSpinner />;
  }

  return (
    <div className='approval-page'>
      <div>
        <hr className='border-0' />
        <Card>
          <CardBody>{renderHeaderTitle()}</CardBody>
        </Card>
        <hr className='border-0' />
        <Card>
          <CardBody>{getCaseInformation()}</CardBody>
        </Card>
        <hr className='border-0' />
        <Card>
          {(headerTypes.includes(assigneeType) || is2021Version()) &&
            getHeaderContent()}
          <CardBody className='approval-body-wrapper'>
            {approvalPageSwitch()}
          </CardBody>
        </Card>
        <InfoModal
          show={showApprovals}
          toggle={() => handleShowApprovals(!showApprovals)}
          header={getApprovalsTitle()}
        >
          <BuildingownerApproval
            props={props}
            questions={getQuestions()}
            aggreementComment={aggreementComment}
            assigneeType={assigneeType}
            schema={schemaType}
            isInfo
          />
        </InfoModal>

        {renderInfoModal()}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    caseInfo: state.cases.selectedCase,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  GetHotworkById,
  PostApproval,
  SignApprovals,
  PostTokenlessApproval,
})(Approval);
