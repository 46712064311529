import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Table,
} from 'reactstrap';

// Icons
import {
  GlobalDocumentIcon,
  StandardDocumentIcon,
  CasesDocumentIcon,
} from './DocumentIcons';

// Actions
import {
  moveFolder,
  copyFolder,
  moveFile,
  copyFile,
  getFolders,
  getFolder,
} from '../../redux/actions/documents';

// Utils
import { getUserData } from '../../utils/userUtil';

// Components
import PageSpinner from '../PageSpinner';

// Constants
import { DOCUMENT_FOLDER_TYPE } from '../../redux/constants';
import { IoIosArrowForward } from 'react-icons/io';

const DocumentCopyMoveModal = props => {
  const { auth, modalData, isMove, isCopy, breadcrumbs } = props;

  const user = getUserData(auth);

  const startingParentId = modalData ? modalData.parentId : 0;

  const [breadcrumbPath, setBreadcrumbs] = useState(
    breadcrumbs ? breadcrumbs : [],
  );
  const data = {
    currentFolderParentId: startingParentId,
    previousFolderParentId: startingParentId,
    customerId: user.customerId,
    isLoading: false,
    isMove: isMove ? isMove : false,
    isCopy: isCopy ? isCopy : false,
    isSubmitting: false,
    targetParentId: startingParentId,
    documents: [],
    showReturn: false,
  };
  const [state, setState] = useState(data);

  const doc = {
    id: modalData ? modalData.id : 0,
    name: modalData ? modalData.name : null,
    path: modalData ? modalData.path : null,
    parentId: modalData ? modalData.parentId : 0,
    isFile: modalData ? modalData.isFile : null,
    isFolder: modalData ? modalData.isFolder : null,
  };
  const [document] = useState(doc);

  useEffect(() => {
    navigate(state.currentFolderParentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = (id, isReturn = false) => {
    const s = state;
    let tempCustomerId = s.customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }

    s.currentFolderParentId = id;
    s.targetParentId = id;
    s.showReturn = id !== 0 ? true : false;
    setState(s);
    props
      .getFolders(tempCustomerId, id)
      .then(resp => {
        s.isLoading = false;
        s.documents = resp.data;
        setState(s);
        setCurrentParentFolder(id, isReturn);
      })
      .catch(err => {
        console.log(err);
        s.isLoading = false;
      });
  };

  const setCurrentParentFolder = (id, isReturn = false) => {
    let tempCustomerId = state.customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }
    let parentFolder = {};
    if (id === 0) {
      parentFolder = {
        id: 0,
        name: i18next.t(7072),
        type: 1,
        last_edited: 'Aldrig',
        isFile: false,
        isFolder: true,
        parentId: 0,
        path: '/0',
      };
      setState({
        ...state,
        isLoading: false,
        currentParentFolder: parentFolder,
      });
      setCrumbs(parentFolder, isReturn, breadcrumbPath);
    } else {
      props
        .getFolder(tempCustomerId, id)
        .then(res => {
          //console.log('test', res, id);
          setCrumbs(res.data, isReturn, breadcrumbPath);
          setState({
            ...state,
            isLoading: false,
            currentParentFolder: res.data,
            previousFolderParentId: res.data.parentId,
          });
        })
        .catch(err => {
          console.log(err);
          setState({ ...state, isLoading: false });
        });
    }

    return parentFolder;
  };

  const setCrumbs = (currentFolder, isReturn, oldCrumbs) => {
    const fullPath = currentFolder.path.split('/').slice(1);
    let crumbs = [];
    oldCrumbs.forEach(x => {
      if (!crumbs.find(y => y.id === x.id)) {
        crumbs.push(x);
      }
    });

    if (crumbs.find(x => x.id === currentFolder.id)) {
      crumbs.length =
        crumbs.indexOf(crumbs.find(x => x.id === currentFolder.id)) + 1;
    }

    if (!isReturn && !crumbs.find(x => x.id === currentFolder.id)) {
      if (fullPath.length > 1) {
        let name = currentFolder.name;
        if (currentFolder.id === 0) {
          name = 'Hjem';
        }

        crumbs.push({
          ...currentFolder,
          path: ' > ' + name,
        });
      }
    }

    setBreadcrumbs(crumbs);
  };

  const goBack = () => {
    navigate(state.previousFolderParentId, true);
  };

  // const getFolderType = type => {
  //   let i18next.t = i18next.t(20);

  //   switch (type) {
  //     case DOCUMENT_FOLDER_TYPE.Global:
  //       i18next.t = i18next.t(7081);
  //       break;
  //     case DOCUMENT_FOLDER_TYPE.Cases:
  //       i18next.t = i18next.t(101);
  //       break;
  //     default:
  //       i18next.t = i18next.t(20);
  //       break;
  //   }
  //   return <React.Fragment>{i18next.t}</React.Fragment>;
  // };

  const folderIcons = i => {
    let out = StandardDocumentIcon;

    switch (i) {
      case DOCUMENT_FOLDER_TYPE.Global:
        out = GlobalDocumentIcon;
        break;
      case DOCUMENT_FOLDER_TYPE.Cases:
        out = CasesDocumentIcon;
        break;
      default:
        out = StandardDocumentIcon;
        break;
    }

    return out;
  };

  const copyItem = event => {
    event.preventDefault();
    const { targetParentId, customerId } = state;
    const { id } = document;
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }
    setState({ ...state, isSubmitting: true });
    // this.setState({ isSubmitting: true }, () => {
    if (modalData.isFile) {
      //console.log("Copy file", targetParentId);
      props
        .copyFile(tempCustomerId, id, targetParentId)
        .then(() => {
          setState({ ...state, isLoading: false });
          setTimeout(() => {
            props.handleRefresh();
            setState({ ...state, isSubmitting: false });
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          setState({ ...state, isLoading: false });
        });
    }
    if (modalData.isFolder) {
      //console.log("Copy folder", targetParentId);
      props
        .copyFolder(tempCustomerId, id, targetParentId)
        .then(() => {
          setState({ ...state, isLoading: false });
          setTimeout(() => {
            props.handleRefresh();
            setState({ ...state, isSubmitting: false });
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          setState({ ...state, isLoading: false });
        });
    }
    //});
  };

  const moveItem = event => {
    event.preventDefault();
    const { targetParentId, customerId } = state;
    const { id } = document;
    let tempCustomerId = customerId;
    if (tempCustomerId <= 0) {
      tempCustomerId = 0;
    }
    setState({ ...state, isSubmitting: true });
    //this.setState({ isSubmitting: true }, () => {
    if (modalData.isFile) {
      //console.log("Move file", targetParentId);
      props
        .moveFile(tempCustomerId, id, targetParentId)
        .then(() => {
          setState({ ...state, isLoading: false });
          setTimeout(() => {
            props.handleRefresh();
            setState({ ...state, isSubmitting: false });
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          setState({ ...state, isLoading: false });
        });
    }
    if (modalData.isFolder) {
      //console.log("Move folder", targetParentId);
      props
        .moveFolder(tempCustomerId, id, targetParentId)
        .then(() => {
          setState({ ...state, isLoading: false });
          setTimeout(() => {
            props.handleRefresh();
            setState({ ...state, isSubmitting: false });
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          setState({ ...state, isLoading: false });
        });
    }
    //});
  };

  // const onChange = e => {
  //   setDocument({ ...document, [e.target.name]: e.target.value });
  //   //this.setState({ [e.target.name]: e.target.value });
  // };

  const renderItem = i => {
    const { customerId } = state;
    const { isFolder, id } = document;
    let allowGlobal = false;
    if (customerId <= 0) {
      allowGlobal = true;
    }

    if (
      i.isFolder &&
      ((i.type === 1 && allowGlobal) || i.type !== 1) &&
      ((isFolder && i.id !== id) || !isFolder)
    ) {
      let key = 'folder-' + i.id;
      if (state.isModal) {
        key = 'modal-folder-' + i.id;
      }
      return (
        <tr id={key} key={key}>
          <td
            onClick={() => {
              navigate(i.id);
            }}
          >
            <div>
              <img
                src={folderIcons(i.type)}
                alt=''
                className='document-item-icon'
              />{' '}
              <div className='document-item-name'>{i.name}</div>
              {i.hasChildFolders && (
                <div className='document-item-child-folder-indicator float-right'>
                  <IoIosArrowForward size={25} />
                </div>
              )}
            </div>
          </td>
        </tr>
      );
    }
  };

  const renderItems = () => {
    if (state.documents.length > 0) {
      return state.documents.map(i => renderItem(i));
    }
  };

  const renderBreadcrums = () => {
    //if (breadcrumbPath.length < 2) return;

    const jumpNav = (id, index) => {
      navigate(id);
    };

    const renderItem = (crumb, index) => {
      return (
        <span
          key={index}
          onClick={() => jumpNav(crumb.id, index)}
          className='can-click'
        >
          {crumb.path}
        </span>
      );
    };

    return <div className='crumbs'>{breadcrumbPath.map(renderItem)}</div>;
  };

  const renderForm = () => {
    const {
      currentFolderParentId,
      isSubmitting,
      isMove,
      isCopy,
      showReturn,
    } = state;

    return (
      <Row>
        <Col>
          {renderBreadcrums()}
          <Form>
            <Table striped hover className='move-copy-table'>
              <tbody>{renderItems()}</tbody>
            </Table>

            {!isSubmitting && (
              <Row>
                <Col className='align-self-center col'>
                  {showReturn && (
                    <Button
                      outline
                      //color='secondary'
                      className='float-left m-1 btn-outline-secondary'
                      onClick={goBack}
                      //size='sm'
                    >
                      {i18next.t(1039)}
                    </Button>
                  )}

                  {isMove && (
                    <Button
                      //size='lg'
                      color='btnSecondary'
                      className='float-right m-1'
                      onClick={moveItem}
                      // onClick={() => console.log('move-test')}
                      disabled={modalData.parentId === currentFolderParentId}
                    >
                      {i18next.t(7083)}
                    </Button>
                  )}

                  {isCopy && (
                    <Button
                      //size='lg'
                      color='btnSecondary'
                      className='float-right m-1'
                      onClick={copyItem}
                      //disabled={modalData.parentId === currentFolderParentId}
                    >
                      {i18next.t(1439)}
                    </Button>
                  )}
                  <Button
                    outline
                    //size='lg'
                    color='btnSecondary'
                    className='float-right m-1'
                    onClick={props.toggle}
                  >
                    {i18next.t(1243)}
                  </Button>
                </Col>
              </Row>
            )}
            {isSubmitting && <PageSpinner />}
          </Form>
        </Col>
      </Row>
    );
  };

  const renderTitle = () => {
    if (!modalData) {
      return (
        <React.Fragment>
          {state.isMove && i18next.t(7095)}
          {state.isCopy && i18next.t(7096)}
        </React.Fragment>
      );
    } else {
      let text = isMove ? i18next.t(7120) : isCopy ? i18next.t(7121) : '';
      text = text.replace('[ITEM]', modalData.name);

      return <React.Fragment>{text}</React.Fragment>;
    }
  };

  //console.log(breadcrumbs);
  //console.log(isMove, isCopy);

  return (
    <div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        fade={false}
        size='lg'
        centered
      >
        <ModalHeader toggle={props.toggle}>{renderTitle()}</ModalHeader>
        {!state.isLoading && (
          <ModalBody>
            <React.Fragment>{renderForm()}</React.Fragment>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};

DocumentCopyMoveModal.propTypes = {
  currentFolderParentId: PropTypes.number,
  modalData: PropTypes.object,
  handleRefresh: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    currentFolder: state.documents.currentFolder,
  };
}

export default connect(mapStateToProps, {
  moveFolder,
  copyFolder,
  moveFile,
  copyFile,
  getFolders,
  getFolder,
})(DocumentCopyMoveModal);
