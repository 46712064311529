import { SET_CUSTOMERS, SET_USER_LANGUAGE, SET_CUSTOMER } from '../constants';

const initialState = {
  customers: [],
  customer: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: action.customers,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.customer,
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        user_lang: action.user_lang,
      };
    default:
      return state;
  }
};
