import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import i18next from '../i18n';
import {
  isMobile,
  // isIOS,
  // isSafari
} from 'react-device-detect';

// redux
import {
  validatePdfSchemaToken,
  downloadPdfSchemaWithToken,
} from '../redux/actions/files';

// utils
import { StringIsEmpty } from '../utils/common';

// components
import PageSpinner from '../components/PageSpinner';

const PdfSchemaPage = props => {
  const { validatePdfSchemaToken, downloadPdfSchemaWithToken } = props;
  const [isLoading, setLoading] = useState(true);
  const [path, setPath] = useState('');
  const [expired, setExpired] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const query = queryString.parse(props.location.search);

    let token = query.token;

    if (!StringIsEmpty(token)) {
      validatePdfSchemaToken(token)
        .then(res => {
          const valid = res.data;

          if (valid) {
            downloadPdfSchemaWithToken(token).then(res => {
              window.location.replace(res);
              setLoading(false);
              setPath(res);
            });
          } else {
            setLoading(false);
            setExpired(true);
          }
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400 || err.response.status === 404) {
              setInvalid(true);
            } else {
              setHasError(true);
            }
          } else {
            setHasError(true);
          }
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const renderBadResultContent = result => {
    return (
      <div className='unsupported-container'>
        <p>
          {i18next.t(212)}: {result}
        </p>
      </div>
    );
  };

  const renderContent = content => {
    return (
      <main className='cr-app bg-light'>
        <div
          className='justify-content-center'
          style={{ display: 'flex', flex: 1 }}
        >
          {content}
        </div>
      </main>
    );
  };

  const renderiOSVersion = () => {
    return (
      <div className='unsupported-container'>
        <div className='pdf-view'>
          <p>
            Hvis rapporten ikke er hentet eller vises,{' '}
            <a href={path}>tryk her</a> for at download den.
          </p>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return renderContent(<PageSpinner />);
  }

  if (hasError) {
    return renderBadResultContent(i18next.t(6034));
  }

  if (invalid) {
    return renderBadResultContent(i18next.t(6032));
  }

  if (expired) {
    return renderBadResultContent(i18next.t(6031));
  }

  if (isMobile) {
    return renderiOSVersion();
  } else {
    return <iframe title={`${new Date()}`} src={path} className='pdf-page' />;
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  validatePdfSchemaToken,
  downloadPdfSchemaWithToken,
})(PdfSchemaPage);
