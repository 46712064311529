import React, { useEffect, useState } from 'react';

// Components
import ImgViewerModal from './ImgViewerModal';
import ImageMarking from '../ImageMarking';
import ReferenceGallery from '../ImageMarking/ReferenceGallery';

const Gallery = props => {
  const { imageHash } = props;
  const [currentFile, setCurrentFile] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const onDelete = (fileId, close = true) => {
    if (close) {
      setShowModal(false);
    }
    if (props.onDelete) {
      props.onDelete(fileId);
    }
  };

  useEffect(() => {
    let file = '';
    if (props.files?.length === 1) {
      file = props.files[0];
    }
    setCurrentFile(file);
  }, [props.files]);

  const handleInfo = (fileId = null, data) => {
    props.handleInfo(fileId, data);
  };

  const handleInfoBulk = items => {
    props.handleInfoBulk(items);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const renderImage = (file, key) => {
    const onClick = () => {
      setCurrentFile(file);
      setStartIndex(key);
      setShowModal(true);
    };

    //console.log('gallery', file);

    let src = !file.cached
      ? `${file.thumbUrl}?${imageHash}`
      : `${file.thumbUrl}`;

    return (
      <div
        className='gallery__figure'
        onClick={onClick}
        key={`${key}-${imageHash}`}
      >
        <img
          src={src}
          alt={file.fileTitle}
          className='gallery__img'
          key={imageHash}
        />
      </div>
    );
  };

  const renderViewerComp = () => {
    if (showModal && !!props.hasAreaMarking && !props.isMovingImages) {
      return (
        <ImageMarking
          currentFile={currentFile}
          show={showModal}
          toggle={toggleModal}
          onDelete={onDelete}
          handleInfo={handleInfo}
          saveFile={props.saveFile}
          disabled={props.disabled}
          isSaving={props.isSaving}
          editor={props.editor}
          schema={props.schema}
          control={props.control}
          startIndex={startIndex}
        />
      );
    }

    if (showModal) {
      return (
        <ImgViewerModal
          currentFile={currentFile}
          show={showModal}
          toggle={toggleModal}
          onDelete={onDelete}
          marking={props.marking}
          handleInfo={handleInfo}
          handleInfoBulk={handleInfoBulk}
          saveFile={props.saveFile}
          disabled={props.disabled}
          isSaving={props.isSaving}
          control={props.control}
          files={props.files}
          startIndex={startIndex}
        />
      );
    }

    return null;
  };

  const renderGallery = () => {
    if (props.editor) {
      const onClick = (file, key) => {
        setCurrentFile(file);
        setStartIndex(key);
        setShowModal(true);
      };

      return (
        <ReferenceGallery
          schema={props.schema}
          control={props.control}
          files={props.files}
          onClick={onClick}
        />
      );
    } else {
      return props.files.map(renderImage);
    }
  };

  return (
    <div className='gallery' key={imageHash}>
      {renderGallery()}

      {renderViewerComp()}
    </div>
  );
};

Gallery.defaultProps = {
  files: [],
};

export default Gallery;
