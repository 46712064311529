import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Reducers
import auth from './auth';
import common from './common';
import customers from './customers';
import customerGroups from './customerGroups';
import employees from './employees';
import insurances from './insurances';
import cases from './cases';
import branding from './branding';
import systemInfo from './systemInfo';
import features from './features';
import documents from './documents';
import roadmap from './roadmap';
import invitation from './invitation';
import notifications from './notifications';
import schemabuilder from './schemabuilder';
import products from './products';
import schemas from './schemas';
import controlPlan from './controlPlan';
import language from './language';
import caseDescriptions from './caseDescriptions';
import markings from './markings';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    common,
    customers,
    customerGroups,
    employees,
    insurances,
    cases,
    branding,
    systemInfo,
    features,
    documents,
    roadmap,
    invitation,
    notifications,
    schemabuilder,
    products,
    schemas,
    controlPlan,
    language,
    caseDescriptions,
    markings,
  });

export default rootReducer;
