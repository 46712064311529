import React, { useState, useRef } from 'react';
import i18next from '../../i18n';
import { connect } from 'react-redux';

// Actions
import {
  updateControlItem,
  updateControlItemViaToken,
  setCurrentSchemaGroups,
} from '../../redux/actions/schemas';
import { SB_CONTROL_TYPE } from '../../redux/constants';

// Utils
import { isPassingCriteria } from '../../utils/schemaBuilderUtils';

// Components;
import Typography from '../Typography';
import {
  ControlItemText,
  ControlItemTextUneditable,
  ControlItemDropdownCustom,
  ControlItemPhoto,
  ControlItemPhotoArea,
  ControlItemSignature,
  ControlItemDateSelector,
  ControlItemDropdownUsers,
  ControlItemDropdownCheckboxes,
  ControlItemDropdownProducts,
  ControlItemTimeTableUsers,
  ControlItemCheckmark,
  ControlItemNumbers,
  ControlItemDropdownCommentPhotos,
  ControlItemTextPhotos,
} from './ControlItemTypes';

const ControlItem = props => {
  const {
    schema,
    groupIndex,
    itemIndex,
    schemaIndex,
    building,
    control,
    disableAllFunctions,
    setUpdating,
    setCurrentSchemaGroups,
    updateControlItem,
    selectControl,
    refreshGroups,
    isServiceAgreement,
    _targetControlId,
    imageObjects,
    areControlsValid,
    clearSignatureValues,
    refreshSignatureStatus,
    signingOnly,
    updateControlItemViaToken,
    signatureToken,
    showSigningSuccessfulToast,
    itemGroupIndex,
    group,
    isGroupControl,
    hasAreaMarking,
    checkForAreaMarkingControl,
    onChangeControlContacts,
  } = props;
  const { schemaGroups } = props.schemas;
  const timerRef = useRef(null);
  const [isMovingImages, setMovingImages] = useState(!!props.isMovingImages);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  ////// onChange methods

  const onChange = e => {
    let val = e.target.value;
    if (!val || val?.trim().length === 0) val = '';

    onEditSchema(e.target.name, val);
  };

  const onChangeBoolean = e => {
    let val = e.target.checked;
    onEditSchema(e.target.name, val);
  };

  const onChangeArray = (name, value) => {
    onEditSchema(name, value);
  };

  const onChangeDate = (name, value) => {
    onEditSchema(name, value);
  };

  const onEditSchema = (name, value) => {
    let updatedSchemas = [...schemaGroups[groupIndex].schemas];

    if (isGroupControl) {
      updatedSchemas[schemaIndex].controlItems[itemGroupIndex].controlItems[
        itemIndex
      ][name] = value;
    } else {
      updatedSchemas[schemaIndex].controlItems[itemIndex][name] = value;
    }

    // Clearing signatures if any
    if (clearSignatureValues) {
      updatedSchemas[schemaIndex].controlItems = updatedSchemas[
        schemaIndex
      ].controlItems.map(x => {
        if (x.controlType === SB_CONTROL_TYPE.Signature) {
          x.signatureValue = null;
        } else if (x.isGroup) {
          x.controlItems = x.controlItems.map(y => {
            if (y.controlType === SB_CONTROL_TYPE.Signature) {
              y.signatureValue = null;
            }
            return y;
          });
        }

        return x;
      });
    }

    let updatedGroup = { ...schemaGroups[groupIndex], schemas: updatedSchemas };

    let updatedGroups = [...schemaGroups];
    updatedGroups[groupIndex] = updatedGroup;

    if (timerRef.current) clearTimeout(timerRef.current);
    setCurrentSchemaGroups(updatedGroups);

    timerRef.current = setTimeout(() => {
      if (props?.onEditSchema) {
        if (isServiceAgreement) {
          props.onEditSchema('actualServiceDate', new Date());
        } else {
          props.onEditSchema('updatedDate', new Date());
        }
      }

      let item;
      if (isGroupControl) {
        item =
          updatedSchemas[schemaIndex].controlItems[itemGroupIndex].controlItems[
            itemIndex
          ];
      } else {
        item = updatedSchemas[schemaIndex].controlItems[itemIndex];
      }

      const dataObj = {
        ...item,
        clearSignatureValues,
      };

      if (
        clearSignatureValues ||
        control.controlType === SB_CONTROL_TYPE.Signature
      ) {
        refreshSignatureStatus();

        if (!!showSigningSuccessfulToast && value?.trim().length > 0) {
          showSigningSuccessfulToast();
        }
      }

      const action = signingOnly
        ? updateControlItemViaToken
        : updateControlItem;

      action(dataObj, signatureToken)
        .then(() => {
          const photoTypes = [SB_CONTROL_TYPE.PhotoAreaMarking];
          if (photoTypes.includes(control.controlType)) {
            checkForAreaMarkingControl();
          }
        })
        .catch(err => {
          console.log(err);
        });
    }, 1000);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  const onChangeCheckmark = e => {
    let name = e.target.name;
    let value = e.target.checked;
    // console.log(name, value);

    /////////////////////////////
    let updatedSchemas = [...schemaGroups[groupIndex].schemas];

    if (name === 'hideMarkedQuestion') {
      if (isGroupControl) {
        updatedSchemas[schemaIndex].controlItems[itemGroupIndex].controlItems[
          itemIndex
        ].marked = false;
        updatedSchemas[schemaIndex].controlItems[itemGroupIndex].controlItems[
          itemIndex
        ].hideMarkedQuestion = true;
      } else {
        updatedSchemas[schemaIndex].controlItems[itemIndex].marked = false;
        updatedSchemas[schemaIndex].controlItems[
          itemIndex
        ].hideMarkedQuestion = true;
      }
    } else {
      if (isGroupControl) {
        updatedSchemas[schemaIndex].controlItems[itemGroupIndex].controlItems[
          itemIndex
        ].marked = value;
        updatedSchemas[schemaIndex].controlItems[itemGroupIndex].controlItems[
          itemIndex
        ].hideMarkedQuestion = false;
      } else {
        updatedSchemas[schemaIndex].controlItems[itemIndex].marked = value;
        updatedSchemas[schemaIndex].controlItems[
          itemIndex
        ].hideMarkedQuestion = false;
      }
    }

    if (isGroupControl) {
      updatedSchemas[schemaIndex].controlItems[itemGroupIndex].controlItems[
        itemIndex
      ].answeredByUserId = -1;
    } else {
      updatedSchemas[schemaIndex].controlItems[itemIndex].answeredByUserId = -1;
    }
    /////////////////////////////

    let updatedGroup = { ...schemaGroups[groupIndex], schemas: updatedSchemas };

    let updatedGroups = [...schemaGroups];
    updatedGroups[groupIndex] = updatedGroup;

    if (timerRef.current) clearTimeout(timerRef.current);
    setCurrentSchemaGroups(updatedGroups);

    timerRef.current = setTimeout(() => {
      let item;
      if (isGroupControl) {
        item =
          updatedSchemas[schemaIndex].controlItems[itemGroupIndex].controlItems[
            itemIndex
          ];
      } else {
        item = updatedSchemas[schemaIndex].controlItems[itemIndex];
      }

      updateControlItem(item).catch(err => {
        console.log(err);
      });
    }, 500);
  };

  // const updateActualServiceDate = () => {
  //   if (props?.onEditSchema) {
  //     props.onEditSchema('actualServiceDate', new Date());
  //   }
  // };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  ////// render methods

  const renderControlByType = () => {
    if (!control) return null;

    let sharedProps = {
      building,
      schema,
      control,
      disabled: disableAllFunctions || signingOnly,
      isServiceAgreement,
      isMovingImages,
      onChange,
      onChangeBoolean,
      onChangeCheckmark,
      onChangeArray,
      onChangeDate,
      setUpdating,
      setMovingImages,
      selectControl,
      refreshGroups,
      _targetControlId,
      imageObjects,
      areControlsValid,
      signingOnly,
      isGroupControl,
      itemGroupIndex,
      group,
      hasAreaMarking,
      onChangeControlContacts,
    };

    switch (control.controlType) {
      case SB_CONTROL_TYPE.PhotoAreaMarking:
        return <ControlItemPhotoArea {...sharedProps} />;
      case SB_CONTROL_TYPE.PhotoDocumentation:
        return <ControlItemPhoto {...sharedProps} />;
      case SB_CONTROL_TYPE.Signature:
        return <ControlItemSignature {...sharedProps} />;
      case SB_CONTROL_TYPE.DateSelector:
        return <ControlItemDateSelector {...sharedProps} />;
      case SB_CONTROL_TYPE.DropdownProducts:
        return <ControlItemDropdownProducts {...sharedProps} />;
      case SB_CONTROL_TYPE.DropdownUsers:
        return <ControlItemDropdownUsers {...sharedProps} />;
      case SB_CONTROL_TYPE.DropdownCustom:
        return <ControlItemDropdownCustom {...sharedProps} />;
      case SB_CONTROL_TYPE.DropdownCommentPhotos:
        return <ControlItemDropdownCommentPhotos {...sharedProps} />;
      case SB_CONTROL_TYPE.Checkboxes:
        return <ControlItemDropdownCheckboxes {...sharedProps} />;
      case SB_CONTROL_TYPE.Text:
        return <ControlItemText {...sharedProps} />;
      case SB_CONTROL_TYPE.TextUneditable:
        return <ControlItemTextUneditable {...sharedProps} />;
      case SB_CONTROL_TYPE.Numbers:
        return <ControlItemNumbers {...sharedProps} />;
      case SB_CONTROL_TYPE.TimeTableUsers:
        return <ControlItemTimeTableUsers {...sharedProps} />;
      case SB_CONTROL_TYPE.Checkmark:
        return <ControlItemCheckmark {...sharedProps} />;
      case SB_CONTROL_TYPE.TextPhotos:
        return <ControlItemTextPhotos {...sharedProps} />;
      default:
        return (
          <div className='unknown'>
            <Typography type='h2'>{i18next.t(12049)}</Typography>
          </div>
        );
    }
  };

  return (
    isPassingCriteria(schema, control) && (
      <>
        {renderControlByType()}
        {!isGroupControl && <hr />}
      </>
    )
  );
};

function mapStateToProps({ schemas }) {
  return {
    schemas,
  };
}

export default connect(mapStateToProps, {
  setCurrentSchemaGroups,
  updateControlItem,
  updateControlItemViaToken,
})(ControlItem);
