import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

// Actions
import { forgotpassword } from '../redux/actions/auth';

// Utils

// Components
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const ForgotPassword = props => {
  const { auth, history, forgotpassword } = props;
  const [resetSent, setResetSent] = useState(false);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      history.push('/');
    }
    // eslint-disable-next-line
  }, []);

  const handleLogoClick = () => {
    window.location.href = 'https://www.nordicwaterproofing.com/';
  };

  const handleForgotPassword = username => {
    forgotpassword({
      username: username,
    })
      .then(res => {
        setResetSent(true);
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={6} lg={4}>
        <Card body>
          <ForgotPasswordForm
            onLogoClick={handleLogoClick}
            forgotpassword={handleForgotPassword}
            resetSent={resetSent}
          />
        </Card>
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  forgotpassword,
})(ForgotPassword);
