import {
  GET_ALL_IMAGE_REFERENCES,
  SET_CURRENT_IMAGE_REFERENCES,
  UPDATE_REFERENCE_INFO,
  IS_REF_SAVE_ALLOWED,
  RECENT_UPLOADS,
} from '../constants';

const initialState = {
  allImageRefs: [],
  currentRefs: null,
  markings: [],
  bindings: {},
  isSaveAllowed: false,
  recentUploads: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ALL_IMAGE_REFERENCES:
      return {
        ...state,
        allImageRefs: action.payload,
      };
    case SET_CURRENT_IMAGE_REFERENCES:
      return {
        ...state,
        currentRefs: action.payload,
      };
    case UPDATE_REFERENCE_INFO:
      return {
        ...state,
        markings: action.payload.markings,
        bindings: action.payload.bindings,
      };
    case IS_REF_SAVE_ALLOWED:
      return {
        ...state,
        isSaveAllowed: action.payload,
      };
    case RECENT_UPLOADS:
      return {
        ...state,
        recentUploads: action.payload,
      };
    default:
      return state;
  }
};
