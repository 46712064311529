/**
 * scrollHook React hoc
 *
 */

function scrollHook(window, body) {
  const wrapper = window.getBoundingClientRect();
  const content = body.getBoundingClientRect();

  const windowHeight = wrapper.height;
  const windowWidth = wrapper.width;

  const height = content.height;
  const width = content.width;
  const scrollY = window.scrollTop;
  const scrollX = window.scrollLeft;

  const progressHeight = parseInt(
    (Math.abs(parseInt(scrollY)) / (height - windowHeight)) * 100,
  );
  const progressWidth = parseInt(
    (Math.abs(parseInt(scrollX)) / (width - windowWidth)) * 100,
  );

  //console.log(progressHeight, height - windowHeight, scrollY);

  return {
    height,
    width,
    progressHeight,
    progressWidth,
    scrollY,
    scrollX,
  };
}

export default scrollHook;
