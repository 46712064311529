import React, { useState } from 'react';
import TaghaandbogenLogo from './../assets/img/logo/method-draw-image.svg';
import { Card, Form, Col, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import i18next from '../i18n';
//import { osName } from 'react-device-detect';

// Actions
import { verifySms, resendSms } from '../redux/actions/auth';
import { CUSTOMER_STATUS } from '../redux/constants';
import { GetBranding } from '../redux/actions/common';
import { getSystemMessage } from '../redux/actions/systemInfo';
import { getFeatures } from '../redux/actions/features';
import { getCustomer } from '../redux/actions/customers';

// Utils

// Components
import TextInput from '../components/Custom/Forms/TextInput';
import PageSpinner from '../components/PageSpinner';
import { useEffect } from 'react';

const Verification = props => {
  const {
    auth,
    verifySms,
    getCustomer,
    logout,
    getSystemMessage,
    getFeatures,
    GetBranding,
    history,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [smsVerify, setSmsVerify] = useState(false);
  const [googleVerify, setGoogleVerify] = useState(false);
  const [windowsVerify, setWindowsVerify] = useState(false);
  const [smsInput, setSmsInput] = useState('');
  // const [showClosedAccountMessage, setShowClosedAccount] = useState(false);
  // const [salesManager, setSalesMaanger] = useState(null);

  useEffect(() => {
    setSmsVerify(true);
    setGoogleVerify(false);
    setWindowsVerify(false);
  }, []);

  const renderSmsVericiation = () => {
    const verify = event => {
      event.preventDefault();
      //console.log('Verify');

      setVerifyFailed(false);
      setLoading(true);

      const dataObj = { userId: auth.user.user.id, code: smsInput };

      verifySms(dataObj).then(res => {
        //console.log(res);
        if (res.data.token === null) {
          setVerifyFailed(true);
          setLoading(false);
        } else {
          if (res.data.customerStatus === CUSTOMER_STATUS.Closed) {
            const { user } = res.data;
            getCustomer(user.groupId, user.belongsToCustomerId)
              .then(res => {
                logout();
                // setShowClosedAccount(true);
                // setSalesMaanger(res.data.salesManager);
              })
              .catch(err => {
                logout();
                // setShowClosedAccount(true);
                // setSalesMaanger(null);
              });
          } else {
            getSystemMessage();
            getFeatures();
            if (res.data.accessLevel > 1 && res.data.user) {
              const { user } = res.data;

              GetBranding(user.belongsToCustomerId)
                .then(res => {
                  history.push('/');
                })
                .catch(err => {
                  console.log('Failed to get branding.', err);
                  history.push('/');
                });
            } else {
              history.push('/');
            }
          }
        }
      });
    };

    const resendSms = () => {
      //console.log('resend sms');
      const dataObj = { userId: auth.user.user.id };
      setLoading(true);
      props.resendSms(dataObj).then(res => {
        setLoading(false);
      });
    };

    const onChangeSmsInput = e => {
      setSmsInput(e.target.value);
    };

    return (
      <Card body>
        <Form onSubmit={verify}>
          <div className='text-left pb-4'>
            <img
              src={TaghaandbogenLogo}
              className='authform_logo'
              alt='Tagpas'
            />
          </div>
          <TextInput
            for={i18next.t(8307)}
            placeholder={i18next.t(8308)}
            name='smsInput'
            onChange={onChangeSmsInput}
            value={smsInput}
          />

          {verifyFailed ? (
            <p style={{ color: 'red' }}>{i18next.t(8309)}</p>
          ) : null}
          {/* // TODO */}

          <Button size='lg' block color='primary' type='submit'>
            {i18next.t(8310)}
          </Button>
          <Button size='lg' block color='primary' onClick={resendSms}>
            {i18next.t(8311)}
          </Button>
        </Form>
      </Card>
    );
  };

  const renderGoogleVerification = () => {};

  const renderWindowsVerification = () => {};

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={6} lg={4}>
        {isLoading && <PageSpinner />}
        {!isLoading && (
          <>
            {smsVerify && renderSmsVericiation()}
            {googleVerify && renderGoogleVerification()}
            {windowsVerify && renderWindowsVerification()}
          </>
        )}
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  verifySms,
  resendSms,
  GetBranding,
  getCustomer,
  getFeatures,
  getSystemMessage,
})(Verification);
