import { SET_SYSTEM_INFO, SET_SYSTEM_MESSAGE } from '../constants';

const initialState = {
  systemInfo: [],
  systemMessage: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SYSTEM_INFO:
      return {
        ...state,
        systemInfo: action.payload,
      };
    case SET_SYSTEM_MESSAGE:
      return {
        ...state,
        systemMessage: action.payload,
      };
    default:
      return state;
  }
};
