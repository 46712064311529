import {
  DATE_TYPE,
  GET_ROADMAP_DATA,
  GET_ROADMAP_MATERIALS_DATA,
  SET_ROADMAP_DATE_INTERVAL,
  RENDERED_ROADMAP_TIMELINE,
  RENDERED_ROADMAP_MATERIALS,
  RENDERED_ROADMAP_TIMEINDICATOR,
  RERENDER_ROADMAP_LOCK_STATE,
  GET_ROADMAP_INFO_SCREEN_DATA,
  TOGGLE_ROADMAP_INFO_SCREEN,
  SET_SIDEMENU_ERRORS,
  ROADMAP_SET_INFOBAR_DATA,
} from '../constants';

const initialState = {
  roadmapData: null,
  roadmapMaterialsData: null,
  dateInterval: DATE_TYPE.MONTHS,
  timelineRendered: false,
  timeIndicatorRendered: false,
  reRenderLocked: false,
  infoScreenData: null,
  showInfoScreen: false,
  sideMenuErrors: [],
  infoBar: null,
  materialsRendered: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ROADMAP_DATA:
      return {
        ...state,
        roadmapData: action.payload,
      };
    case GET_ROADMAP_MATERIALS_DATA:
      return {
        ...state,
        roadmapMaterialsData: action.payload,
      };
    case SET_ROADMAP_DATE_INTERVAL:
      return {
        ...state,
        dateInterval: action.payload,
      };
    case RENDERED_ROADMAP_TIMELINE:
      return {
        ...state,
        timelineRendered: action.payload,
      };
    case RENDERED_ROADMAP_TIMEINDICATOR:
      return {
        ...state,
        timeIndicatorRendered: action.payload,
      };
    case RERENDER_ROADMAP_LOCK_STATE:
      return {
        ...state,
        reRenderLocked: action.payload,
      };
    case GET_ROADMAP_INFO_SCREEN_DATA:
      return {
        ...state,
        infoScreenData: action.payload,
      };
    case TOGGLE_ROADMAP_INFO_SCREEN:
      return {
        ...state,
        showInfoScreen: action.payload,
      };
    case SET_SIDEMENU_ERRORS:
      return {
        ...state,
        sideMenuErrors: action.payload,
      };
    case ROADMAP_SET_INFOBAR_DATA:
      return {
        ...state,
        infoBar: action.payload,
      };
    case RENDERED_ROADMAP_MATERIALS:
      return {
        ...state,
        materialsRendered: action.payload,
      };
    default:
      return state;
  }
};
