/**
 * @module actions/cases
 */
import axios from 'axios';
import FileSaver from 'file-saver';
import {
  API_URL,
  RECENT_UPLOADS,
  SET_CASE,
  SET_CASE_IMAGE_GALLERY,
} from '../constants';

///////////////////////// GENERAL /////////////////////////

/**
 * @description Clear selected case.
 * * @return {Promise}
 */
export function ClearSelectedCase() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_CASE,
        selectedCase: null,
      });
      resolve();
    });
  };
}

/**
 * @description Set selected case
 * @return {Promise} Promise
 */
export function SetSelectedCase(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_CASE,
        selectedCase: data,
      });
      resolve(data);
    });
  };
}

///////////////////////// COMMENTS /////////////////////////

/**
 * @description Get comments on case
 * @return {Promise} Promise
 */
export function getCommentsOnCase(id) {
  // console.log(id, data);
  let url = API_URL + `/cases/${id}/comment`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Post comments on case
 * @return {Promise} Promise
 */
export function postCommentOnCase(id, data) {
  // console.log(id, data);
  let url = API_URL + `/cases/${id}/comment`;
  return dispatch => {
    return axios.post(url, data);
  };
}

///////////////////////// SIGNATURE /////////////////////////

/**
 * @description Get signature approvals by case Id
 * @return {Promise} Axios promise
 */
export function GetSignatures(id) {
  let url = API_URL + '/signature/' + id;
  return axios.get(url).then(res => {
    return res.data;
  });
}

/**
 * @description Post approvals
 * @return {Promise} Axios promise
 */
export function PostApproval(data) {
  let url = API_URL + '/signature/token';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Post approvals
 * @return {Promise} Axios promise
 */
export function PostTokenlessApproval(data) {
  let url = API_URL + '/signature';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Post signature
 * @return {Promise} Axios promise
 */
export function SignApprovals(data, token) {
  let url = API_URL + '/signature/sign';
  let config = {};

  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  return dispatch => {
    return axios.post(url, data, config);
  };
}

///////////////////////// CASE ASSIGNMENTS /////////////////////////

/**
 * @description Get standard case assignments
 * @return {Promise} Axios promise
 */
export function GetCaseAssignments(id) {
  let url = API_URL + '/assignments/' + id;
  return dispatch => {
    return axios.get(url).then(res => {
      return res.data;
    });
  };
}

/**
 * @description Post new standard assignment
 * @return {Promise} Axios promise
 */
export function PostAssignments(data) {
  let url = API_URL + '/assignments';
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * @description Delete standard assignment
 * @return {Promise} Axios promise
 */
export function DeleteAssignment(id) {
  let url = API_URL + `/assignments/${id}/delete`;
  return dispatch => {
    return axios.post(url).then(res => {
      return res.data;
    });
  };
}

///////////////////////// PDF /////////////////////////

/**
 * @description Download case pdf
 * @return {Promise} Axios promise
 */
export function DownloadPdf(id) {
  let url = API_URL + `/pdf/download/${id}`;
  return dispatch => {
    return axios.get(url, { responseType: 'blob' }).then(res => {
      if (res.data.type === 'text/plain') {
        //console.log('output');
        return res.data.text();
      }

      const regex = /filename=["]?(.*\.pdf)["]?;/g;
      const headerContentDisposition = res.headers['content-disposition'];
      const fileNameGroups = regex.exec(headerContentDisposition);
      // console.log('download', fileNameGroups, headerContentDisposition)

      FileSaver.saveAs(res.data, fileNameGroups[1]);
    });
  };
}

/**
 * @description Download case pdf
 * @return {Promise} Axios promise
 */
export function DownloadQIPdf(caseObj, filter) {
  let url = API_URL + `/pdf/download/qi/${caseObj.id}`;
  return dispatch => {
    return axios.post(url, filter, { responseType: 'blob' }).then(res => {
      let fileName = '';

      if (res.data.type === 'text/plain') {
        //console.log('output');
        return res.data.text();
      }

      if (caseObj.documentName?.length > 0) {
        fileName = caseObj.documentName + '.pdf';
      } else {
        const regex = /filename=["]?(.*\.pdf)["]?;/g;
        const headerContentDisposition = res.headers['content-disposition'];
        const fileNameGroups = regex.exec(headerContentDisposition);
        // console.log('download', fileNameGroups, headerContentDisposition)

        fileName = fileNameGroups[1];
      }

      FileSaver.saveAs(res.data, fileName);
    });
  };
}

/**
 * @description Download schema / schemagroup pdf
 * @return {Promise} Axios promise
 */
export function DownloadSchemas(data) {
  let url = API_URL + `/pdf/download/schemas`;
  return dispatch => {
    return axios.post(url, data, { responseType: 'blob' }).then(res => {
      let fileName = '';

      if (res.data.type === 'text/plain') {
        //console.log('output');
        return res.data.text();
      }

      const regex = /filename=["]?(.*\.pdf)["]?;/g;
      const headerContentDisposition = res.headers['content-disposition'];
      const fileNameGroups = regex.exec(headerContentDisposition);
      // console.log('download', fileNameGroups, headerContentDisposition)

      fileName = fileNameGroups[1];

      FileSaver.saveAs(res.data, fileName);
    });
  };
}

/**
 * @description Get quality case pdf filter
 * @param {Number} caseId
 * @return {Promise} Axios promise
 */
export function getCaseQualityPdfFilter(caseId) {
  let url = API_URL + `/pdf/qualityFilter/${caseId}`;
  return dispatch => {
    return axios.get(url);
  };
}

export function getCaseQualityTokenPdfFilter(caseId) {
  let url = API_URL + `/pdf/qualityFilter/token/${caseId}`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Get customer standard quality case pdf filter
 * @param {Number} customerId
 * @return {Promise} Axios promise
 */
export function getCustomerQualityPdfFilter(customerId) {
  let url = API_URL + `/pdf/qualityFilter/customer/${customerId}`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Get quality case pdf filter
 * @param {Number} customerId
 * @param {Object} filter
 * @return {Promise} Axios promise
 */
export function updateCustomerQualityPdfFilter(customerId, filter) {
  let url = API_URL + `/pdf/qualityFilter/customer/${customerId}`;
  return dispatch => {
    return axios.post(url, filter);
  };
}

/**
 * @description Download pdf document from case
 * @return {Promise} Axios promise
 */
export function downloadDocument(caseId, doc, type = 'KS') {
  let urlDirection = '';
  switch (type) {
    case 'KS':
      urlDirection = 'qi';
      break;
    case 'SA':
      urlDirection = 'sa';
      break;
    case 'VA':
    default:
      break;
  }

  let url =
    API_URL + `/pdf/download/${urlDirection}/${caseId}/document/${doc.id}`;
  return dispatch => {
    return axios.get(url, { responseType: 'blob' }).then(res => {
      // const regex = /filename=["]?(.*\.[a-zA-Z0-9]*)["]?;/g;
      // const headerContentDisposition = res.headers['content-disposition'];
      // const fileNameGroups = regex.exec(headerContentDisposition);
      // console.log('download', fileNameGroups, headerContentDisposition);

      // console.log('document', doc);
      FileSaver.saveAs(res.data, doc.fileName);
    });
  };
}

///////////////////////// FILES /////////////////////////

/**
 * @description Upload file
 * @return {Promise} Axios promise
 */
export function uploadFiles(
  files,
  {
    caseId,
    qualityCaseId,
    qualitySchemaId,
    qualitySchemaControlId,
    customerId,
    serviceAgreementId,
    document,
    coverImage,
    isContract,
    controlItemId,
    caseBuildingId,
    isPlatform,
  },
) {
  return dispatch => {
    let url = API_URL + `/file/upload/`;
    if (caseId) {
      url += `?caseId=${caseId}`;
    } else if (qualityCaseId || serviceAgreementId) {
      if (serviceAgreementId) {
        url += `?serviceAgreementId=${serviceAgreementId}`;
      } else if (qualityCaseId) {
        url += `?qualityCaseId=${qualityCaseId}`;
      }

      if (qualitySchemaId) {
        url += `&qualitySchemaId=${qualitySchemaId}`;
      } else if (qualitySchemaControlId) {
        url += `&qualitySchemaControlId=${qualitySchemaControlId}`;
      } else if (document) {
        url += `&document=${document}`;
      } else if (controlItemId) {
        url += `&controlItemId=${controlItemId}`;
      }
      if (coverImage) {
        url += `&coverImage=${coverImage}`;
      }

      if (isContract) {
        url += `&isContract=${isContract}`;
      }

      if (caseBuildingId) {
        url += `&caseBuildingId=${caseBuildingId}`;
      }
    } else if (customerId) {
      url += `?customerId=${customerId}`;

      if (isPlatform) {
        url += `&isPlatform=true`;
      }
    }

    let data = new window.FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.append(file.name, file);
    }

    return axios.post(url, data).then(res => {
      console.log('uploadFilesResult', res);
      dispatch({
        type: RECENT_UPLOADS,
        payload: res.data,
      });
      return res.data;
    });
  };
}

/**
 * @description Update file description
 * @param {Number} id fileId
 * @param {String} description file description
 * @return {Promise} Axios promise
 */
export function updateFileInfo(id, data) {
  return dispatch => {
    let url = API_URL + `/file/updateInfo/${id}`;

    return axios
      .post(url, data)
      .then(res => {
        //console.log('res', res);
      })
      .catch(err => {
        //console.log('err', err.response.data);
      });
  };
}

/**
 * @description Delete file
 * @param {Number} id fileId
 * @return {Promise} Axios promise
 */
export function deleteFile(id) {
  return dispatch => {
    let url = API_URL + `/file/delete/${id}`;

    return axios
      .post(url)
      .then(res => {
        // console.log('res', res);
      })
      .catch(err => {
        console.log('err', err.response.data);
      });
  };
}

/**
 * @description Delete document from case
 * @param {Number} id fileId
 * @return {Promise} Axios promise
 */
export function deleteDocument(id) {
  return dispatch => {
    let url = API_URL + `/file/delete/document/${id}`;

    return axios
      .post(url)
      .then(res => {
        // console.log('res', res);
      })
      .catch(err => {
        console.log('err', err.response.data);
      });
  };
}

/**
 * @description Get images from Quality Insurance case
 * @return {Promise} Promise
 */
export function getImages(data) {
  let url = API_URL + '/file/images';
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(res => {
          dispatch({
            type: SET_CASE_IMAGE_GALLERY,
            images: res.data,
          });

          resolve(res);
        })
        .then(err => reject(err));
    });
  };
}

/**
 * @description Reset gallery images
 */
export function resetGalleryImages() {
  return {
    type: SET_CASE_IMAGE_GALLERY,
    images: [],
  };
}

export function setGalleryImages(data) {
  return dispatch => {
    dispatch({
      type: SET_CASE_IMAGE_GALLERY,
      images: data,
    });
  };
}

/**
 * @description Add image to schema from gallery
 * @return {Promise} Promise
 */
export function addImageToSchema(data) {
  let url = API_URL + '/file/addImage';
  return dispatch => {
    return axios.post(url, data);
  };
}

///////////////////////// CASE DESCRIPTIONS /////////////////////////

/**
 * @description Update case description
 * @return {Promise} Promise
 */
export function updateCaseDescription(data, isServiceAgreement) {
  let url =
    API_URL +
    (isServiceAgreement
      ? '/serviceAgreements/description'
      : '/qualitycases/description');
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Reset case description
 * @return {Promise} Promise
 */
export function resetCaseDescription(data, isServiceAgreement) {
  let url =
    API_URL +
    (isServiceAgreement
      ? '/serviceAgreements/description/reset'
      : '/qualitycases/description/reset');
  return dispatch => {
    return axios.post(url, data);
  };
}

///////////////////////// BUILDINGS /////////////////////////

/**
 * @description Add building to case
 * @return {Promise} Promise
 */
export function addBuildingToCase(data) {
  let url = API_URL + '/qualitycases/buildings';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update building on case
 * @return {Promise} Promise
 */
export function updateBuildingOnCase(data) {
  let url = API_URL + '/qualitycases/buildings/update';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Remove building on case
 * @return {Promise} Promise
 */
export function removeBuildingOnCase(data) {
  let url = API_URL + '/qualitycases/buildings/remove';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Copy building on case
 * @return {Promise} Promise
 */
export function copyBuildingOnCase(data) {
  let url = API_URL + '/qualitycases/buildings/copy';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update case responsible
 * @return {Promise} Promise
 */
export function updateResponsibleOnCase(id, data) {
  let url = API_URL + `/qualitycases/case/${id}/update/responsible`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Move images to new control
 * @return {Promise} Promise
 */
export function moveImagesToNewControl(data) {
  let url = API_URL + `/qualitycases/moveimages`;
  return dispatch => {
    return axios.post(url, data);
  };
}
