import { GET_CUSTOMER_BRANDING } from '../constants';

const initialState = {
  customerBranding: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CUSTOMER_BRANDING:
      return {
        ...state,
        customerBranding: action.payload,
      };
    default:
      return state;
  }
};
