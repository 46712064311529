import React, { useState } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';

// Actions
import { moveImagesToNewControlItem } from '../../../redux/actions/schemas';
import { getAllMarkingReferences } from '../../../redux/actions/markings';
import { getImages } from '../../../redux/actions/cases';
import { refreshImageHash } from '../../../redux/actions/common';

// Utils
import PageSpinner from '../../PageSpinner';

// Components
import SchemaPage from './components/SchemaPage';

const MoveImagesModal = props => {
  const {
    cases,
    control,
    moveImagesToNewControlItem,
    show,
    toggle,
    callback,
    isServiceAgreement,
    getAllMarkingReferences,
    getImages,
  } = props;
  const { selectedCase } = cases;
  const [movingImages, setMovingImages] = useState(false);
  const [targetControlId, setTargetId] = useState(0);
  const [images, setImages] = useState([]);
  const [imageObjects, setImageObjects] = useState([]);
  const [page, setPage] = useState(1);
  const [showUnknownError, setUnknownError] = useState(false);
  const [schemaGroup, setGroup] = useState(0);
  const [modalTitle, setModalTitle] = useState(i18next.t(8319));
  const gallery = control.files;

  const toggleAction = () => {
    setGroup(null);
    setTargetId(0);
    setPage(1);
    setImages([]);
    setImageObjects([]);
    toggle();
  };

  const refreshImageReferences = () => {
    let dataObj = {};
    if (isServiceAgreement) {
      dataObj.saId = selectedCase.id;
    } else {
      dataObj.ksId = selectedCase.id;
    }

    getAllMarkingReferences(dataObj).catch(err => console.log(err));
    getImages({ id: selectedCase.id, type: selectedCase.type })
      .then(refreshImageHash)
      .catch(err => console.log(err));
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////// CONTENT ////////////////////////////////////////////////////////////////////////////////////////

  const renderContent = () => {
    const Page1 = () => {
      const imageAction = (id, imageObject) => {
        let updatedImages = [...images];
        if (updatedImages.includes(id)) {
          updatedImages = updatedImages.filter(x => x !== id);
        } else {
          updatedImages.push(id);
        }

        let updatedImageObjects = [...imageObjects];
        if (updatedImageObjects.includes(imageObject)) {
          updatedImageObjects = updatedImageObjects.filter(
            x => x !== imageObject,
          );
        } else {
          updatedImageObjects.push(imageObject);
        }

        setImages(updatedImages);
        setImageObjects(updatedImageObjects);
      };

      return (
        <div className='control-gallery'>
          {gallery.map((image, index) => {
            // console.log(image);

            let title = i18next.t(21276);
            if (image.fileDescription?.trim().length > 0) {
              title = image.fileDescription;
            }
            // const origName = image.originalFileName;
            const target = 'tp-' + image.id;

            return (
              <div
                key={index}
                className={
                  'image-wrapper' + (images.includes(image.id) ? ' active' : '')
                }
                onClick={() => imageAction(image.id, image)}
              >
                <img className='image' src={image.thumbUrl} alt={title} />
                <div className='name-wrap'>
                  <p id={target} className='name'>
                    {title}
                  </p>
                </div>
                <UncontrolledTooltip className='image-tooltip' target={target}>
                  {title}
                </UncontrolledTooltip>
              </div>
            );
          })}
        </div>
      );
    };

    const Page2 = () => {
      const selectControl = id => {
        setTargetId(id);
      };

      let sharedSchemasProps = {
        currentCase: selectedCase,
        selectControl,
        setPage,
        setGroup,
        schemaGroup,
        _targetControlId: targetControlId,
        imageObjects,
      };
      return <SchemaPage {...sharedSchemasProps} />;
    };

    /////////////////////////////////////////////////////////////////

    const renderErrors = () => {
      if (!showUnknownError) return null;

      return (
        <div className='error-wrapper'>
          <p>{i18next.t(8318)}</p>
        </div>
      );
    };

    let content;
    if (page === 1) content = Page1();
    if (page === 2 || page === 3) content = Page2();

    return (
      <div className='move-images-wrapper'>
        {content}
        {renderErrors()}
      </div>
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////// FOOOTER /////////////////////////////////////////////////////////////////////////////////////////

  const renderFooter = () => {
    const cancelButton = (
      <Button
        onClick={toggleAction}
        disabled={movingImages}
        color='btnSecondary'
        outline
      >
        {i18next.t(2)}
      </Button>
    );

    const moveImages = () => {
      setMovingImages(true);
      setUnknownError(false);

      const moveReq = {
        caseId: selectedCase.id,
        originControlId: control.id,
        targetControlId,
        images,
      };

      moveImagesToNewControlItem(moveReq)
        .then(() => {
          toggleAction();
          if (callback) callback();
          refreshImageReferences();

          setMovingImages(false);
        })
        .catch(err => {
          console.log(err);

          setMovingImages(false);
          setUnknownError(true);
        });
    };

    const markAll = () => {
      const allIds = gallery.map(x => x.id);
      setImages(allIds);
    };

    let content;
    switch (page) {
      case 1:
        content = (
          <>
            <Button onClick={markAll}>{i18next.t(8323)}</Button>
            <div className='right-btns'>
              {cancelButton}
              <Button
                onClick={() => {
                  setModalTitle(i18next.t(9125));
                  setPage(2);
                }}
                disabled={images.length === 0}
                color='btnSecondary'
              >
                {i18next.t(254)}
              </Button>
            </div>
          </>
        );
        break;
      case 2:
      case 3:
        const action =
          schemaGroup > 0
            ? () => {
                setTargetId(0);
              }
            : () => {
                setModalTitle(i18next.t(8319));
                setPage(1);
              };
        content = (
          <>
            <Button
              onClick={action}
              className='goback'
              outline
              disabled={movingImages}
            >
              {i18next.t(1039)}
            </Button>
            <div className='right-btns'>
              {cancelButton}
              <Button
                onClick={moveImages}
                disabled={movingImages || targetControlId === 0}
                color='btnSecondary'
              >
                {i18next.t(1180)}
              </Button>
            </div>
          </>
        );
        break;
      default:
        content = cancelButton;
        break;
    }

    return (
      <ModalFooter>
        <div className='footer-btns move-images'>{content}</div>
      </ModalFooter>
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////// Feedback modal /////////////////////////////////////////////////////////////////////////////

  const feedbackModal = () => {
    return (
      <Modal
        className='custom-modal overlay'
        isOpen={movingImages}
        toggle={() => {}}
        fade={false}
        size={'sm'}
        centered
        backdrop={false}
      >
        <ModalBody>
          <PageSpinner />
        </ModalBody>
      </Modal>
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <Modal
      className='custom-modal moving-images'
      isOpen={show}
      toggle={toggleAction}
      fade={false}
      size={'xl'}
      centered
    >
      <ModalHeader toggle={toggleAction}>{modalTitle}</ModalHeader>
      <ModalBody>
        {renderContent()}
        {feedbackModal()}
      </ModalBody>
      {renderFooter()}
    </Modal>
  );
};

function mapStateToProps({ auth, cases }) {
  return {
    auth,
    cases,
  };
}

export default connect(mapStateToProps, {
  moveImagesToNewControlItem,
  getAllMarkingReferences,
  getImages,
  refreshImageHash,
})(MoveImagesModal);
