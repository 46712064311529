import * as React from 'react';

const SvgIconKvalitetssikring = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <path data-name='Rectangle 1170' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 151'>
      <path data-name='Rectangle 1171' fill='none' d='M0 0h24v24H0z' />
      <g data-name='Group 150'>
        <path
          data-name='Path 238'
          d='M12.001 10.868a2.178 2.178 0 1 1-2.178 2.178 2.178 2.178 0 0 1 2.178-2.178'
        />
        <path
          data-name='Path 239'
          d='M12 18.534a5.445 5.445 0 1 1 5.445-5.445A5.445 5.445 0 0 1 12 18.534M8.733 2.2l-2 2.178H3.288A2.184 2.184 0 0 0 1.11 6.556v13.067A2.177 2.177 0 0 0 3.288 21.8H20.71a2.177 2.177 0 0 0 2.178-2.178V6.556a2.184 2.184 0 0 0-2.178-2.178h-3.447l-2-2.178Z'
        />
        <path data-name='Rectangle 1172' fill='none' d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
);

export default SvgIconKvalitetssikring;
