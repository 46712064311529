import i18next from '../../../i18n';

export function GetQuestion(number, type, schemaType) {
  // Type is the assigneeType
  const questions = [
    {
      number: 1,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2000),
    },
    {
      number: 2,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2001),
    },
    {
      number: 3,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2002),
    },
    {
      number: 4,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2003),
    },
    {
      number: 5,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2004),
    },
    {
      number: 6,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2005),
    },
    {
      number: 7,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2006),
    },
    {
      number: 8,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2007),
    },
    {
      number: 9,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2008),
    },
    {
      number: 10,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2009),
    },
    {
      number: 11,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2039),
    },
    {
      number: 12,
      group: 1,
      schema: 1,
      type: 3,
      text: i18next.t(2011),
    },
    {
      number: 13,
      group: 3,
      schema: 1,
      type: 3,
      text: i18next.t(2012),
    },
    {
      number: 1,
      group: 1,
      schema: 1,
      type: 1,
      text: i18next.t(2013),
    },
    {
      number: 2,
      group: 1,
      schema: 1,
      type: 1,
      text: i18next.t(2014),
    },
    {
      number: 3,
      group: 1,
      schema: 1,
      type: 1,
      text: i18next.t(2015),
    },
    {
      number: 4,
      group: 1,
      schema: 1,
      type: 1,
      text: i18next.t(2016),
    },
    {
      number: 5,
      group: 1,
      schema: 1,
      type: 1,
      text: i18next.t(2017),
    },
    {
      number: 6,
      group: 2,
      schema: 1,
      type: 1,
      text: i18next.t(2047),
    },
    {
      number: 7,
      group: 3,
      schema: 1,
      type: 1,
      text: i18next.t(2019),
    },
    {
      number: 1,
      group: 1,
      schema: 1,
      type: 2,
      text: i18next.t(2020),
    },
    {
      number: 2,
      group: 1,
      schema: 1,
      type: 2,
      text: i18next.t(2050),
    },
    {
      number: 3,
      group: 1,
      schema: 1,
      type: 2,
      text: i18next.t(2022),
    },
    {
      number: 4,
      group: 1,
      schema: 1,
      type: 2,
      text: i18next.t(2023),
    },
    {
      number: 5,
      group: 1,
      schema: 1,
      type: 2,
      text: i18next.t(2017),
    },
    {
      number: 6,
      group: 2,
      schema: 1,
      type: 2,
      text: i18next.t(2025),
    },
    {
      number: 7,
      group: 2,
      schema: 1,
      type: 2,
      text: i18next.t(2055),
    },
    {
      number: 8,
      group: 3,
      schema: 1,
      type: 2,
      text: i18next.t(2027),
    },
    {
      number: 9,
      group: 3,
      schema: 1,
      type: 2,
      text: i18next.t(2028),
    },
    // DBI Schema
    {
      number: 1,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2000),
    },
    {
      number: 2,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2001),
    },
    {
      number: 3,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2002),
    },
    {
      number: 4,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2003),
    },
    {
      number: 5,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2004),
    },
    {
      number: 6,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2005),
    },
    {
      number: 7,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2006),
    },
    {
      number: 8,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2036),
    },
    {
      number: 9,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2037),
    },
    {
      number: 10,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2038),
    },
    {
      number: 11,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2039),
    },
    {
      number: 12,
      group: 1,
      schema: 101,
      type: 3,
      text: i18next.t(2011),
    },
    {
      number: 13,
      group: 3,
      schema: 101,
      type: 3,
      text: i18next.t(2012),
    },
    {
      number: 1,
      group: 1,
      schema: 101,
      type: 1,
      text: i18next.t(2042),
    },
    {
      number: 2,
      group: 1,
      schema: 101,
      type: 1,
      text: i18next.t(2043),
    },
    {
      number: 3,
      group: 1,
      schema: 101,
      type: 1,
      text: i18next.t(2015),
    },
    {
      number: 4,
      group: 1,
      schema: 101,
      type: 1,
      text: i18next.t(2045),
    },
    {
      number: 5,
      group: 1,
      schema: 101,
      type: 1,
      text: i18next.t(2017),
    },
    {
      number: 6,
      group: 2,
      schema: 101,
      type: 1,
      text: i18next.t(2047),
    },
    {
      number: 7,
      group: 3,
      schema: 101,
      type: 1,
      text: i18next.t(2019),
    },
    {
      number: 1,
      group: 1,
      schema: 101,
      type: 2,
      text: i18next.t(2020),
    },
    {
      number: 2,
      group: 1,
      schema: 101,
      type: 2,
      text: i18next.t(2050),
    },
    {
      number: 3,
      group: 1,
      schema: 101,
      type: 2,
      text: i18next.t(2022),
    },
    {
      number: 4,
      group: 1,
      schema: 101,
      type: 2,
      text: i18next.t(2023),
    },
    {
      number: 5,
      group: 1,
      schema: 101,
      type: 2,
      text: i18next.t(2017),
    },
    {
      number: 6,
      group: 2,
      schema: 101,
      type: 2,
      text: i18next.t(2025),
    },
    {
      number: 7,
      group: 2,
      schema: 101,
      type: 2,
      text: i18next.t(2055),
    },
    {
      number: 8,
      group: 3,
      schema: 101,
      type: 2,
      text: i18next.t(2027),
    },
    {
      number: 9,
      group: 3,
      schema: 101,
      type: 2,
      text: i18next.t(2028),
    },
    //////////////////////////////////// DBI 2021 ////////////////////////////////////
    //////////////////////////////////// DBI 2021 ////////////////////////////////////
    //// Bestiller ////
    //// Bestiller ////
    {
      number: 1,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(2000),
    },
    {
      number: 2,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4006),
    },
    {
      number: 3,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4007),
    },
    {
      number: 4,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4008),
    },
    {
      number: 5,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4009),
    },
    {
      number: 6,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4010),
    },
    {
      number: 7,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4011),
    },
    {
      number: 8,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4012),
    },
    {
      number: 9,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4013),
    },
    {
      number: 10,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(2039),
    },
    {
      number: 11,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4015),
    },
    {
      number: 12,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4016),
    },
    {
      number: 13,
      group: 1,
      schema: 102,
      type: 3,
      text: i18next.t(4017),
    },
    {
      number: 14,
      group: 3,
      schema: 102,
      type: 3,
      text: i18next.t(2012),
    },
    //// Udførende ////
    //// Udførende ////
    {
      number: 1,
      group: 1,
      schema: 102,
      type: 1,
      text: i18next.t(4019),
    },
    {
      number: 2,
      group: 1,
      schema: 102,
      type: 1,
      text: i18next.t(4020),
    },
    {
      number: 3,
      group: 1,
      schema: 102,
      type: 1,
      text: i18next.t(4021),
    },
    {
      number: 4,
      group: 1,
      schema: 102,
      type: 1,
      text: i18next.t(4022),
    },
    {
      number: 5,
      group: 1,
      schema: 102,
      type: 1,
      text: i18next.t(4023),
    },
    {
      number: 6,
      group: 1,
      schema: 102,
      type: 1,
      text: i18next.t(2045),
    },
    {
      number: 7,
      group: 2,
      schema: 102,
      type: 1,
      text: i18next.t(4025),
    },
    {
      number: 8,
      group: 2,
      schema: 102,
      type: 1,
      text: i18next.t(4026),
    },
    {
      number: 9,
      group: 3,
      schema: 102,
      type: 1,
      text: i18next.t(2019),
    },
    {
      number: 10,
      group: 3,
      schema: 102,
      type: 1,
      text: i18next.t(4028),
    },
    //// Brandvagt ////
    //// Brandvagt ////
    {
      number: 1,
      group: 1,
      schema: 102,
      type: 2,
      text: i18next.t(4029),
    },
    {
      number: 2,
      group: 1,
      schema: 102,
      type: 2,
      text: i18next.t(4030),
    },
    {
      number: 3,
      group: 1,
      schema: 102,
      type: 2,
      text: i18next.t(4031),
    },
    {
      number: 4,
      group: 2,
      schema: 102,
      type: 2,
      text: i18next.t(4032),
    },
    {
      number: 5,
      group: 3,
      schema: 102,
      type: 2,
      text: i18next.t(2027),
    },
    {
      number: 6,
      group: 3,
      schema: 102,
      type: 2,
      text: i18next.t(2028),
    },
    //////////////////////////////////// Koebstaederne 2021 ////////////////////////////////////
    //////////////////////////////////// Koebstaederne 2021 ////////////////////////////////////
    //// Bestiller ////
    //// Bestiller ////
    {
      number: 1,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(2000),
    },
    {
      number: 2,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4038),
    },
    {
      number: 3,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4007),
    },
    {
      number: 4,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4008),
    },
    {
      number: 5,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4009),
    },
    {
      number: 6,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4010),
    },
    {
      number: 7,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4011),
    },
    {
      number: 8,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4012),
    },
    {
      number: 9,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4013),
    },
    {
      number: 10,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(2039),
    },
    {
      number: 11,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4015),
    },
    {
      number: 12,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4016),
    },
    {
      number: 13,
      group: 1,
      schema: 103,
      type: 3,
      text: i18next.t(4017),
    },
    {
      number: 14,
      group: 3,
      schema: 103,
      type: 3,
      text: i18next.t(2012),
    },
    //// Udførende ////
    //// Udførende ////
    {
      number: 1,
      group: 1,
      schema: 103,
      type: 1,
      text: i18next.t(4019),
    },
    {
      number: 2,
      group: 1,
      schema: 103,
      type: 1,
      text: i18next.t(4020),
    },
    {
      number: 3,
      group: 1,
      schema: 103,
      type: 1,
      text: i18next.t(4021),
    },
    {
      number: 4,
      group: 1,
      schema: 103,
      type: 1,
      text: i18next.t(4022),
    },
    {
      number: 5,
      group: 1,
      schema: 103,
      type: 1,
      text: i18next.t(4023),
    },
    {
      number: 6,
      group: 1,
      schema: 103,
      type: 1,
      text: i18next.t(2045),
    },
    {
      number: 11,
      group: 1,
      schema: 103,
      type: 1,
      text: i18next.t(2017),
    },
    {
      number: 7,
      group: 2,
      schema: 103,
      type: 1,
      text: i18next.t(4025),
    },
    {
      number: 8,
      group: 2,
      schema: 103,
      type: 1,
      text: i18next.t(4026),
    },
    {
      number: 9,
      group: 3,
      schema: 103,
      type: 1,
      text: i18next.t(2019),
    },
    {
      number: 10,
      group: 3,
      schema: 103,
      type: 1,
      text: i18next.t(4028),
    },
    //// Brandvagt ////
    //// Brandvagt ////
    {
      number: 1,
      group: 1,
      schema: 103,
      type: 2,
      text: i18next.t(4029),
    },
    {
      number: 2,
      group: 1,
      schema: 103,
      type: 2,
      text: i18next.t(4030),
    },
    {
      number: 3,
      group: 1,
      schema: 103,
      type: 2,
      text: i18next.t(4031),
    },
    {
      number: 7,
      group: 1,
      schema: 103,
      type: 2,
      text: i18next.t(2017),
    },
    {
      number: 4,
      group: 2,
      schema: 103,
      type: 2,
      text: i18next.t(4032),
    },
    {
      number: 5,
      group: 3,
      schema: 103,
      type: 2,
      text: i18next.t(2027),
    },
    {
      number: 6,
      group: 3,
      schema: 103,
      type: 2,
      text: i18next.t(2028),
    },

    //////////////////////////////////// QBE 2022 ////////////////////////////////////
    //////////////////////////////////// QBE 2022 ////////////////////////////////////
    //// Bestiller ////
    //// Bestiller ////
    {
      number: 1,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(2000),
    },
    {
      number: 2,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4006),
    },
    {
      number: 3,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4007),
    },
    {
      number: 4,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4008),
    },
    {
      number: 5,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4009),
    },
    {
      number: 6,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4010),
    },
    {
      number: 7,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4011),
    },
    {
      number: 8,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4012),
    },
    {
      number: 9,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4013),
    },
    {
      number: 10,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(2039),
    },
    {
      number: 11,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4015),
    },
    {
      number: 12,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4016),
    },
    {
      number: 13,
      group: 1,
      schema: 3,
      type: 3,
      text: i18next.t(4017),
    },
    {
      number: 14,
      group: 3,
      schema: 3,
      type: 3,
      text: i18next.t(2012),
    },
    //// Udførende ////
    //// Udførende ////
    {
      number: 1,
      group: 1,
      schema: 3,
      type: 1,
      text: i18next.t(4019),
    },
    {
      number: 2,
      group: 1,
      schema: 3,
      type: 1,
      text: i18next.t(4020),
    },
    {
      number: 3,
      group: 1,
      schema: 3,
      type: 1,
      text: i18next.t(4021),
    },
    {
      number: 4,
      group: 1,
      schema: 3,
      type: 1,
      text: i18next.t(4022),
    },
    {
      number: 5,
      group: 1,
      schema: 3,
      type: 1,
      text: i18next.t(4023),
    },
    {
      number: 6,
      group: 1,
      schema: 3,
      type: 1,
      text: i18next.t(2045),
    },
    {
      number: 7,
      group: 2,
      schema: 3,
      type: 1,
      text: i18next.t(4025),
    },
    {
      number: 8,
      group: 2,
      schema: 3,
      type: 1,
      text: i18next.t(4026),
    },
    {
      number: 9,
      group: 3,
      schema: 3,
      type: 1,
      text: i18next.t(2019),
    },
    {
      number: 10,
      group: 3,
      schema: 3,
      type: 1,
      text: i18next.t(4028),
    },
    //// Brandvagt ////
    //// Brandvagt ////
    {
      number: 1,
      group: 1,
      schema: 3,
      type: 2,
      text: i18next.t(4029),
    },
    {
      number: 2,
      group: 1,
      schema: 3,
      type: 2,
      text: i18next.t(4030),
    },
    {
      number: 3,
      group: 1,
      schema: 3,
      type: 2,
      text: i18next.t(4031),
    },
    {
      number: 4,
      group: 2,
      schema: 3,
      type: 2,
      text: i18next.t(4032),
    },
    {
      number: 5,
      group: 3,
      schema: 3,
      type: 2,
      text: i18next.t(2027),
    },
    {
      number: 6,
      group: 3,
      schema: 3,
      type: 2,
      text: i18next.t(2028),
    },

    //////////////////////////////////// TRUST 2023 ////////////////////////////////////
    //////////////////////////////////// TRUST 2023 ////////////////////////////////////
    //// Bestiller ////
    //// Bestiller ////
    {
      number: 1,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(2000),
    },
    {
      number: 2,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4006),
    },
    {
      number: 3,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4007),
    },
    {
      number: 4,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4008),
    },
    {
      number: 5,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4009),
    },
    {
      number: 6,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4010),
    },
    {
      number: 7,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4011),
    },
    {
      number: 8,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4012),
    },
    {
      number: 9,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4013),
    },
    {
      number: 10,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(2039),
    },
    {
      number: 11,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4015),
    },
    {
      number: 12,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4016),
    },
    {
      number: 13,
      group: 1,
      schema: 4,
      type: 3,
      text: i18next.t(4017),
    },
    {
      number: 14,
      group: 3,
      schema: 4,
      type: 3,
      text: i18next.t(2012),
    },
    //// Udførende ////
    //// Udførende ////
    {
      number: 1,
      group: 1,
      schema: 4,
      type: 1,
      text: i18next.t(4019),
    },
    {
      number: 2,
      group: 1,
      schema: 4,
      type: 1,
      text: i18next.t(4020),
    },
    {
      number: 3,
      group: 1,
      schema: 4,
      type: 1,
      text: i18next.t(4021),
    },
    {
      number: 4,
      group: 1,
      schema: 4,
      type: 1,
      text: i18next.t(4022),
    },
    {
      number: 5,
      group: 1,
      schema: 4,
      type: 1,
      text: i18next.t(4023),
    },
    {
      number: 6,
      group: 1,
      schema: 4,
      type: 1,
      text: i18next.t(2045),
    },
    {
      number: 7,
      group: 2,
      schema: 4,
      type: 1,
      text: i18next.t(4025),
    },
    {
      number: 8,
      group: 2,
      schema: 4,
      type: 1,
      text: i18next.t(4026),
    },
    {
      number: 9,
      group: 3,
      schema: 4,
      type: 1,
      text: i18next.t(2019),
    },
    {
      number: 10,
      group: 3,
      schema: 4,
      type: 1,
      text: i18next.t(4028),
    },
    //// Brandvagt ////
    //// Brandvagt ////
    {
      number: 1,
      group: 1,
      schema: 4,
      type: 2,
      text: i18next.t(4029),
    },
    {
      number: 2,
      group: 1,
      schema: 4,
      type: 2,
      text: i18next.t(4030),
    },
    {
      number: 3,
      group: 1,
      schema: 4,
      type: 2,
      text: i18next.t(4031),
    },
    {
      number: 4,
      group: 2,
      schema: 4,
      type: 2,
      text: i18next.t(4032),
    },
    {
      number: 5,
      group: 3,
      schema: 4,
      type: 2,
      text: i18next.t(2027),
    },
    {
      number: 6,
      group: 3,
      schema: 4,
      type: 2,
      text: i18next.t(2028),
    },
  ];

  let arr = questions.filter(
    q => q.number === number && q.type === type && q.schema === schemaType,
  );

  if (arr.length > 0) {
    return arr[0];
  }

  return null;
}
