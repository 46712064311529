/**
 * @module actions/cases
 */
import axios from 'axios';
import { API_URL, SET_CASES_ALL, SET_CASE } from '../constants';

/**
 * @description Search hotworks
 * @param category All = 0, New = 1, Archived = 2
 * @return {Promise} Axios promise
 */
export function searchHotworks(category = 0) {
  let url = API_URL + '/hotwork/search/';
  return dispatch => {
    return axios.post(url, { category }).then(res => {
      dispatch({
        type: SET_CASES_ALL,
        cases: res.data,
      });
      return res.data;
    });
  };
}

/**
 * @description Get hotwork by Id
 * @return {Promise} Axios promise
 */
export function GetHotworkById(id, token = null) {
  let url = API_URL + '/hotwork/' + id;
  let config = {};

  if (token) {
    url += '/token';
    config.headers = { Authorization: `Bearer ${token}` };
  }

  return dispatch => {
    return axios.get(url, config).then(res => {
      let obj = {
        customer: res.data.customerData,
        case: res.data,
      };

      if (token) {
        obj.case = res.data.case;
        obj.assigneeType = res.data.assigneeType;
        obj.userId = res.data.userId;
      }

      dispatch({
        type: SET_CASE,
        selectedCase: obj,
      });

      return obj;
    });
  };
}

/**
 * @description Get hotwork information with a token
 * @return {Promise} Axios promise
 */
export function getHotworkWithToken(token) {
  let url = API_URL + '/hotwork/va?token=' + token;
  let config = { headers: { Authorization: `Bearer ${token}` } };

  return dispatch => {
    return axios.get(url, config).then(res => {
      dispatch({
        type: SET_CASE,
        selectedCase: res.data,
      });

      return res.data;
    });
  };
}

/**
 * @description Create hotwork
 * @return {Promise} Axios promise
 */
export function CreateHotwork(data) {
  let url = API_URL + '/hotwork';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update hotwork
 * @return {Promise} Axios promise
 */
export function UpdateHotwork(id, data) {
  let url = API_URL + `/hotwork/${id}/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Copy case
 * @return {Promise} Axios promise
 */
export function CopyHotwork(id) {
  let url = API_URL + `/hotwork/${id}/copy`;
  return dispatch => {
    return axios.post(url);
  };
}

/**
 * @description Delete case
 * @return {Promise} Axios promise
 */
export function DeleteHotwork(id) {
  let url = API_URL + `/hotwork/${id}/delete`;
  return dispatch => {
    return axios.post(url, null);
  };
}

/**
 * @description Resend user notifications for hotwork
 * @return {Promise} Promise
 */
export function resendUserNotificationHotwork(id, data) {
  // console.log(id, data);
  let url = API_URL + `/hotwork/resendUserNotificationVa/${id}`;
  return dispatch => {
    return axios.post(url, data);
  };
}
