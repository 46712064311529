import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Actions

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import InfoTip from '../../Custom/InfoTip';
import { Col, FormGroup, Label } from 'reactstrap';
import ReactSelect from '../../Custom/ReactSelect';
import LastEditor from '../../Custom/LastEditor';
import ControlItemMetaAdminModal from '../ControlItemMetaAdminModal';

const ControlItemDropdownUsers = props => {
  const {
    control,
    disabled,
    employees,
    areControlsValid,
    refreshGroups,
  } = props;
  const {
    title,
    alternativeTitle,
    userOptions,
    dropdownSelectedUsers,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    placeholder,
    multipleChoice,
    errorMessage,
  } = control;
  const [valid, setValid] = useState(true);
  const allowedTypes = userOptions?.map(x => x.type) ?? [];
  const selectedIds = dropdownSelectedUsers?.map(x => x.userId) ?? [];
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [dropdownSelectedUsers]);

  const onChange = (name, value) => {
    props.onChangeArray(name, value);
  };

  const onChangeSelection = (name, value) => {
    let val = !!value ? value : [];
    if (!multipleChoice) val = [val];

    val = val.map(x => {
      return {
        userId: x.value,
      };
    });

    onChange(name, val);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  const sharedProps = {
    placeholder: placeholder,
    closeMenuOnSelect: !multipleChoice,
    isDisabled: disabled,
    isMulti: multipleChoice,
  };

  const renderDropdown = () => {
    let options = [];
    let value;

    if (allowedTypes?.length > 0) {
      options = employees
        .filter(x => allowedTypes.includes(x.employeeType))
        .map(x => {
          return {
            label: x.name,
            value: x.id,
          };
        });
    }

    if (dropdownSelectedUsers?.length > 0) {
      if (multipleChoice) {
        value = options.filter(x => selectedIds.includes(x.value));
      } else {
        // We expect only ONE id in the list.
        value = options.find(x => selectedIds.includes(x.value));
      }
    }

    return (
      <ReactSelect
        name='dropdownSelectedUsers'
        options={options}
        value={value}
        onChange={v => onChangeSelection('dropdownSelectedUsers', v)}
        {...sharedProps}
      />
    );
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <FormGroup row>
      <Label sm={labelSize}>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor {...control} />
      </Label>
      <Col sm={inputSize}>
        {renderDropdown()}
        {!disabled && (
          <ControlItemMetaAdminModal
            control={control}
            callback={refreshGroups}
          />
        )}
      </Col>
    </FormGroup>
  );
};

function mapStateToProps({ auth, schemas, cases, employees }) {
  return {
    auth,
    schemas,
    cases,
    employees: employees.employees,
  };
}

export default connect(mapStateToProps, {})(ControlItemDropdownUsers);
