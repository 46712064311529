import * as React from 'react';

const SvgIconSprog = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      d='M11.99 1.5A10.5 10.5 0 1 0 22.5 12 10.495 10.495 0 0 0 11.99 1.5Zm7.276 6.3h-3.1a16.432 16.432 0 0 0-1.446-3.738A8.431 8.431 0 0 1 19.266 7.8ZM12 3.642A14.791 14.791 0 0 1 14.005 7.8h-4.01A14.791 14.791 0 0 1 12 3.642ZM3.873 14.1a8.213 8.213 0 0 1 0-4.2h3.549a17.341 17.341 0 0 0-.147 2.1 17.341 17.341 0 0 0 .147 2.1Zm.861 2.1h3.1a16.432 16.432 0 0 0 1.449 3.738A8.386 8.386 0 0 1 4.734 16.2Zm3.1-8.4h-3.1A8.386 8.386 0 0 1 9.28 4.062 16.432 16.432 0 0 0 7.832 7.8ZM12 20.358A14.791 14.791 0 0 1 9.995 16.2h4.011A14.791 14.791 0 0 1 12 20.358Zm2.457-6.258H9.543a15.448 15.448 0 0 1-.168-2.1 15.314 15.314 0 0 1 .168-2.1h4.914a15.314 15.314 0 0 1 .168 2.1 15.448 15.448 0 0 1-.168 2.1Zm.263 5.838a16.432 16.432 0 0 0 1.449-3.738h3.1a8.431 8.431 0 0 1-4.549 3.738Zm1.858-5.838a17.341 17.341 0 0 0 .147-2.1 17.342 17.342 0 0 0-.147-2.1h3.549a8.213 8.213 0 0 1 0 4.2Z'
      // fill='#004388'
    />
    <path fill='none' d='M0 0h24v24H0z' />
  </svg>
);

export default SvgIconSprog;
