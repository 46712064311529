/**
 * @module actions/error
 */
import axios from 'axios';
import { ADD_TRACE_LOG, API_URL } from '../constants';

/**
 * @description Send error log
 * @return {Promise} Axios promise
 */
export function sendErrorLog(data) {
  let url = API_URL + `/error`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Add trace log for errors
 * @return {Promise} Axios promise
 */
export function addTraceLog(message) {
  return dispatch => {
    dispatch({
      type: ADD_TRACE_LOG,
      payload: message,
    });
  };
}
