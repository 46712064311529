import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Actions
import {
  getUserAvatar,
  getSpecificUserAvatar,
} from '../../redux/actions/employees';

// Utils

// Components
import { Spinner } from 'reactstrap';
import { generateInitials } from '../../utils/common';
import { IconProfil } from '../../assets/icons_v2';

const UserAvatar = props => {
  const { updating, userId, name, uri } = props;
  const [isLoading, setLoading] = useState(true);
  const [showInitials, setInitials] = useState(!!name);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (uri?.trim().length > 0) {
      setLoading(false);
      setInitials(false);
      setImage({ fileName: 'avatar.png', path: uri });
      return;
    }

    const action = userId ? props.getSpecificUserAvatar : props.getUserAvatar;
    action(userId)
      .then(res => {
        if (res?.data) {
          let imageObj = {
            name: res.data.fileName,
            fileName: res.data.fileName,
            path: res.data.fileUrl,
          };
          setImage(imageObj);
          setInitials(false);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [updating]);

  const handleClick = () => {
    if (props.handleClick) {
      props.handleClick();
    }
  };

  const isClickable = () => {
    if (props.handleClick) {
      return ' clickable';
    }
    return '';
  };

  const smallClass = () => {
    if (props.small) return ' small';
    return '';
  };

  const extraClasses = () => {
    if (props.className) return ' ' + props.className;
    return '';
  };

  const isInitials = () => {
    if (props.name) return ' initials';
    return '';
  };

  const classNames =
    'user_avatar' + isClickable() + smallClass() + extraClasses();

  if (isLoading || props.updating) {
    return <Spinner className={'user_avatar_spinner' + smallClass()} />;
  }

  if (showInitials) {
    return (
      <div className={classNames + isInitials()}>{generateInitials(name)}</div>
    );
  }

  if (!image) {
    return (
      <IconProfil
        className={classNames}
        fill={props.color ? props.color : '#fff'}
      />
    );
  }

  return (
    <img
      src={image.path}
      className={classNames}
      alt='User avatar'
      onClick={handleClick}
    />
  );
};

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

export default connect(mapStateToProps, {
  getUserAvatar,
  getSpecificUserAvatar,
})(UserAvatar);
