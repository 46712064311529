import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { FormText } from 'reactstrap';

// Utils
import { SB_CONTROL_TYPE } from '../../redux/constants';
import { createGalleryObjectsFromReferences } from '../../utils/componentUtils';

const ReferenceGallery = props => {
  const { imageHash, onClick, control, schema } = props;
  const [imageBindingInfo, setImageBindingInfo] = useState(null);

  useEffect(() => {
    const allowedTypes = [
      SB_CONTROL_TYPE.PhotoDocumentation,
      SB_CONTROL_TYPE.TextPhotos,
      SB_CONTROL_TYPE.DropdownCommentPhotos,
    ];

    const foundExistingRefs = props.markings?.allImageRefs.find(
      x => x.controlId === control?.id,
    );

    if (!!foundExistingRefs) {
      const initialGuid = uuidv4();

      const prefixId = schema.rawControlItems
        .filter(x => allowedTypes.includes(x.controlType))
        .findIndex(x => x.id === control?.id);

      //console.log('useEffectInput', foundExistingRefs, prefixId, control);

      const result = createGalleryObjectsFromReferences(
        foundExistingRefs,
        initialGuid,
        `${prefixId + 1}.`,
      );

      setImageBindingInfo(result);
    } else {
      setImageBindingInfo({ imgGuids: {}, bindings: {} });
    }

    // eslint-disable-next-line
  }, [props.markings?.allImageRefs]);

  const renderImage = (file, key) => {
    let title =
      file.fileDescription?.trim().length > 0 ? file.fileDescription : '';
    let prefix;
    const ref = imageBindingInfo?.imgGuids[file.id];

    if (ref) {
      if (imageBindingInfo.bindings[ref]) {
        prefix = imageBindingInfo.bindings[ref].displayId;

        if (title?.length > 0) {
          prefix += ':';
        }
      }
    }

    if (!!prefix) {
      title = `${prefix} ${title}`;
    }

    const action = () => {
      onClick(file, key);
    };

    //console.log('gallery', file);

    let src = !file.cached
      ? `${file.thumbUrl}?${imageHash}`
      : `${file.thumbUrl}`;

    return (
      <div
        className='gallery__figure'
        onClick={action}
        key={`${key}-${imageHash}`}
      >
        <img src={src} alt={title} className='gallery__img' key={imageHash} />
        <FormText className='gallery__text'>{title}</FormText>
      </div>
    );
  };

  return props.files.map(renderImage);
};

ReferenceGallery.defaultProps = {
  files: [],
  onClick: () => {},
  imageHash: 0,
};

function mapStateToProps({ markings }) {
  return {
    markings,
  };
}

export default connect(mapStateToProps, null)(ReferenceGallery);
