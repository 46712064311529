import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

// Utils
import { languages } from '../../utils/translation/langauges';

// Flags

// Actions

const LangaugePicker = props => {
  const [showFlags, setShowFlags] = useState(false);
  const lang = window.localStorage.getItem('user_lang');

  const setLang = lang => {
    if (!lang || lang?.trim().length === 0) {
      lang = 'en';
    }

    window.localStorage.setItem('user_lang', lang);
    setShowFlags(false);
    window.location.reload();
  };

  const getCurrentLangIcon = () => {
    let tmp = languages.find(x => x.lang === lang);
    if (!tmp) languages.find(x => x.lang === 'en');

    return tmp.icon;
  };

  const renderFlags = () => {
    let cl = 'language-picker-flags';

    // Adjust class?
    //console.log(props.language.currentLanguage)

    const onAction = item => {
      setShowFlags(false);
      setLang(item.lang);
    };

    return (
      <div className={cl}>
        {languages
          .filter(x => x.lang !== lang)
          .map((x, index) => {
            return (
              <Button
                key={x.name + '_' + index}
                color='none'
                onClick={() => onAction(x)}
              >
                {x.icon}
              </Button>
            );
          })}
      </div>
    );
  };

  return (
    <div className='language-picker'>
      {showFlags && renderFlags()}
      <Button color='none' onClick={() => setShowFlags(!showFlags)}>
        {getCurrentLangIcon()}
      </Button>
    </div>
  );
};

function mapStateToProps({ language }) {
  return {
    language,
  };
}

export default connect(mapStateToProps, {})(LangaugePicker);
