import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';

// Actions
import {
  getSingleProduct,
  getSingleProductCategory,
} from '../../../redux/actions/products';

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import InfoTip from '../../Custom/InfoTip';
import { Col, FormGroup, Label } from 'reactstrap';
import ReactSelect from '../../Custom/ReactSelect';
import LastEditor from '../../Custom/LastEditor';
import ControlItemMetaAdminModal from '../ControlItemMetaAdminModal';

const ControlItemDropdownProducts = props => {
  const { control, disabled, areControlsValid, refreshGroups } = props;
  const { products } = props.products;
  const {
    title,
    alternativeTitle,
    allCategories,
    categoryOptions,
    productOptions,
    categoryValues,
    productValues,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    placeholder,
    multipleChoice,
    errorMessage,
  } = control;
  const [categoryDropDownOptions, setCategoryDropDownOptions] = useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);

  const [productDropDownOptions, setProductDropDownOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [valid, setValid] = useState(true);
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));

    let hasCategoryValues =
      (categoryOptions?.length > 0 || allCategories) &&
      (!!categoryValues || categoryValues?.trim().length > 0);
    let options = [];

    let cOptions = [];

    let customerOptions = [];
    let value;
    let selectedCategories = [];

    if (hasCategoryValues) {
      selectedCategories = categoryValues
        .split(',')
        .filter(x => !!x || x?.length > 0)
        .map(x => parseInt(x));
    } else if (categoryOptions?.length > 0 && productOptions?.length === 0) {
      selectedCategories = categoryOptions.map(x => x.productCategoryId);
      hasCategoryValues = true;
    }

    if (allCategories) {
      products.forEach(x => {
        if (selectedCategories.includes(x.id)) {
          console.log(x);
          x.products.forEach(y => {
            const data = {
              label: y.name,
              value: y.id,
              order: y.sortOrder,
            };

            if (!y.customerId) options.push(data);
            if (!!y.customerId) customerOptions.push(data);
          });
        }
        cOptions.push({ label: x.name, value: x.id });
      });
    } else if (productOptions?.length > 0) {
      const prodIds = productOptions.map(x => x.productId);

      products.forEach(x => {
        x.products
          .filter(y => prodIds.includes(y.id))
          .forEach(y => {
            const data = {
              label: y.name,
              value: y.id,
              order: y.sortOrder,
            };

            if (!y.customerId) options.push(data);
            if (!!y.customerId) customerOptions.push(data);
          });
      });
    } else if (categoryOptions?.length > 0) {
      const catIds = categoryOptions.map(x => x.productCategoryId);
      const categories = products.filter(x => catIds.includes(x.id));

      categories.forEach(x => {
        if (selectedCategories.includes(x.id)) {
          x.products.forEach(y => {
            const data = {
              label: y.name,
              value: y.id,
              order: y.sortOrder,
            };

            if (!y.customerId) options.push(data);
            if (!!y.customerId) customerOptions.push(data);
          });
        }
        cOptions.push({ label: x.name, value: x.id });
      });
    }

    options.sort((a, b) => a.sortOrder - b.sortOrder);
    customerOptions.sort((a, b) => a.sortOrder - b.sortOrder);
    options = [...options, ...customerOptions];
    setProductDropDownOptions(options);
    setCategoryDropDownOptions(cOptions);

    if (categoryValues?.trim().length > 0) {
      const splitted = categoryValues
        .split(',')
        .filter(x => !!x || x?.length > 0);
      //console.log('check', splitted, cOptions, allCategories);

      if (multipleChoice) {
        value = [];
        if (splitted.length > 0) {
          splitted.forEach(x => {
            var val = cOptions.find(y => y.value + '' === x);
            if (val) {
              value.push(val);
            } else {
              props.getSingleProductCategory(x).then(res => {
                if (res) {
                  // Add to options only as long as it is on the schema
                  value.push({ label: res.name, value: res.id });
                  cOptions.push(value);
                  setSelectedCategoryValue(value);
                  setCategoryDropDownOptions(cOptions);
                }
              });
            }
          });
        }
        setSelectedCategoryValue(value);
      } else {
        value = cOptions.find(x => splitted.includes(x.value + ''));
        setSelectedCategoryValue(value);
        if (splitted.length > 0) {
          if (!value && splitted[0] !== null) {
            // Get specific missing category
            props.getSingleProductCategory(splitted[0]).then(res => {
              if (res) {
                // Add to options only as long as it is on the schema
                value = { label: res.name, value: res.id };
                cOptions.push(value);
                setSelectedCategoryValue(value);
                setCategoryDropDownOptions(cOptions);
              }
            });
          }
        }
      }
    }

    if (productValues?.trim().length > 0) {
      const splitted = productValues
        .split(',')
        .filter(x => !!x || x?.length > 0);
      //console.log('check', splitted, options, products);
      if (multipleChoice) {
        value = [];
        if (splitted.length > 0) {
          splitted.forEach(x => {
            var val = options.find(y => y.value + '' === x);
            if (val) {
              value.push(val);
            } else {
              props.getSingleProduct(x).then(res => {
                if (res) {
                  // Add to options only as long as it is on the schema
                  value.push({ label: res.name, value: res.id });
                  options.push({ label: res.name, value: res.id });
                  setSelectedValue(value);
                  setProductDropDownOptions(options);
                }
              });
            }
          });
        }
        setSelectedValue(value);
      } else {
        value = options.find(x => splitted.includes(x.value + ''));
        setSelectedValue(value);
        if (splitted.length > 0) {
          if (!value && splitted[0] !== null) {
            // Get specific missing product
            props.getSingleProduct(splitted[0]).then(res => {
              if (res) {
                // Add to options only as long as it is on the schema
                value = { label: res.name, value: res.id };
                options.push(value);
                setSelectedValue(value);
                setProductDropDownOptions(options);
              }
            });
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [productValues, categoryValues]);

  const onChange = (name, value) => {
    props.onChange({
      target: {
        name,
        value,
      },
    });
  };

  const onChangeSelection = (name, value) => {
    let val = !!value ? value : [];
    if (!multipleChoice) val = [val];

    val = val.reduce((prev, cur) => prev + cur.value + ',', '');

    onChange(name, val);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  const sharedProps = {
    placeholder: placeholder,
    closeMenuOnSelect: !multipleChoice,
    isDisabled: disabled,
    isMulti: multipleChoice,
  };

  const renderCategoryDropdown = () => {
    return (
      <ReactSelect
        name='categoryValues'
        options={categoryDropDownOptions}
        value={selectedCategoryValue}
        onChange={v => onChangeSelection('categoryValues', v)}
        {...sharedProps}
      />
    );
  };

  const renderProductDowndown = () => {
    let hasCategoryValues =
      (categoryOptions?.length > 0 || allCategories) &&
      (!!categoryValues || categoryValues?.trim().length > 0);

    if (categoryOptions?.length > 0 && productOptions?.length === 0) {
      hasCategoryValues = true;
    }

    return (
      <ReactSelect
        name='productValues'
        options={productDropDownOptions}
        value={selectedValue}
        onChange={v => onChangeSelection('productValues', v)}
        {...sharedProps}
        isDisabled={disabled || (!hasCategoryValues && allCategories)}
      />
    );
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  if (allCategories) {
    return (
      <div>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor {...control} />
        <br />
        <FormGroup row>
          <Label sm={labelSize}>
            <div className='label-wrap'>{i18next.t(9035)}</div>
          </Label>
          <Col sm={inputSize}>{renderCategoryDropdown()}</Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelSize}>
            <div className='label-wrap'>{i18next.t(9073)}</div>
          </Label>
          <Col sm={inputSize}>
            {renderProductDowndown()}
            {!disabled && (
              <ControlItemMetaAdminModal
                control={control}
                callback={refreshGroups}
              />
            )}
          </Col>
        </FormGroup>
      </div>
    );
  }

  return (
    <FormGroup row>
      <Label sm={labelSize}>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor {...control} />
      </Label>
      <Col sm={inputSize}>
        {renderProductDowndown()}
        {!disabled && (
          <ControlItemMetaAdminModal
            control={control}
            callback={refreshGroups}
          />
        )}
      </Col>
    </FormGroup>
  );
};

function mapStateToProps({ auth, schemas, cases, products }) {
  return {
    auth,
    schemas,
    cases,
    products,
  };
}

export default connect(mapStateToProps, {
  getSingleProduct,
  getSingleProductCategory,
})(ControlItemDropdownProducts);
