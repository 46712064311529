import * as React from 'react';

const SvgIconKundeVirksomhed = props => (
  <svg
    data-name='Icon - Kunde_Virksomhed'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Kunde_Virksomhed_svg__a'>
        <path data-name='Rectangle 1253' d='M0 0h20.857v20.857H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1252' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 252'>
      <g
        data-name='Group 251'
        clipPath='url(#Icon_-_Kunde_Virksomhed_svg__a)'
        transform='translate(1.113 1.146)'
      >
        <path
          data-name='Path 297'
          d='M0 2.039h6.929V.614A.618.618 0 0 0 6.315 0H.602a.618.618 0 0 0-.614.614v1.425ZM17.844 16.4a.618.618 0 0 1-.614.614h-2.19a.618.618 0 0 1-.614-.614v-2.588a.618.618 0 0 1 .614-.614h2.19a.618.618 0 0 1 .614.614Zm-5.411 0a.618.618 0 0 1-.614.614H9.64a.618.618 0 0 1-.614-.614v-2.588a.618.618 0 0 1 .614-.614h2.19a.618.618 0 0 1 .614.614V16.4Zm8.424 3.847V6.35a.627.627 0 0 0-.3-.533.642.642 0 0 0-.614 0l-6.055 3.534V6.338a.627.627 0 0 0-.3-.533.642.642 0 0 0-.614 0l-6.06 3.557V3.256H0v16.987a.618.618 0 0 0 .614.614h19.64a.616.616 0 0 0 .6-.614'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconKundeVirksomhed;
