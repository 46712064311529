import React from 'react';
import ReactTooltip from 'react-tooltip';

export const getTooltip = (
  target,
  content,
  place = 'top',
  effect = 'solid',
) => {
  return (
    <ReactTooltip id={target} place={place} effect={effect}>
      {content}
    </ReactTooltip>
  );
};
