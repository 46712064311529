/**
 * @module actions/features
 */
import axios from 'axios';
import { API_URL, GET_FEATURES } from '../constants';
import { featureCheckingEnabled } from '../../utils/contentByAccessLevel';

/**
 * @description Retrieves available features of a customer
 * @param {Number} id Used to get a specific customers available features. Is nullable.
 */
export function getFeatures(id = null) {
  let url = API_URL + '/features';
  if (id) url += `?customerId=${id}`;

  return dispatch => {
    return axios.get(url).then(res => {
      let payload = res.data;

      if (!featureCheckingEnabled()) {
        payload = defaultAccess();
      }

      if (!id) {
        dispatch({
          type: GET_FEATURES,
          payload,
        });
      }

      return res;
    });
  };
}

/**
 * Add a new feature to the system and updates redux with the added feature.
 * @param {Object} data contains data for the new feature.
 * @returns A promise.
 */
export function addFeature(data) {
  let url = API_URL + '/features/add';

  return dispatch => {
    return axios.post(url, data).then(res => {
      let payload = res.data;

      if (!featureCheckingEnabled()) {
        payload = defaultAccess();
      }

      dispatch({
        type: GET_FEATURES,
        payload,
      });

      return res;
    });
  };
}

/**
 * Updates an existing feature in the system and refreshes features stored in redux.
 * @param {Object} data contains data for the update feature.
 * @returns A promise.
 */
export function updateFeature(data) {
  let url = API_URL + '/features/update';

  return dispatch => {
    return axios.post(url, data).then(res => {
      let payload = res.data;

      if (!featureCheckingEnabled()) {
        payload = defaultAccess();
      }

      dispatch({
        type: GET_FEATURES,
        payload,
      });

      return res;
    });
  };
}

/**
 * Toggles a customers feature.
 * @param {Object} data Contains the feature
 * @returns A promise
 */
export function toggleFeature(data) {
  let url = API_URL + '/features/toggle';

  return dispatch => {
    return axios.post(url, data).then(res => {
      let payload = res.data;

      if (!featureCheckingEnabled()) {
        payload = defaultAccess();
      }

      dispatch({
        type: GET_FEATURES,
        payload,
      });

      return res;
    });
  };
}

/**
 * Grant a customer a feature.
 * @param {Object} data Contains the feature
 * @returns A promise
 */
export function grantFeature(data) {
  let url = API_URL + '/features/grant';

  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * The purpose of this function is to provide default access when feature checking is disabled.
 * @returns Array of features, all of active.
 */
function defaultAccess() {
  return [
    {
      id: 1,
      featureId: 1,
      title: 'Varmt Arbejde',
      subscription: 1,
      type: 0,
      active: true,
    },
    {
      id: 2,
      featureId: 2,
      title: 'Kvalitetssikring',
      subscription: 1,
      type: 1,
      active: true,
    },
    {
      id: 3,
      featureId: 3,
      title: 'Service Aftaler',
      subscription: 1,
      type: 2,
      active: true,
    },
  ];
}
