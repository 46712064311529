import React, { useState, useEffect } from 'react';
import i18next from '../../i18n';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormText,
  Spinner,
} from 'reactstrap';
import parse from 'html-react-parser';

// Actions
import {
  getUserTermsOfAgreement,
  acceptTermsOfAgreement,
  handledTermsOfAgreement,
} from '../../redux/actions/common';
import { logout } from '../../redux/actions/auth';

// Utils
import { parseDate } from '../../utils/common';
import { displayCurrentLangObj } from '../../utils/translation/langauges';

// Elements
import PageSpinner from '../PageSpinner';
import ConfirmModal from './ConfirmModal';
import Typography from '../Typography';

const TermsOfAgreementModal = props => {
  const { extraClass, language } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const [terms, setTerms] = useState([]);
  const [atBottom, setAtBottom] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);

  useEffect(() => {
    if (!props.common.termsOfAgreementHandled) {
      props
        .getUserTermsOfAgreement()
        .then(res => {
          const { terms, allAccepted } = res.data;
          // this.props.handledTermsOfAgreement(!allAccepted);
          setTerms(terms);
          setShow(!allAccepted);
          setLoading(false);
          //this.setState({ terms, show: !allAccepted, loading: false });
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  const handleCloseHeader = () => {
    props.logout();
    props.history.push('/login');
    window.location.reload();
  };

  const handleApproveTerms = () => {
    setUpdating(true);
    props
      .acceptTermsOfAgreement(terms)
      .then(() => {
        props.handledTermsOfAgreement();
        setUpdating(false);
        setShow(false);
        if (props.history.location.pathname === '/create-profile') {
          props.history.push('/');
        }
      })
      .catch(err => {
        console.log(err);
        setUpdating(false);
      });
  };

  const renderTerm = (term, index) => {
    const id = `term__item-${index}`;
    const parsedDate = parseDate(term.lastEdit);

    const scrollCheck = e => {
      const el = document.getElementById(id);
      const atBottom = el.scrollHeight - el.scrollTop - 25 <= el.clientHeight;
      // console.log(el.scrollHeight - el.scrollTop, el.clientHeight);

      if (atBottom) {
        setAtBottom(atBottom);
      }
    };

    const content = displayCurrentLangObj(
      term.content,
      language.currentLanguage,
    );

    return (
      <div className='term__item' key={index}>
        <Typography type='h2'>
          {displayCurrentLangObj(term.title, language.currentLanguage)}
        </Typography>
        <div className='content' id={id} onScroll={scrollCheck}>
          {parse(content.replace(/<p><\/p>/g, '<br/>'))}
        </div>
        {/* <FormText>
          {i18next.t(6401)}: {term.version}
        </FormText> */}
        <FormText>
          {i18next.t(174)}: {parsedDate}
        </FormText>
      </div>
    );
  };

  const renderTerms = () => {
    if (loading) {
      return <PageSpinner />;
    }

    return (
      <div className='terms__wrapper'>
        <div className='terms__container'>{terms.map(renderTerm)}</div>
        <Button
          onClick={handleApproveTerms}
          color='btnPrimary'
          className='float-right'
          disabled={!atBottom}
        >
          {updating ? <Spinner /> : i18next.t(1)}
        </Button>
      </div>
    );
  };
  return (
    <div className={extraClass}>
      <Modal
        isOpen={show}
        toggle={() => setShowCloseModal(!showCloseModal)}
        fade={false}
        size='lg'
        centered
        backdrop='static'
      >
        <ModalHeader toggle={() => setShowCloseModal(!showCloseModal)}>
          {i18next.t(6400)}
        </ModalHeader>
        <ModalBody>{renderTerms()}</ModalBody>
      </Modal>
      <ConfirmModal
        header={i18next.t(1052)}
        show={showCloseModal}
        toggle={() => setShowCloseModal(!showCloseModal)}
        confirmFunc={() => setShowCloseModal(!showCloseModal)}
        cancelFunc={handleCloseHeader}
        color='btnPrimary'
        confirmText={i18next.t(0)}
        cancelText={i18next.t(12)}
      >
        <Typography>{i18next.t(6405)}</Typography>
      </ConfirmModal>
    </div>
  );
};

function mapStateToProps({ common, language }) {
  return {
    common,
    language,
  };
}

export default connect(mapStateToProps, {
  getUserTermsOfAgreement,
  acceptTermsOfAgreement,
  handledTermsOfAgreement,
  logout,
})(TermsOfAgreementModal);
