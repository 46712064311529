import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

// Components
import PageSpinner from '../PageSpinner';

const InfoModal = props => {
  const { showActionSpinner } = props;

  let toggleAction = !showActionSpinner ? props.toggle : null;

  return (
    <Modal
      isOpen={props.show}
      toggle={toggleAction}
      fade={false}
      size={props.size}
      centered
    >
      {props.header && (
        <ModalHeader toggle={toggleAction}>{props.header}</ModalHeader>
      )}
      <ModalBody>
        {showActionSpinner && (
          <PageSpinner color='primary' extraClass='margin-bot-15px' />
        )}
        {props.children}
      </ModalBody>
      {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
    </Modal>
  );
};

export default InfoModal;
